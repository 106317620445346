import { User } from "../../domain/User";
import { UserDTO } from "./UserDTO";

export function APIUserToDomainMapper(apiDto: UserDTO | null): User {
    return {
        id: apiDto?.userId ?? "",
        name: apiDto?.userDisplayName ?? "",
        email: apiDto?.userEmail ?? "",
        employeeID: apiDto?.userEmployeeID ?? "",
    }
}