import "./MobileSubSidebar.css";

import { Avatar } from "../avatar/Avatar";
import { ContextualMenu } from "../contextual-menu/ContextualMenu";
import { CssClassnameBuilder } from "../../utils/CssClassnameBuilder";
import { MenuItem } from "./MenuItem";
import { ReactComponent as MoreActions } from "../../assets/icons/more-actions.svg";
import { ScalableIconButton } from "../buttons/ScalableIconButton";
import { SubSideBarButton } from "./SubSideBarButton";
import { useMemo } from "react";
import { useWindowResize } from "../../infrastructure/ui/UIServices";

export interface SubSidebarUser {
  name: string;
  jobTitle: string;
}

export interface ISideBarProps {
  className?: string;
  buttons: MenuItem[];
  user?: SubSidebarUser;
}

export function MobileSubSidebar(props: ISideBarProps) {
  const windowResize = useWindowResize()
  const sidebarCss = useMemo(() => {
    return CssClassnameBuilder.new()
      .add("mobile-sub-side-bar")
      .addConditional(props.className, props.className)
      .build();
  }, [props.className]);

  const renderUserInfo = useMemo(() => {
    if (props.user && props.buttons.length < 5)
      return <div className="sub-side-bar-user">
        <Avatar name={props.user.name} className="user-avatar" />
      </div>;
  }, [props.user, props.buttons]);

  const renderSideBarButtons = useMemo(() => {
    const visibleButtons = windowResize < 425 ? (props.buttons.length > 5 ? props.buttons.slice(0, 4) : props.buttons) : props.buttons;

    return (
      <div className="sub-side-bar-btns">
        {visibleButtons.map((m, idx) => (
          <SubSideBarButton
            key={idx}
            icon={m.icon}
            navigateTo={m.navigateTo}
            name={m.name}
          />
        ))}
        {windowResize < 425 && props.buttons.length > 5 && (
          <ContextualMenu items={
            props.buttons.slice(4).map((m, idx) => (
              {
                key: idx,
                label: m.name,
                navigateTo: m.navigateTo,
                icon: m.icon,
              }
            ))}>
            <ScalableIconButton icon={<MoreActions />} size={30} />
          </ContextualMenu>
        )}
      </div>
    );
  }, [props.buttons, windowResize]);

  return <div className={sidebarCss}>
    {renderUserInfo}
    {renderSideBarButtons}
  </div>;
}
