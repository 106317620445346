import "./AddInfoAttachments.css";

import { IResponsiveDataTableProps, ResponsiveDataTable } from "../../../lib/components/table/ResponsiveDataTable";
import { usePopup, useWindowResize } from "../../../lib/infrastructure/ui/UIServices";

import { CardContainer } from "../../../lib/layouts/containers/card/CardContainer";
import { CssClassnameBuilder } from "../../../lib/utils/CssClassnameBuilder";
import { Loader } from "../../../lib/components/loader/Loader";
import { Spacer } from "../../../lib/components/separator/Spacer";
import { TextButton } from "../../../lib/components/buttons/TextButton";
import { ViewAttachmentsPopup } from "../../evaluations/pages/popups/ViewAttachmentsPopup";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { useMemo } from "react";

interface IAddInfoProps<T> {
  className?: string;
  title?: string;
  tableInfo?: IResponsiveDataTableProps<T>;
  buttonType?: "normal" | "file-upload";
  isLoading: boolean;
  locationId: number | undefined;
  evaluationPeriodId: string | undefined;
}


export function AddInfoAttachments<T>(props: IAddInfoProps<T>) {
  const openPopup = usePopup();
  const windowResize = useWindowResize();

  const renderTable = useMemo(() => {
    if (props.tableInfo?.items.length) {
      return (
        <ResponsiveDataTable
        
          className=""
          items={props.tableInfo.items.slice(0, 2)}
          totalitems={props.tableInfo.totalitems}
          columnDefinitions={props.tableInfo.columnDefinitions}
          isLoading={props.tableInfo.isLoading}
          onClickRow={props.tableInfo.onClickRow}
        />
      );
    }
  }, [props]);


  const addInfoCss = useMemo(() => {
    return CssClassnameBuilder.new()
      .add("add-info")
      .addConditional(props.className, props.className)
      .build();
  }, [props.className]);

  return (
    <div className={addInfoCss}>
      <CardContainer>
        {props.isLoading ? <Loader /> :
          <>
            <div className="add-info-header">
              <div className="subtitle">
                {translate("EVALUATIONS.EvaluationAttachments")}
              </div>
              {props.tableInfo && (props.tableInfo.items.length > 2) ?
                <TextButton
                  text={translate("EVALUATIONS.ViewAllAttachments")}
                  type="primary"
                  // icon={<ArrowRightIcon />}
                  onClick={() => {
                    openPopup(
                      <ViewAttachmentsPopup
                        evaluationPeriodId={props.evaluationPeriodId}
                        locationId={props.locationId}
                        isLoading={props.isLoading}
                        tableInfo={props.tableInfo}
                        className={props.className}
                      />
                    );
                  }}
                />
                : null}
            </div>
            {windowResize <= 768 ? <Spacer mode="vertical" px={10} /> : null}
            <div className="add-info-content">{renderTable}</div>
          </>}
      </CardContainer>
    </div>
  );
}
