export enum EvaluationStatusEnum {
    REJECTED = 1,
    CONFIRMED = 2,
    ONGOING = 3,
    READYTOEVALUATE = 4,
    CALIBRATED = 5,
}

export const EvaluationStatusTranslationTerms: { [key: string]: string } = {
    1: "PERIODS.STATUS.Rejected",
    2: "PERIODS.STATUS.Confirmed",
    3: "PERIODS.STATUS.OnGoing",
    4: "PERIODS.STATUS.ReadyToEvaluate",
    5: "PERIODS.STATUS.Calibrated",
}

export const EvaluationStatusAsEnum: { [key: number]: EvaluationStatusEnum } = {
    1: EvaluationStatusEnum.REJECTED,
    2: EvaluationStatusEnum.CONFIRMED,
    3: EvaluationStatusEnum.ONGOING,
    4: EvaluationStatusEnum.READYTOEVALUATE,
    5: EvaluationStatusEnum.CALIBRATED,
};


