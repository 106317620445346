import { ConfigurationProvider } from "../lib/infrastructure/configuration/ConfigurationProvider";
import { HttpClient } from "../lib/infrastructure/http/HttpClient";
import { LocationGroupType, } from "../models/enums/LocationGroupType";
import { LocationGroupsResponseDTO } from "../models/api/access-groups/LocationGroupsResponseDTO";
import { UpdateLocationGroupAccessRequestDTO } from "../models/api/access-groups/UpdateLocationGroupAccessRequestDTO";

const Route = (path: string) => {
    return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};

interface LocationGroupItem {
    name: string;
    objectId: string;
}
export interface LocationGroups {
    locationAdmin: LocationGroupItem,
    locationAccess: LocationGroupItem,
    humanResources: LocationGroupItem,
    evaluator: LocationGroupItem
}

export interface UpdateLocationGroupItem {
    type: LocationGroupType;
    objectId: string;
}
export interface UpdateLocationGroups {
    locationAdmin: UpdateLocationGroupItem,
    locationAccess: UpdateLocationGroupItem,
    humanResources: UpdateLocationGroupItem,
    evaluator: UpdateLocationGroupItem
}



export class AccessGroupsService {

    getLocationGroups(locationId: string): Promise<LocationGroups> {
        return HttpClient.sessionRequest<LocationGroupsResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/LocationGroups`),
        })
            .then((response) => {

                const mapGroup = (type: LocationGroupType): LocationGroupItem => {
                    let res = response.data.locationGroups.find(p => p.type === type);
                    if (res) {
                        return ({ name: res.aadName, objectId: res.aadGroupId });
                    }
                    return ({ name: "", objectId: "" })
                }

                var lg: LocationGroups = {
                    locationAdmin: mapGroup(LocationGroupType.LOCATION_ADMIN),
                    humanResources: mapGroup(LocationGroupType.HUMAN_RESOURCES),
                    evaluator: mapGroup(LocationGroupType.EVALUATOR),
                    locationAccess: mapGroup(LocationGroupType.ACCESS)
                }

                return lg;

            }).catch((error) => {
                throw error;
            });
    }


    updateLocationGroup(locationId: string, accessGroups: UpdateLocationGroups): Promise<void> {

        var request: UpdateLocationGroupAccessRequestDTO = {
            locationGroupAccesses: []
        };

        const handleGroup = (g: UpdateLocationGroupItem) => {
            if (g.objectId) request.locationGroupAccesses.push({ type: g.type, AADGroupId: g.objectId })
        }

        handleGroup(accessGroups.locationAdmin);
        handleGroup(accessGroups.humanResources);
        handleGroup(accessGroups.evaluator);
        handleGroup(accessGroups.locationAccess);

        return HttpClient.sessionRequest({
            method: "PUT",
            url: Route(`/api/v1/Locations/${locationId}/LocationGroups`),
            data: request
        })
            .then((_) => {
            })
            .catch((error) => {
                throw error;
            });
    }
}
