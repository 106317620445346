import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import { AdminContainer } from "../common/layouts/content/AdminContainer";
import { AdminMainLayout } from "../common/layouts/admin-main-layout/AdminMainLayout";
import { AdministrationModule } from "./App.RoutingModule.AdministrationModule";
import { AppMainLayout } from "../common/layouts/app-main-layout/AppMainLayout";
import { BonusModule } from "./App.RoutingModule.BonusModule";
import { MainContainer } from "../common/layouts/content/MainContainer";
import { PermissionNavigate } from "../lib/infrastructure/authorization/PermissionNavigate";
import { Permission } from "../models/api/gate/Permission";
import { CollaboratorModule } from "./App.RoutingModule.CollaboratorModule";

const MainTemplate = () => (
  <AppMainLayout>
    <MainContainer>
      <Outlet />
    </MainContainer>
  </AppMainLayout>
);

const AdminTemplate = () => (
  <AdminMainLayout>
    <AdminContainer>
      <Outlet />
    </AdminContainer>
  </AdminMainLayout>
);

export const accessPeriodsPermissions = [
  Permission.GLOBAL_ADMIN,
  Permission.LOCATION_ADMIN,
  Permission.HUMAN_RESOURCES,
  Permission.LOCATION_MANAGER
];


export function AppRoutingModule() {
  return (
    <Routes>
      <Route element={<MainTemplate />}>

        <Route path="/" element={<PermissionNavigate to="/bonus" fallbackTo="/me" permissions={accessPeriodsPermissions} />} />
        
        <Route path="/bonus/*" element={<BonusModule />} />
        <Route path="/me/*" element={<CollaboratorModule />} />
      </Route>

      <Route element={<AdminTemplate />}>
        <Route path="/settings/*" element={<AdministrationModule />} />
      </Route>
      <Route path="/*" element={<Navigate to="/notfound" />} />
    </Routes>
  );
}
