import { CutReasonDTO } from "../dtos/CutReasonDTO";
import { CutReasonDetails } from "../domain/CutReasonDetails";

export function APICutReasonToDomainMapper(apiDto: CutReasonDTO): CutReasonDetails {
    return {
        companyCutFactors: apiDto.companyCutFactors,
        employeeCutFactors: apiDto.employeeCutFactors,
        cutPercentage: apiDto.cutPercentage,
        cutReasonId: apiDto.cutReasonId,
        nameEn: apiDto.nameEn,
        namePt: apiDto.namePt,
        isDisabled: apiDto.isDisabled
        }
}