import { Tag } from "../tag/Tag";
import { Tooltip } from "../tooltip/Tooltip";
import "./InfoTag.css";

interface InfoTagProps {
    tagColor: "status-red"
    | "status-blue"
    | "status-yellow"
    | "status-light-grey"
    | "status-green"
    | "status-lime-green"
    | "status-secondary-orange"
    | "status-secondary-green"
    tagContent: string
    value: string | "";
    icon: React.ReactNode;
    tooltipText: string;
}

export function InfoTag(props: InfoTagProps) {
    return (
        <div className="info-tag">
            <Tag text={props.tagContent} backgroundColor={props.tagColor} isTiny />
            <div className="info-tag-value">{props.value}</div>
            <Tooltip text={props.tooltipText}>      {props.icon} </Tooltip>
        </div>
    );
}
