import { achievementPercentage, decimalsAndZero, regexIntegerOrDecimals, validateRegex, weigthPercentageExpression } from "../../../../../lib/validators/ValidateRegex";

import { FormFieldTextInput } from "../../../../../lib/components/form/form-field/FormFieldTextInput";
import { translate } from "../../../../../lib/infrastructure/i18n/InternationalizationService";
import { useEffect } from "react";
import { useFormControl } from "../../../../../lib/components/form/Form";
import { validateWhiteSpaceStrings } from "../../../../../lib/validators/ValidateWhiteSpaceStrings";
import { replaceCommaByPoint } from "../../../../../common/helpers/replaceCommaByPoint";

interface INameFieldProps {
    value: string;
    onValueChanged: (value: string | undefined, isValid: boolean) => void;
}

export function NameField(props: INameFieldProps) {

    const valueFieldFormControl = useFormControl<string>({
        initialValue: props.value,
        validators: [validateWhiteSpaceStrings()],
        enableAutoValidate: true,
    });


    useEffect(() => {
        valueFieldFormControl.validate()
    }, []);


    useEffect(() => {
        props.onValueChanged(valueFieldFormControl.value, valueFieldFormControl.isValid)
    }, [valueFieldFormControl.value, valueFieldFormControl.isValid])

    return (
        <FormFieldTextInput formControl={valueFieldFormControl} placeholder={translate("PERIODS.CRITERIAS.NamePlaceholder")} alternativeStyle />
    );
}


interface IDescriptionFieldProps {
    value: string;
    onValueChanged: (value: string | undefined, isValid: boolean) => void;
}

export function DescriptionField(props: IDescriptionFieldProps) {

    const valueFieldFormControl = useFormControl<string>({
        initialValue: props.value,
        validators: [validateWhiteSpaceStrings()],
        enableAutoValidate: true,
    });


    useEffect(() => {
        valueFieldFormControl.validate()
    }, []);


    useEffect(() => {
        props.onValueChanged(valueFieldFormControl.value, valueFieldFormControl.isValid)
    }, [valueFieldFormControl.value, valueFieldFormControl.isValid])

    return (
        <FormFieldTextInput formControl={valueFieldFormControl} placeholder={translate("PERIODS.CRITERIAS.DescriptionPlaceholder")} alternativeStyle />
    );
}



interface IWeightFieldProps {
    value: number | undefined;
    onValueChanged: (value: number | undefined, isValid: boolean) => void;
    className?: string;
}

export function WeightField(props: IWeightFieldProps) {

    const valueFieldFormControl = useFormControl<string>({
        initialValue: "" + props.value,
        validators: [validateRegex(weigthPercentageExpression, false, "PERIODS.VALIDATIONS.WeigthPercentage")],
        enableAutoValidate: true,
    });


    useEffect(() => {
        valueFieldFormControl.validate();
    }, []);



    useEffect(() => {
        if (!valueFieldFormControl.value) return;

        var parsedValue = parseFloat(valueFieldFormControl.value.replace(',', '.'));

        if (Number.isNaN(parsedValue))
            props.onValueChanged(0, false);
        else
            props.onValueChanged(parsedValue, valueFieldFormControl.isValid)
    }, [valueFieldFormControl.value, valueFieldFormControl.isValid])

    return (
        <FormFieldTextInput className={props.className} formControl={valueFieldFormControl} alternativeStyle />
    );
}



interface ITargetFieldProps {
    value: number | undefined;
    onValueChanged: (value: number | undefined, isValid: boolean) => void;
    className?: string;
}


export function TargetValueField(props: ITargetFieldProps) {

    const valueFieldFormControl = useFormControl<string>({
        initialValue: "" + props.value,
        validators: [validateRegex(decimalsAndZero, false, "COMMON.FORM.VALIDATIONS.PositiveNumberOrDecimal")],
        enableAutoValidate: true,
    });


    useEffect(() => {
        valueFieldFormControl.validate()
    }, []);


    useEffect(() => {
        if (!valueFieldFormControl.value) return;

        var parsedValue = parseFloat(valueFieldFormControl.value.replace(',', '.'));

        if (Number.isNaN(parsedValue))
            props.onValueChanged(0, false);
        else
            props.onValueChanged(parsedValue, valueFieldFormControl.isValid)
    }, [valueFieldFormControl.value, valueFieldFormControl.isValid])

    return (
        <FormFieldTextInput className={props.className} formControl={valueFieldFormControl} placeholder={translate("PERIODS.CRITERIAS.TargetValueV2Placeholder")} alternativeStyle /* className="small-input" */ />
    );
}




interface ICurrentFieldProps {
    value: number | undefined;
    onValueChanged: (value: number | undefined, isValid: boolean) => void;
    className?: string;
}

export function CurrentValueField(props: ICurrentFieldProps) {

    const valueFieldFormControl = useFormControl<string>({
        initialValue: "" + props.value,
        validators: [validateRegex(decimalsAndZero, false, "COMMON.FORM.VALIDATIONS.ZeroOrDecimalNumber")],
        enableAutoValidate: true,
    });

    useEffect(() => {

        valueFieldFormControl.validate()
    }, []);

    useEffect(() => {

        if (!valueFieldFormControl.value) return;

        var parsedValue = parseFloat(valueFieldFormControl.value.replace(',', '.'));


        if (Number.isNaN(parsedValue))
            props.onValueChanged(0, false);
        else
            props.onValueChanged(parsedValue, valueFieldFormControl.isValid);
    }, [valueFieldFormControl.value, valueFieldFormControl.isValid])

    return (
        <FormFieldTextInput className={props.className} formControl={valueFieldFormControl} placeholder={translate("PERIODS.CRITERIAS.CurrentValuePlaceholder")} alternativeStyle /* className="small-input" */ />
    );
}

interface IAchievementPercentageFieldProps {
    value: number | undefined;
    onValueChanged: (value: number | undefined, isValid: boolean) => void;
    className?: string;
}



export function AchievementPercentageField(props: IAchievementPercentageFieldProps) {

    const valueFieldFormControl = useFormControl<string>({
        initialValue: "" + props.value,
        validators: [validateRegex(weigthPercentageExpression, false, "PERIODS.VALIDATIONS.WeigthPercentage")],
        enableAutoValidate: true,
    });



    useEffect(() => {
        valueFieldFormControl.validate()
    }, []);

    useEffect(() => {

        if (!valueFieldFormControl.value) return;

        var parsedValue = parseFloat(valueFieldFormControl.value.replace(',', '.'));


        if (Number.isNaN(parsedValue))
            props.onValueChanged(0, false);
        else
            props.onValueChanged(parsedValue, valueFieldFormControl.isValid);
    }, [valueFieldFormControl.value, valueFieldFormControl.isValid])

    return (
        <FormFieldTextInput className={props.className} formControl={valueFieldFormControl} placeholder={translate("PERIODS.CRITERIAS.AchievementPlaceholder")} alternativeStyle /* className="small-input" */ />
    );
}



interface IStartValueFieldProps {
    value: number | undefined;
    onValueChanged: (value: number | undefined, isValid: boolean) => void;
    className?: string;
}

export function StartValueField(props: IStartValueFieldProps) {

    const valueFieldFormControl = useFormControl<string>({
        initialValue: "" + props.value,
        validators: [validateRegex(decimalsAndZero, false, "COMMON.FORM.VALIDATIONS.ZeroOrDecimalNumber")],
        enableAutoValidate: true,
    });

    useEffect(() => {
        valueFieldFormControl.validate()
    }, []);

    useEffect(() => {
        if (!valueFieldFormControl.value) return;

        var parsedValue = parseFloat(valueFieldFormControl.value.replace(',', '.'));

        if (Number.isNaN(parsedValue))
            props.onValueChanged(0, false);
        else
            props.onValueChanged(parsedValue, valueFieldFormControl.isValid);
    }, [valueFieldFormControl.value, valueFieldFormControl.isValid])

    return (
        <FormFieldTextInput className={props.className} formControl={valueFieldFormControl} placeholder={translate("PERIODS.CRITERIAS.StartValueV2Placeholder")} alternativeStyle /* className="small-input" */ />
    );
}
