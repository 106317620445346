import { ILanguage } from "./ILanguage";

export const languagePT: ILanguage = {
  COMMON: {
    ProjectName: "Bonus",
    Continue: "Continuar",
    Yes: "Sim",
    No: "Não",
    Cancel: "Cancelar",
    Create: "Criar",
    Add: "Adicionar",
    Import: "Importar",
    Change: "Alterar",
    Suspend: "Suspender",
    Save: "Guardar",
    Edit: "Editar",
    Delete: "Apagar",
    Remove: "Remover",
    Deactivate: "Desativar",
    Activate: "Ativar",
    Logout: "Terminar Sessão",
    Optional: "Opcional",
    Registar: "Registar",
    PrepositionForDateTime: " a ",
    CreatedBy: "Registado por",
    CreatedOn: "Registada a",
    All: "Todos",
    Clear: "Limpar",
    Deliver: "Entregar",
    OtherActions: "Outras ações",
    CloneAction: "Clonar Amostra",
    SelectOneOptionPlaceholder: "Selecione uma opção",
    Export: "Exportar",
    Weight: "Peso",
    Confirm: "Confirmar",
    Name: "Nome",
    Upload: "Upload",
    Status: "Estado",
    SaveAll: "Guardar todos",
    ImportCollaborators: "Importar colaboradores",
    DeleteCAPSLOCK: "Apagar",
    Saved: "Informação alterada.",
    TestEnvironment: "Ambiente de Testes",

    ERRORS: {
      ErrorSavingValues: "Ccorreu um problema ao guardar os dados. Por favor verifique avaliações que não foram alteradas."
    },

    POPUPS: {
      Download: "Download",
      ImportOptions: "Opções de Importação",
      ExcelTemplate: "Template Excel",
      PdfTemplate: "Template PDF",
      PdfTemplateText: "Clique no botão abaixo para fazer o download de um ficheiro modelo. Este é um exemplo de orientação para avaliação de desempenho para os gestores de equipa",
      ExcelTemplateText: "Clique no botão abaixo para fazer o download de um ficheiro modelo de Excel. Este modelo contém a estrutura correta para a importação de dados.",
      Import: "Importar",
      ImportText: "Clique no botão abaixo para selecionar um ficheiro Excel e iniciar o processo de importação. Certifique-se que seleciona o ficheiro correto.",
      ImportEvaluationAttachs: "Clique no botão abaixo para importar um ficheiro. O ficheiro será exibido para todos os utilizadores com permissão na área de avaliações.",
      SelectDate: "Selecione uma data"
    },

    FORM: {
      Details: "Detalhes",
      Search: "Pesquisar",
      SearchPlaceholder: "Pesquisar",
      InitialDate: "Data Inicial",
      InitialDatePlaceholder: "Data Inicial",
      InitialCreationDatePlaceholder: "Data Inicial de Criação",
      FinalDate: "Data Final",
      FinalDatePlaceholder: "Data Final",
      FinalCreationDatePlaceholder: "Data Fim de Criação",
      RequestDatePlaceholder: "Selecione uma data",
      DefaultPlaceholder: "Digite Aqui",
      SelectDefaultPlaceholder: "Digite para pesquisar",
      SelectStatusPlaceholder: "Selecione estados",
      Filters: "Filtros",
      CleanFilters: "Limpar Filtros",
      ClearSelection: "Limpar seleção",
      Sunday: "Do",
      Monday: "Se",
      Tuesday: "Te",
      Wednesday: "Qa",
      Thursday: "Qi",
      Friday: "Sx",
      Saturday: "Sa",

      VALIDATIONS: {
        CannotBeEmpty: "Este campo não pode estar vazio.",
        RequiredField: "Este campo é obrigatório.",
        GuidValidator: "Formato GUID inválido.",
        SelectOneOption: "Selecione uma opção.",
        BatchFormatError: "Deverá conter o seguinte formato: ####### ou #######/# ou #######/##",
        FillMandatoryFields: "Por favor preencha os campos obrigatórios!",
        InvalidLocationId: "O ID da localização é inválido.",
        InvalidRequestId: "O ID do pedido é inválido.",
        PositiveNumber: "Insira um número inteiro",
        PositiveNumberOrDecimal: "Insira um número positivo / decimal",
        SelectDate: "Por favor selecione uma data",
        ZeroOrDecimalNumber: "Insira um número inteiro / decimal",

        
      }
    },

    ERRORPAGES: {
      SomethingWrong: "Ocorreu um erro",
      BackToHome: "Página Inicial",
      AccessDenied: "Acesso Negado",
      AccessDeniedText1: "Não tem permissão para ver esta página!",
      AccessDeniedText2: "Por favor, verifique as suas credenciais e tente novamente.",
      PageNotFound: "Página Não Encontrada",
      PageNotFoundText: "Não conseguimos encontrar a página que procura.",
      SessionExpired: "Sessão Expirada",
      SessionExpiredText: "A sessão expirou devido à sua inatividade",
      Login: "Login",
    },

    SYSTEMPOPUPS: {
      Error: "Erro!",
      ErrorText: "",
      Success: "Sucesso!",
      SuccessText: "",
      Warning: "Aviso!",
      WarningText: "",
    },

    TABLE: {
      NoResults: "Nenhum resultado encontrado",
      NoResultsText: "Não encontramos o que procura, por favor procure novamente",
      RowsPerPage: "Items por pág.",
      Of: "de",
    }
  },

  PERIODS: {
    Period: "Período",
    RegisteredPeriodMessage: "Este Período de avaliação encontra-se na fase de definição pela administração, por isso, as avaliações ainda não se encontram disponíveis.",
    EvaluationPeriods: "Períodos de Avaliação",
    EvaluationPeriodDetails: "Detalhes do Período de Avaliação",
    EditEvaluationPeriod: "Editar Período de Avaliação",
    ClonePeriod: "Clonar Período de Avaliação",
    CreatePeriod: "Criar Período",
    CreateEvaluationPeriod: "Criar Período de Avaliação",
    SimilarPreviousPeriod: "Similar ao período anterior",
    Cancel: "Cancelar",
    Activate: "Ativar",
    Close: "Fechar",
    Matrix: "Matriz",
    Managers: "Gestores",
    HoursAndAbsenceMap: "Mapa Horas/Faltas",
    Salaries: "Salários",
    EvaluationsAttachments: "Manuais Apoio",
    Reopen: "Reabrir",
    SubTotalAchieved: "Subtotal atingido",

    INFOANDFORM: {

      SumFactorsWarning: "Somatório do peso dos factores de Empresa e do Colaborador deve ser 100%",
      EvaluationPeriodData: "Dados do Período de Avaliação",
      Name: "Nome",
      NamePlaceholder: "Ex: 1º Trimestre 2023",
      InitialDate: "Data Inicial",
      InitialDatePlaceholder: "Selecione uma data inicial",
      FinalDate: "Data Final",
      FinalDatePlaceholder: "Selecione uma data final",
      CompanyEvaluationFactors: "Fatores de Avaliação da Empresa",
      EmployeeEvaluationFactors: "Fatores de Avaliação do Colaborador",
      Factor: "Fator",
      FactorPlaceholder: "Ex: Assiduidade",
      Description: "Descrição",
      DescriptionPlaceholder: "Escreva uma descrição do fator",
      Weight: "Peso",
      WeightPlaceholder: "Valor",
      Max: "Máx",
      MaxPlaceholder: "Valor",
      Min: "Min",
      MinPlaceholder: "Valor",
      PresentValue: "Valor Atual",
      PresentValuePlaceholder: "Valor",
      Evaluation: "Avaliação",
      EvaluationPlaceholder: "Valor",
      AddFactor: "Adicionar Fator",
      MonthlySalaryMultiplier: "Multiplicador Salário mensal",
      PeriodDays: "N.º Dias do Período",
      PeriodMonetaryCeilingPercentage: "Percentagem do valor máximo monetário do Período",
      PeriodDaysReduced: "N.º de Dias",
      MonthlySalaryMultiplierReduced: "Multiplicador do Salário",
      PeriodMonetaryCeilingPercentageReduced: "Valor Máx. Monetário (%)",
      PeriodMonetaryCeiling: "Valor Máx. Monetário",
      MinimumBonusPercentage: "Percentagem mínima para atingimento de Bónus",
      MinimumBonusPercentageV2: "Perc. mín. atingimento Bónus",
      SaveAllCompanyCriterias: "Guardar todos os fatores de empresa",
      SaveAllEmployeeCriterias: "Guardar todos os fatores de colaborador",

      InitialDayToEvaluate: "Primeiro dia para avaliar",
      FinalDayToEvaluate: "Último dia para avaliar",
      InitialDayToCalibrate: "Primeiro dia para calibrar",
      FinalDayToCalibrate: "Último dia para calibrar",

      DatesToNotificateManagersByEmail: "Datas para notificar Gestores de Equipa via e-mail",

      StartEvaluations: "Início Avaliações",
      EndEvaluations: "Fim Avaliações",
      
      StartCalibrations: "Início Calibrações",
      EndCalibrations: "Fim Calibrações"

    },

    VALIDATIONS: {
      WeigthPercentage: "Digite um número entre 0-100",
    },

    CRITERIAS: {
      Name: "Nome",
      NamePlaceholder: "Digite um nome",
      Description: "Descrição",
      DescriptionPlaceholder: "Digite uma descrição",
      WeightPercentage: "Peso (%)",
      WeightPercentagePlaceholder: "Digite o peso (%)",
      TargetValue: "Valor Objetivo",
      TargetValueV2: "Valor Máximo",
      TargetValueV2Placeholder: "Digite o valor máximo",
      StartValue: "Valor Inicial",
      StartValuePlaceholder: "Digite Valor Inicial",
      StartValueV2: "Valor Mínimo",
      StartValueV2Placeholder: "Digite o valor mínimo",
      TargetValuePlaceholder: "Digite o valor Objetivo",
      CurrentValue: "Valor atual",
      CurrentValuePlaceholder: "Digite o valor atual",
      Achievement: "Atingido",
      AchievementPlaceholder: "Digite a percentagem atingida (%)",
      Score: "Nota",
    },

    MESSAGES: {
      CreatePeriodSuccess: "Novo Período de Avaliação adicionado",
      EditPeriodSuccess: "Período de Avaliação Editado",
      PeriodCanceledSuccess: "Período de Avaliação Cancelado",
      PeriodAtivatedSuccess: "Período de Avaliação em Progresso",
      PeriodCompletedSuccess: "Período de Avaliação Concluído",
      PeriodReopenSuccess: "Período de Avaliação Reaberto",
      CreatedCompanyCriteriaSuccess: "Fator da Empresa criado",
      MultipleCompanyCriteriasUpdatedSuccess: "Fatores da Empresa atualizados",
      MultipleEmployeeCriteriasUpdatedSuccess: "Fatores de Colaborador atualizados",
      ChangedCompanyCriteriaSuccess: "Fator da Empresa alterado",
      DeleteCompanyCriteriaSucess: "Fator da Empresa removido",
      CreatedEmployeeCriteriaSuccess: "Fator de Colaborador criado",
      ChangedEmployeeCriteriaSuccess: "Fator de Colaborador alterado",
      DeleteEmployeeCriteriaSucess: "Fator de Colaborador removido",

    },

    STATUS: {
      Rejected: "Rejeitado",
      Confirmed: "Confirmado",
      OnGoing: "A Decorrer",
      ReadyToEvaluate: "Pronto a avaliar",
      Calibrated: "Calibrado"
    },
    STATUS2: {
      Canceled: "Cancelado",
      Completed: "Concluído",
      InProgress: "Em Progresso",
      Registered: "Registado",
    },

    POPUPS: {
      Cancel: "Cancelar",
      CancelEvaluationPeriodConfirmText: "Tem a certeza que quer cancelar o período de avaliação?",
      Close: "Fechar",
      CloseEvaluationPeriodConfirmText: "Tem a certeza que quer fechar o período de avaliação?",
      Activate: "Ativar",
      ActivateEvaluationPeriodConfirmText: "Tem a certeza que quer ativar o período de avaliação?",
      Reopen: "Reabrir",
      ReopenEvaluationPeriodConfirmText: "Tem a certeza que quer reabrir o período de avaliação?",
    }
  },

  HOURSANDABSENCE: {
    HoursAndAbsenceMap: "Mapa de Horas e Faltas",
    AbsenceMap: "Ausências",
    AddMultipleHoursRecordsSuccessMessage: "Novos Registos de Horas Adicionados",
    ViewAbsenceRecords: "Clique para consultar o registo de ausências",

    INFOANDFORM: {
      CollaboratorName: "Nome do Colaborador",
      AbsenteeismCode: "Código de Absentismo",
      AbsenceHours: "Horas de Ausência",
      AbsenceDays: "Dias de Ausência",
      CodeDescription: "Descrição da ausência",
      Problematic: "Problemático"
    },

    POPUPS: {
      ImportHoursMapConfirmText: "Reimportar 'Mapa de Horas e Ausências' irá substituir os dados existentes."
    }
  },

  SALARIES: {
    Salaries: "Salários",
    AddMultipleSalariesSuccessMessage: "Novos Salários Adicionados",

    INFOANDFORM: {
      CollaboratorName: "Nome do Colaborador",
      ValmetId: "Número Pessoal",
      EmployeeCode: "Código do Funcionário",
      QuarterSalary: "Salário do Trimestre",
      MonthlySalary: "Salário Mensal",
      MaxBonusCeiling: "Valor de Bónus Máximo",
      ConfidentialInformation: "Informação confidencial"
    },
  },

  ATTACHMENTS: {
    EvaluationAttachments: "Manuais de Apoio",

    INFOANDFORM: {
      FileName: "Nome do Ficheiro",
      AddedAttachmentBy: "Adicionado por",
      NewAttachmentAdded: "Novo anexo adicionado!",
      DeleteAttachmentSuccess: "Anexo removido",
    },

    POPUPS: {
      DeleteAttachment: "Remover",
      DeleteAttachmentConfirmText: "Tem a certeza que pretende remover o anexo? Os dados serão removidos permanentemente!",
      DeleteAttachmentSuccess: "Anexo removido.",
    },

    ACTIONS: {
      AddAttachments: "Adicionar Manuais de Apoio",
    },
  },

  
  EVALUATIONS: {
    Evaluation: "Avaliação",
    MyEvaluations: "As minhas avaliações",
    Evaluations: "Avaliações",
    BonusCutReasons: "Razões Cortar Bónus",
    EvaluationValidated: "Avaliação Validada!",
    EvaluationStatusUpdatedTo: "Estado da Avaliação alterado para {0}",
    MultipleEvaluationsStatusUpdatedTo: "Possíveis avaliações alteradas para {0}",
    EvaluationAttachments: "Manuais de Apoio",
    ViewAllAttachments: "Ver Todos",
    ManagerEvaluations: "Avaliações do Gestor de Equipa {0}",
    LoadingEvaluations: "A carregar as avaliações ...",
    NoEvaluationsFound: "Não foram encontradas avaliações",
    ProblematicAbsencesInfo: "Este colaborador tem ausências marcadas como problemáticas",
    SearchForPeriodName: "Pesquisar por nome do período",
    EvaluationsDelegatedTo: "Delegadas a {0}",
    EvaluationIndicators: "Indicadores de performance (avaliação individual)",

    PERFORMANCE: {
      Performance: "Desempenho",
      Exceptional: "Excepcional",
      VeryGood: "Muito Bom",
      Good: "Bom",
      Reasonable: "Razoável",
      ToImprove: "A melhorar",
      ExceptionalDescription: "Excepcional, excedeu todas as expectativas/objectivos em muitas áreas",
      VeryGoodDescription: "Muito Bom, atingiu todas as expectativas/objectivos ou excedeu parcialmente",
      GoodDescription: "Bom, atingiu a maioria das expectativas/objectivos",
      ReasonableDescription: "Razoável, atingiu parte das expectativas/objectivos",
      ToImproveDescription: "A melhorar, não atingiu as expectativas/objectivos"
    },

    INFOANDFORM: {
      SalaryUndefined: "Salário não definido",
      MidtermScoreTooltip: "Total dos factores de avaliação da Empresa + Total da avaliação individual",
      WeightedAverage: "Média ponderada da avaliação",
      RejectedReason: "Razão de Rejeição",
      Cut: "Corte",
      EmployeeFactorsScore: "Avaliação Individual",
      EmployeeFactorsScoreHeaderTooltip: "Avaliação Individual (baseado na média ponderada)",
      MidtermScore: "Avaliação Intermédia",
      PresentAttendanceRate: "Mostrar Taxa de Presença",
      PresentMidtermEvaluation: "Mostrar Avaliação Intermédia",
      CancelBonusCut: "Anular Corte do Bónus",
      EmployeeName: "Nome",
      Total: "Total",
      Bonus: "Bónus",
      CutBonus: "Cortar Bónus",
      RemoveEvaluation: "Remover Avaliação",
      BonusCut: "Bónus Cortado",
      Revert: "Reverter",
      Reject: "Rejeitar",
      Register: "Registar",
      Edit: "Editar",
      History: "Histórico",
      Employee: "Colaborador",
      Reason: "Justificação",
      Feedback: "Feedback",
      Comment: "Observação",
      BonusCutApplied: "Corte de Bónus aplicado",
      LastComment: "Última Observação",
      LastMessageToCalibrator: "Última mensagem para o Calibrador",
      RejectReasonPlaceholder: "Digite uma razão para a rejeição",
      RegisterFeedbackPlaceholder: "Digite o feedback",
      BonusCutReasonPlaceholder: "Digite uma razão para cortar o bónus",
      TotalEmployeeFactors: "Total fatores do colaborador",
      TotalAbsentismFactors: "Absentismo",
      TotalCompanyFactor: "Total fatores da empresa",
      TotalFinalScore: "Avaliação final",
      TotalFinalScoreHeaderTooltip: "Avaliação intermédia * Taxa de Presença * (100 - percentagem de corte)",
      TotalFinalScoreWithCutPercentage: "Avaliação final com Corte",
      Observations: "Observações",
      NeedsFeedback: "Deve registar feedback ao colaborador",
      QuarterlyMaximumValue: "Valor máximo trimestral",
      AbseentismHeaderTooltip: "Avaliação intermédia * Taxa de Presenca",
      Presence: "Presença",
      FeedbackToCollaborator: "Feedback ao Colaborador",
    },

    MESSAGES: {
      ScoreSavedSuccess: "Nota de avaliação guardada",
      MultipleScoresSavedSuccess: "Notas de avaliações guardadas"
    },

    STATUS: {
      Rejected: "Rejeitada",
      CutBonus: "Corte do Bónus",
      RejectEvaluation: "Rejeitar Avaliação",
      ConfirmEvaluation: "Confirmar Avaliação",
      CalibrateEvaluation: "Calibrar Avaliação",
      RevertCutBonus: "Reverter Corte do Bónus",
      AddFeedbackToEvaluation: "Adicionar Feedback à Avaliação",
      UpdateEvaluationStatus: "Atualizar Estado da Avaliação",
      UpdateEvaluationFactor: "Atualizar Fator da Avaliação",
      RevertEvaluationStatus: "Reverter Estado da Avaliação",
    },

    POPUPS: {
      NoComments: "Não foram adicionadas observações",
      NoFeedbackGiven: "Não foi dado Feedback ao Colaborador",
      FeedbackGivenToCollaborator: "Feedback dado ao Colaborador",
      FeedbackVisibleToCollaborator: "Feedback ficará visível para o Colaborador",
      FeedbackGivenInPerson:"Feedback dado ao colaborador presencialmente",
      RejectEvaluation: "Rejeitar Avaliação",
      CutBonus: "Cortar Bónus",
      RevertText: "Tem a certeza que pretende reverter o estado da avaliação?",
      History: "History",
      RejectEvaluationSuccessMessage: "Avaliação rejeitada",
      RegisterFeedback: "Feedback ao Colaborador",
      RegisterFeedbackSuccess: "Feedback registado!",
      CutEvaluationBonusSuccessMessage: "Bónus cortado com sucesso",
      CancelCutBonusSuccessMessage: "Corte do bónus anulado com sucesso",
      AddEvaluationCommentSuccessMessage: "Observação adicionada!",
      CancelCutBonusConfirmText: "Tem a certeza que pretende anular este corte do bónus?",
      DeleteEvaluationSuccess: "Avaliação removida.",
      RemoveEvaluationTitleCAPSLOCK: "Apagar Avaliação",
      DeleteEvaluationConfirmMessage: "Esta ação apaga permanentemente a avaliação deste colaborador e é irreversível. Tem a certeza que pretende apagar?",
      FeedbackWasGiven: "Feedback foi dado ao colaborador presencialmente"
    },

    HISTORY: {
      NoHistoryLines: "Ainda não há registos de histórico sobre esta avaliação.",
      HistoryLineForCuttingBonusWithoutJustification: "Bónus foi cortado com a razão '{0}'.",
      HistoryLineForCuttingBonusWithJustification: "Bónus foi cortado com a razão '{0}' e a justificação '{1}'.",
      HistoryLineForRejectingEvaluationWithoutJustification: "Avaliação foi rejeitada.",
      HistoryLineForRejectingEvaluationWithJustification: "Avaliação foi rejeitada com a justificação '{0}'.",
      HistoryLineForConfirmingEvaluation: "Avaliação foi confirmada.",
      HistoryLineForCalibratingEvaluation: "Avaliação foi calibrada.",
      HistoryLineForRevertingCutBonus: "Corte do Bónus foi cancelado.",
      HistoryLineForAddingFeedbackToEvaluation: "Feedback '{0}' foi registado.",
      HistoryLineForUpdatingEvaluationStatus: "Estado da Avaliação foi atualizado.",
      HistoryLineForUpdatingEvaluationFactor: "A percentagem do fator '{0}' foi atualizada do valor '{1}' para o valor '{2}'.",
      HistoryLineForRevertingEvaluationStatus: "Avaliação foi revertida.",
      HistoryLineForMarkingFeedbackAsGivenInPerson: "Feedback da avaliação marcado como 'dado pessoalmente'.",
      HistoryLineForMarkingFeedbackAsNotGivenInPerson: "Feedback da avaliação marcado como 'não dado pessoalmente'.",
    },

    COMMENTS: {
      Comments: "Observações",
      Comment: "Observação",
      InsertComment: "Digite a observação",
      MessagesToCalibrator: "Mensagens para Calibrador",
      MessagesVisibleToCalibrator: "Mensagens visíveis para o Calibrador",
      Message: "Mensagem",
      MessagePlaceholder: "Digite a mensagem",
      
    }
  },

  MANAGERS: {
    PeriodManagers: "Gestores de Equipa do Período",
    Managers: "Gestores de Equipa",
    AddManager: "Adicionar Gestor",
    AddManagerSuccessMessage: "Novo Gestor de Equipa adicionado",
    AddMultipleManagersSuccessMessage: "Novos Gestores de Equipa adicionados",
    DeleteManagerConfirmText: "Tem a certeza que pretende remover este Gestor de Equipa?",
    DeleteManagerSuccessMessage: "Gestor de Equipa Removido",
    Manager: "Gestor",
    Availability: "Disponibilidade",

    INFOANDFORM: {
      OtherManagerSelectedTitle: "Novo Gestor selecionado",
      SuggestedDelegationTitle: "Delegação sugerida pelo sistema",
      SelectedManager: "Gestor selecionado",
      SearchOtherPerson: "Se pretende delegar a outra pessoa, utilize a caixa de pesquisa abaixo",
      SearchOtherManager: "Pesquisar outro Gestor",
      DelegateEvaluations: "Delegar Avaliações",
      Superior: "Superior",
      DelegatedTo: "Delegado a",
      Delegations: "Delegações",
      Delegate: "Delegar",
      RemoveDelegation: "Remover Delegação",
      RemoveManager: "Remover Gestor",
      NoSuperiorFoundMessage: "Não foi encontrado um Superior direto de '{0}' para delegar.",
      NoSuggestionFound: "Não foram encontradas sugestões para delegar"
    },

    POPUPS: {
      RemoveDelegationConfirmText: "Tem a certeza que pretende remover esta delegação?",
      RemoveDelegationSuccessMessage: "Delegação removida!",
      DelegateManagerSuccess: "Gestor de Equipa delegado!",
    }
  },



  CUTREASONS: {
    CutReason: "Razão de Cortar Bónus",
    CutReasons: "Razões de Cortar Bónus",

    INFOANDFORM: {
      CreateCutReason: "Criar Razão de Cortar Bónus",
      EditCutReason: "Editar Razão de Cortar Bónus",
      CutReasonNamePlaceholder: "Digite o Nome",
      CutPercentagePlaceholder: "0 - 100",
      Name: "Nome",
      NamePT: "Nome (pt-PT)",
      NameEN: "Nome (en-EN)",
      CutPercentage: "Percentagem de Corte %",
      CutPercentageAbrev: "Corte (%)",
      CompanyFactors: "Fatores da Empresa",
      CompanyFactorsToCut: "Fatores da Empresa a cortar",
      EmployeeFactors: "Fatores do Colaborador",
      EmployeeFactorsToCut: "Fatores do Colaborador a cortar",
      CutFactors: "Fatores de Corte",
      Description: "Descrição",
      SelectCutReasonPlaceholder: "Selecione uma razão"
    },

    POPUP: {
      ActivateCutReason: "Ativar",
      ActivateCutReasonConfirmText: "Tem a certeza que pretende ativar esta razão de cortar bónus?",
      ActivateCutReasonSuccess: "Razão de cortar bónus ativada.",
      CreateCutReasonSuccess: "Razão de cortar bónus criada",
      Deactivate: "Desativar",
      DeactivateCutReasonConfirmText: "Tem a certeza que pretende desativar esta razão de cortar bónus?",
      DeactivateCutReasonSuccess: "Razão de cortar bónus desativada!",
      EditCutReasonSuccess: "Razão de cortar bónus editada!",
    },
  },

  GLOBALADMIN: {
    GlobalAdmin: "Administração global",
    LOCATIONS: {
      Locations: "Localizações",
      CreateLocation: "Criar Localização",
      AccessGroups: "Grupos de Acesso",

      INFO: {
        Name: "Nome",
        NumGroups: "Nº Grupos",
        Type: "Tipo",
      },

      POPUP: {
        Activate: "Ativar",
        ActivateConfirmText: "Tem a certeza que quer ativar a localização?",
        ActivateLocationError: "Erro ao ativar localização!",
        ActivateLocationSuccess: "Localização ativada!",
        CreateLocationSuccess: "Localização criada!",
        EditLocation: "Editar Localização",
        EditLocationError: "Erro ao editar localização!",
        EditLocationSuccess: "Localização editada!",
        DeactivateLocationError: "Erro ao desativar localização!",
        Deactivate: "Desativar",
        DeactivateConfirmText: "Tem a certeza que quer desativar a localização?",
        DeactivateLocationSuccess: "Location deactivated!"
      },

      ACCESSGROUPS: {
        Type: "Tipo",
        Name: "Nome",
      },
    },
    FACILITIES: {
      CreateFacility: "Criar Unidade",
      Facilities: "Unidades",
      INFOANDFORM: {
        Name: "Nome",
      },
      POPUP: {
        ActivateFacilityConfirmText: "Tem a certeza que quer ativar a unidade?",
        ActivateFacilitySuccess: "Unidade ativada!",
        ActivateFacilityError: "Erro ao ativar a unidade!",
        CreateFacilitySuccess: "Unidade criada!",
        DeactivateFacilityError: "Erro ao desativar unidade!",
        DeactivateFacilityConfirmText: "Tem a certeza que quer desativar a unidade?",
        DeactivateFacilitySuccess: "Unidade desativada!",
        Deactivate: "Desativar",
        EditFacilityError: "Erro ao editar a unidade!",
        EditFacility: "Editar Unidade",
        EditFacilitySuccess: "Unidade editada!",
        LoadingFacilityError: "Erro ao carregar a informação da unidade!"
      }
    },

    ACCESSGROUPS:
    {
      Type: "Tipo",
      ID: "ID",
      Name: "Nome",
      EditedGroupSuccess: "Grupos de acesso editados!",
      AccessGroups: "Grupos de Acesso",
      SubmitError: "Por favor, verifique os erros no formulário!",
      TYPES: {
        LocalAdmin: "Administrador Local",
        RH: "Recursos Humanos",
        TeamManager: "Gestor de Equipa",
        Collaborator: "Colaborador"
      }
    },

    EMPLOYEES:
    {
      Employees: "Lista de Colaboradores",
      EmployeesAddedSuccess: "Colaboradores e Gestores importados.",

      INFOANDFORM: {
        Name: "Nome",
        Email: "E-mail",
        JobTitle: "Cargo",
        Department: "Setor",
      },
    },

    ABSENCECODES: {
      AbsenceCodes: "Códigos de Absentismo",
      AddCode: "Adicionar Código",

      INFOANDFORM: {
        Code: "Código SAP",
        CodePlaceholder: "Ex: 5100",
        Description: "Descrição",
        DescriptionPlaceholder: "Ex: Baixa Médica",
        Problematic: "Problemático",
        ProblematicExplanation: "Quando o código SAP não menciona acidentes de trabalho por negligência ou não do trabalhador, etc",
        AbsenceCodeStatus: "Indicador do código de absentismo"
      },

      POPUPS: {
        Change: "Alterar",
        ChangeAbsenceCodeProblematicConfirmText: "Tem a certeza que quer alterar o indicador do código de absentismo para 'Problemático'?",
        ChangeAbsenceCodeIndicatorSuccess: "Indicador do código de absentismo alterado!",
        ChangeAbsenceCodeNotProblematicConfirmText: "Tem a certeza que quer alterar o indicador do código de absentismo para 'Não Problemático'?",
        CreateAbsenceCodeSuccess: "Código de absentismo criado!",
        DeleteAbsenceCodeSuccess: "Código de absentismo removido!",
      }
    }
  },

  BONUS: {
    Quarter: "Trimestre",
    StartDate: "Data Início",
    EndDate: "Data Fim",
    Status: "Estado",
    Quarter1: "Primeiro",
    Quarter2: "Segundo",
    Quarter3: "Terceiro",
    Quarter4: "Quarto",
    January: "Janeiro",
    April: "Abril",
    August: "Agosto",
    December: "Dezembro",

    ROLES: {
      Admin: "Administrador",
      Colaborator: "Colaborador",
      HR: "Recursos Humanos",
      Manager: "Gestor"
    },

    COMMON: {
      SearchPlaceholder: "Search",
      Name: "Name",
      Type: "Type"
    },
  }

};
