import "./AdminMainLayout.css";

import {
  InternationalizationProvider,
  LanguageLocale,
} from "../../../lib/infrastructure/i18n/InternationalizationProvider";
import { useCallback, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  usePopup,
  useSideBarButtons,
  useWindowResize,
} from "../../../lib/infrastructure/ui/UIServices";

import { ContextualMenuItems } from "../../../lib/components/contextual-menu/ContextualMenu";
import { ReactComponent as EndSessionSVG } from "../../../lib/assets/icons/end-session.svg";
import { ReactComponent as IconEN } from "../../../lib/assets/icons/en-icon.svg";
import { ReactComponent as IconPT } from "../../../lib/assets/icons/pt-icon.svg";
import { LangChangedEvent } from "../../../events/LangChangedEvent";
import { MobileNavBar } from "../../../lib/components/nav-bar/MobileNavBar";
import { NavBar } from "../../../lib/components/nav-bar/NavBar";
import { SplashScreen } from "../../pages/splash-screen/SplashScreen";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../lib/components/form/Form";
import { useGlobalLocation } from "../../../lib/infrastructure/location/LocationServices";
import { useIdentityIsProcessing } from "../../../lib/infrastructure/identity/hooks/useIdentityIsProcessing";
import { useIdentityUserDisplayName } from "../../../lib/infrastructure/identity/hooks/useIdentityUserDisplayName";
import { useIsAuthenticated } from "@azure/msal-react";
import { TestEnvironmentMessage } from "../../../lib/components/message-bar/TestEnvironmentMessage";
import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";

/***** ICONS ******/





interface IAppMainLayoutProps {
  children: React.ReactNode;
}

interface Location {
  name: string;
  id: string;
  isDisabled?: boolean;
}


export function AdminMainLayout(props: IAppMainLayoutProps) {
  const navigate = useNavigate();
  const routerLocation = useLocation();
  const isLogged = useIsAuthenticated();
  const windowResize = useWindowResize();
  const sideBarButtons = useSideBarButtons();
  const globalLocationID = useGlobalLocation();
  const isProcessing = useIdentityIsProcessing();
  const useIdentity = useIdentityUserDisplayName();

  const locationFormControl = useFormControl<Location>({});


  useEffect(() => {
    if (!isProcessing && !isLogged) {
      navigate("/identity/login");
      return;
    }
  }, []);


  const handleOnClickLanguage = useCallback((value: LanguageLocale) => {
    InternationalizationProvider.setCurrentLocale(value);
    LangChangedEvent.fireLanguageChanged();
  }, []);

  const isTestEnvironment = useMemo(() => {
    return ConfigurationProvider.getConfiguration().App.IsTestEnvironment;
  }, []);


  const languages: ContextualMenuItems[] = useMemo(() => [
    {
      label: "PT",
      icon: <IconPT />,
      navigateTo: "",
      onClick: () => {
        handleOnClickLanguage("pt-PT");
      },
    },
    {
      label: "EN",
      icon: <IconEN />,
      navigateTo: "",
      onClick: () => {
        handleOnClickLanguage("en-EN");
      },
    },
  ], [handleOnClickLanguage]);



  const accountInfo = useMemo(() => {
    return {
      info: {
        name: useIdentity.userDisplayName || "",
        email: useIdentity.userDisplayEmail || "",
      },
      menuItems: [
        {
          icon: <EndSessionSVG />,
          label: translate("COMMON.Logout"),
          navigateTo: "identity/logout",
        },
      ],
    };
  }, [useIdentity.userDisplayName, useIdentity.userDisplayEmail]);


  const renderNavBar = useMemo(() => {
    if (windowResize > 768)
      return <NavBar accountInfo={useIdentity.userDisplayName ? accountInfo : undefined} languages={languages} />;
    else
      return <MobileNavBar buttons={sideBarButtons} accountInfo={accountInfo} />;
  }, [
    windowResize,
    locationFormControl.hasErrors,
    locationFormControl.isDisabled,
    locationFormControl.value,
    languages,
    accountInfo,
    useIdentity.userDisplayName,
    sideBarButtons,
  ]);


  if (!globalLocationID && !routerLocation.pathname.includes("settings")) {
    return <SplashScreen />
  }


  return (
    <>
      {renderNavBar}
      {isTestEnvironment && <TestEnvironmentMessage />}
      <div className="content-wrapper">{props.children}</div>
    </>
  );
}
