import { useEffect, useState } from "react";
import { GateService } from "../../../services/GateService";
import { Permission } from '../../../models/api/gate/Permission';
import { useGlobalLocation } from "../location/LocationServices";
import { useNavigate } from "react-router-dom";

var gateSvc = new GateService()

export function useHasPermissions(permissions: Permission[]) {

  const globalLocation = useGlobalLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [hasPermission, setHasPermission] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    gateSvc.getUserPermissions().then(res => {

      if(!res) return; 

      const hasGeneralPermission = res.globalPermissions.find(p => permissions.includes(p))
        || res.locations.find(l => l.locationId === globalLocation)?.permissions.find(p => permissions.includes(p));

      if (hasGeneralPermission) {
        setHasPermission(true);
      }
      setIsLoading(false);
    }).catch(error => {
      setIsLoading(false);
      if (!error) return;
      navigate("/unavailable")
    });
  }, [globalLocation, permissions]);


  return { isLoadingPermissions: isLoading, hasPermission: hasPermission };
}
