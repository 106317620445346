

export function FinalScoreFromPercentage(percentage: number | undefined): number | undefined {
    if (percentage === undefined) { return undefined; }
    if (percentage < 26) {
        return 1;
    } else if (percentage < 51) {
        return 2;
    } else if (percentage < 71) {
        return 3;
    } else if (percentage < 90) {
        return 4;
    }
    return 5;
}

export function ScoreTagColor(value: number | undefined): "status-red" | "status-secondary-orange" | "status-yellow" | "status-secondary-green" | "status-green" {
    if (value === undefined) { return "status-red" }
    if (value < 26) {
        return "status-red";
    } else if (value < 51) {
        return "status-secondary-orange";
    } else if (value < 71) {
        return "status-yellow";
    } else if (value < 90) {
        return "status-secondary-green"
    }
    return "status-green";
}

