import "./RejectEvaluationPopup.css";

import { useCallback, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../lib/infrastructure/ui/UIServices";

import { AllValid } from "../../../../common/validators/ValidateFormControls";
import { CardContainer } from "../../../../lib/layouts/containers/card/CardContainer";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { EvaluationDetails } from "../../models/domain/EvaluationDetails";
import { EvaluationStatusEnum } from "../../models/domain/EvaluationStatusEnum";
import { EvaluationUpdateStatusDTO } from "../../models/dtos/EvaluationUpdateStatusDTO";
import { EvaluationsService } from "../../services/EvaluationsService";
import { FormContainer } from "../../../../lib/layouts/containers/form/FormContainer";
import { FormFieldTextArea } from "../../../../lib/components/form/form-field/FormFieldTextArea";
import { Notification } from "../../../../lib/components/notifications/Notification";
import { PopupActionButtons } from "../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../lib/layouts/containers/popup-header/PopupHeader";
import { Spacer } from "../../../../lib/components/separator/Spacer";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../../lib/components/form/Form";
import { useServiceCallPro } from "../../../../lib/hooks/useServiceCall";
import { validateWhiteSpaceStrings } from "../../../../lib/validators/ValidateWhiteSpaceStrings";
import { ConditionalRender } from "../../../../lib/functional/ConditionalRender";
import { FullScreenLoader } from "../../../../lib/components/loader/FullScreenLoader";

export interface IRejectEvaluationPopupProps {
    employeeName: string;
    locationId: number;
    evaluationPeriodId: string;
    isPeriodStatusInvalid: boolean,
    evaluationId: number;
    onRejectEvaluation: (freshEvaluation: EvaluationDetails) => void; 
}

const evaluationsService = new EvaluationsService();


export function RejectEvaluationPopup(props: IRejectEvaluationPopupProps) {
    const openPopup = usePopup();
    const closePopup = useClosePopup();
    const createNotification = useCreateNotification();

    const updateEvaluationStatusCall = useServiceCallPro(evaluationsService.updateEvaluationStatus);

    const reasonFormControl = useFormControl<string>({
        validators: [validateWhiteSpaceStrings()],
        enableAutoValidate: true,
        isDisabled: false,
    });


    const handleOnClickReject = useCallback(() => {
        if (!props.locationId || !props.evaluationPeriodId) return;

        if (!AllValid(reasonFormControl.validate())) return;
        
        var dto: EvaluationUpdateStatusDTO = {
            nextStatus: EvaluationStatusEnum.REJECTED,
            statusReason: reasonFormControl.value || ""
        };

        updateEvaluationStatusCall.invoke(`${props.locationId}`, props.evaluationPeriodId, `${props.evaluationId}`, dto)
            .then((data) => {
                closePopup();
                props.onRejectEvaluation(data); 
                createNotification(<Notification type="success" title={translate("COMMON.SYSTEMPOPUPS.Success")}
                text={translate("EVALUATIONS.POPUPS.RejectEvaluationSuccessMessage")}
                ></Notification>);
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
        });
    }, [closePopup, reasonFormControl.validate, reasonFormControl.value, props.evaluationId, props.evaluationPeriodId, props.locationId,
    createNotification, openPopup, updateEvaluationStatusCall.invoke]);

    
    return (
        <PopupContainer className="popup-reject-evaluation">
            <PopupHeader title={translate("EVALUATIONS.POPUPS.RejectEvaluation")} />
            <ConditionalRender if={updateEvaluationStatusCall.isLoading}> <FullScreenLoader/></ConditionalRender>
            <PopupContent>
                <div className="reject-evaluation">
                    <CardContainer className="employee-card">
                        <div className="small-copy employee-label">{translate("EVALUATIONS.INFOANDFORM.Employee")}</div>
                        <div className="employee-value">{props.employeeName}</div>
                    </CardContainer>
                    <FormContainer>
                        <FormFieldTextArea
                            formControl={reasonFormControl}
                            label={translate("EVALUATIONS.INFOANDFORM.Reason")}
                            placeholder={translate("EVALUATIONS.INFOANDFORM.RejectReasonPlaceholder")}
                        />
                    </FormContainer>
                </div>
                <Spacer mode={"vertical"} px="30" />
                <PopupActionButtons
                    buttons={[
                        {
                            text: translate("COMMON.Cancel"),
                            type: "tertiary",
                            onClick: () => closePopup(),
                        },
                        {
                            text: translate("EVALUATIONS.INFOANDFORM.Reject"),
                            className: "reject-btn",
                            type: "secondary",
                            onClick: handleOnClickReject,
                            isDisabled: props.isPeriodStatusInvalid
                        },
                    ]}
                />
            </PopupContent>
        </PopupContainer>
    );
}
