import "./Sidebar.css";

import { CssClassnameBuilder } from "../../utils/CssClassnameBuilder";
import { MenuItem } from "./MenuItem";
import { SideBarButton } from "./SideBarButton";
import { useMemo } from "react";

export interface ISideBarProps {
  className?: string;
  buttons: MenuItem[];
}


export function Sidebar(props: ISideBarProps) {

  const sidebarCss = useMemo(() => {
    return CssClassnameBuilder.new()
      .add("side-bar")
      .addConditional(props.className, props.className)
      .build();
  }, [props.className]);



  const sideBarButtons = useMemo(() => {
    return props.buttons.map((m, idx) => {
      if(m.isHidden) return null; 

      return (
        <SideBarButton
          key={idx}
          icon={m.icon}
          navigateTo={m.navigateTo}
          name={m.name}
          isBottomAligned={m.isBottomAligned}
        ></SideBarButton>
      );
    });
  }, [props.buttons]);






  return <div className={sidebarCss}>{sideBarButtons}</div>;
}
