import { APIEmployeesHoursRecordsDetailsToDomainMapper } from "../models/mappers/APIEmployeesHoursRecordsDetailsToDomainMapper";
import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { EmployeesHoursRecordsQueryOptions } from "../models/dtos/EmployeesHoursRecordsQueryOptions";
import { EmployeesHoursRecordsResponseDTO } from "../models/dtos/EmployeesHoursRecordsResponseDTO";
import { EmployeesHoursRecordsSummary } from "../models/domain/EmployeesHoursRecordsSummary";
import { HttpClient } from "../../../lib/infrastructure/http/HttpClient";
import qs from "qs";

const Route = (path: string) => {
    return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};

export class EmployeeHoursRecordsService {

    getEmployeesHoursRecords(locationId: string, periodId: string, queryOptions?: EmployeesHoursRecordsQueryOptions): Promise<EmployeesHoursRecordsSummary> {
        return HttpClient.sessionRequest<EmployeesHoursRecordsResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/EvaluationPeriods/${periodId}/EmployeesHoursRecords`),
            params: queryOptions,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" });
            },
        })
            .then((response) => {
                
                return ({
                    employeeHoursRecords: response.data.employeeHoursRecords.map(e => APIEmployeesHoursRecordsDetailsToDomainMapper(e)),
                    totalItems: response.data.totalItems
                })
            })
            .catch((error) => {
                throw error;
            });
    }



    uploadEmployeeHoursRecordsFromXLSX(locationId: string, periodId: string, file: File): Promise<void> {

        var formData = new FormData();
        formData.append("file", file)

        return HttpClient.sessionRequest({
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept": "application/json",
            },
            url: Route(`/api/v1/Locations/${locationId}/EvaluationPeriods/${periodId}/EmployeesHoursRecords/FromXLSX`),
            data: formData,
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }
}
