import "./Tag.css";

import { CssClassnameBuilder } from "../../utils/CssClassnameBuilder";
import { useMemo } from "react";

export interface ITagProps {
  text: string;
  icon?: React.ReactNode;
  className?: string;
  backgroundColor?:
    | "status-red"
    | "status-blue"
    | "status-yellow"
    | "status-light-grey"
    | "status-green"
    | "status-lime-green"
    | "status-secondary-orange"
    | "status-secondary-green"
  isTiny?: boolean;
}



export function Tag(props: ITagProps) {
  const tagCss = useMemo(() => {
    return CssClassnameBuilder.new()
      .add("tag")
      .addConditional(props.backgroundColor, props.backgroundColor)
      .addConditional(props.isTiny, "tiny-tag")
      .addConditional(props.icon && !props.isTiny, "has-icon")
      .addConditional(props.className, props.className)
      .build();
  }, [props.backgroundColor, props.icon, props.className, props.isTiny]);

  return (
    <div className={tagCss}>
      {props.icon && !props.isTiny ? (
        <div className="tag-icon">{props.icon}</div>
      ) : null}
      {props.text}
    </div>
  );
}
