import { DateTime } from "luxon";
import { IFormControl, Optional, ValidatorFunction } from "../../lib/components/form/Form";
import { translate } from "../../lib/infrastructure/i18n/InternationalizationService";


export function validateExternalDateTimeControl<A>(externalDateTimeControl: IFormControl<DateTime>): ValidatorFunction<Optional<A>> {
    return (value: A | undefined) => {
        if (externalDateTimeControl.value && !value) {
            return translate("COMMON.FORM.VALIDATIONS.CannotBeEmpty");
        }
        return null;
    };
}