import { APIAbsenteeismCodeDetailsToDomainMapper } from "../models/mappers/APIAbsenteeismCodeDetailsToDomainMapper";
import { AbsenteeismCodeCreateDTO } from "../models/dtos/AbsenteeismCodeCreateDTO";
import { AbsenteeismCodeDTO } from "../models/dtos/AbsenteeismCodeDTO";
import { AbsenteeismCodeDetails } from "../models/domain/AbsenteeismCodeDetails";
import { AbsenteeismCodeSummary } from "../models/domain/AbsenteeismCodeSummary";
import { AbsenteeismCodesQueryOptions } from "../models/dtos/AbsenteeismCodesQueryOptions";
import { AbsenteeismCodesResponseDTO } from "../models/dtos/AbsenteeismCodesResponseDTO";
import { ConfigurationProvider } from "../../../../lib/infrastructure/configuration/ConfigurationProvider";
import { HttpClient } from "../../../../lib/infrastructure/http/HttpClient";
import { UpdateAbsenteeismProblematicIndicatorDTO } from "../models/dtos/UpdateAbsenteeismProblematicIndicatorDTO";
import qs from "qs";

const Route = (path: string) => {
    return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};

export class AbsenteeismCodeService{

    getAbsenteeismCodes(locationId: string | number, queryOptions?: AbsenteeismCodesQueryOptions): Promise<AbsenteeismCodeSummary | null> {
        return HttpClient.sessionRequest<AbsenteeismCodesResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/AbsenteeismCodes`),
            params: queryOptions,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" });
            },
        })
            .then((response) => {
                return ({
                    absenteeismCodes: response.data.absenteeismCodes.map(c => APIAbsenteeismCodeDetailsToDomainMapper(c)),
                    total: response.data.total
                })
            })
            .catch((error) => {
                throw error;
            });
    }

    getAbsenteeismCodeById(locationId: number | string, absenteeismCodeId: number | string): Promise<AbsenteeismCodeDetails> {
        return HttpClient.sessionRequest<AbsenteeismCodeDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/AbsenteeismCodes/${absenteeismCodeId}`),
        })
        .then((response) => { return response.data })
        .catch((error) => { throw error; });
    }


    createAbsenteeismCodes(locationId: string | number, requestDto: AbsenteeismCodeCreateDTO): Promise<void> {
        return HttpClient.sessionRequest({
            method: "POST",
            url: Route(`/api/v1/Locations/${locationId}/AbsenteeismCodes`),
            data: requestDto,
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }

    updateAbsenteeismCodeProblematicIndicator(locationId: number | string, absenteeismCodeId: number | string, problematic: boolean): Promise<void> {
        const requestBody: UpdateAbsenteeismProblematicIndicatorDTO = {
          isProblematic: problematic
        }
        
        return HttpClient.sessionRequest({
          method: "PUT",
          url: Route(`/api/v1/Locations/${locationId}/AbsenteeismCodes/${absenteeismCodeId}/ProblematicIndicator`),
          data: requestBody
        })
          .then((_) => {
          })
          .catch((error) => {
            throw error;
          });
      }

      deleteEvaluation(locationId: number | string, absenteeismCodeId: number | string): Promise<void> {
        return HttpClient.sessionRequest({
            method: "DELETE",
            url: Route(`/api/v1/Locations/${locationId}/AbsenteeismCodes/${absenteeismCodeId}`),
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }

}