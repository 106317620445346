import {
  ColumnDefinition,
  IOrderInfo,
  PaginationOptions,
} from "./TableInterfaces";

import { DataTable } from "./DataTable";
import { ITableCellProps as ITableCell } from "./table-cell/TableCell";
import { MiniDataTable } from "./MiniDataTable";
import { useMemo } from "react";
import { useWindowResize } from "../../infrastructure/ui/UIServices";

export interface IResponsiveDataTableProps<T> {
  className?: string;
  rowClassName?: (cells: T) => string;
  items: T[];
  columnDefinitions: ColumnDefinition<T>[];
  totalitems: number;
  currentpage?: number;
  paginationOptions?: PaginationOptions;
  isLoading?: boolean;
  maxBodyHeight?: number;
  orderColumns?: IOrderInfo[];
  onOrderChanged?: (orderInfos: IOrderInfo[]) => void;
  //onItemsPerPageChanged?: (rowsPerPage: number, newPage: number) => void;
  //onCurrentPageChanged?: (rowsPerPage: number, newPage: number) => void;
  onClickRow?: (cells: T) => void;
  onPageAndItemsChanged?: (rowsPerPage: number, newPage: number) => void;
  showSelectedRowHighlighted?: boolean;
}

export function ResponsiveDataTable<T>(props: IResponsiveDataTableProps<T>) {
  const windowResize = useWindowResize();

  const renderTable = useMemo(() => {
    if (windowResize <= 768) {
      return <MiniDataTable {...props} />;
    } else {
      return <DataTable {...props} />;
    }
  }, [props, windowResize]);

  return <>{renderTable}</>;
}
