import "./StatusCard.css";

import { useCallback, useMemo } from "react";

import { CssClassnameBuilder } from "../../../utils/CssClassnameBuilder";

interface IStatsCardProps {
    title: string;
    value: string;
    backgroundColor?: "status-red" | "status-blue" | "status-yellow" | "status-light-grey" | "status-green" | "status-dark-grey" | "status-lime-green"
    onClick?: (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    className?: string;
}

export function StatusCard(props: IStatsCardProps) {

    const cardCss = useMemo(() => {
        return CssClassnameBuilder.new()
            .add("status-card-container")
            .addConditional(props.onClick, "selectable")
            .addConditional(props.backgroundColor, props.backgroundColor)
            .addConditional(props.className, props.className)
            .build();
    }, [props.backgroundColor, props.className, props.onClick]);


    const handleClicked = useCallback(
        (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            ev.stopPropagation();
            if (props.onClick)
                props.onClick && props.onClick(ev);
        },
        [props.onClick]
    );


    return (
        <div className={cardCss} onClick={handleClicked}>
            <div className="status-card-value">{props.value}</div>
            <div className="status-card-text">{props.title}</div>
        </div>
    );
}