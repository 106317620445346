


import { Navigate, Outlet, Route, Routes, } from "react-router-dom";
import { EmployeesEvaluationsPage } from "../features/employees/pages/evaluations/EmployeesEvaluationsPage";
import { RouteGuard } from "../lib/infrastructure/authorization/RouteGuard";
import { Permission } from "../models/api/gate/Permission";
import { PersonalEvaluationPage } from "../features/employees/pages/personal-evaluation/PersonalEvaluationPage";


const permissions = [  Permission.GLOBAL_ADMIN,
    Permission.LOCATION_ADMIN,
    Permission.HUMAN_RESOURCES,
    Permission.LOCATION_MANAGER, Permission.LOCATION_ACCESS];

export function CollaboratorModule() {

    return (
        <RouteGuard permissions={permissions} >
        <Routes>
            <Route path="/" element={<Navigate to="evaluations" />} />
            <Route path="evaluations" element={<EmployeesEvaluationsPage />} />
            <Route path="evaluations/:id" element={<PersonalEvaluationPage />} />

{/*             <Route path="/evaluations/:evaluationID" element={
                <EmployeesContainer>
                    <Outlet />
                </EmployeesContainer>}>
            </Route> */}
        </Routes>
        </RouteGuard >
    );
}
