import "./RegisterCommentPopup.css";

import { CommentInfo, EvaluationComment } from "../../components/EvaluationComment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../lib/infrastructure/ui/UIServices";

import { AllValid } from "../../../../common/validators/ValidateFormControls";
import { CardContainer } from "../../../../lib/layouts/containers/card/CardContainer";
import { ConditionalRender } from "../../../../lib/functional/ConditionalRender";
import { DateTime } from "luxon";
import { EmptyState } from "../../../../lib/components/emptystates/EmptyState";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { EvaluationCommentCreateDTO } from "../../../evaluation-comments/models/dtos/EvaluationCommentCreateDTO";
import { EvaluationCommentService } from "../../../evaluation-comments/services/EvaluationCommentService";
import { EvaluationDetails } from "../../models/domain/EvaluationDetails";
import { FormContainer } from "../../../../lib/layouts/containers/form/FormContainer";
import { FormFieldTextArea } from "../../../../lib/components/form/form-field/FormFieldTextArea";
import { FullScreenLoader } from "../../../../lib/components/loader/FullScreenLoader";
import { Notification } from "../../../../lib/components/notifications/Notification";
import { PopupActionButtons } from "../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../lib/layouts/containers/popup-header/PopupHeader";
import { Spacer } from "../../../../lib/components/separator/Spacer";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../../lib/components/form/Form";
import { useServiceCallPro } from "../../../../lib/hooks/useServiceCall";
import { validateWhiteSpaceStrings } from "../../../../lib/validators/ValidateWhiteSpaceStrings";
import { ReactComponent as WarningIcon } from "../../../../lib/assets/icons/warning-icon-v2.svg";
import { Tag } from "../../../../lib/components/tag/Tag";

var evaluationCommentSvc = new EvaluationCommentService();
export interface IRegisterCommentPopupProps {
    isPeriodDisabled: boolean;
    onAddCommentCompleted: (ev: EvaluationDetails) => void;
    employeeName: string;
    locationId: number;
    periodId: string;
    evaluationId: number;
    isPeriodClosed: boolean;
}

export function RegisterCommentPopup(props: IRegisterCommentPopupProps) {
    const openPopup = usePopup();
    const closePopup = useClosePopup();
    const createNotification = useCreateNotification();

    const addEvaluationCommentCall = useServiceCallPro(evaluationCommentSvc.addEvaluationComment);
    const getEvaluationCommentsCall = useServiceCallPro(evaluationCommentSvc.getEvaluationComments);

    const [comments, setComments] = useState<CommentInfo[]>();

    const commentFormControl = useFormControl<string>({
        validators: [validateWhiteSpaceStrings()],
        enableAutoValidate: true,
        isDisabled: false,
    });

    /****************************
    * DATA REQUESTS
    *****************************/

    const getEvaluationComments = useCallback(() => {
        if (!props.locationId || !props.periodId || !props.evaluationId) return;

        getEvaluationCommentsCall.invoke(`${props.locationId}`, props.periodId, `${props.evaluationId}`)
            .then((data) => {
                if (data) {
                    var mappedComments: CommentInfo[] = data.map((c) => ({
                        text: c.comment,
                        createdBy: c.createdBy,
                        createdDate: c.createdDate ? DateTime.fromISO(c.createdDate).toFormat("dd/MM/yyyy HH'h'mm") : "",
                    }));

                    setComments(mappedComments);
                }
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [props.locationId, props.periodId, props.evaluationId, setComments, getEvaluationCommentsCall.invoke, openPopup]);



    const addEvaluationComment = useCallback((dto: EvaluationCommentCreateDTO) => {
        if (!props.locationId || !props.periodId || !props.evaluationId) return;

        addEvaluationCommentCall.invoke(`${props.locationId}`, props.periodId, `${props.evaluationId}`, dto)
            .then((freshEvaluationLine) => {
                props.onAddCommentCompleted(freshEvaluationLine);
                closePopup();
                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("EVALUATIONS.POPUPS.AddEvaluationCommentSuccessMessage")}
                    ></Notification>
                );
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [props.locationId, props.periodId, props.evaluationId,
    props.onAddCommentCompleted, addEvaluationCommentCall.invoke,
        openPopup, closePopup, createNotification]);

    /****************************
    * DATA MANIPULATION EFFECTS
    *****************************/

    useEffect(() => {
        getEvaluationComments();
    }, []);



    /****************************
     * USER ACTIONS
     *****************************/

    const handleAddCommentBtnClicked = useCallback(() => {
        if (!props.locationId || !props.periodId || !props.evaluationId || !AllValid(commentFormControl.validate())) return;


        var dto: EvaluationCommentCreateDTO = {
            comment: commentFormControl.value ?? ""
        }

        addEvaluationComment(dto);

    }, [
        props.locationId, props.periodId, props.evaluationId, commentFormControl.value, commentFormControl.validate, addEvaluationComment
    ]);

    /****************************
     * CSS & HTML
     *****************************/

    return (
        <PopupContainer className="popup-register-comment">
            <PopupHeader title={translate("EVALUATIONS.COMMENTS.MessagesToCalibrator")} />
            <PopupContent isLoading={getEvaluationCommentsCall.isLoading}>
                {addEvaluationCommentCall.isLoading ? <FullScreenLoader /> : null}
                <CardContainer className="employee-card">
                    <div className="small-copy employee-label">{translate("EVALUATIONS.INFOANDFORM.Employee")}</div>
                    <div className="employee-value">{props.employeeName}</div>
                </CardContainer>
                <Spacer mode="vertical" px={20} />

                {comments && comments.length > 0 ? (
                    <>
                        <div className="comments">
                            {comments?.map((comment, idx) => <EvaluationComment key={idx} comment={comment} />)}
                        </div>
                        <Spacer mode="vertical" px={20} /></>)
                    : null}



                {props.isPeriodClosed && comments?.length === 0 ?
                    <EmptyState hideSubtitle /* title={translate("EVALUATIONS.INFOANDFORM.Feedback")} */ subtitleMessage={translate("EVALUATIONS.POPUPS.NoComments")} />
                    : null}





                <ConditionalRender if={!props.isPeriodClosed}>
                    <FormContainer>
                        <FormFieldTextArea
                            formControl={commentFormControl}
                            label={translate("EVALUATIONS.COMMENTS.Message")}
                            placeholder={translate("EVALUATIONS.COMMENTS.MessagePlaceholder")}
                        />
                    </FormContainer>

                </ConditionalRender>
                <Spacer mode="vertical" px={20} />

                <Tag icon={<WarningIcon />} text={translate("EVALUATIONS.COMMENTS.MessagesVisibleToCalibrator")} backgroundColor="status-yellow" />



                

                <ConditionalRender if={!props.isPeriodClosed}>
                <Spacer mode="vertical" px={20} />
                    <PopupActionButtons
                        buttons={[
                            {
                                text: translate("COMMON.Cancel"),
                                type: "tertiary",
                                onClick: () => closePopup(),
                            },
                            {
                                text: translate("EVALUATIONS.INFOANDFORM.Register"),
                                className: "register-btn",
                                type: "primary",
                                onClick: handleAddCommentBtnClicked,
                            },
                        ]}
                    />
                </ConditionalRender>
            </PopupContent>
        </PopupContainer>
    );
}
