import "./EditEvaluationPeriodPopup.css";

import { regexIntegerOrDecimals, regexOnlyPositiveIntegers, validateRegexOrEmpty } from "../../../lib/validators/ValidateRegex";
import { useCallback, useEffect, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup, useWindowResize } from "../../../lib/infrastructure/ui/UIServices";

import { AllValid } from "../../../common/validators/ValidateFormControls";
import { DateTime } from "luxon";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { EvaluationPeriodUpdateDTO } from "../models/api/EvaluationPeriodUpdateDTO";
import { EvaluationPeriodsService } from "../services/EvaluationPeriodsService";
import { FormContainer } from "../../../lib/layouts/containers/form/FormContainer";
import { FormFieldDatePicker } from "../../../lib/components/form/form-field/FormFieldDatePicker";
import { FormFieldTextInput } from "../../../lib/components/form/form-field/FormFieldTextInput";
import { FormSection } from "../../../lib/components/form/section/FormSection";
import { FullScreenLoader } from "../../../lib/components/loader/FullScreenLoader";
import { Notification } from "../../../lib/components/notifications/Notification";
import { PopupActionButtons } from "../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../lib/layouts/containers/popup-header/PopupHeader";
import { Spacer } from "../../../lib/components/separator/Spacer";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { trimString } from "../../../lib/utils/TrimString";
import { useFormControl } from "../../../lib/components/form/Form";
import { useServiceCallPro } from "../../../lib/hooks/useServiceCall";
import { validateDateTime } from "../../../lib/validators/ValidateDateTime";
import { validateWhiteSpaceStrings } from "../../../lib/validators/ValidateWhiteSpaceStrings";
import { replaceCommaByPoint } from "../../../common/helpers/replaceCommaByPoint";
import { validateExternalDateTimeControl } from "../../../common/validators/ValidateDateTimeFormControls";
import { request } from "http";

var evaluationPeriodsSvc = new EvaluationPeriodsService();

export interface EditEvaluationPeriodProps {
    locationID: string;
    periodID: string;
    onCompletedOperations: () => void;
}

export function EditEvaluationPeriodPopup(props: EditEvaluationPeriodProps) {
    const closePopup = useClosePopup();
    const openPopup = usePopup();
    const createNotification = useCreateNotification();
    const windowResize = useWindowResize();

    const nameFormControl = useFormControl<string>({
        validators: [validateWhiteSpaceStrings()],
        enableAutoValidate: true,
    });

    const initialDateFormControl = useFormControl<DateTime>({
        validators: [validateDateTime()],
        enableAutoValidate: false
    });

    const finalDateFormControl = useFormControl<DateTime>({
        validators: [validateDateTime()],
        enableAutoValidate: false
    });

    const totalPeriodDaysFormControl = useFormControl<string>({
        validators: [validateRegexOrEmpty(regexOnlyPositiveIntegers, false, "COMMON.FORM.VALIDATIONS.PositiveNumber")],
        enableAutoValidate: true,
    });

    const monthlySalaryMultiplier = useFormControl<string>({
        validators: [validateRegexOrEmpty(regexIntegerOrDecimals, false, "COMMON.FORM.VALIDATIONS.PositiveNumberOrDecimal")],
        enableAutoValidate: true,
    });

    const periodMonetaryCeiling = useFormControl<string>({
        validators: [validateRegexOrEmpty(regexIntegerOrDecimals, false, "COMMON.FORM.VALIDATIONS.PositiveNumberOrDecimal")],
        enableAutoValidate: true,
    });

    const minimumBonusPercentage = useFormControl<string>({
        validators: [validateRegexOrEmpty(regexIntegerOrDecimals, false, "COMMON.FORM.VALIDATIONS.PositiveNumberOrDecimal")],
        enableAutoValidate: true,
    });

    const evaluationsStartDayControl = useFormControl<DateTime>({
        validators: [],
        enableAutoValidate: false
    });

    const evaluationsFinalDayControl = useFormControl<DateTime>({
        validators: [validateExternalDateTimeControl(evaluationsStartDayControl)],
        enableAutoValidate: false,
        isDisabled: true
    });

    const calibrationsStartDayControl = useFormControl<DateTime>({
        validators: [],
        enableAutoValidate: false
    });

    const calibrationsFinalDayControl = useFormControl<DateTime>({
        validators: [validateExternalDateTimeControl(calibrationsStartDayControl)],
        enableAutoValidate: false,
        isDisabled: true
    });


    /*****************
     * SERVICE CALLS 
     ****************/

    const getPeriodSummaryInfoCall = useServiceCallPro(evaluationPeriodsSvc.getEvaluationPeriodByID);
    const editPeriodInfoCall = useServiceCallPro(evaluationPeriodsSvc.editEvaluationPeriod);

    useEffect(() => {

        if (!props.locationID || !props.periodID) return;

        getPeriodSummaryInfoCall.invoke(`${props.locationID}`, props.periodID)
            .then((data) => {
                if (!data) return;

                if (data.startDate && DateTime.fromISO(data.startDate))
                    initialDateFormControl.setValue(DateTime.fromISO(data.startDate));

                if (data.endDate && DateTime.fromISO(data.endDate))
                    finalDateFormControl.setValue(DateTime.fromISO(data.endDate));

                nameFormControl.setValue(data.name);
                totalPeriodDaysFormControl.setValue(data.periodDays.toString());
                monthlySalaryMultiplier.setValue(data.monthlySalaryMultiplier.toString());
                periodMonetaryCeiling.setValue(data.periodMonetaryCeilingPercentage.toString());
                minimumBonusPercentage.setValue(data.minimumBonusPercentage.toString());

                if (data.evaluationsStartDay && DateTime.fromISO(data.evaluationsStartDay))
                    evaluationsStartDayControl.setValue(DateTime.fromISO(data.evaluationsStartDay));

                if (data.evaluationsFinalDay && DateTime.fromISO(data.evaluationsFinalDay))
                    evaluationsFinalDayControl.setValue(DateTime.fromISO(data.evaluationsFinalDay));

                if (data.calibrationsStartDay && DateTime.fromISO(data.calibrationsStartDay))
                    calibrationsStartDayControl.setValue(DateTime.fromISO(data.calibrationsStartDay));

                if (data.calibrationsFinalDay && DateTime.fromISO(data.calibrationsFinalDay))
                    calibrationsFinalDayControl.setValue(DateTime.fromISO(data.calibrationsFinalDay));
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [props.locationID, props.periodID]);




    const handleEditButtonClicked = useCallback(() => {

        if (!props.locationID || !props.periodID) return;

        if (!AllValid(nameFormControl.validate(), totalPeriodDaysFormControl.validate(), monthlySalaryMultiplier.validate(),
            periodMonetaryCeiling.validate(), minimumBonusPercentage.validate(),
            calibrationsFinalDayControl.validate(), evaluationsFinalDayControl.validate())) return;

        var requestDto: EvaluationPeriodUpdateDTO = {
            startDate: initialDateFormControl.value?.toUTC().toISO() || undefined,
            endDate: finalDateFormControl.value?.toUTC().toISO() || undefined,
            name: nameFormControl.value || "",
            periodDays: totalPeriodDaysFormControl.value ? parseInt(trimString(totalPeriodDaysFormControl.value)) : 0,
            monthlySalaryMultiplier: monthlySalaryMultiplier.value ? parseFloat(trimString(replaceCommaByPoint(monthlySalaryMultiplier.value))) : 0,
            periodMonetaryCeilingPercentage: periodMonetaryCeiling ? parseFloat(trimString(replaceCommaByPoint(periodMonetaryCeiling.value))) : 0,
            minimumBonusPercentage: minimumBonusPercentage ? parseFloat(trimString(replaceCommaByPoint(minimumBonusPercentage.value))) : 0,
        };

        if (evaluationsStartDayControl.value && evaluationsFinalDayControl.value) {
            requestDto.evaluationsStartDay = evaluationsStartDayControl.value?.toISO();
            requestDto.evaluationsFinalDay = evaluationsFinalDayControl.value?.toISO();
        }

        if (calibrationsStartDayControl.value && calibrationsFinalDayControl.value) {
            requestDto.calibrationsStartDay = calibrationsStartDayControl.value?.toISO();
            requestDto.calibrationsFinalDay = calibrationsFinalDayControl.value?.toISO();
        }


        editPeriodInfoCall.invoke(`${props.locationID}`, props.periodID, requestDto)
            .then((_) => {
                props.onCompletedOperations();
                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("PERIODS.MESSAGES.EditPeriodSuccess")}
                    ></Notification>
                );
                closePopup();
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            })

    }, [props.locationID, props.periodID, initialDateFormControl.value,
    finalDateFormControl.value, minimumBonusPercentage.validate, minimumBonusPercentage.value,
    nameFormControl.validate, nameFormControl.value, totalPeriodDaysFormControl.validate,
    totalPeriodDaysFormControl.value, monthlySalaryMultiplier.validate, calibrationsFinalDayControl.validate,
    evaluationsFinalDayControl.validate, monthlySalaryMultiplier.value,
    periodMonetaryCeiling.validate, periodMonetaryCeiling.value,
        editPeriodInfoCall, editPeriodInfoCall.invoke, props.onCompletedOperations,
        closePopup, openPopup, createNotification,
    evaluationsStartDayControl.value, evaluationsFinalDayControl.value,
    calibrationsStartDayControl.value, calibrationsFinalDayControl.value]);



    useEffect(() => {
        if (evaluationsStartDayControl.value)
            evaluationsFinalDayControl.setIsDisabled(false);
        if (calibrationsStartDayControl.value)
            calibrationsFinalDayControl.setIsDisabled(false);

        if (!evaluationsStartDayControl.value)
            evaluationsFinalDayControl.setIsDisabled(true);
        if (!calibrationsStartDayControl.value)
            calibrationsFinalDayControl.setIsDisabled(true);
    }, [evaluationsStartDayControl.value, calibrationsStartDayControl.value]);


    return (
        <PopupContainer className="popup-edit-evaluation-period">
            {editPeriodInfoCall.isLoading ? <FullScreenLoader /> : null}

            <PopupHeader
                title={translate("PERIODS.EditEvaluationPeriod")}
            />

            <PopupContent isLoading={getPeriodSummaryInfoCall.isLoading}>
                <FormContainer>
                    <FormSection isInputGap>
                        <FormFieldTextInput
                            formControl={nameFormControl}
                            label={translate("GLOBALADMIN.LOCATIONS.INFO.Name")}
                        />
                    </FormSection>
                    <FormSection isInputGap>
                        <FormFieldDatePicker formControl={initialDateFormControl}
                            placeholder={translate("PERIODS.INFOANDFORM.InitialDatePlaceholder")}
                            label={translate("PERIODS.INFOANDFORM.InitialDate")} maxDate={finalDateFormControl.value}
                        />
                        <FormFieldDatePicker formControl={finalDateFormControl}
                            placeholder={translate("PERIODS.INFOANDFORM.FinalDatePlaceholder")}
                            label={translate("PERIODS.INFOANDFORM.FinalDate")} minDate={initialDateFormControl.value} />
                    </FormSection>
                    <FormSection isInputGap>
                        <FormFieldTextInput formControl={totalPeriodDaysFormControl} label={translate("PERIODS.INFOANDFORM.PeriodDays")} placeholder={"Ex: 64"} />
                        <FormFieldTextInput formControl={monthlySalaryMultiplier} label={translate("PERIODS.INFOANDFORM.MonthlySalaryMultiplier")} placeholder={"Ex: 3.5"} />
                    </FormSection>
                    <FormSection isInputGap>
                        <FormFieldTextInput formControl={periodMonetaryCeiling} label={translate("PERIODS.INFOANDFORM.PeriodMonetaryCeilingPercentageReduced")} placeholder={"Ex: 10 (%)"} />
                        <FormFieldTextInput formControl={minimumBonusPercentage} label={translate("PERIODS.INFOANDFORM.MinimumBonusPercentage")} placeholder={"Ex: 25 (%)"} />
                        </FormSection>
                        <Spacer mode="vertical" px="5" />
                        <div className="separator">{translate("PERIODS.INFOANDFORM.DatesToNotificateManagersByEmail")}</div>

                        <FormSection isInputGap>
                            <FormFieldDatePicker
                                formControl={evaluationsStartDayControl}
                                placeholder={translate("COMMON.POPUPS.SelectDate")}
                                label={translate("PERIODS.INFOANDFORM.InitialDayToEvaluate")}

                                maxDate={evaluationsFinalDayControl.value ?? calibrationsStartDayControl.value ?? calibrationsFinalDayControl.value} />

                            <FormFieldDatePicker
                                formControl={evaluationsFinalDayControl}
                                placeholder={translate("COMMON.POPUPS.SelectDate")}
                                label={translate("PERIODS.INFOANDFORM.FinalDayToEvaluate")}
                                minDate={evaluationsStartDayControl.value}
                                maxDate={calibrationsStartDayControl.value ?? calibrationsFinalDayControl.value}
                            />
                        </FormSection>

                        <FormSection isInputGap>
                            <FormFieldDatePicker
                                formControl={calibrationsStartDayControl}
                                placeholder={translate("COMMON.POPUPS.SelectDate")}
                                label={translate("PERIODS.INFOANDFORM.InitialDayToCalibrate")}
                                minDate={evaluationsStartDayControl.value ?? evaluationsFinalDayControl.value}
                                maxDate={calibrationsFinalDayControl.value}
                            />
                            <FormFieldDatePicker
                                formControl={calibrationsFinalDayControl}
                                placeholder={translate("COMMON.POPUPS.SelectDate")}
                                label={translate("PERIODS.INFOANDFORM.FinalDayToCalibrate")}
                                minDate={calibrationsStartDayControl.value ?? evaluationsStartDayControl.value ?? evaluationsFinalDayControl.value}
                            />
                        </FormSection>

                    
                </FormContainer>
                <Spacer mode={"vertical"} px="30"></Spacer>
                <PopupActionButtons
                    buttons={[
                        {
                            text: translate("COMMON.Cancel"),
                            type: "tertiary",
                            onClick: () => closePopup(),
                        },
                        {
                            text: translate("COMMON.Edit"),
                            type: "primary",
                            onClick: handleEditButtonClicked,
                        },
                    ]}
                />
            </PopupContent>
        </PopupContainer>
    );
}