import "./EvaluationCriteriaColumnDefinition.css";

import { ContextualMenu, ContextualMenuItems } from "../../../lib/components/contextual-menu/ContextualMenu";
import { useCallback, useEffect } from "react";
import { useClosePopup, useCreateNotification, usePopup } from '../../../lib/infrastructure/ui/UIServices';
import { validateRegex, weigthPercentageExpression } from "../../../lib/validators/ValidateRegex";

import { ReactComponent as ChatIcon } from "../../../lib/assets/icons/chat.svg";
import { ReactComponent as CommentsOutlineIcon } from "../../../lib/assets/icons/comment-outline.svg";
import { ConsultHistoryLinesPopup } from "./popups/ConsultHistoryLinesPopup";
import { ReactComponent as CutBonusIcon } from "../../../lib/assets/icons/cut.svg";
import { CutBonusPopup } from "./popups/CutBonusPopup";
import { DeleteEmployeeEvaluationPopup } from "./popups/DeleteEmployeeEvaluationPopup";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { Evaluation } from "../models/domain/Evaluation";
import { EvaluationDetails } from "../models/domain/EvaluationDetails";
import { EvaluationStatusEnum } from "../models/domain/EvaluationStatusEnum";
import { EvaluationsService } from "../services/EvaluationsService";
import { FormFieldTextInput } from "../../../lib/components/form/form-field/FormFieldTextInput";
import { FullScreenLoader } from "../../../lib/components/loader/FullScreenLoader";
import { ReactComponent as HistoryIcon } from "../../../lib/assets/icons/history.svg";
import { ReactComponent as MoreActions } from "../../../lib/assets/icons/more-actions.svg";
import { Notification } from "../../../lib/components/notifications/Notification";
import { Permission } from "../../../models/api/gate/Permission";
import { RegisterCommentPopup } from "./popups/RegisterCommentPopup";
import { RegisterFeedbackPopup } from "./popups/RegisterFeedbackPopup";
import { RejectEvaluationPopup } from "./popups/RejectEvaluationPopup";
import { ReactComponent as RejectIcon } from "../../../lib/assets/icons/reject-dark.svg";
import { ReactComponent as RemoveIcon } from "../../../lib/assets/icons/remover-outline.svg";
import { ReactComponent as RevertIcon } from "../../../lib/assets/icons/revert.svg";
import { ScalableIconButton } from "../../../lib/components/buttons/ScalableIconButton";
import { WarningPopup } from "../../../lib/components/popup/WarningPopup";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../lib/components/form/Form";
import { useHasPermissions } from "../../../lib/infrastructure/authorization/useHasLocationPermissions";
import { useServiceCallPro } from "../../../lib/hooks/useServiceCall";

interface IColumnDefinitionCriteria {
    value?: number;
    onValueChanged: (value: number | undefined, isValid: boolean) => void;
    placeholder?: string;
    columnHeaderTitle?: string;
}

export function EvaluationCriteriaColumnDefinition(props: IColumnDefinitionCriteria) {

    const valueFieldFormControl = useFormControl<string>({
        initialValue: props.value !== undefined ? "" + props.value : "0",
        validators: [validateRegex(weigthPercentageExpression, false, "PERIODS.VALIDATIONS.WeigthPercentage")],
        enableAutoValidate: true,
    });



    useEffect(() => {
        if (!valueFieldFormControl.value) {
            props.onValueChanged(0, false);
            return;
        }

        var parsedValue = parseFloat(valueFieldFormControl.value.replace(',', '.'));

        if (Number.isNaN(parsedValue))
            props.onValueChanged(0, false);
        else
            props.onValueChanged(parsedValue, valueFieldFormControl.isValid)
    }, [valueFieldFormControl.value, valueFieldFormControl.isValid])


    return (
        <FormFieldTextInput
            className="evaluation"
            formControl={valueFieldFormControl}
            placeholder={props.placeholder}
            alternativeStyle
        />
    );
}

var evaluationSvc = new EvaluationsService();

export interface IPerm {
    isLoadingPermissions: boolean;
    hasPermission: boolean;
}

interface IEvaluationContextMenuProps {
    onlyRHPermission: boolean;
    loadingOnlyRHPermission: boolean;
    isPeriodClosed: boolean;
    evaluation: Evaluation
    locationId: number;
    evaluationPeriodId: string;
    onCommentCompleted: (ev: EvaluationDetails) => void;
    onFeedbackCompleted: (ev: EvaluationDetails) => void;
    onCutBonusApplied: (ev: EvaluationDetails) => void;
    onRejectEvaluationCompleted: (ev: EvaluationDetails) => void;
    onCancelBonusCutCompleted: (ev: EvaluationDetails) => void;
    onRevertEvaluationStatusClicked: () => void;
    onDeleteEvaluationCompleted: (evaluationID: number) => void;
    loadingPermissions: boolean;
    hasPermission: boolean;
    canCalibrate: boolean;
    canRevertConfirmation: boolean;
}


export function EvaluationContextMenu(props: IEvaluationContextMenuProps) {

    const openPopup = usePopup();
    const closePopup = useClosePopup();


    const createNotification = useCreateNotification();

    const updateEvaluationToCutBonusCall = useServiceCallPro(evaluationSvc.updateEvaluationToCutBonus);



    const moreActionsBtnItems = useCallback((evaluation: Evaluation) => {

        let items: ContextualMenuItems[] = [
            {
                label: translate("EVALUATIONS.INFOANDFORM.CancelBonusCut"), navigateTo: "", icon: <RejectIcon />, onClick: () => {
                    openPopup(
                        <WarningPopup
                            onDismissClicked={() => {
                                closePopup();
                            }}
                            onConfirmClicked={() => {

                                updateEvaluationToCutBonusCall.invoke(props.locationId.toString(), props.evaluationPeriodId, props.evaluation.id.toString(), { cutReasonId: null })
                                    .then((response) => {
                                        props.onCancelBonusCutCompleted(response);
                                        createNotification(
                                            <Notification
                                                type="success"
                                                title={translate("COMMON.SYSTEMPOPUPS.Success")}
                                                text={translate("EVALUATIONS.POPUPS.CancelCutBonusSuccessMessage")}
                                            ></Notification>
                                        );

                                        closePopup();

                                    }).catch((error) => {
                                        if (!error) return;
                                        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
                                    })
                            }}
                            dismissText={translate("COMMON.Cancel")}
                            confirmText={translate("COMMON.Confirm")}
                        >
                            {translate("EVALUATIONS.POPUPS.CancelCutBonusConfirmText")}
                        </WarningPopup>
                    );
                },
                isVisible: (evaluation.isBonusCut && !props.isPeriodClosed && (!props.loadingPermissions && props.hasPermission))
            },
            {
                label: translate("EVALUATIONS.INFOANDFORM.FeedbackToCollaborator"),
                navigateTo: "",
                icon: <CommentsOutlineIcon />,
                onClick: () => {
                    openPopup(
                        <RegisterFeedbackPopup
                            evaluationStatus={evaluation.status}
                            onlyRead={props.isPeriodClosed || (evaluation.status !== EvaluationStatusEnum.ONGOING && evaluation.status !== EvaluationStatusEnum.READYTOEVALUATE)}
                            employeeName={evaluation.employee.name ? evaluation.employee.name : "-"}
                            locationId={props.locationId}
                            periodId={props.evaluationPeriodId}
                            evaluationId={evaluation.id}
                            isPeriodStatusInvalid={props.isPeriodClosed}
                            onAddFeedbackCompleted={props.onFeedbackCompleted} />)
                }
            },
            {
                label: translate("EVALUATIONS.COMMENTS.MessagesToCalibrator"),
                navigateTo: "",
                icon: <ChatIcon />,
                onClick: () => {
                    openPopup(
                        <RegisterCommentPopup
                            isPeriodClosed={props.isPeriodClosed}
                            isPeriodDisabled={props.isPeriodClosed}
                            employeeName={evaluation.employee.name ? evaluation.employee.name : "-"}
                            periodId={props.evaluationPeriodId}
                            locationId={props.locationId}
                            evaluationId={props.evaluation.id}
                            onAddCommentCompleted={(ev) => props.onCommentCompleted(ev)} />)
                }
            },
            {
                label: translate("EVALUATIONS.INFOANDFORM.History"), navigateTo: "", icon: <HistoryIcon />, onClick: () => {
                    openPopup(
                        <ConsultHistoryLinesPopup
                            periodId={props.evaluationPeriodId}
                            locationId={props.locationId}
                            evaluationId={props.evaluation.id}
                            onAddCommentCompleted={props.onCommentCompleted} />)
                }
            },
        ];

        switch (evaluation.status) {
            case EvaluationStatusEnum.READYTOEVALUATE:

                return items = [{
                    label: translate("EVALUATIONS.INFOANDFORM.CutBonus"), navigateTo: "", icon: <CutBonusIcon />,
                    isVisible: !props.isPeriodClosed && (!props.loadingPermissions && props.hasPermission),
                    onClick: () => {
                        openPopup(<CutBonusPopup locationId={props.locationId} evaluationId={props.evaluation.id} evaluationPeriodId={props.evaluationPeriodId} employeeName={evaluation.employee.name ? evaluation.employee.name : "-"}
                            isPeriodStatusInvalid={props.isPeriodClosed} onCutBonusApplied={(ev) => { props.onCutBonusApplied(ev) }} cutReasonId={evaluation.bonusCutReasonId} cutReasonJustification={evaluation.cutReasonJustification} />)
                    }
                },
                {
                    label: translate("EVALUATIONS.INFOANDFORM.RemoveEvaluation"), navigateTo: "", icon: <RemoveIcon />,
                    isVisible: !props.isPeriodClosed && (!props.loadingOnlyRHPermission && props.onlyRHPermission),
                    onClick: () => {
                        openPopup(<DeleteEmployeeEvaluationPopup
                            locationId={props.locationId}
                            evaluationPeriodId={props.evaluationPeriodId}
                            evaluation={evaluation}
                            onDeleteEvaluationCompleted={(id) => { props.onDeleteEvaluationCompleted(id) }}
                        />)
                    }
                },
                ...items,]

            case EvaluationStatusEnum.ONGOING:

                return items = [{
                    label: translate("EVALUATIONS.INFOANDFORM.CutBonus"), navigateTo: "", icon: <CutBonusIcon />,
                    isVisible: !props.isPeriodClosed && (!props.loadingPermissions && props.hasPermission),
                    onClick: () => {
                        openPopup(<CutBonusPopup locationId={props.locationId} evaluationId={props.evaluation.id} evaluationPeriodId={props.evaluationPeriodId} employeeName={evaluation.employee.name ? evaluation.employee.name : "-"}
                            isPeriodStatusInvalid={props.isPeriodClosed} onCutBonusApplied={(ev) => { props.onCutBonusApplied(ev) }} cutReasonId={evaluation.bonusCutReasonId} cutReasonJustification={evaluation.cutReasonJustification} />)
                    }
                },
                {
                    label: translate("EVALUATIONS.INFOANDFORM.RemoveEvaluation"), navigateTo: "", icon: <RemoveIcon />,
                    isVisible: !props.isPeriodClosed && (!props.loadingOnlyRHPermission && props.onlyRHPermission),
                    onClick: () => {
                        openPopup(<DeleteEmployeeEvaluationPopup
                            locationId={props.locationId}
                            evaluationPeriodId={props.evaluationPeriodId}
                            evaluation={evaluation}
                            onDeleteEvaluationCompleted={(id) => { props.onDeleteEvaluationCompleted(id) }}
                        />)
                    }
                },


                ...items,]

            case EvaluationStatusEnum.CONFIRMED:
                return items = [{
                    label: translate("EVALUATIONS.INFOANDFORM.CutBonus"), navigateTo: "", icon: <CutBonusIcon />,
                    isVisible: !props.isPeriodClosed && (!props.loadingPermissions && props.hasPermission),
                    onClick: () => {
                        openPopup(<CutBonusPopup locationId={props.locationId} evaluationId={props.evaluation.id} evaluationPeriodId={props.evaluationPeriodId} employeeName={evaluation.employee.name ? evaluation.employee.name : "-"}
                            isPeriodStatusInvalid={props.isPeriodClosed} onCutBonusApplied={(ev) => { props.onCutBonusApplied(ev) }} cutReasonId={evaluation.bonusCutReasonId} cutReasonJustification={evaluation.cutReasonJustification} />)
                    },
                },
                {
                    label: translate("EVALUATIONS.INFOANDFORM.Revert"),
                    isVisible: !props.isPeriodClosed && props.canRevertConfirmation,
                    navigateTo: "",
                    icon: <RevertIcon />,
                    onClick: () => {
                        openPopup(
                            <WarningPopup
                                className="revert-evaluation-popup"
                                onDismissClicked={() => {
                                    closePopup();
                                }}
                                onConfirmClicked={() => { props.onRevertEvaluationStatusClicked() }}
                                dismissText={translate("COMMON.Cancel")}
                                confirmText={translate("EVALUATIONS.INFOANDFORM.Revert")}
                            >
                                {translate("EVALUATIONS.POPUPS.RevertText")}
                            </WarningPopup>
                        );
                    }
                },
                {
                    label: translate("EVALUATIONS.INFOANDFORM.Reject"), navigateTo: "", icon: <RejectIcon />, onClick: () => {
                        openPopup(
                            <RejectEvaluationPopup
                                employeeName={evaluation.employee.name ? evaluation.employee.name : "-"}
                                evaluationPeriodId={props.evaluationPeriodId}
                                locationId={props.locationId}
                                evaluationId={props.evaluation.id}
                                isPeriodStatusInvalid={props.isPeriodClosed}
                                onRejectEvaluation={(ev) => props.onRejectEvaluationCompleted(ev)}
                            />)
                    },
                    isVisible: (props.evaluation.status === EvaluationStatusEnum.CONFIRMED || props.evaluation.status === EvaluationStatusEnum.CALIBRATED) && (!props.isPeriodClosed && ((!props.loadingPermissions && props.hasPermission) || props.canCalibrate)),
                },
                ...items,]



            case EvaluationStatusEnum.CALIBRATED:
                return [
                    {
                        label: translate("EVALUATIONS.INFOANDFORM.CutBonus"), navigateTo: "", icon: <CutBonusIcon />,
                        isVisible: !props.isPeriodClosed && (!props.loadingPermissions && props.hasPermission),
                        onClick: () => {
                            openPopup(<CutBonusPopup locationId={props.locationId} evaluationId={props.evaluation.id} evaluationPeriodId={props.evaluationPeriodId} employeeName={evaluation.employee.name ? evaluation.employee.name : "-"}
                                isPeriodStatusInvalid={props.isPeriodClosed} onCutBonusApplied={(ev) => { props.onCutBonusApplied(ev) }} cutReasonId={evaluation.bonusCutReasonId} cutReasonJustification={evaluation.cutReasonJustification} />)
                        }
                    },
                    {
                        label: translate("EVALUATIONS.INFOANDFORM.Reject"), navigateTo: "", icon: <RejectIcon />, onClick: () => {
                            openPopup(
                                <RejectEvaluationPopup
                                    employeeName={evaluation.employee.name ? evaluation.employee.name : "-"}
                                    evaluationPeriodId={props.evaluationPeriodId}
                                    locationId={props.locationId}
                                    evaluationId={props.evaluation.id}
                                    isPeriodStatusInvalid={props.isPeriodClosed}
                                    onRejectEvaluation={(ev) => props.onRejectEvaluationCompleted(ev)}
                                />)
                        },
                        isVisible: (props.evaluation.status === EvaluationStatusEnum.CONFIRMED || props.evaluation.status === EvaluationStatusEnum.CALIBRATED) && (!props.isPeriodClosed && !props.loadingPermissions && props.hasPermission),
                    },

                    ...items]

            case EvaluationStatusEnum.REJECTED:
                return [
                    ...items
                ]
            default: return [];
        }
    }, [closePopup, openPopup, props.evaluationPeriodId, props.evaluation.id,
        createNotification, updateEvaluationToCutBonusCall, props.isPeriodClosed,
        props.evaluation.status, props.locationId, props.onCutBonusApplied, props.onRejectEvaluationCompleted,
        props.loadingOnlyRHPermission, props.onlyRHPermission,
        props.onCommentCompleted, props.hasPermission, props.loadingPermissions, props.onDeleteEvaluationCompleted,
        props.canCalibrate, props.onRevertEvaluationStatusClicked, props.canRevertConfirmation])


    return (
        <ContextualMenu items={moreActionsBtnItems(props.evaluation)} >
            {updateEvaluationToCutBonusCall.isLoading ? <FullScreenLoader /> : null}
            <ScalableIconButton icon={<MoreActions />} size={24} />
        </ContextualMenu>
    );
}

