import * as qs from "qs";

import { APICutReasonToDomainMapper } from "../models/mappers/APICutReasonToDomainMapper";
import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { CutReasonCreateDTO } from "../models/dtos/CutReasonCreateDTO";
import { CutReasonDTO } from "../models/dtos/CutReasonDTO";
import { CutReasonDetails } from "../models/domain/CutReasonDetails";
import { CutReasonSummary } from "../models/domain/CutReasonSummary";
import { CutReasonUpdateDTO } from "../models/dtos/CutReasonUpdateDTO";
import { CutReasonsQueryOptions } from "../models/dtos/CutReasonsQueryOptions";
import { CutReasonsResponseDTO } from "../models/dtos/CutReasonsResponseDTO";
import { EditCutReasonStatusDTO } from "../models/dtos/EditCutReasonStatusDTO";
import { HttpClient } from "../../../lib/infrastructure/http/HttpClient";

const Route = (path: string) => {
    return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};

export class CutReasonsService {
    getCutReasons(locationId: string | number, periodId: string | number, queryOptions?: CutReasonsQueryOptions): Promise<CutReasonSummary | null> {
        return HttpClient.sessionRequest<CutReasonsResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/EvaluationPeriods/${periodId}/CutReason`),
            params: queryOptions,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" });
            },
        })
        
            .then((response) => {
                return ({
                    cutReasons: response.data.cutReasons.map(c => APICutReasonToDomainMapper(c)),
                    total: response.data.total
                })
            })
            .catch((error) => {
                throw error;
            });
    }

    getCutReasonById(locationId: number | string, periodId: number | string, cutReasonId: number | string): Promise<CutReasonDetails> {
        return HttpClient.sessionRequest<CutReasonDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/EvaluationPeriods/${periodId}/CutReason/${cutReasonId}`),
        })
        .then((response) => { return response.data })
        .catch((error) => { throw error; });
    }


    createCutReason(locationId: string | number, periodId: string | number, requestDto: CutReasonCreateDTO): Promise<void> {
        return HttpClient.sessionRequest({
            method: "POST",
            url: Route(`/api/v1/Locations/${locationId}/EvaluationPeriods/${periodId}/CutReason`),
            data: requestDto,
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }

    updateCutReason(locationId: string | number, periodId: string | number, cutReasonId: string | number, requestDto: CutReasonUpdateDTO): Promise<void> {
        return HttpClient.sessionRequest({
            method: "PUT",
            url: Route(`/api/v1/Locations/${locationId}/EvaluationPeriods/${periodId}/CutReason/${cutReasonId}`),
            data: requestDto
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }

    editCutReasonStatus(locationId: number | string, periodId: number | string, cutReasonId: number | string, disable: boolean): Promise<void> {
        const requestBody: EditCutReasonStatusDTO = {
          disable: disable
        }
        
        return HttpClient.sessionRequest({
          method: "PUT",
          url: Route(`/api/v1/Locations/${locationId}/EvaluationPeriods/${periodId}/CutReason/${cutReasonId}/Status`),
          data: requestBody
        })
          .then((_) => {
          })
          .catch((error) => {
            throw error;
          });
      }


}