import * as qs from "qs";

import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { HttpClient } from "../../../lib/infrastructure/http/HttpClient";
import { ManagerAndDelegation } from "../models/domain/ManagersAndDelegations";
import { ManagerCreateDTO } from "../models/api/ManagerCreateDTO";
import { ManagerDetailsDTO } from "../models/api/ManagerDetailsDTO";
import { ManagersQueryOptions } from "../models/api/ManagersQueryOptions";
import { ManagersResponseDTO } from "../models/api/ManagersResponseDTO";
import { ManagersToDelegate } from "../models/domain/ManagersToDelegate";
import { PeriodManagerDetails } from "../models/domain/PeriodManagerDetails";
import { PeriodManagersDetailsResponse } from "../models/domain/PeriodManagerDetailsResponse";
import { PossibleManagerToDelegateDetailsResponse } from "../models/domain/PossibleManagerToDelegateDetailsResponse";
import { PossibleManagerToDelegateResponseDTO } from "../models/api/PossibleManagerToDelegateResponseDTO";

const Route = (path: string) => {
    return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};

export class PeriodManagerService {

    getPeriodManagers(locationID: string, periodID: string, options: ManagersQueryOptions): Promise<PeriodManagersDetailsResponse> {
        return HttpClient.sessionRequest<ManagersResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationID}/EvaluationPeriods/${periodID}/Managers`),
            params: options,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" });
            },
        })
            .then((response) => {

                var mappedManagers = response.data.managers.map(({ id, email, displayName, delegation }) => ({
                    id, email, displayName, delegation: delegation
                        ? { id: delegation.id, displayName: delegation.displayName, email: delegation.email }
                        : null,
                } as ManagerAndDelegation));

                return ({
                    managers: mappedManagers,
                    totalItems: response.data.totalItems
                });
            })
            .catch((error) => {
                throw error;
            });
    }


    // getPossibleManagersToDelegate(locationID: string, periodID: string, managerEmail: string, options: ManagersQueryOptions): Promise<PeriodManagersDetailsResponse> {
    //     return HttpClient.sessionRequest<ManagersResponseDTO>({
    //         method: "GET",
    //         url: Route(`/api/v1/Locations/${locationID}/EvaluationPeriods/${periodID}/Managers/${managerEmail}/ManagersToDelegate`),
    //         params: options,
    //         paramsSerializer: (params) => {
    //             return qs.stringify(params, { arrayFormat: "repeat" });
    //         },
    //     })
    //         .then((response) => {

    //             var mappedManagers = response.data.managers.map(({ id, email, displayName, delegation }) => ({
    //                 id, email, displayName, delegation: delegation
    //                     ? { id: delegation.id, displayName: delegation.displayName, email: delegation.email }
    //                     : null,
    //             } as ManagerAndDelegation));

    //             return ({
    //                 managers: mappedManagers,
    //                 totalItems: response.data.totalItems
    //             });
    //         })
    //         .catch((error) => {
    //             throw error;
    //         });
    // }


    getPossibleManagersToDelegate(locationID: string, periodID: string, managerEmail: string, options: ManagersQueryOptions): Promise<PossibleManagerToDelegateDetailsResponse> {
        return HttpClient.sessionRequest<PossibleManagerToDelegateResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationID}/EvaluationPeriods/${periodID}/Managers/${managerEmail}/ManagersToDelegate`),
            params: options,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" });
            },
        })
            .then((response) => {

                var mappedManagers = response.data.possibleManagersToDelegate.map(({ id, email, displayName }) => ({
                    id, email, displayName,
                } as ManagersToDelegate));

                return ({
                    possibleManagersToDelegate: mappedManagers,
                    totalItems: response.data.totalItems
                });
            })
            .catch((error) => {
                throw error;
            });
    }

    getTopManager(locationID: string, periodID: string, managerEmail: string): Promise<PeriodManagerDetails> {
        return HttpClient.sessionRequest<ManagerDetailsDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationID}/EvaluationPeriods/${periodID}/Managers/${managerEmail}/TopManager`),
        })
            .then((response) => {

                var mappedManager: PeriodManagerDetails = {
                    email: response.data.email,
                    id: response.data.id,
                    name: response.data.displayName
                };

                return mappedManager;
            })
            .catch((error) => {
                throw error;
            });
    }



    addPeriodManager(locationID: string, periodID: string, requestDto: ManagerCreateDTO): Promise<void> {

        return HttpClient.sessionRequest({
            method: "POST",
            url: Route(`/api/v1/Locations/${locationID}/EvaluationPeriods/${periodID}/Managers`),
            data: requestDto
        })
            .then(() => { })
            .catch((error) => {
                throw error;
            });
    }




    removePeriodManager(locationID: string, periodID: string, managerID: string): Promise<void> {

        return HttpClient.sessionRequest({
            method: "DELETE",
            url: Route(`/api/v1/Locations/${locationID}/EvaluationPeriods/${periodID}/Managers/${managerID}`),
        })
            .then(() => { })
            .catch((error) => {
                throw error;
            });
    }



    uploadPeriodManagersFromXLSX(locationId: string, periodID: string, file: File): Promise<void> {

        var formData = new FormData();
        formData.append("file", file)

        return HttpClient.sessionRequest({
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept": "application/json",
            },
            url: Route(`/api/v1/Locations/${locationId}/EvaluationPeriods/${periodID}/Managers/FromXLSX`),
            data: formData
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }
}