import "./DelegateManagerPopup.css";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup } from "../../../lib/infrastructure/ui/UIServices";

import { Avatar } from "../../../lib/components/avatar/Avatar";
import { CardContainer } from "../../../lib/layouts/containers/card/CardContainer";
import { ColumnDefinition } from "../../../lib/components/table/TableInterfaces";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { FormFieldTextInput } from "../../../lib/components/form/form-field/FormFieldTextInput";
import { FullScreenLoader } from "../../../lib/components/loader/FullScreenLoader";
import { ManagerAndDelegation } from "../models/domain/ManagersAndDelegations";
import { ManagerDelegationCreateDTO } from "../models/api/ManagerDelegationCreateDTO";
import { ManagersDelegationsService } from "../services/ManagersDelegationsService";
import { ManagersQueryOptions } from "../models/api/ManagersQueryOptions";
import { ManagersToDelegate } from "../models/domain/ManagersToDelegate";
import { Notification } from "../../../lib/components/notifications/Notification";
import { PeriodManagerDetails } from "../models/domain/PeriodManagerDetails";
import { PeriodManagerService } from "../services/PeriodManagerService";
import { PopupActionButtons } from "../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../lib/layouts/containers/popup-header/PopupHeader";
import { ResponsiveDataTable } from "../../../lib/components/table/ResponsiveDataTable";
import { ReactComponent as SearchIcon } from "../../../lib/assets/icons/search-icon.svg";
import { Spacer } from "../../../lib/components/separator/Spacer";
import { ReactComponent as UserIcon } from "../../../lib/assets/icons/user.svg";
import { toPairs } from "lodash";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../lib/components/form/Form";
import { useServiceCallPro2 } from "../../../lib/hooks/useServiceCall";

/* ICONS */




var svc = new PeriodManagerService();
var delegationsSvc = new ManagersDelegationsService();

export interface DelegateManagerPopupProps {
    originalManagerEmail: string;
    originalManagerName: string;
    locationID: number | undefined;
    periodID: string | undefined;
    onChangesMade: () => void;
}


export function DelegateManagerPopup(props: DelegateManagerPopupProps) {
    const openPopup = usePopup();
    const closePopup = useClosePopup();
    const createNotification = useCreateNotification();

    var searchboxFormControl = useFormControl<string>({});

    const [tableRenderId, setTableRenderID] = useState<number>(1);

    const [selectedManagerToDelegate, setSelectedManagerToDelegate] = useState<PeriodManagerDetails>();
    const [possibleManagersToDelegate, setPossibleManagersToDelegate] = useState<ManagersToDelegate[]>([]);
    const [topManager, setTopManager] = useState<PeriodManagerDetails>();
    const [totalItems, setTotalItems] = useState<number>();


    const [itemsPerPage, setItemsPerPage] = useState(3);
    const [currentpage, setCurrentPage] = useState(0);

    const [timer, setTimer] = useState<NodeJS.Timeout>();
    const [searchWord, setSearchWord] = useState<string>();


    /*****************
     * SERVICE CALLS 
     ****************/

    useEffect(() => {
        getTopManager();
    }, []);



    useEffect(() => {
        if (searchWord?.trim()) {
            setPossibleManagersToDelegate([]);
            setTableRenderID(tableRenderId + 1);
            setSelectedManagerToDelegate(topManager);
            getPossibleManagersToDelegate();
        }
        else {
            setPossibleManagersToDelegate([]);
            setSelectedManagerToDelegate(topManager);
        }
    }, [currentpage, itemsPerPage, searchWord]);






    const [getPossibleManagersToDelegateCall, getPossibleManagersToDelegateCallIsLoading] = useServiceCallPro2(svc, svc.getPossibleManagersToDelegate);
    const [createManagerDelegationCall, createManagerDelegationCallIsLoading] = useServiceCallPro2(delegationsSvc, delegationsSvc.createManagerDelegation);
    const [getTopManagerCall, getTopManagerCallIsLoading] = useServiceCallPro2(svc, svc.getTopManager);




    useEffect(() => {
        if (timer) {
            clearTimeout(timer);
        }
        var responseTimeOut = setTimeout(() => {
            setCurrentPage(0);
            setSearchWord(searchboxFormControl.value);

        }, 500);
        setTimer(responseTimeOut);
    }, [searchboxFormControl.value]);




    const handleOnPageAndItemsChanged = useCallback(
        (items: number, currentPage: number) => {
            setItemsPerPage(items);
            setCurrentPage(currentPage);
        },
        [setItemsPerPage, setCurrentPage]
    );



    const getPossibleManagersToDelegate = useCallback(() => {

        if (!props.locationID || !props.periodID) return;

        var queryOptions: ManagersQueryOptions = {
            page: currentpage,
            pageLength: itemsPerPage,
            searchWord: searchWord,
            filterByIsActive: false,
        };

        getPossibleManagersToDelegateCall(`${props.locationID}`, props.periodID, props.originalManagerEmail, queryOptions)
            .then((data) => {
                setPossibleManagersToDelegate(data.possibleManagersToDelegate);
                setTotalItems(data.totalItems);
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [props.locationID, props.periodID, getPossibleManagersToDelegateCall, setPossibleManagersToDelegate, setTotalItems, openPopup, currentpage, itemsPerPage, searchWord]);




    const getTopManager = useCallback(() => {

        if (!props.locationID || !props.periodID) return;

        getTopManagerCall(`${props.locationID}`, props.periodID, props.originalManagerEmail)
            .then((data) => {
                setTopManager(data);
                setSelectedManagerToDelegate(data);
            })
            .catch((error) => {

                if (!error) return;

                if (error?.response?.data?.message === "Not Found") {
                    setTopManager({ id: null, email: "", name: "" });
                    return;
                }
                openPopup(<ErrorPopup>{error?.response?.data?.message}</ErrorPopup>);
            });
    }, [props.locationID, props.periodID, props.originalManagerEmail, setTopManager, getTopManagerCall, setSelectedManagerToDelegate, openPopup]);




    const handleCreateManagerDelegationBtnClicked = useCallback(() => {
        if (!props.locationID || !props.periodID) return;

        if (!selectedManagerToDelegate?.email) return;

        let request: ManagerDelegationCreateDTO = {
            nextManager: selectedManagerToDelegate?.email,
            originalManager: props.originalManagerEmail
        };


        createManagerDelegationCall(`${props.locationID}`, props.periodID, request)
            .then(() => {
                closePopup();

                props.onChangesMade();
                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate(
                            "MANAGERS.POPUPS.DelegateManagerSuccess"
                        )}
                    ></Notification>
                );
            })
            .catch((error) => {
                if (!error) return;

                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [props.locationID, props.periodID, props.originalManagerEmail, createManagerDelegationCall,
        closePopup, createNotification, openPopup, selectedManagerToDelegate]);




    const columns: ColumnDefinition<ManagersToDelegate>[] = useMemo(
        () => [
            {
                cellRenderProp: (v) =>
                    <div className="employee-info">
                        <div className="employee-name">
                            {v.displayName}
                        </div>
                        <div className="employee-email">{v.email}
                        </div>
                    </div>,
                headerRender: translate("COMMON.Name"),
                isMobilePrimaryCell: true
            },
        ], []);


    return (
        <PopupContainer className="popup-delegate-managers">
            <PopupHeader title={translate("MANAGERS.INFOANDFORM.DelegateEvaluations")} />
            <PopupContent isLoading={getTopManagerCallIsLoading}>
                {createManagerDelegationCallIsLoading ? <FullScreenLoader /> : null}
                <div className="container">
                    <CardContainer className="manager-card">
                        <div className="small-copy manager-label">{translate("MANAGERS.Manager")}</div>
                        <div className="manager-value"><UserIcon /><span>{props.originalManagerName ?? "-"}</span></div>
                    </CardContainer>

                    <Spacer mode="vertical" px={16} />

                    <CardContainer className="selected-manager-container">
                        <div className="manager-label">{topManager?.email?.toLowerCase() === selectedManagerToDelegate?.email?.toLowerCase() ? translate("MANAGERS.INFOANDFORM.SuggestedDelegationTitle") :
                            translate("MANAGERS.INFOANDFORM.OtherManagerSelectedTitle")}
                        </div>
                        <div className="manager-value">
                            {topManager?.id === null ? translate("MANAGERS.INFOANDFORM.NoSuggestionFound") :
                                <>
                                    <Avatar name={selectedManagerToDelegate?.name ?? "-"} />
                                    {selectedManagerToDelegate?.name}
                                </>
                            }

                        </div>
                    </CardContainer>

                    <Spacer mode="vertical" px={16} />

                    <div className="small-copy bold">{translate("MANAGERS.INFOANDFORM.SearchOtherPerson")}</div>

                    <Spacer mode={"vertical"} px="12" />

                    <FormFieldTextInput
                        formControl={searchboxFormControl}
                        icon={<SearchIcon />}
                        placeholder={translate("COMMON.Form.SearchPlaceholder")}
                        className="manager-box"
                    />

                    <Spacer mode={"vertical"} px="8" />
                    {searchboxFormControl.value?.trim() ?
                        <div className="possible-managers-table">
                            <ResponsiveDataTable
                                key={tableRenderId}
                                columnDefinitions={columns}
                                items={possibleManagersToDelegate || []}
                                totalitems={totalItems || 0}
                                paginationOptions={{ itemsPerPage: itemsPerPage, itemsPerPageOptions: [3, 6] }}
                                currentpage={currentpage}
                                showSelectedRowHighlighted
                                isLoading={getPossibleManagersToDelegateCallIsLoading}
                                onClickRow={(selectedRow) => {
                                    if (selectedRow.email.toLowerCase() === selectedManagerToDelegate?.email.toLowerCase())
                                        setSelectedManagerToDelegate(topManager);
                                    else setSelectedManagerToDelegate({ name: selectedRow.displayName, email: selectedRow.email });
                                }}

                                onPageAndItemsChanged={handleOnPageAndItemsChanged}
                            /> <Spacer mode={"vertical"} px="16" /> </div> : <Spacer mode={"vertical"} px="16" />}
                </div>

                <PopupActionButtons
                    buttons={[
                        {
                            text: translate("COMMON.Cancel"),
                            type: "tertiary",
                            onClick: () => closePopup(),
                        },
                        {
                            text: translate("MANAGERS.INFOANDFORM.Delegate"),
                            className: "delegate-btn",
                            type: "primary",
                            onClick: () => { handleCreateManagerDelegationBtnClicked() }
                        },
                    ]}
                />
            </PopupContent>
        </PopupContainer>
    );
}



