import { AbsenteeismCodeDTO } from "../dtos/AbsenteeismCodeDTO";
import { AbsenteeismCodeDetails } from "../domain/AbsenteeismCodeDetails";

export function APIAbsenteeismCodeDetailsToDomainMapper(apiDto: AbsenteeismCodeDTO): AbsenteeismCodeDetails {
    return {
        absenteeismCodeId: apiDto.absenteeismCodeId,
        description: apiDto.description,
        isProblematic: apiDto.isProblematic,
        sapNumber: apiDto.sapNumber
    }
}