import './ConsultHistoryLinesPopup.css';

import { useCallback, useEffect, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../lib/infrastructure/ui/UIServices";

import { EmptyState } from '../../../../lib/components/emptystates/EmptyState';
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { EvaluationDetails } from "../../models/domain/EvaluationDetails";
import { EvaluationHistoriesService } from "../../../evaluation-history/services/EvaluationHistoriesService";
import { EvaluationHistory } from "../../../evaluation-history/components/EvaluationHistory";
import { EvaluationHistoryDetails } from "../../../evaluation-history/models/domain/EvaluationHistoryDetails";
import { Loader } from '../../../../lib/components/loader/Loader';
import { PopupContainer } from "../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../lib/layouts/containers/popup-header/PopupHeader";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../../lib/components/form/Form";
import { useServiceCallPro2 } from "../../../../lib/hooks/useServiceCall";

var svc = new EvaluationHistoriesService();

export interface IConsultHistoryLinesPopupProps {
    onAddCommentCompleted: (ev: EvaluationDetails) => void;
    locationId: number;
    periodId: string;
    evaluationId: number;
}

export function ConsultHistoryLinesPopup(props: IConsultHistoryLinesPopupProps) {
    const openPopup = usePopup();
    const closePopup = useClosePopup();
    const createNotification = useCreateNotification();

    const [evaluationHistoryLines, setEvaluationHistoryLines] = useState<EvaluationHistoryDetails[]>([]);
    const [totalItems, setTotalItems] = useState<number>();

    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchWord, setSearchWord] = useState<string>();

    const [timer, setTimer] = useState<NodeJS.Timeout>();

    var searchBoxFormControl = useFormControl<string>({
        isDisabled: false,
        enableAutoValidate: false,
    });



    /*****************
     * SERVICE CALLS 
    ****************/



    const [getEvaluationHistoryLinesCall, getEvaluationHistoryLinesCallIsLoading] = useServiceCallPro2(svc, svc.getEvaluationHistoryLines);



    /****************************
    * DATA REQUESTS
    *****************************/



    const handleOnPageAndItemsChanged = useCallback(
        (items: number, currentPage: number) => {
            setItemsPerPage(items);
            setCurrentPage(currentPage);
        },
        [setItemsPerPage, setCurrentPage]
    );




    const getEvaluationHistoryLines = useCallback(() => {

        if (!props.locationId || !props.periodId || !props.evaluationId) return;


        getEvaluationHistoryLinesCall(`${props.locationId}`, props.periodId, `${props.evaluationId}`)
            .then((data) => {
                setEvaluationHistoryLines(data.evaluations);
                setTotalItems(data.totalItems);
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [props.locationId, props.periodId, props.evaluationId, getEvaluationHistoryLinesCall, setEvaluationHistoryLines,
        setTotalItems, openPopup]);





    /****************************
     * DATA MANIPULATION EFFECTS
    *****************************/



    useEffect(() => {
        getEvaluationHistoryLines();
    }, [props.locationId, props.periodId, props.evaluationId]);



    /****************************
     * USER ACTIONS
    *****************************/







    /****************************
     * CSS & HTML
    *****************************/

    return (<PopupContainer className="popup-history-lines">
        <PopupHeader title={translate("EVALUATIONS.INFOANDFORM.History")} />
        <PopupContent>
            {getEvaluationHistoryLinesCallIsLoading
                ? <Loader />
                : evaluationHistoryLines && evaluationHistoryLines.length > 0
                    ? <EvaluationHistory locationId={props.locationId} periodId={props.periodId} historyLines={evaluationHistoryLines}></EvaluationHistory>
                    : <div className="table-empty-loader-container">
                        <EmptyState hideSubtitle title={translate("EVALUATIONS.INFOANDFORM.History")} subtitleMessage={translate("EVALUATIONS.HISTORY.NoHistoryLines")} />
                    </div>}
        </PopupContent>
    </PopupContainer>)
}