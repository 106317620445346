import { APIUserToDomainMapper } from "../../../../models/api/users/APIUserToDomainMapper";
import { EvaluationPeriodDetails } from "../domain/EvaluationPeriodDetails";
import { EvaluationPeriodDetailsDTO } from "../dtos/EvaluationPeriodDetailsDTO";

export function APIEvaluationPeriodDetailstoDomainMapper(apiDto: EvaluationPeriodDetailsDTO): EvaluationPeriodDetails {
    return {
        id: apiDto.id,
        startDate: apiDto.startDate,
        endDate: apiDto.endDate,
        name: apiDto.name,
        state: apiDto.state,
        createdAt: apiDto.createdAt,
        modifiedAt: apiDto.modifiedAt,
        createdBy: APIUserToDomainMapper(apiDto?.createdBy),
        modifiedBy: APIUserToDomainMapper(apiDto?.modifiedBy),
        periodDays: apiDto.periodDays,
        monthlySalaryMultiplier: apiDto.monthlySalaryMultiplier,
        periodMonetaryCeilingPercentage: apiDto.periodMonetaryCeilingPercentage,
        isMatrixValid: apiDto.isMatrixValid,
        periodMonetaryCeiling: apiDto.periodMonetaryCeiling,
        minimumBonusPercentage: apiDto?.minimumBonusPercentage ? apiDto.minimumBonusPercentage : 0,

        evaluationsStartDay: apiDto.evaluationsStartDay,
        evaluationsFinalDay: apiDto.evaluationsFinalDay,

        calibrationsStartDay: apiDto.calibrationsStartDay,
        calibrationsFinalDay: apiDto.calibrationsFinalDay
    }
}