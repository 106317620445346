import * as qs from "qs";

import { APIEvaluationPeriodDetailstoDomainMapper } from "../models/mappers/APIEvaluationPeriodDetailstoDomainMapper";
import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { EvaluationPeriodCreateDTO } from "../models/dtos/EvaluationPeriodCreateDTO";
import { EvaluationPeriodDetails } from "../models/domain/EvaluationPeriodDetails";
import { EvaluationPeriodDetailsDTO } from "../models/dtos/EvaluationPeriodDetailsDTO";
import { EvaluationPeriodStatusUpdateDTO } from "../models/dtos/EvaluationPeriodStatusUpdateDTO";
import { EvaluationPeriodSummary } from "../models/domain/EvaluationPeriodSummary";
import { EvaluationPeriodSummaryDTO } from "../models/dtos/EvaluationPeriodSummaryDTO";
import { EvaluationPeriodUpdateDTO } from "../models/api/EvaluationPeriodUpdateDTO";
import { EvaluationPeriodsResponseDTO } from "../models/dtos/EvaluationPeriodsResponseDTO";
import { HttpClient } from "../../../lib/infrastructure/http/HttpClient";
import { QueryOptions } from "../../../models/api/QueryOptions";

const Route = (path: string) => {
    return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};

export interface EvaluationPeriodsSummary {
    info: EvaluationPeriodSummary[];
    totalItems: number;
}

export class EvaluationPeriodsService {


    getEvaluationPeriods(locationId: string, query: QueryOptions, abortSignal: AbortSignal | undefined): Promise<EvaluationPeriodsSummary> {
        return HttpClient.sessionRequest<EvaluationPeriodsResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/EvaluationPeriods`),
            signal: abortSignal,
            params: query,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" });
            },
        })
            .then((response) => {

                var mappedEvaluationPeriods = response.data.periods
                    .map((p): EvaluationPeriodSummary => ({
                        id: p.id,
                        name: p.name,
                        startDate: p.startDate,
                        endDate: p.endDate,
                        state: p.state,
                        periodDays: p.periodDays,
                        monthlySalaryMultiplier: p.monthlySalaryMultiplier,
                        periodMonetaryCeilingPercentage: p.periodMonetaryCeilingPercentage,
                        periodMonetaryCeiling: p.periodMonetaryCeiling,
                    }));
                    
                return ({
                    info: mappedEvaluationPeriods,
                    totalItems: response.data.totalItems
                })
            })
            .catch((error) => {
                throw error;
            });
    }



    createEvaluationPeriods(locationId: string, requestDto: EvaluationPeriodCreateDTO): Promise<void> {
        return HttpClient.sessionRequest<EvaluationPeriodDetailsDTO>({
            method: "POST",
            url: Route(`/api/v1/Locations/${locationId}/EvaluationPeriods`),
            data: requestDto,
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }

    
    cloneEvaluationPeriods(locationId: string, periodIdToClone: string, requestDto: EvaluationPeriodCreateDTO): Promise<void> {
        return HttpClient.sessionRequest<EvaluationPeriodDetailsDTO>({
            method: "POST",
            url: Route(`/api/v1/Locations/${locationId}/EvaluationPeriods/${periodIdToClone}`),
            data: requestDto,
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }




    getEvaluationPeriodByID(locationId: string, evaluationPeriodID: string): Promise<EvaluationPeriodDetails | null> {
        return HttpClient.sessionRequest<EvaluationPeriodDetailsDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/EvaluationPeriods/${evaluationPeriodID}`),
        })
            .then((response) => {

                if (!response.data) return null;

                return APIEvaluationPeriodDetailstoDomainMapper(response.data);
            })
            .catch((error) => {
                throw error;
            });
    }

    getEvaluationPeriodByIDSummary(locationId: string, evaluationPeriodID: string): Promise<EvaluationPeriodSummary | null> {
        return HttpClient.sessionRequest<EvaluationPeriodSummaryDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/EvaluationPeriods/${evaluationPeriodID}/Summary`),
        })
            .then((res) => {

                if (!res.data) return null;

                var mappedResponse: EvaluationPeriodSummary = {
                    id: res.data.id,
                    name: res.data.name,
                    startDate: res.data.startDate,
                    endDate: res.data.endDate,
                    state: res.data.state,
                    periodDays: res.data.periodDays,
                    monthlySalaryMultiplier: res.data.monthlySalaryMultiplier,
                    periodMonetaryCeilingPercentage: res.data.periodMonetaryCeilingPercentage,
                    periodMonetaryCeiling: res.data.periodMonetaryCeiling,
                    minimumBonusPercentage: res.data.minimumBonusPercentage ?? 0
                };

                return mappedResponse;
            })
            .catch((error) => {
                throw error;
            });
    }


    editEvaluationPeriod(locationID: string, evaluationPeriodID: string, requestDto: EvaluationPeriodUpdateDTO): Promise<void> {
        return HttpClient.sessionRequest({
            method: "PUT",
            url: Route(`/api/v1/Locations/${locationID}/EvaluationPeriods/${evaluationPeriodID}`),
            data: requestDto
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }

    updateEvaluationPeriodStatus(locationID: string, evaluationPeriodID: string, requestDto: EvaluationPeriodStatusUpdateDTO): Promise<void> {
        return HttpClient.sessionRequest({
            method: "PUT",
            url: Route(`/api/v1/Locations/${locationID}/EvaluationPeriods/${evaluationPeriodID}/Status`),
            data: requestDto
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }

}
