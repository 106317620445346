import "./ViewEvaluationPeriodsPage.css";

import { EvaluationPeriodState, EvaluationPeriodStateTranslationTerms } from "../../../models/enums/EvaluationPeriodState";
import { InternationalizationService, translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { useCallback, useEffect, useMemo, useState } from "react";
import { usePopup, useWindowResize } from "../../../lib/infrastructure/ui/UIServices";
import { ReactComponent as AddIcon } from "../../../lib/assets/icons/add.svg";
import { Button } from "../../../lib/components/buttons/Button";
import { ReactComponent as CloneIcon } from "../../../lib/assets/icons/clone.svg";
import { ColumnDefinition } from "../../../lib/components/table/TableInterfaces";
import { CreatePeriodPopup } from "./create-evaluation-period/CreatePeriodPopup";
import { DateTime } from "luxon";
import { EditEvaluationPeriodPopup } from "./EditEvaluationPeriodPopup";
import { ReactComponent as EditIcon } from "../../../lib/assets/icons/editar.svg";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { EvaluationPeriodSummary } from "../models/domain/EvaluationPeriodSummary";
import { EvaluationPeriodsService } from "../services/EvaluationPeriodsService";
import { FormFieldTextInput } from "../../../lib/components/form/form-field/FormFieldTextInput";
import { GetEvaluationPeriodStateCss } from "../../../common/helpers/GetEvaluationPeriodStateCss";
import { IconButton } from "../../../lib/components/buttons/IconButton";
import { PageLayout } from "../../../lib/layouts/main-content/PageLayout";
import { QueryOptions } from "../../../models/api/QueryOptions";
import { ResponsiveDataTable } from "../../../lib/components/table/ResponsiveDataTable";
import { ScalableIconButton } from "../../../lib/components/buttons/ScalableIconButton";
import { ReactComponent as SearchIcon } from "../../../lib/assets/icons/search-icon.svg";
import { Tag } from "../../../lib/components/tag/Tag";
import { Tooltip } from "../../../lib/components/tooltip/Tooltip";
import { useFormControl } from "../../../lib/components/form/Form";
import { useGlobalLocation } from "../../../lib/infrastructure/location/LocationServices";
import { useNavigate } from "react-router-dom";
import { useServiceCallPro } from "../../../lib/hooks/useServiceCall";
import { Permission } from "../../../models/api/gate/Permission";
import { useHasPermissions } from "../../../lib/infrastructure/authorization/useHasLocationPermissions";
import axios from "axios";
var evaluationPeriodsSvc = new EvaluationPeriodsService();

export interface IOrderInfo {
  direction: "ASC" | "DESC" | "NONE";
  columnKey: string;
}


const topPermissions = [
  Permission.GLOBAL_ADMIN,
  Permission.LOCATION_ADMIN,
  Permission.HUMAN_RESOURCES
];


export function ViewEvaluationPeriodsPage() {

  const { isLoadingPermissions, hasPermission } = useHasPermissions(topPermissions);
  const navigate = useNavigate();

  const windowResize = useWindowResize();

  const openPopup = usePopup();
  const locationID = useGlobalLocation();

  const [evaluationPeriods, setEvaluationPeriods] = useState<EvaluationPeriodSummary[]>([]);
  const [totalItems, setTotalItems] = useState<number>();

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentpage, setCurrentPage] = useState(0);

  const [timer, setTimer] = useState<NodeJS.Timeout>();
  const [searchWord, setSearchWord] = useState<string>();

  var searchboxController = useFormControl<string>({
    isDisabled: false,
    enableAutoValidate: false,
  });


  /*****************
   * SERVICE CALLS 
   ****************/

  const getEvaluationPeriodsCall = useServiceCallPro(evaluationPeriodsSvc.getEvaluationPeriods);

  

  useEffect(() => {

    const controller = new AbortController();
    getEvaluationPeriods(controller);
    return () => { controller.abort() };

  }, [currentpage, itemsPerPage, searchWord]);




  const getEvaluationPeriods = useCallback((controller: AbortController | undefined) => {

    if (!locationID) return;

    var queryOpts: QueryOptions = {
      searchWord: searchWord,
      page: currentpage,
      pageLength: itemsPerPage

    };

    getEvaluationPeriodsCall.invoke(`${locationID}`, queryOpts, controller?.signal)
      .then((data) => {
        setEvaluationPeriods(data.info);
        setTotalItems(data.totalItems);
      })
      .catch((error) => {
        if (!error || axios.isCancel(error)) return;
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
      });
  }, [locationID, getEvaluationPeriodsCall, openPopup,
    setEvaluationPeriods, setTotalItems, searchWord, currentpage, itemsPerPage]);





  useEffect(() => {
    if (timer)
      clearTimeout(timer);

    var responseTimeOut = setTimeout(() => {
      if (timer)
        setSearchWord(searchboxController.value);
    }, 1000);

    setTimer(responseTimeOut);

  }, [searchboxController.value]);





  const handleOnPageAndItemsChanged = useCallback(
    (items: number, currentPage: number) => {
      setItemsPerPage(items);
      setCurrentPage(currentPage);
    },
    [setItemsPerPage, setCurrentPage]
  );




  const columns: ColumnDefinition<EvaluationPeriodSummary>[] = useMemo(
    () => [
      {
        cellRenderProp: (v) => v.name,
        headerRender: translate("BONUS.Quarter"),
        isMobilePrimaryCell: true,
      },
      {
        cellRenderProp: (v) => v.startDate ? DateTime.fromISO(v.startDate).toFormat("dd LLL yyyy", { locale: InternationalizationService.getLocale() }) : "-",
        headerRender: translate("BONUS.StartDate"),
      },
      {
        cellRenderProp: (v) => v.endDate ? DateTime.fromISO(v.endDate).toFormat("dd LLL yyyy", { locale: InternationalizationService.getLocale() }) : "-",
        headerRender: translate("BONUS.EndDate"),
      },
      {
        cellRenderProp: (v) => (
          <Tag
            text={translate(EvaluationPeriodStateTranslationTerms[v.state])}
            backgroundColor={GetEvaluationPeriodStateCss(v.state)}
            isTiny={windowResize < 1024}
          />
        ),
        headerRender: translate("BONUS.Status"),
        isStatusCell: true
      },
      {
        cellRenderProp: (v) => {
          if (isLoadingPermissions || !hasPermission) return null;
          return (
            <Tooltip text={translate("PERIODS.EditEvaluationPeriod")}>
              <ScalableIconButton
                isDisabled={v.state === EvaluationPeriodState.Completed || v.state === EvaluationPeriodState.Canceled}
                icon={<EditIcon />}
                onClick={() =>
                  openPopup(
                    <EditEvaluationPeriodPopup
                      locationID={`${locationID}`}
                      periodID={`${v.id}`}
                      onCompletedOperations={() => { getEvaluationPeriods(undefined); }}
                    />
                  )}
                size={24}
              />
            </Tooltip>
          );
        },
        width: "24px",
        columnKey: "stats",
        isSortable: false,
        isMobileHeaderIcon: true,
      },
      {
        cellRenderProp: (v) => {
          if (isLoadingPermissions || !hasPermission) return null;
          return (<Tooltip text={translate("PERIODS.ClonePeriod")}>
            <ScalableIconButton
              icon={<CloneIcon />}
              onClick={() => locationID ? openPopup(<CreatePeriodPopup locationID={`${locationID}`} periodIdToClone={`${v.id}`}
                onCompletedOperations={() => getEvaluationPeriods(undefined)} />) : null}
              size={24}
            />
          </Tooltip>)
        },
        headerRender: <>{translate("REQUESTS.INFOANDFORM.Clone")}</>,
        columnKey: "clone",
        width: "24px",
        isMobileHeaderIcon: true,
      },
    ], [openPopup, getEvaluationPeriods, locationID, windowResize, isLoadingPermissions, hasPermission]);



  const renderCreatePeriodBtn = useMemo(() => {
    if (isLoadingPermissions || !hasPermission) return null;
    if (windowResize > 768)
      return (
        <Button
          text={translate("PERIODS.CreatePeriod")}
          type="primary"
          onClick={() => locationID ? openPopup(<CreatePeriodPopup locationID={`${locationID}`}
            onCompletedOperations={() => getEvaluationPeriods(undefined)} />) : null}
        />
      );
    else
      return (
        <IconButton
          icon={<AddIcon />}
          type="primary"
          onClick={() => locationID ? openPopup(<CreatePeriodPopup locationID={`${locationID}`}
            onCompletedOperations={() => getEvaluationPeriods(undefined)} />) : null}
        />
      );
  }, [getEvaluationPeriods, locationID, openPopup, windowResize, hasPermission, isLoadingPermissions]);


  return (
    <PageLayout
      tabTitle={translate("PERIODS.EvaluationPeriods")}
      pageTitle={translate("PERIODS.EvaluationPeriods")}
      className="evaluation-periods"
      actions={renderCreatePeriodBtn}
    >
      <FormFieldTextInput
        formControl={searchboxController}
        icon={<SearchIcon />}
        placeholder={translate("COMMON.FORM.SearchPlaceholder")}
        className="search-box"
      />
      <ResponsiveDataTable
        columnDefinitions={columns}
        items={evaluationPeriods || []}
        totalitems={totalItems || 0}
        paginationOptions={{ itemsPerPage: itemsPerPage, itemsPerPageOptions: [10, 20, 30] }}
        currentpage={currentpage}
        isLoading={getEvaluationPeriodsCall.isLoading}
        onPageAndItemsChanged={handleOnPageAndItemsChanged}
        onClickRow={(period) => navigate(`${period.id}`)}
      ></ResponsiveDataTable>
    </PageLayout>
    
  );
}
