import { EvaluationHistoryDetails } from "../domain/EvaluationHistoryDetails";
import { EvaluationHistoryDetailsDTO } from "../dtos/EvaluationHistoryDetailsDTO";

export function APIEvaluationHistoryDetailsToDomainMapper(apiDto: EvaluationHistoryDetailsDTO): EvaluationHistoryDetails {
    return {
        evaluationHistoryId: apiDto.evaluationHistoryId,
        operation: apiDto.operation,
        operationAt: apiDto.operationAt,
        user: {
            email: apiDto.user.userEmail,
            id: apiDto.user?.userId ?? "",
            name: apiDto.user.userDisplayName, 
            employeeID: apiDto.user?.userEmployeeID ?? ""
        },
        cutReasonId: apiDto.cutReasonId,
        cutReason: apiDto.cutReason,
        factorId: apiDto.factorId,
        factor: apiDto.factor,
        oldFactorValue: apiDto.oldFactorValue,
        newFactorValue: apiDto.newFactorValue,
        feedback: apiDto.feedback,
        rejectReason: apiDto.rejectReason,
        cutReasonJustification: apiDto.cutReasonJustification,
        isFeedbackGivenInPerson: apiDto.isFeedbackGivenInPerson,
        evaluationId: apiDto.evaluationId,
    }
}