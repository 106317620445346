import { APIEvaluationValueToDomainMapper } from "./APIEvaluationValueToDomainMapper";
import { APIUserToDomainMapper } from "../../../../models/api/users/APIUserToDomainMapper";
import { EvaluationDetails } from "../domain/EvaluationDetails";
import { EvaluationDetailsDTO } from "../dtos/EvaluationDetailsDTO";
import { APIHoursDTOtoHoursSummary } from "../../../hours-absence-map/models/mappers/APIHoursDTOtoHoursSummary";

export function APIEvaluationDetailsToDomainMapper(apiDto: EvaluationDetailsDTO): EvaluationDetails {
    return {
        id: apiDto.id,
        targetEmployee: {
            id: apiDto.targetEmployee?.userId ?? "",
            employeeID: apiDto.targetEmployee?.userEmployeeID ?? "",
            email: apiDto.targetEmployee.userEmail,
            name: apiDto.targetEmployee.userDisplayName
        },

        employeeFactorTotal: apiDto.employeeFactorTotal,
        employeeFactorTotalPercentage: apiDto.employeeFactorTotalPercentage,

        employeeScoreTotal: apiDto.employeeScoreTotal,
        employeeScoreTotalPercentage: apiDto.employeeScoreTotalPercentage,

        finalScore: apiDto.finalScore,
        finalScorePercentage: apiDto.finalScorePercentage,

        finalScoreWithCut: apiDto.finalScoreWithCut,
        finalScoreWithCutPercentage: apiDto.finalScoreWithCutPercentage,

        companyFactorTotal: apiDto.companyFactorTotal,
        companyFactorTotalPercentage: apiDto.companyFactorTotalPercentage,

        absentismFactorTotal: apiDto.absentismFactorTotal,
        absentismFactorTotalPercentage: apiDto.absentismFactorTotalPercentage,

        baseValue: apiDto.baseValue,
        finalPayout: apiDto.finalPayout,

        createdBy: APIUserToDomainMapper(apiDto.createdBy),
        createdAt: apiDto.createdAt,

        modifiedBy: APIUserToDomainMapper(apiDto.modifiedBy),
        modifiedAt: apiDto.modifiedAt,

        status: apiDto.status,
        rejectedAt: apiDto.rejectedAt,
        rejectedBy: apiDto.rejectedBy,

        isBonusCut: apiDto.isBonusCut,
        bonusCutReasonId: apiDto.bonusCutReasonId,
        bonusCutReason: apiDto.bonusCutReason,
        cutReasonJustification: apiDto.cutReasonJustification,
        needsFeedback: apiDto.needsFeedback,
        isFeedbackGivenInPerson: apiDto.isFeedbackGivenInPerson ?? false,
        statusReason: apiDto.statusReason,

        feedback: apiDto.feedback,
        lastComment: apiDto.lastComment,
        totalComments: apiDto.totalComments,

        evaluationValues: apiDto.evaluationValues.map(ev => APIEvaluationValueToDomainMapper(ev)),

        managerId: apiDto.parentManagerId,
        managerName: apiDto.parentManagerName,

        hasProblematicAbsences: apiDto.hasProblematicAbsences ?? false,

        hourRecords: apiDto.hourRecords?.map(r => APIHoursDTOtoHoursSummary(r)),

        permissions: {
            canCalibrate: apiDto.permissions?.canCalibrate ?? false,
            canEvaluate: apiDto.permissions?.canEvaluate ?? false,
            canRevertConfirmation: apiDto.permissions?.canRevertConfirmation ?? false
        }
    }
}