export enum LocationGroupType {
    ACCESS = 1,
    LOCATION_ADMIN = 3,
    HUMAN_RESOURCES = 10,
    EVALUATOR = 11 //Team Manager
}


export const LocationGroupTypeTranslationTerms: { [key: number]: string } = {
    1: "GLOBALADMIN.ACCESSGROUPS.TYPES.Collaborator",
    3: "GLOBALADMIN.ACCESSGROUPS.TYPES.LocalAdmin",
    10: "GLOBALADMIN.ACCESSGROUPS.TYPES.RH",
    11: "GLOBALADMIN.ACCESSGROUPS.TYPES.TeamManager",

}