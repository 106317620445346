import { Navigate, Route, Routes } from "react-router-dom";
import { LocationsModule } from './App.RoutingModule.LocationModule';

export function AdministrationModule() {
  return (
      <Routes>
        <Route index element={<Navigate to="locations" />} />
        <Route path="locations/*" element={<LocationsModule />} />
      </Routes>
  );
}