import './ConsultEmployeeHourRecordsPopup.css';

import { PopupContainer } from "../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../lib/layouts/containers/popup-header/PopupHeader";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { HourRecordSummary } from '../../../hours-absence-map/models/domain/HourRecordSummary';
import { ResponsiveDataTable } from '../../../../lib/components/table/ResponsiveDataTable';
import { ColumnDefinition } from '../../../../lib/components/table/TableInterfaces';
import { useMemo } from 'react';
import { CardContainer } from '../../../../lib/layouts/containers/card/CardContainer';
import { InfoBlock } from '../../../../lib/components/info-block/InfoBlock';
import { Spacer } from '../../../../lib/components/separator/Spacer';
import { ReactComponent as WarningIcon } from "../../../../lib/assets/icons/warning-page-icon.svg";



export interface ConsultEmployeeHourRecordsPopupProps {
    employeeName: string;
    employeeValmetID: string;
    hourRecords: HourRecordSummary[];
}

export function ConsultEmployeeHourRecordsPopup(props: ConsultEmployeeHourRecordsPopupProps) {


    const columns: ColumnDefinition<HourRecordSummary>[] = useMemo(
        () => [
            {
                cellRenderProp: (v) => v.code,
                headerRender: translate("HOURSANDABSENCE.INFOANDFORM.AbsenteeismCode"),
                width: "20%",
            },
            {
                cellRenderProp: (v) => v.codeDescription,
                headerRender: translate("HOURSANDABSENCE.INFOANDFORM.CodeDescription"),
                isMobilePrimaryCell: true,
            },
            {
                cellRenderProp: (v) => v.isProblematic ? <WarningIcon className="warning-icon" /> : null,
                headerRender: translate("HOURSANDABSENCE.INFOANDFORM.Problematic"),
                isMobilePrimaryCell: true,
            },
            {
                cellRenderProp: (v) => v.abscenceDays,
                headerRender: translate("HOURSANDABSENCE.INFOANDFORM.AbsenceDays"),
                isMobilePrimaryCell: true,
            },
        ], [props.employeeName, props.hourRecords]);



    return (
        <PopupContainer className="popup-employee-hour-records">

            <PopupHeader title={translate("HOURSANDABSENCE.AbsenceMap")} />

            <CardContainer >
                <InfoBlock label='Valmet ID' value={props.employeeValmetID ?? "-"} />
                <InfoBlock label={translate("HOURSANDABSENCE.INFOANDFORM.CollaboratorName")} value={props.employeeName ?? "-"} />
            </CardContainer>

            <Spacer mode='vertical' px={20} />

            <PopupContent >
                <ResponsiveDataTable
                    columnDefinitions={columns}
                    items={props.hourRecords || []}
                    totalitems={props.hourRecords.length || 0}
                />
            </PopupContent>
        </PopupContainer>)
}