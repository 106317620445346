
import { ManagerEvaluations } from "../domain/ManagerEvaluations";
import { ManagerEvaluationsDTO } from "../dtos/ManagerEvaluationsDTO";
import { APIEvaluationDetailsToDomainMapper } from "./APIEvaluationDetailsToDomainMapper";

export function APIManagerEvaluationsDTOtoDomainMapper(m: ManagerEvaluationsDTO): ManagerEvaluations {

    return {
        managerId: m.managerId,
        managerName: m.managerName,
        nextManagerName: m.nextManagerName,
        evaluations: m.evaluations.map(e => APIEvaluationDetailsToDomainMapper(e)),
        totalItems: m.totalItems
    }
}