import { EmployeeHourRecordDetailsDTO } from "../dtos/EmployeeHourRecordDetailsDTO";
import { EmployeesHoursRecordsDetails } from "../domain/EmployeesHoursRecordsDetails";

export function APIEmployeesHoursRecordsDetailsToDomainMapper(apiDto: EmployeeHourRecordDetailsDTO): EmployeesHoursRecordsDetails {
    return {
        abscenceDays: apiDto.abscenceDays,
        code: apiDto.code,
        codeDescription: apiDto.codeDescription,
        employeeEmail: apiDto.employeeEmail,
        employeeHourRecordId: apiDto.employeeHourRecordId,
        employeeName: apiDto.employeeName,
        employeeNumber: apiDto.employeeNumber
    }
}



