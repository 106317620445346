import "./EmployeesListPage.css";

import { useMemo, useState } from "react";

import { ColumnDefinition } from "../../../lib/components/table/TableInterfaces";
import { Employee } from "../../evaluations/models/domain/Employee";
import { FormFieldTextInput } from "../../../lib/components/form/form-field/FormFieldTextInput";
import { PageLayout } from "../../../lib/layouts/main-content/PageLayout";
import { ResponsiveDataTable } from "../../../lib/components/table/ResponsiveDataTable";
import { ReactComponent as SearchIcon } from "../../../lib/assets/icons/search-icon.svg";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../lib/components/form/Form";
import { useNavigate } from "react-router-dom";

const employeesMock: Employee[] = [{
    id: "01",
    email: "john.doe@valmet.com",
    name: "John Doe",
    jobTitle: "Operário",
    department: "Setor X",
}, {
    id: "02",
    email: "john.doe@valmet.com",
    name: "John Doe",
    jobTitle: "Operário",
    department: "Setor X",
}, {
    id: "03",
    email: "john.doe@valmet.com",
    name: "John Doe",
    jobTitle: "Operário",
    department: "Setor X",
},];

interface IEmployeesListPageProps { }

export function EmployeesListPage(props: IEmployeesListPageProps) {
    const navigate = useNavigate();

    const [employees, setEmployees] = useState<Employee[]>(employeesMock);

    var searchboxController = useFormControl<string>({
        isDisabled: false,
        enableAutoValidate: false,
    });

    const columns: ColumnDefinition<Employee>[] = useMemo(
        () => [
            {
                cellRenderProp: (v) => v.name,
                headerRender: <>{translate("GLOBALADMIN.EMPLOYEES.INFOANDFORM.Name")}</>,
                isMobilePrimaryCell: true,
            },
            {
                cellRenderProp: (v) => v.email,
                headerRender: <>{translate("GLOBALADMIN.EMPLOYEES.INFOANDFORM.Email")}</>,
            },
            {
                cellRenderProp: (v) => v.jobTitle,
                headerRender: <>{translate("GLOBALADMIN.EMPLOYEES.INFOANDFORM.JobTitle")}</>,
            },
            {
                cellRenderProp: (v) => v.department,
                headerRender: <>{translate("GLOBALADMIN.EMPLOYEES.INFOANDFORM.Department")}</>,
            },
        ], []);

    return <PageLayout
        tabTitle={translate("GLOBALADMIN.EMPLOYEES.Employees")}
        showBreadcrumb
        pageTitle={translate("GLOBALADMIN.EMPLOYEES.Employees")}
        className="employees-list-page"
    >
        <FormFieldTextInput
            formControl={searchboxController}
            icon={<SearchIcon />}
            placeholder={translate("COMMON.FORM.SearchPlaceholder")}
            className="search-box"
        />

        <ResponsiveDataTable
            columnDefinitions={columns}
            items={employees || []}
            totalitems={employees.length || 0}
            // paginationOptions={{ itemsPerPage: itemsPerPage, itemsPerPageOptions: [6, 12, 18, 24] }}
            // currentpage={currentpage}
            // isLoading={getEvaluationPeriodsCall.isLoading}
            // onPageAndItemsChanged={handleOnPageAndItemsChanged}
            onClickRow={(employee) => navigate(`${employee.id}`)}
        />
    </PageLayout>;
}