import "./EvaluationPeriodDetailsPage.css";

import { EvaluationPeriodState, EvaluationPeriodStateTranslationTerms } from "../../../../models/enums/EvaluationPeriodState";
import { InternationalizationService, translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup, useWindowResize } from "../../../../lib/infrastructure/ui/UIServices";
import { Button } from "../../../../lib/components/buttons/Button";
import { CardContainer } from "../../../../lib/layouts/containers/card/CardContainer";
import { ReactComponent as CheckIcon } from "../../../../lib/assets/icons/check-btn.svg";
import { CompanyCriteriasSection } from "./period-criterias/CompanyCriteriasSection";
import { ContextualMenu } from "../../../../lib/components/contextual-menu/ContextualMenu";
import { DateTime } from "luxon";
import { EditEvaluationPeriodPopup } from "../EditEvaluationPeriodPopup";
import { EmployeeCriteriasSection } from "./period-criterias/EmployeeCriteriasSection";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { EvaluationPeriodDetails } from "../../models/domain/EvaluationPeriodDetails";
import { EvaluationPeriodsService } from "../../services/EvaluationPeriodsService";
import { FormSection } from "../../../../lib/components/form/section/FormSection";
import { FullScreenLoader } from "../../../../lib/components/loader/FullScreenLoader";
import { GetEvaluationPeriodStateCss } from "../../../../common/helpers/GetEvaluationPeriodStateCss";
import { IconButton } from "../../../../lib/components/buttons/IconButton";
import { InfoBlock } from "../../../../lib/components/info-block/InfoBlock";
import { ReactComponent as MoreActions } from "../../../../lib/assets/icons/more-actions.svg";
import { Notification } from "../../../../lib/components/notifications/Notification";
import { PageLayout } from "../../../../lib/layouts/main-content/PageLayout";
import { ReactComponent as ReOpenIcon } from "../../../../lib/assets/icons/reopen.svg";
import { ReactComponent as RejectDarkIcon } from "../../../../lib/assets/icons/reject-dark.svg";
import { ReactComponent as RejectIcon } from "../../../../lib/assets/icons/reject.svg";
import { ScalableIconButton } from "../../../../lib/components/buttons/ScalableIconButton";
import { Tag } from "../../../../lib/components/tag/Tag";
import { ReactComponent as WarningIcon } from "../../../../lib/assets/icons/warning-icon-v2.svg";
import { WarningPopup } from "../../../../lib/components/popup/WarningPopup";
import { useGlobalLocation } from "../../../../lib/infrastructure/location/LocationServices";
import { useParams } from "react-router-dom";

import { useServiceCallPro } from "../../../../lib/hooks/useServiceCall";
import { Permission } from "../../../../models/api/gate/Permission";
import { PagePermissions } from "../../../../lib/infrastructure/authorization/PagePermissions";
import { useHasPermissions } from "../../../../lib/infrastructure/authorization/useHasLocationPermissions";


var svc = new EvaluationPeriodsService();

const basePermissions = [Permission.GLOBAL_ADMIN, Permission.LOCATION_ADMIN, Permission.HUMAN_RESOURCES, Permission.LOCATION_MANAGER];
const topPermissions = [Permission.GLOBAL_ADMIN, Permission.LOCATION_ADMIN, Permission.HUMAN_RESOURCES];

export function EvaluationPeriodDetailsPage() {

    /*****************
     * SERVICE CALLS
     ****************/
    const getPeriodSummaryInfoCall = useServiceCallPro(svc.getEvaluationPeriodByID);
    const updateEvaluationPeriodStatus = useServiceCallPro(svc.updateEvaluationPeriodStatus);

    const openPopup = usePopup();
    const closePopup = useClosePopup();
    const locationID = useGlobalLocation();

    const createNotification = useCreateNotification();
    const windowResize = useWindowResize();

    const { id: evaluationPeriodID } = useParams();


    const [periodSummaryInfo, setPeriodSummaryInfo] = useState<EvaluationPeriodDetails>();


    const { isLoadingPermissions: loadingTopPermissions, hasPermission: hasTopPermissions } = useHasPermissions(topPermissions);



    const getEvaluationPeriodSummaryInfo = useCallback(() => {

        if (!locationID || !evaluationPeriodID) return;

        getPeriodSummaryInfoCall.invoke(`${locationID}`, evaluationPeriodID)
            .then((data) => {
                if (data)
                    setPeriodSummaryInfo(data);

            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [locationID, evaluationPeriodID, getPeriodSummaryInfoCall, openPopup, setPeriodSummaryInfo]);



    useEffect(() => {
        getEvaluationPeriodSummaryInfo();
    }, []);


    const handleChangePeriodStateClicked = useCallback((newState: EvaluationPeriodState, notificationSuccessMessage: string) => {

        if (!locationID || !evaluationPeriodID) return;

        updateEvaluationPeriodStatus.invoke(`${locationID}`, evaluationPeriodID, { state: newState })
            .then((_) => {
                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate(notificationSuccessMessage)}
                    ></Notification>
                );

                getEvaluationPeriodSummaryInfo();

            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [locationID, evaluationPeriodID, createNotification, getEvaluationPeriodSummaryInfo, openPopup]);




    const isPeriodClosed = useMemo(() => {
        return periodSummaryInfo?.state === EvaluationPeriodState.Completed || periodSummaryInfo?.state === EvaluationPeriodState.Canceled;
    }, [periodSummaryInfo]);



    const renderCardContainerSubtitleHeader = useMemo(() => {
        if (!periodSummaryInfo?.createdBy.name || !periodSummaryInfo.createdAt) return;
        return (
            <div className="sub-header-period-data">
                <div className="small-copy created-date">
                    {translate("COMMON.CreatedBy") + ": " + periodSummaryInfo?.createdBy.name
                        + translate("COMMON.PrepositionForDateTime") + " " +
                        DateTime.fromISO(periodSummaryInfo.createdAt).toFormat("dd LLL yyyy HH'h'mm", { locale: InternationalizationService.getLocale() }
                        )}
                </div>
            </div>
        );
    }, [periodSummaryInfo?.createdBy.name, periodSummaryInfo?.createdAt]);


    const renderPageActionsBtns = useMemo(() => {
        if (!periodSummaryInfo?.state) return;
        if (loadingTopPermissions || !hasTopPermissions) return;
        switch (periodSummaryInfo.state) {
            case EvaluationPeriodState.Registered:
                if (windowResize > 768)
                    return <>
                        <Button text={translate("PERIODS.Cancel")} className="button-red" type="primary" onClick={() =>
                            openPopup(
                                <WarningPopup
                                    className="cancel-evaluation-period-popup"
                                    onDismissClicked={() => {
                                        closePopup();
                                    }}
                                    onConfirmClicked={() => {
                                        handleChangePeriodStateClicked(EvaluationPeriodState.Canceled, "PERIODS.MESSAGES.PeriodCanceledSuccess");
                                    }}
                                    dismissText={translate("COMMON.Cancel")}
                                    confirmText={translate(
                                        "PERIODS.POPUPS.Cancel"
                                    )}
                                >
                                    {translate(
                                        "PERIODS.POPUPS.CancelEvaluationPeriodConfirmText"
                                    )}
                                </WarningPopup>
                            )
                        } />
                        <Button text={translate("PERIODS.Activate")} type="primary" className="button-blue"
                            onClick={() =>
                                openPopup(
                                    <WarningPopup
                                        className="activate-evaluation-period-popup"
                                        onDismissClicked={() => {
                                            closePopup();
                                        }}
                                        onConfirmClicked={() => {
                                            handleChangePeriodStateClicked(EvaluationPeriodState.InProgress, "PERIODS.MESSAGES.PeriodAtivatedSuccess");
                                        }}
                                        dismissText={translate("COMMON.Cancel")}
                                        confirmText={translate(
                                            "PERIODS.POPUPS.Activate"
                                        )}
                                    >
                                        {translate(
                                            "PERIODS.POPUPS.ActivateEvaluationPeriodConfirmText"
                                        )}
                                    </WarningPopup>
                                )
                            } />
                    </>
                else
                    return <>
                        <IconButton icon={<RejectIcon />} className="button-red" type="primary" onClick={() =>
                            openPopup(
                                <WarningPopup
                                    className="cancel-evaluation-period-popup"
                                    onDismissClicked={() => {
                                        closePopup();
                                    }}
                                    onConfirmClicked={() => {
                                        handleChangePeriodStateClicked(EvaluationPeriodState.Canceled, "PERIODS.MESSAGES.PeriodCanceledSuccess");
                                    }}
                                    dismissText={translate("COMMON.Cancel")}
                                    confirmText={translate(
                                        "PERIODS.POPUPS.Cancel"
                                    )}
                                >
                                    {translate(
                                        "PERIODS.POPUPS.CancelEvaluationPeriodConfirmText"
                                    )}
                                </WarningPopup>
                            )
                        } />
                        <IconButton icon={<CheckIcon />} type="primary" className="button-blue"
                            onClick={() =>
                                openPopup(
                                    <WarningPopup
                                        className="activate-evaluation-period-popup"
                                        onDismissClicked={() => {
                                            closePopup();
                                        }}
                                        onConfirmClicked={() => {
                                            handleChangePeriodStateClicked(EvaluationPeriodState.InProgress, "PERIODS.MESSAGES.PeriodAtivatedSuccess");
                                        }}
                                        dismissText={translate("COMMON.Cancel")}
                                        confirmText={translate(
                                            "PERIODS.POPUPS.Activate"
                                        )}
                                    >
                                        {translate(
                                            "PERIODS.POPUPS.ActivateEvaluationPeriodConfirmText"
                                        )}
                                    </WarningPopup>
                                )
                            } />
                    </>
            case EvaluationPeriodState.InProgress:
                if (windowResize > 768)
                    return <>
                        <Button text={translate("PERIODS.Cancel")} className="button-red" type="primary" onClick={() =>
                            openPopup(
                                <WarningPopup
                                    className="cancel-evaluation-period-popup"
                                    onDismissClicked={() => {
                                        closePopup();
                                    }}
                                    onConfirmClicked={() => {
                                        handleChangePeriodStateClicked(EvaluationPeriodState.Canceled, "PERIODS.MESSAGES.PeriodCanceledSuccess");
                                    }}
                                    dismissText={translate("COMMON.Cancel")}
                                    confirmText={translate(
                                        "PERIODS.POPUPS.Cancel"
                                    )}
                                >
                                    {translate(
                                        "PERIODS.POPUPS.CancelEvaluationPeriodConfirmText"
                                    )}
                                </WarningPopup>
                            )
                        } />
                        <Button text={translate("PERIODS.Close")} type="primary"
                            onClick={() =>
                                openPopup(
                                    <WarningPopup
                                        className="close-evaluation-period-popup"
                                        onDismissClicked={() => {
                                            closePopup();
                                        }}
                                        onConfirmClicked={() => {
                                            handleChangePeriodStateClicked(EvaluationPeriodState.Completed, "PERIODS.MESSAGES.PeriodCanceledSuccess");
                                        }}
                                        dismissText={translate("COMMON.Cancel")}
                                        confirmText={translate(
                                            "PERIODS.POPUPS.Close"
                                        )}
                                    >
                                        {translate(
                                            "PERIODS.POPUPS.CloseEvaluationPeriodConfirmText"
                                        )}
                                    </WarningPopup>
                                )} />

                    </>
                else
                    return <><ContextualMenu
                        items={[
                            {
                                label: translate("PERIODS.Cancel"),
                                icon: <RejectDarkIcon />,
                                navigateTo: "",
                                onClick: () => openPopup(
                                    <WarningPopup
                                        className="cancel-evaluation-period-popup"
                                        onDismissClicked={() => {
                                            closePopup();
                                        }}
                                        onConfirmClicked={() => {
                                            handleChangePeriodStateClicked(EvaluationPeriodState.Canceled, "PERIODS.MESSAGES.PeriodCanceledSuccess");
                                        }}
                                        dismissText={translate("COMMON.Cancel")}
                                        confirmText={translate(
                                            "PERIODS.POPUPS.Cancel"
                                        )}
                                    >
                                        {translate(
                                            "PERIODS.POPUPS.CancelEvaluationPeriodConfirmText"
                                        )}
                                    </WarningPopup>
                                ),
                            }
                        ]}
                    >
                        <ScalableIconButton size={30} icon={<MoreActions />} />
                    </ContextualMenu>
                        <IconButton icon={<CheckIcon />} type="primary"
                            onClick={() =>
                                openPopup(
                                    <WarningPopup
                                        className="close-evaluation-period-popup"
                                        onDismissClicked={() => {
                                            closePopup();
                                        }}
                                        onConfirmClicked={() => {
                                            handleChangePeriodStateClicked(EvaluationPeriodState.Completed, "PERIODS.MESSAGES.PeriodCanceledSuccess");
                                        }}
                                        dismissText={translate("COMMON.Cancel")}
                                        confirmText={translate(
                                            "PERIODS.POPUPS.Close"
                                        )}
                                    >
                                        {translate(
                                            "PERIODS.POPUPS.CloseEvaluationPeriodConfirmText"
                                        )}
                                    </WarningPopup>
                                )} />

                    </>
            case EvaluationPeriodState.Completed:
                if (windowResize > 768)
                    return <>
                        <Button text={translate("PERIODS.Reopen")} type="primary"
                            onClick={() =>
                                openPopup(
                                    <WarningPopup
                                        className="reopen-evaluation-period-popup"
                                        onDismissClicked={() => {
                                            closePopup();
                                        }}
                                        onConfirmClicked={() => {
                                            handleChangePeriodStateClicked(EvaluationPeriodState.InProgress, "PERIODS.MESSAGES.PeriodReopenSuccess");
                                        }}
                                        dismissText={translate("COMMON.Cancel")}
                                        confirmText={translate(
                                            "PERIODS.POPUPS.Reopen"
                                        )}
                                    >
                                        {translate(
                                            "PERIODS.POPUPS.ReopenEvaluationPeriodConfirmText"
                                        )}
                                    </WarningPopup>
                                )} />

                    </>
                else
                    return <IconButton icon={<ReOpenIcon />} type="primary"
                        onClick={() =>
                            openPopup(
                                <WarningPopup
                                    className="reopen-evaluation-period-popup"
                                    onDismissClicked={() => {
                                        closePopup();
                                    }}
                                    onConfirmClicked={() => {
                                        handleChangePeriodStateClicked(EvaluationPeriodState.InProgress, "PERIODS.MESSAGES.PeriodReopenSuccess");
                                    }}
                                    dismissText={translate("COMMON.Cancel")}
                                    confirmText={translate(
                                        "PERIODS.POPUPS.Reopen"
                                    )}
                                >
                                    {translate(
                                        "PERIODS.POPUPS.ReopenEvaluationPeriodConfirmText"
                                    )}
                                </WarningPopup>
                            )} />
            case EvaluationPeriodState.Canceled:
                if (windowResize > 768)
                    return <>
                        <Button text={translate("PERIODS.Reopen")} type="primary"
                            onClick={() =>
                                openPopup(
                                    <WarningPopup
                                        className="reopen-evaluation-period-popup"
                                        onDismissClicked={() => {
                                            closePopup();
                                        }}
                                        onConfirmClicked={() => {
                                            handleChangePeriodStateClicked(EvaluationPeriodState.InProgress, "PERIODS.MESSAGES.PeriodReopenSuccess");
                                        }}
                                        dismissText={translate("COMMON.Cancel")}
                                        confirmText={translate(
                                            "PERIODS.POPUPS.Reopen"
                                        )}
                                    >
                                        {translate(
                                            "PERIODS.POPUPS.ReopenEvaluationPeriodConfirmText"
                                        )}
                                    </WarningPopup>
                                )} />

                    </>
                else
                    return <IconButton icon={<ReOpenIcon />} type="primary"
                        onClick={() =>
                            openPopup(
                                <WarningPopup
                                    className="reopen-evaluation-period-popup"
                                    onDismissClicked={() => {
                                        closePopup();
                                    }}
                                    onConfirmClicked={() => {
                                        handleChangePeriodStateClicked(EvaluationPeriodState.InProgress, "PERIODS.MESSAGES.PeriodReopenSuccess");
                                    }}
                                    dismissText={translate("COMMON.Cancel")}
                                    confirmText={translate(
                                        "PERIODS.POPUPS.Reopen"
                                    )}
                                >
                                    {translate(
                                        "PERIODS.POPUPS.ReopenEvaluationPeriodConfirmText"
                                    )}
                                </WarningPopup>
                            )} />
        }
    }, [periodSummaryInfo?.state, handleChangePeriodStateClicked, closePopup, openPopup, windowResize, loadingTopPermissions, hasTopPermissions])



    const renderPeriodDataBtns = useMemo(() => {
        if (!periodSummaryInfo) return;
        if (isPeriodClosed) return;
        if (loadingTopPermissions || !hasTopPermissions) return;
        return <div className="period-data-action-buttons">
            <Button
                text={translate("COMMON.Edit")}
                type="secondary"

                isDisabled={isPeriodClosed}
                onClick={() => openPopup(<EditEvaluationPeriodPopup
                    locationID={`${locationID}`}
                    periodID={`${evaluationPeriodID}`}
                    onCompletedOperations={getEvaluationPeriodSummaryInfo}
                />)

                }
            />
        </div>
    }, [periodSummaryInfo, locationID, isPeriodClosed, evaluationPeriodID, loadingTopPermissions, hasTopPermissions,
        getEvaluationPeriodSummaryInfo, openPopup])




    return (
        <PagePermissions permissions={basePermissions} >
            <PageLayout
                tabTitle={translate("PERIODS.EvaluationPeriodDetails")}
                showBreadcrumb
                pageTitle={translate("PERIODS.EvaluationPeriodDetails")}
                className="period-details"
                actions={renderPageActionsBtns}
            >
                {updateEvaluationPeriodStatus.isLoading ? <FullScreenLoader /> : null}

                <CardContainer className="period-data">

                    <div className="card-header-container">
                        <div className="header">
                            <div className="subtitle">
                                {translate("PERIODS.INFOANDFORM.EvaluationPeriodData")}
                            </div>
                            <Tag
                                text={periodSummaryInfo?.state ? translate(EvaluationPeriodStateTranslationTerms[periodSummaryInfo?.state]) : "-"}
                                backgroundColor={GetEvaluationPeriodStateCss(periodSummaryInfo?.state ?? -1)}
                            />
                        </div>
                        {renderCardContainerSubtitleHeader}
                    </div>

                    <FormSection unsetFlexGrow>
                        <InfoBlock label={translate("PERIODS.INFOANDFORM.Name")}
                            value={periodSummaryInfo?.name ? periodSummaryInfo?.name : "-"} />
                        <InfoBlock label={translate("PERIODS.INFOANDFORM.InitialDate")}
                            value={periodSummaryInfo?.startDate ? DateTime.fromISO(periodSummaryInfo?.startDate).toFormat("dd LLL yyyy", { locale: InternationalizationService.getLocale() }) : "-"} />
                        <InfoBlock label={translate("PERIODS.INFOANDFORM.FinalDate")}
                            value={periodSummaryInfo?.endDate ? DateTime.fromISO(periodSummaryInfo?.endDate).toFormat("dd LLL yyyy", { locale: InternationalizationService.getLocale() }) : "-"} />
                        <InfoBlock label={translate("PERIODS.INFOANDFORM.PeriodDays")}
                            value={periodSummaryInfo?.periodDays ? periodSummaryInfo?.periodDays?.toString() : "-"} />
                        <InfoBlock label={translate("PERIODS.INFOANDFORM.MonthlySalaryMultiplier")}
                            value={periodSummaryInfo?.monthlySalaryMultiplier ? periodSummaryInfo?.monthlySalaryMultiplier?.toString() : "-"} />
                        <InfoBlock label={translate("PERIODS.INFOANDFORM.PeriodMonetaryCeilingPercentageReduced")}
                            value={periodSummaryInfo?.periodMonetaryCeilingPercentage ? periodSummaryInfo?.periodMonetaryCeilingPercentage?.toString() : "-"} />
                        <InfoBlock label={translate("PERIODS.INFOANDFORM.MinimumBonusPercentageV2")}
                            value={periodSummaryInfo?.minimumBonusPercentage ? periodSummaryInfo?.minimumBonusPercentage?.toString() + " %" : "-"} />

                        <InfoBlock label={translate("PERIODS.INFOANDFORM.StartEvaluations")}
                            value={periodSummaryInfo?.evaluationsStartDay ? DateTime.fromISO(periodSummaryInfo?.evaluationsStartDay).toFormat("dd LLL yyyy", { locale: InternationalizationService.getLocale() }) : "-"} />
                        <InfoBlock label={translate("PERIODS.INFOANDFORM.EndEvaluations")}
                            value={periodSummaryInfo?.evaluationsFinalDay ? DateTime.fromISO(periodSummaryInfo?.evaluationsFinalDay).toFormat("dd LLL yyyy", { locale: InternationalizationService.getLocale() }) : "-"} />

                        <InfoBlock label={translate("PERIODS.INFOANDFORM.StartCalibrations")}
                            value={periodSummaryInfo?.calibrationsStartDay ? DateTime.fromISO(periodSummaryInfo?.calibrationsStartDay).toFormat("dd LLL yyyy", { locale: InternationalizationService.getLocale() }) : "-"} />
                        <InfoBlock label={translate("PERIODS.INFOANDFORM.EndCalibrations")}
                            value={periodSummaryInfo?.calibrationsFinalDay ? DateTime.fromISO(periodSummaryInfo?.calibrationsFinalDay).toFormat("dd LLL yyyy", { locale: InternationalizationService.getLocale() }) : "-"} />
                    </FormSection>

                    {renderPeriodDataBtns}

                </CardContainer>

                {!periodSummaryInfo?.isMatrixValid && !getPeriodSummaryInfoCall.isLoading ?

                    <div className="warning-total-factors">
                        <Tag
                            className="warning-total-factors-content"
                            icon={<WarningIcon />}
                            text={translate("PERIODS.INFOANDFORM.SumFactorsWarning")}
                            backgroundColor="status-red"
                        />
                    </div> : null}

                <div className="criterias">
                    <CompanyCriteriasSection
                        isClosed={isPeriodClosed}
                        locationId={"" + locationID}
                        periodId={"" + evaluationPeriodID}
                        hasTopPermissions={hasTopPermissions}
                        isloadingTopPermissions={loadingTopPermissions}
                        onCompletedOperations={getEvaluationPeriodSummaryInfo} />
                    <EmployeeCriteriasSection
                        hasTopPermissions={hasTopPermissions}
                        isloadingTopPermissions={loadingTopPermissions}
                        isPeriodClosed={isPeriodClosed}
                        locationId={"" + locationID}
                        periodId={"" + evaluationPeriodID}
                        onCompletedOperations={getEvaluationPeriodSummaryInfo}
                    />
                </div>
            </PageLayout >
        </PagePermissions>
    );
}

