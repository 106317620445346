import { APICompanyCriteriaToDomainMapper } from "../models/mappers/APICompanyCriteriaToDomainMapper";
import { APIEmployeeCriteriaToDomainMapper } from "../models/mappers/APIEmployeeCriteriaToDomainMapper";
import { CompanyCriteria } from "../models/domain/CompanyCriteria";
import { CompanyCriteriaCreateDTO } from "../models/dtos/CompanyCriteriaCreateDTO";
import { CompanyCriteriaDTO } from '../models/dtos/CompanyCriteriaDTO';
import { CompanyCriteriaUpdateDTO } from "../models/dtos/CompanyCriteriaUpdateDTO";
import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { EmployeeCriteria } from "../models/domain/EmployeeCriteria";
import { EmployeeCriteriaCreateDTO } from "../models/dtos/EmployeeCriteriaCreateDTO";
import { EmployeeCriteriaDTO } from '../models/dtos/EmployeeCriteriaDTO';
import { EmployeeCriteriaUpdateDTO } from "../models/dtos/EmployeeCriteriaUpdateDTO";
import { EvaluationMatrix } from "../models/domain/EvaluationMatrix";
import { EvaluationPeriodMatrixResponseDTO } from "../models/dtos/EvaluationPeriodMatrixResponseDTO";
import { HttpClient } from "../../../lib/infrastructure/http/HttpClient";

const Route = (path: string) => {
    return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};


export class EvaluationPeriodMatrixesService {

    getEvaluationPeriodMatrix(locationId: string, periodId: string): Promise<EvaluationMatrix | null> {
        return HttpClient.sessionRequest<EvaluationPeriodMatrixResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/EvaluationPeriods/${periodId}/Matrix`),
        })
            .then((response) => {

                return ({
                    companyCriterias: response.data.companyCriterias.map(cc => APICompanyCriteriaToDomainMapper(cc)),
                    employeeCriterias: response.data.employeeCriterias.map(ec => APIEmployeeCriteriaToDomainMapper(ec)),
                })
            })
            .catch((error) => {
                throw error;
            });
    }

    createCompanyCriteria(locationId: string, periodId: string, requestDto: CompanyCriteriaCreateDTO): Promise<CompanyCriteriaDTO> {
        return HttpClient.sessionRequest<CompanyCriteriaDTO>({
            method: "POST",
            url: Route(`/api/v1/Locations/${locationId}/EvaluationPeriods/${periodId}/Matrix/CompanyCriteria`),
            data: requestDto,
        })
            .then((res) => res.data)
            .catch((error) => {
                throw error;
            });
    }

    getCompanyCriteriaById(locationId: string, periodId: string, companyCriteriaId: string): Promise<CompanyCriteria | null> {
        return HttpClient.sessionRequest<CompanyCriteriaDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/EvaluationPeriods/${periodId}/Matrix/CompanyCriteria/${companyCriteriaId}`),
        })
            .then((response) => {

                if (!response.data) return null;

                return APICompanyCriteriaToDomainMapper(response.data)
            })
            .catch((error) => {
                throw error;
            });
    }

    updateCompanyCriteria(locationId: string, periodId: string, companyCriteriaId: string, requestDto: CompanyCriteriaUpdateDTO): Promise<CompanyCriteriaDTO> {
        return HttpClient.sessionRequest<CompanyCriteriaDTO>({
            method: "PUT",
            url: Route(`/api/v1/Locations/${locationId}/EvaluationPeriods/${periodId}/Matrix/CompanyCriteria/${companyCriteriaId}`),
            data: requestDto
        })
            .then((res) => res.data)
            .catch((error) => {
                throw error;
            });
    }

    deleteCompanyCriteria(locationId: string, periodId: string, companyCriteriaId: string): Promise<void> {
        return HttpClient.sessionRequest({
            method: "DELETE",
            url: Route(`/api/v1/Locations/${locationId}/EvaluationPeriods/${periodId}/Matrix/CompanyCriteria/${companyCriteriaId}`),
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }

    createEmployeeCriteria(locationId: string, periodId: string, requestDto: EmployeeCriteriaCreateDTO): Promise<EmployeeCriteriaDTO> {
        return HttpClient.sessionRequest<EmployeeCriteriaDTO>({
            method: "POST",
            url: Route(`/api/v1/Locations/${locationId}/EvaluationPeriods/${periodId}/Matrix/EmployeeCriteria`),
            data: requestDto,
        })
            .then((res) => res.data)
            .catch((error) => {
                throw error;
            });
    }

    getEmployeeCriteriaById(locationId: string, periodId: string, employeeCriteriaId: string): Promise<EmployeeCriteria | null> {
        return HttpClient.sessionRequest<EmployeeCriteriaDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/EvaluationPeriods/${periodId}/Matrix/EmployeeCriteria/${employeeCriteriaId}`),
        })
            .then((response) => {

                if (!response.data) return null;

                return APIEmployeeCriteriaToDomainMapper(response.data)
            })
            .catch((error) => {
                throw error;
            });
    }

    updateEmployeeCriteria(locationId: string, periodId: string, employeeCriteriaId: string, requestDto: EmployeeCriteriaUpdateDTO): Promise<EmployeeCriteriaDTO> {
        return HttpClient.sessionRequest<EmployeeCriteriaDTO>({
            method: "PUT",
            url: Route(`/api/v1/Locations/${locationId}/EvaluationPeriods/${periodId}/Matrix/EmployeeCriteria/${employeeCriteriaId}`),
            data: requestDto
        })
            .then((res) => res.data)
            .catch((error) => {
                throw error;
            });
    }

    deleteEmployeeCriteria(locationId: string, periodId: string, employeeCriteriaId: string): Promise<void> {
        return HttpClient.sessionRequest({
            method: "DELETE",
            url: Route(`/api/v1/Locations/${locationId}/EvaluationPeriods/${periodId}/Matrix/EmployeeCriteria/${employeeCriteriaId}`),
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }
}
