import './EvaluationsAttachmentsPage.css'

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useClosePopup, useCreateNotification, usePopup, useWindowResize } from '../../../lib/infrastructure/ui/UIServices';

import { Button } from '../../../lib/components/buttons/Button';
import { ColumnDefinition } from '../../../lib/components/table/TableInterfaces';
import { ConditionalRender } from '../../../lib/functional/ConditionalRender';
import { ConfigurationProvider } from '../../../lib/infrastructure/configuration/ConfigurationProvider';
import { ReactComponent as DeleteIcon } from "../../../lib/assets/icons/remover.svg";
import { ReactComponent as DownloadIcon } from "../../../lib/assets/icons/download.svg";
import { ErrorPopup } from '../../../lib/components/popup/ErrorPopup';
import { EvaluationPeriodFileSummary } from '../model/domain/EvaluationPeriodFileSummary';
import { EvaluationPeriodFilesService } from '../services/EvaluationPeriodFilesService';
import { FileUploadIconButton } from '../../../lib/components/file/FileUploadIconButton';
import { FullScreenLoader } from '../../../lib/components/loader/FullScreenLoader';
import { IconButton } from '../../../lib/components/buttons/IconButton';
import { ImportEvaluationAttachsPopup } from '../../../lib/components/popup/ImportEvaluationAttachsPopup';
import { ReactComponent as ImportIcon } from "../../../lib/assets/icons/import.svg";
import { Notification } from '../../../lib/components/notifications/Notification';
import { PageLayout } from '../../../lib/layouts/main-content/PageLayout';
import { PagePermissions } from '../../../lib/infrastructure/authorization/PagePermissions';
import { Permission } from '../../../models/api/gate/Permission';
import { ResponsiveDataTable } from '../../../lib/components/table/ResponsiveDataTable';
import { ScalableIconButton } from '../../../lib/components/buttons/ScalableIconButton';
import { WarningPopup } from '../../../lib/components/popup/WarningPopup';
import { translate } from '../../../lib/infrastructure/i18n/InternationalizationService';
import { useEvaluationPeriodStatus } from '../../../lib/infrastructure/period/useEvaluationPeriodStatus';
import { useGlobalLocation } from '../../../lib/infrastructure/location/LocationServices';
import { useParams } from 'react-router-dom';
import { useServiceCallPro2 } from '../../../lib/hooks/useServiceCall';
import { FileUploadButton } from '../../../lib/components/file/FileUploadButton';
import axios from "axios";

var svc = new EvaluationPeriodFilesService();
const permissions = [Permission.GLOBAL_ADMIN, Permission.LOCATION_ADMIN, Permission.HUMAN_RESOURCES];

export function EvaluationsAttachmentsPage() {
    const openPopup = usePopup();
    const closePopup = useClosePopup();
    const createNotification = useCreateNotification();
    const windowResize = useWindowResize();

    const locationID = useGlobalLocation();
    const { id: evaluationPeriodID } = useParams();


    const [attachmentsInfo, setAttachmentsInfo] = useState<EvaluationPeriodFileSummary[]>([]);
    const [totalAttachments, setTotalAttachments] = useState<number>(0);

    const periodInfo = useEvaluationPeriodStatus();


    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchWord, setSearchWord] = useState<string>();


    /*****************
     * SERVICE CALLS 
     ****************/

    const [uploadAttachmentCall, uploadAttachmentCallIsLoading] = useServiceCallPro2(svc, svc.uploadEvaluationPeriodAttachment);
    const [getAttachmentsCall, getAttachmentsCallIsLoading] = useServiceCallPro2(svc, svc.getEvaluationPeriodAttachments);
    const [getUrlDownloadAttachmentCall, getUrlDownloadAttachmentCallIsLoading] = useServiceCallPro2(svc, svc.getUrlDownloadAttachment);
    const [deleteAttachmentCall, deleteAttachmentCallIsLoading] = useServiceCallPro2(svc, svc.deleteAttachment);


    /****************************
    * DATA REQUESTS
    *****************************/

    const getEvaluationPeriodAttachments = useCallback(() => {

        if (!locationID || !evaluationPeriodID) return;


        getAttachmentsCall(`${locationID}`, evaluationPeriodID)
            .then((attachs) => {
                setAttachmentsInfo(attachs);
                setTotalAttachments(attachs.length);
            }).catch((error) => {
                if (!error || axios.isCancel(error)) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [locationID, evaluationPeriodID, getAttachmentsCall, setAttachmentsInfo, openPopup, setTotalAttachments, currentPage, itemsPerPage, searchWord]);


    const getUrlDownloadAttachment = useCallback((fileId: string) => {
        if (!locationID || !evaluationPeriodID) return;

        getUrlDownloadAttachmentCall(`${locationID}`, evaluationPeriodID, fileId)
            .then((url) => window.open(url, "_blank"))
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            })
    }, [locationID, evaluationPeriodID, getUrlDownloadAttachmentCall, openPopup]);


    const deleteEvaluationPeriodAttachment = useCallback((fileId: string) => {

        if (!locationID || !evaluationPeriodID) return;

        deleteAttachmentCall(`${locationID}`, evaluationPeriodID, fileId)
            .then((_) => {
                getEvaluationPeriodAttachments();
                createNotification(<Notification
                    type="success"
                    title={translate("COMMON.SYSTEMPOPUPS.Success")}
                    text={translate("ATTACHMENTS.INFOANDFORM.DeleteAttachmentSuccess")} />);
            }).catch(error => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [locationID, evaluationPeriodID, deleteAttachmentCall,
        getEvaluationPeriodAttachments, createNotification, openPopup]);


    const handleOnPageAndItemsChanged = useCallback(
        (items: number, currentPage: number) => {
            setItemsPerPage(items);
            setCurrentPage(currentPage);
        },
        [setItemsPerPage, setCurrentPage]
    );


    /****************************
    * DATA MANIPULATION EFFECTS
    *****************************/

    useEffect(() => {
        getEvaluationPeriodAttachments();
    }, [currentPage, itemsPerPage, searchWord]);


    const columns: ColumnDefinition<EvaluationPeriodFileSummary>[] = useMemo(() => [
        {
            cellRenderProp: (v) => v.name,
            headerRender: translate("ATTACHMENTS.INFOANDFORM.FileName"),
            columnKey: "name",
            isMobilePrimaryCell: true,
        },
        {
            cellRenderProp: (v) => v.creatorName,
            headerRender: translate("ATTACHMENTS.INFOANDFORM.AddedAttachmentBy"),
            columnKey: "creatorName",
        },
        {
            cellRenderProp: (v) => (
                <ScalableIconButton
                    size={24}
                    icon={<DownloadIcon />}
                    onClick={() => {
                        getUrlDownloadAttachment(`${v.id}`);
                    }}
                />
            ),
            columnKey: "download",
            width: "24px",
            isMobileHeaderIcon: true,
        },
        {
            cellRenderProp: (v) => (
                !periodInfo.isPeriodClosed ?
                    <ScalableIconButton size={24} icon={<DeleteIcon />} onClick={() => {

                        openPopup(
                            <WarningPopup
                                onDismissClicked={() => { closePopup(); }}
                                onConfirmClicked={() => { deleteEvaluationPeriodAttachment(`${v.id}`); }}
                                dismissText={translate("COMMON.Cancel")}
                                confirmText={translate("ATTACHMENTS.POPUPS.DeleteAttachment")}
                                className="button-red"
                            >
                                {translate("ATTACHMENTS.POPUPS.DeleteAttachmentConfirmText")}
                            </WarningPopup>
                        );
                    }} /> : null),
            columnKey: "delete",
            width: "24px",
            isMobileHeaderIcon: true,
        }
    ], [openPopup, closePopup, getUrlDownloadAttachment, deleteEvaluationPeriodAttachment, periodInfo.isPeriodClosed]);


    const handleFileImport = useCallback((file: File) => {

        if (!locationID || !evaluationPeriodID) return;

        uploadAttachmentCall("" + locationID, evaluationPeriodID, file)
            .then(_ => {
                getEvaluationPeriodAttachments();
                createNotification(<Notification
                    type="success"
                    title={translate("COMMON.SYSTEMPOPUPS.Success")}
                    text={translate("ATTACHMENTS.INFOANDFORM.NewAttachmentAdded")} />);
            }).catch(error => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [locationID, evaluationPeriodID, uploadAttachmentCall, openPopup, createNotification, getEvaluationPeriodAttachments]);



    const renderActionBtns = useMemo(() => {
        if (periodInfo.isPeriodClosed) return;
        if (windowResize > 768)
            return (
                <FileUploadButton
                    text={translate("COMMON.POPUPS.Import")}
                    type="secondary"
                    onFileSelected={handleFileImport}
                    className="upload-attachments"
                    icon={<ImportIcon />}
                />
            );
        else
            return (
                <FileUploadIconButton
                    type="secondary"
                    icon={<ImportIcon />}
                    onFileSelected={ handleFileImport}
                    className="upload-attachments"
                />
            );
    }, [periodInfo.isPeriodClosed, windowResize, handleFileImport]);




    return (
        <PagePermissions permissions={permissions} >
            <PageLayout
                tabTitle={translate("ATTACHMENTS.EvaluationAttachments")}
                showBreadcrumb
                pageTitle={translate("ATTACHMENTS.EvaluationAttachments")}
                className="evaluations-attachments-page"
                actions={renderActionBtns}>
                <ResponsiveDataTable
                    columnDefinitions={columns}
                    items={attachmentsInfo || []}
                    totalitems={totalAttachments || 0}
                    paginationOptions={{
                        itemsPerPage: itemsPerPage,
                        itemsPerPageOptions: [10, 20, 30, 40, 50],
                    }}
                    currentpage={currentPage}
                    isLoading={getAttachmentsCallIsLoading}
                    onPageAndItemsChanged={handleOnPageAndItemsChanged}
                ></ResponsiveDataTable>
                <ConditionalRender if={uploadAttachmentCallIsLoading} >
                    <FullScreenLoader />
                </ConditionalRender>
            </PageLayout>
        </PagePermissions>
    );
}