import "./EvaluationsPage.css";

import { EvaluationPeriodState, EvaluationPeriodStateTranslationTerms } from "../../../models/enums/EvaluationPeriodState";
import { InternationalizationService, translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useCreateNotification, usePopup, useWindowResize } from "../../../lib/infrastructure/ui/UIServices";
import { useServiceCallPro, useServiceCallPro2 } from "../../../lib/hooks/useServiceCall";

import { AddInfoAttachments } from "../../evaluations-attachments/pages/AddInfoAttachments";
import { Button } from "../../../lib/components/buttons/Button";
import { CardContainer } from "../../../lib/layouts/containers/card/CardContainer";
import { Checkbox } from "../../../lib/components/checkbox/Checkbox";
import { ColumnDefinition } from "../../../lib/components/table/TableInterfaces";
import { ConditionalRender } from "../../../lib/functional/ConditionalRender";
import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { DateTime } from "luxon";
import { ReactComponent as DownloadIcon } from "../../../lib/assets/icons/download.svg";
import { EmptyState } from "../../../lib/components/emptystates/EmptyState";
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { EvaluationMatrix } from '../../matrixes/models/domain/EvaluationMatrix';
import { EvaluationPeriodFileSummary } from "../../evaluations-attachments/model/domain/EvaluationPeriodFileSummary";
import { EvaluationPeriodFilesService } from "../../evaluations-attachments/services/EvaluationPeriodFilesService";
import { EvaluationPeriodMatrixesService } from "../../matrixes/services/EvaluationPeriodMatrixesService";
import { EvaluationPeriodSummary } from "../../periods/models/domain/EvaluationPeriodSummary";
import { EvaluationPeriodsService } from "../../periods/services/EvaluationPeriodsService";
import { EvaluationsEditor } from "./EvaluationsEditor";
import { EvaluationsService } from "../services/EvaluationsService";
import { EvaluationsSummary } from "../models/domain/EvaluationsSummary";
import { ReactComponent as ExportIcon } from "../../../lib/assets/icons/export.svg";
import { FormFieldTextInput } from "../../../lib/components/form/form-field/FormFieldTextInput";
import { FormSection } from "../../../lib/components/form/section/FormSection";
import { FullScreenLoader } from "../../../lib/components/loader/FullScreenLoader";
import { GetEvaluationPeriodStateCss } from "../../../common/helpers/GetEvaluationPeriodStateCss";
import { IResponsiveDataTableProps } from "../../../lib/components/table/ResponsiveDataTable";
import { IconButton } from "../../../lib/components/buttons/IconButton";
import { ReactComponent as ImportIcon } from "../../..//lib/assets/icons/import.svg";
import { ImportPopup } from "../../../lib/components/popup/ImportPopup";
import { InfoBlock } from "../../../lib/components/info-block/InfoBlock";
import { ReactComponent as InfoIcon } from "../../../lib/assets/icons/info.svg";
import { InfoTag } from "../../../lib/components/info-block/InfoTag";
import { Loader } from "../../../lib/components/loader/Loader";
import { Notification } from "../../../lib/components/notifications/Notification";
import { PageLayout } from "../../../lib/layouts/main-content/PageLayout";
import { PagePermissions } from "../../../lib/infrastructure/authorization/PagePermissions";
import { Permission } from "../../../models/api/gate/Permission";
import React from "react";
import { ScalableIconButton } from "../../../lib/components/buttons/ScalableIconButton";
import { ReactComponent as SearchIcon } from "../../../lib/assets/icons/search-icon.svg";
import { Spacer } from "../../../lib/components/separator/Spacer";
import { Tag } from "../../../lib/components/tag/Tag";
import axios from "axios";
import { useFormControl } from "../../../lib/components/form/Form";
import { useGlobalLocation } from "../../../lib/infrastructure/location/LocationServices";
import { useHasPermissions } from "../../../lib/infrastructure/authorization/useHasLocationPermissions";
import { useParams } from "react-router-dom";
import { useEvaluationPeriodStatus } from "../../../lib/infrastructure/period/useEvaluationPeriodStatus";

const matrixSvc = new EvaluationPeriodMatrixesService();
const periodSvc = new EvaluationPeriodsService();
const evaluationSvc = new EvaluationsService();
const attachmentsSvc = new EvaluationPeriodFilesService();

const basePermissions = [Permission.GLOBAL_ADMIN, Permission.LOCATION_ADMIN, Permission.HUMAN_RESOURCES, Permission.LOCATION_MANAGER];
const topPermissions = [Permission.GLOBAL_ADMIN, Permission.LOCATION_ADMIN, Permission.HUMAN_RESOURCES];
const rhPermissions = [Permission.HUMAN_RESOURCES];


export function EvaluationsPage() {
    const openPopup = usePopup();
    const windowResize = useWindowResize();
    const locationID = useGlobalLocation();
    const { id: evaluationPeriodID } = useParams();

    const getPeriodSummaryInfoCall = useServiceCallPro(periodSvc.getEvaluationPeriodByID);
    const getMatrixSvcCall = useServiceCallPro(matrixSvc.getEvaluationPeriodMatrix);
    const getEvaluationsCall = useServiceCallPro(evaluationSvc.getEvaluations);

    const evaluationPeriodStatus = useEvaluationPeriodStatus();

    const [getAttachmentsCall, getAttachmentsCallIsLoading] = useServiceCallPro2(attachmentsSvc, attachmentsSvc.getEvaluationPeriodAttachments);

    const [getUrlDownloadAttachmentCall, getUrlDownloadAttachmentCallIsLoading] = useServiceCallPro2(attachmentsSvc, attachmentsSvc.getUrlDownloadAttachment);
    const [getUrlDownloadXLSXReportCall, getUrlDownloadXLSXReportCallIsLoading] = useServiceCallPro2(attachmentsSvc, attachmentsSvc.downloadUrlForExportBonusXLSX);


    const [matrix, setMatrix] = useState<EvaluationMatrix | null>();
    const [period, setPeriod] = useState<EvaluationPeriodSummary>();
    const [attachmentsInfo, setAttachmentsInfo] = useState<EvaluationPeriodFileSummary[]>([]);

    const [showMidtermEvaluationColumn, setShowMidtermEvaluationColumn] = useState<boolean>(false);
    const [showAbsenteeismColumn, setShowAbsentismColumn] = useState<boolean>(false);

    const { isLoadingPermissions: loadingTopPermissions, hasPermission: hasTopPermissions } = useHasPermissions(topPermissions);
    const { isLoadingPermissions: loadingOnlyRHPermissions, hasPermission: onlyRHPermissions } = useHasPermissions(rhPermissions);

    const [indexManagerEvaluations, setIndexManagerEvaluations] = useState<number | null>(0);

    const [allEvaluations, setEvaluations] = useState<EvaluationsSummary | null>();

    const [timer, setTimer] = useState<NodeJS.Timeout>();

    const [searchWord, setSearchWord] = useState<string>();

    const searchBoxFormControl = useFormControl<string>({
        isDisabled: false,
        enableAutoValidate: false,
    });



    /****************************
    * DATA REQUESTS
    *****************************/

    const getEvaluationPeriodMatrix = useCallback(() => {
        if (!locationID || !evaluationPeriodID) return;

        getMatrixSvcCall.invoke("" + locationID, "" + evaluationPeriodID)
            .then((data) => {
                if (data) {
                    setMatrix(data);
                }
            })
            .catch((error) => {
                if (!error || axios.isCancel(error)) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [locationID, evaluationPeriodID, setMatrix, getMatrixSvcCall.invoke, openPopup]);




    const getEvaluations = useCallback(() => {
        if (!locationID || !evaluationPeriodID) return;

        getEvaluationsCall
            .invoke(`${locationID}`, evaluationPeriodID, { searchWord: searchWord })
            .then((data) => {
                if (data)
                    setEvaluations(data);
            })
            .catch((error) => {
                if (!error || axios.isCancel(error)) return;
                openPopup(<ErrorPopup>{error.response?.data?.message || JSON.stringify(error)}</ErrorPopup>);
            });

    }, [locationID, evaluationPeriodID, searchWord,
        getEvaluationsCall.invoke, setEvaluations, openPopup]);






    const getPeriodSummaryInfo = useCallback(() => {
        if (!locationID || !evaluationPeriodID) return;

        getPeriodSummaryInfoCall.invoke(`${locationID}`, evaluationPeriodID)
            .then((data) => {
                if (data) setPeriod(data);
            })
            .catch((error) => {
                if (!error || axios.isCancel(error)) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [locationID, evaluationPeriodID, getPeriodSummaryInfoCall.invoke, setPeriod, openPopup]);





    const getEvaluationPeriodAttachments = useCallback(() => {

        if (!locationID || !evaluationPeriodID) return;


        getAttachmentsCall(`${locationID}`, evaluationPeriodID)
            .then((attachs) => {
                setAttachmentsInfo(attachs);
            }).catch(error => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [locationID, evaluationPeriodID, getAttachmentsCall, setAttachmentsInfo, openPopup]);





    const getUrlDownloadAttachment = useCallback((fileId: string) => {
        if (!locationID || !evaluationPeriodID) return;

        getUrlDownloadAttachmentCall(`${locationID}`, evaluationPeriodID, fileId)
            .then((url) => window.open(url, "_blank"))
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            })
    }, [locationID, evaluationPeriodID, getUrlDownloadAttachmentCall, openPopup]);




    /*     const handleFileImport = useCallback((file: File) => {
    
            if (!locationID || !evaluationPeriodID) return;
    
            uploadFileCall(`${locationID}`, "" + evaluationPeriodID, file)
                .then((_) => {
                    getEvaluations();
    
                    createNotification(
                        <Notification
                            type="success"
                            title={translate("COMMON.SYSTEMPOPUPS.Success")}
                            text={translate("MANAGERS.AddMultipleManagersSuccessMessage")}
                        ></Notification>
                    );
                })
                .catch(error => {
                    if (!error) return;
                    openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
                });
    
        }, [locationID, evaluationPeriodID, uploadFileCall, openPopup, getEvaluations, createNotification]); */


    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/


    useEffect(() => {
        if (timer)
            clearTimeout(timer);

        var responseTimeOut = setTimeout(() => {
            if (timer) {
                setSearchWord(searchBoxFormControl.value);
            }
        }, 1000);
        setTimer(responseTimeOut);
    }, [searchBoxFormControl.value]);


    useEffect(() => {
        getEvaluationPeriodMatrix();
        getPeriodSummaryInfo();
        getEvaluationPeriodAttachments();
    }, []);


    useEffect(() => {
        getEvaluations();
    }, [searchWord]);


    const columns: ColumnDefinition<EvaluationPeriodFileSummary>[] = useMemo(() => [
        {
            cellRenderProp: (v) => v.name,
            headerRender: translate("ATTACHMENTS.INFOANDFORM.FileName"),
            columnKey: "name",
            isMobilePrimaryCell: true,
        },
        {
            cellRenderProp: (v) => v.creatorName,
            headerRender: translate("ATTACHMENTS.INFOANDFORM.AddedAttachmentBy"),
            columnKey: "creatorName"
        },
        {
            cellRenderProp: (v) => (
                <ScalableIconButton
                    size={24}
                    icon={<DownloadIcon />}
                    onClick={() => {
                        getUrlDownloadAttachment(`${v.id}`);
                    }}
                />
            ),
            columnKey: "download",
            width: "24px",
            isMobileHeaderIcon: true,
        },
    ], [getUrlDownloadAttachment]);



    const attachmentsTableInfo: IResponsiveDataTableProps<EvaluationPeriodFileSummary> = useMemo(() => {
        return {
            items: attachmentsInfo || [],
            columnDefinitions: columns,
            totalitems: attachmentsInfo.length,
            isLoading: getAttachmentsCallIsLoading
        };
    }, [columns, attachmentsInfo, getAttachmentsCallIsLoading]);



    /****************************
     * USER ACTIONS
     *****************************/

    const handleOnExportButtonClicked = useCallback(() => {

        if (!locationID || !period?.id) return;

        getUrlDownloadXLSXReportCall(`${locationID}`, "" + period.id)
            .then(url => window.open(url, "_blank"))
            .catch((error) => { alert("Sorry, something went wrong.") });

    }, [locationID, period?.id, getUrlDownloadXLSXReportCall]);







    /****************************
     * CSS & HTML
     *****************************/

    const renderActionBtns = useMemo(() => {
        if (windowResize > 768)
            return (<ConditionalRender if={onlyRHPermissions && !loadingOnlyRHPermissions}>
                <Button text={translate("COMMON.Export")} type="secondary" onClick={handleOnExportButtonClicked} />
            </ConditionalRender>
            );
        else
            return (<IconButton type="secondary" icon={<ExportIcon />} onClick={handleOnExportButtonClicked} />);
    }, [handleOnExportButtonClicked, windowResize, loadingOnlyRHPermissions, onlyRHPermissions]);




    const evaluationsGroupByManager = useMemo(() => {

        return allEvaluations?.managerEvaluations?.map((managerEvaluations, index) => (

            <React.Fragment key={index}>
                <div className="sublime-line" />
        
                <EvaluationsEditor
                    showTable={index === indexManagerEvaluations}
                    handleOnClickRow={() => setIndexManagerEvaluations((prevIndex) => (prevIndex === index ? null : index))}
                    isPeriodClosed={evaluationPeriodStatus.isPeriodClosed}
                    locationId={locationID}
                    evaluationPeriodId={evaluationPeriodID}
                    matrix={matrix}
                    state={period?.state}
                    showAbsentismColumn={showAbsenteeismColumn}
                    showMidtermEvaluationColumn={showMidtermEvaluationColumn}
                    managerEvaluations={managerEvaluations}
                    hasPermission={hasTopPermissions}
                    isLoadingPermissions={loadingTopPermissions}
                    onlyRHPermission={onlyRHPermissions}
                    loadingOnlyRHPermission={loadingOnlyRHPermissions}
                />
{/*                 <Spacer px={10} mode="vertical" /> */}
            </React.Fragment>
        ));
    }, [locationID, evaluationPeriodID, matrix, period?.state, showAbsenteeismColumn, indexManagerEvaluations, setIndexManagerEvaluations,
        showMidtermEvaluationColumn, hasTopPermissions, loadingTopPermissions, allEvaluations, loadingOnlyRHPermissions, onlyRHPermissions, evaluationPeriodStatus.isPeriodClosed]);







    return (
        <PagePermissions permissions={basePermissions} >

            <PageLayout
                tabTitle={translate("EVALUATIONS.Evaluations")}
                showBreadcrumb
                pageTitle={translate("EVALUATIONS.Evaluations")}
                className="evaluations-page"
                actions={renderActionBtns}
            >

                {getUrlDownloadXLSXReportCallIsLoading ? <FullScreenLoader /> : null}

                {!period ? null :
                    <div className="evaluations-page-header">
                        <CardContainer className="period-data">
                            <div className="card-header">
                                <div className="subtitle">
                                    {translate("PERIODS.INFOANDFORM.EvaluationPeriodData")}
                                </div>
                                <Tag
                                    text={translate(EvaluationPeriodStateTranslationTerms[period.state])}
                                    backgroundColor={GetEvaluationPeriodStateCss(period.state)}
                                    isTiny={windowResize <= 1024}
                                />
                            </div>
                            <FormSection unsetFlexGrow>
                                <InfoBlock label={translate("PERIODS.INFOANDFORM.Name")} value={period.name} />
                                <InfoBlock label={translate("PERIODS.INFOANDFORM.InitialDate")} value={period.startDate ? DateTime.fromISO(period.startDate).toFormat("dd LLL yyyy", { locale: InternationalizationService.getLocale() }) : "-"} />
                                <InfoBlock label={translate("PERIODS.INFOANDFORM.FinalDate")} value={period.endDate ? DateTime.fromISO(period.endDate).toFormat("dd LLL yyyy", { locale: InternationalizationService.getLocale() }) : "-"} />
                                <InfoBlock label={translate("PERIODS.INFOANDFORM.PeriodDaysReduced")} value={period?.periodDays?.toString() || "-"} />
                                <InfoBlock label={translate("PERIODS.INFOANDFORM.MonthlySalaryMultiplierReduced")} value={period?.monthlySalaryMultiplier?.toString() || "-"} />
                                <InfoBlock label={translate("PERIODS.INFOANDFORM.PeriodMonetaryCeilingPercentageReduced")} value={period?.periodMonetaryCeilingPercentage?.toString() || "-"} />
                                <InfoBlock label={translate("PERIODS.INFOANDFORM.MinimumBonusPercentage")} value={period?.minimumBonusPercentage?.toString() + " %" || "-"} />
                            </FormSection>
                            <div className="card-separator-line"></div>

                            <div className="performance-indicators">
                                <div className="label">{translate("EVALUATIONS.EvaluationIndicators")}</div>

                                <div className="container">
                                    <InfoTag tagColor="status-green" tagContent="5" value={translate("EVALUATIONS.PERFORMANCE.Exceptional")} tooltipText={translate("EVALUATIONS.PERFORMANCE.ExceptionalDescription")} icon={<InfoIcon />} />
                                    <InfoTag tagColor="status-secondary-green" tagContent="4" value={translate("EVALUATIONS.PERFORMANCE.VeryGood")} tooltipText={translate("EVALUATIONS.PERFORMANCE.VeryGoodDescription")} icon={<InfoIcon />} />
                                    <InfoTag tagColor="status-yellow" tagContent="3" value={translate("EVALUATIONS.PERFORMANCE.Good")} tooltipText={translate("EVALUATIONS.PERFORMANCE.GoodDescription")} icon={<InfoIcon />} />
                                    <InfoTag tagColor="status-secondary-orange" tagContent="2" value={translate("EVALUATIONS.PERFORMANCE.Reasonable")} tooltipText={translate("EVALUATIONS.PERFORMANCE.ReasonableDescription")} icon={<InfoIcon />} />
                                    <InfoTag tagColor="status-red" tagContent="1" value={translate("EVALUATIONS.PERFORMANCE.ToImprove")} tooltipText={translate("EVALUATIONS.PERFORMANCE.ToImproveDescription")} icon={<InfoIcon />} />
                                </div>
                            </div>

                        </CardContainer>

                        {getAttachmentsCallIsLoading || attachmentsTableInfo.items.length === 0 ? null :
                            <AddInfoAttachments
                                tableInfo={attachmentsTableInfo}
                                className="add-info-attachments"
                                isLoading={getAttachmentsCallIsLoading}
                                evaluationPeriodId={evaluationPeriodID}
                                locationId={locationID}
                            />}
                    </div>
                }

                {!loadingTopPermissions && !hasTopPermissions && period?.state === EvaluationPeriodState.Registered ?

                    <div className="period-not-active-message">
                        <Tag backgroundColor="status-yellow" text={translate("PERIODS.RegisteredPeriodMessage")} />
                    </div> :

                    <>
                        <div className="filters-container">
                            <FormFieldTextInput
                                formControl={searchBoxFormControl}
                                icon={<SearchIcon />}
                                placeholder={translate("COMMON.FORM.SearchPlaceholder")}
                                className="search-box"
                            />
                            <div className="checkbox-info">
                                <Checkbox
                                    isChecked={showMidtermEvaluationColumn}
                                    onClick={() => setShowMidtermEvaluationColumn(!showMidtermEvaluationColumn)}
                                    text={translate("EVALUATIONS.INFOANDFORM.PresentMidtermEvaluation")} />

                                <Checkbox
                                    isChecked={showAbsenteeismColumn} onClick={() =>
                                        setShowAbsentismColumn(!showAbsenteeismColumn)}
                                    text={translate("EVALUATIONS.INFOANDFORM.PresentAttendanceRate")} />
                            </div>
                        </div>
                        {!getEvaluationsCall.isLoading && allEvaluations?.totalEvaluations === 0 ?
                            <EmptyState hideSubtitle subtitleMessage={translate("EVALUATIONS.NoEvaluationsFound")} /> :
                            <>

                                {getEvaluationsCall.isLoading ?
                                    <div className="evaluations-loader">
                                        <Spacer mode='vertical' px='60' />
                                        <Loader text={translate("EVALUATIONS.LoadingEvaluations")} />
                                    </div> : 
                                    <div className="all-evaluations">{evaluationsGroupByManager}</div>
                                    
                                }
                            </>
                        }
                    </>
                }
            </PageLayout >
        </PagePermissions>
    );
}
