import { trimString } from "../../lib/utils/TrimString";



export function replaceCommaByPoint(value: string | undefined | null) {
    if (!value) return undefined;
    if (value?.includes(','))
        return trimString(value.replace(',', '.'));
    return trimString(value);
}
