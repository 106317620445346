import { EmployeeEvaluationSummary } from "../domain/EmployeeEvaluationSummary";
import { EmployeeEvaluationSummaryDTO } from "../dtos/EmployeeEvaluationSummaryDTO";

export function APIEmployeeEvaluationSummaryToDomainMapper(apiDto: EmployeeEvaluationSummaryDTO): EmployeeEvaluationSummary {
    return {
        id: apiDto.id,
        evaluationPeriodStartDate: apiDto.evaluationPeriodStartDate,
        evaluationPeriodEndDate: apiDto.evaluationPeriodEndDate,
        evaluationPeriodName: apiDto.evaluationPeriodName,
        evaluationPeriodId: apiDto.evaluationPeriodId,
        employeeScoreTotal: apiDto.employeeScoreTotal,
        employeeScoreTotalPercentage: apiDto.employeeScoreTotalPercentage,
        evaluationPeriodState: apiDto.evaluationPeriodState,
        finalScoreWithCut: apiDto.finalScoreWithCut,
        finalScoreWithCutPercentage: apiDto.finalScoreWithCutPercentage,

    };
}
