import { useEffect, useState } from "react";
import { useGlobalLocation } from "../location/LocationServices";
import { useNavigate, useParams } from "react-router-dom";
import { EvaluationPeriodsService } from "../../../features/periods/services/EvaluationPeriodsService";
import { EvaluationPeriodState } from "../../../models/enums/EvaluationPeriodState";

var svc = new EvaluationPeriodsService();


export function useEvaluationPeriodStatus() {

    const { id: evaluationPeriodID } = useParams();

    const globalLocationID = useGlobalLocation();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);
    const [isClosed, setIsClosed] = useState(true);

    useEffect(() => {

        setIsLoading(true);

        if (!globalLocationID || !evaluationPeriodID)
            setIsLoading(true);

        svc.getEvaluationPeriodByIDSummary("" + globalLocationID, "" + evaluationPeriodID)
            .then((data) => {
                if (data?.state && (data.state === EvaluationPeriodState.Canceled || data.state === EvaluationPeriodState.Completed))
                    setIsClosed(true);
                else
                    setIsClosed(false);
            })
            .catch((error) => {
                setIsLoading(false);
                if (!error) return;
                navigate("/unavailable")
            });
    }, [globalLocationID, evaluationPeriodID]);


    return { isLoadingPeriodStatus: isLoading, isPeriodClosed: isClosed };
}