import { useNavigate } from "react-router-dom";
import { useHasPermissions } from "./useHasLocationPermissions";
import { Permission } from "../../../models/api/gate/Permission";


export interface IPagePermissionsProps {
  children: React.ReactNode;
  permissions: Permission[];
}

export function PagePermissions(props: IPagePermissionsProps) {
  const navigate = useNavigate();

  const { isLoadingPermissions, hasPermission } = useHasPermissions(props.permissions);

  if (isLoadingPermissions) return <></>;

  if (!hasPermission) navigate("/access-denied");

  return (<>{props.children}</>);
}