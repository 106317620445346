import "./CriteriasSections.css";

import { DescriptionField, NameField, WeightField } from "./PeriodCriteriasSectionFields";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useCreateNotification, usePopup } from "../../../../../lib/infrastructure/ui/UIServices";
import { ReactComponent as AddIcon } from "../../../../../lib/assets/icons/add.svg";
import { AllValid } from "../../../../../common/validators/ValidateFormControls";
import { ColumnDefinition } from "../../../../../lib/components/table/TableInterfaces";
import { ConditionalRender } from "../../../../../lib/functional/ConditionalRender";
import { CriteriaState } from "./CompanyCriteriasSection";
import { ReactComponent as DeleteIcon } from "../../../../../lib/assets/icons/remover.svg";
import { EvaluationMatrix } from "../../../../matrixes/models/domain/EvaluationMatrix";
import { EvaluationPeriodMatrixesService } from "../../../../matrixes/services/EvaluationPeriodMatrixesService";
import { FullScreenLoader } from "../../../../../lib/components/loader/FullScreenLoader";
import { IconButton } from "../../../../../lib/components/buttons/IconButton";
import { Notification } from "../../../../../lib/components/notifications/Notification";
import { ObjectHelper } from "../../../../../lib/helpers/ObjectHelper";
import { ResponsiveDataTable } from "../../../../../lib/components/table/ResponsiveDataTable";
import { ReactComponent as SaveIcon } from "../../../../../lib/assets/icons/save2.svg";
import { ScalableIconButton } from "../../../../../lib/components/buttons/ScalableIconButton";
import { Tooltip } from "../../../../../lib/components/tooltip/Tooltip";
import { translate } from "../../../../../lib/infrastructure/i18n/InternationalizationService";
import { useServiceCallPro } from "../../../../../lib/hooks/useServiceCall";
import { v4 as uuid } from 'uuid';
import { ErrorPopup } from "../../../../../lib/components/popup/ErrorPopup";
import { Tag } from "../../../../../lib/components/tag/Tag";

export interface EmployeeCriteriasSectionProps {
    isPeriodClosed: boolean;
    locationId: string;
    periodId: string;
    isloadingTopPermissions: boolean;
    hasTopPermissions: boolean;
    onCompletedOperations: () => void;
}

interface RecordLine {
    original: EmployeeCriteria;
    patch: Partial<EmployeeCriteria>;
}

export interface EmployeeCriteria {
    id?: number;
    idx: string;
    name?: string;
    isNameValid?: boolean;
    description?: string;
    isDescriptionValid?: boolean;
    weightPercentage?: number;
    isWeightPercentageValid?: boolean;
    state: CriteriaState;
}

const baseValidCriteria: Partial<EmployeeCriteria> = {
    isNameValid: true,
    isDescriptionValid: true,
    isWeightPercentageValid: true,
};


const svc = new EvaluationPeriodMatrixesService();


function calcSumAllCriteriaWeights(criterias: EmployeeCriteria[]) {
    const sumAllCriteriasWeightPercentage = criterias?.reduce((sum, criteria) => {
        return sum + (criteria?.weightPercentage ?? 0);
    }, 0);
    return sumAllCriteriasWeightPercentage;
}

export function EmployeeCriteriasSection(props: EmployeeCriteriasSectionProps) {

    const openPopup = usePopup();
    const createNotification = useCreateNotification();

    const getMatrixSvcCall = useServiceCallPro(svc.getEvaluationPeriodMatrix);
    const deleteEmployeeCriteriaSvcCall = useServiceCallPro(svc.deleteEmployeeCriteria);
    const createEmployeeCriteriaSvcCall = useServiceCallPro(svc.createEmployeeCriteria);
    const updateEmployeeCriteriaSvcCall = useServiceCallPro(svc.updateEmployeeCriteria);

    const [data, setData] = useState<EmployeeCriteria[]>([]);
    const [matrixSubtotal, setMatrixSubtotal] = useState<number>();

    const [isSaveAllEnabled, setIsSaveAllEnabled] = useState<boolean>(false);


    useEffect(() => {
        if (props.locationId && props.periodId) {
            getMatrixSvcCall.invoke(props.locationId, props.periodId).then((res: EvaluationMatrix | null) => {
                const criterias = res?.employeeCriterias.map(c => ({
                    ...baseValidCriteria,
                    idx: uuid(),
                    state: CriteriaState.SAVED,
                    id: c.id,
                    name: c.name,
                    description: c.description,
                    weightPercentage: c.weightPercentage,
                }));

                setData(criterias ?? []);
                setMatrixSubtotal(calcSumAllCriteriaWeights(criterias ?? []));

            }).catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            })
        }
    }, [props.locationId, props.periodId]);





    const updateLine = useCallback((original: EmployeeCriteria, patch: Partial<EmployeeCriteria>) => {
        const clone = [...data];
        let targetIdx = clone.findIndex(i => i.idx === original.idx);
        clone[targetIdx] = { ...clone[targetIdx], ...patch }
        if (clone[targetIdx].state === CriteriaState.SAVED && !patch.state) {
            if (!ObjectHelper.deepEqual(clone[targetIdx], original)) {
                clone[targetIdx].state = CriteriaState.EDITED
            }
        }
        if (clone[targetIdx].state === CriteriaState.SAVED)
            setMatrixSubtotal(calcSumAllCriteriaWeights(clone ?? []));

        setData(clone);
    }, [data, setData, setMatrixSubtotal]);




    const handleDeleteCriteriaBtnClicked = useCallback((record: EmployeeCriteria) => {

        const clone = [...data.filter(t => t.idx !== record.idx)];

        if (record.id) {
            deleteEmployeeCriteriaSvcCall.invoke(props.locationId, props.periodId, "" + record.id).then(_ => {

                props.onCompletedOperations();
                setData(clone);
                setMatrixSubtotal(calcSumAllCriteriaWeights(clone ?? []));

                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("PERIODS.MESSAGES.DeleteEmployeeCriteriaSucess")}
                    ></Notification>
                );
            }).catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            })
        } else {
            setData(clone);
        }

    }, [data, createNotification, setData,
        deleteEmployeeCriteriaSvcCall.invoke, openPopup, setMatrixSubtotal,
        props.locationId, props.periodId, props.onCompletedOperations]);



    const handleSaveCriteriaBtnClicked = useCallback((record: EmployeeCriteria) => {
        if (record.state === CriteriaState.NEW) {
            createEmployeeCriteriaSvcCall.invoke(props.locationId, props.periodId, {
                name: record.name ?? "",
                description: record.description ?? "",
                weightPercentage: record.weightPercentage ?? 0,
            }).then(res => {

                props.onCompletedOperations();

                updateLine(record, { state: CriteriaState.SAVED, id: res.id, weightPercentage: res.weightPercentage })

                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("PERIODS.MESSAGES.CreatedEmployeeCriteriaSuccess")}
                    ></Notification>
                );
            }).catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
        }

        if (record.state === CriteriaState.EDITED) {
            updateEmployeeCriteriaSvcCall.invoke(props.locationId, props.periodId, "" + record.id, {
                name: record.name ?? "",
                description: record.description ?? "",
                weightPercentage: record.weightPercentage ?? 0,

            }).then(res => {
                updateLine(record, { state: CriteriaState.SAVED, weightPercentage: res.weightPercentage })

                props.onCompletedOperations();

                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("PERIODS.MESSAGES.ChangedEmployeeCriteriaSuccess")}
                    ></Notification>
                );
            }).catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            })
        }


    }, [updateLine, createNotification,
        createEmployeeCriteriaSvcCall.invoke, updateEmployeeCriteriaSvcCall.invoke,
        openPopup, props.onCompletedOperations, data]);




    const companyCriteriasColumns: ColumnDefinition<EmployeeCriteria>[] = useMemo(
        () => [
            {
                columnKey: "name",
                cellRenderProp: (v: EmployeeCriteria) => <FieldWrapper value={v.name} isLocked={props.isPeriodClosed || !props.hasTopPermissions || props.isloadingTopPermissions}> <NameField key={v.idx} value={v.name || ""} onValueChanged={(value, isValid) => updateLine(v, { name: value, isNameValid: isValid })} /> </FieldWrapper>,
                headerRender: translate("PERIODS.CRITERIAS.Name"),
                isMobilePrimaryCell: true,
                width: "20%"
            },
            {
                columnKey: "description",
                cellRenderProp: (v: EmployeeCriteria) => <FieldWrapper value={v.name} isLocked={props.isPeriodClosed || !props.hasTopPermissions || props.isloadingTopPermissions}> <DescriptionField key={v.idx} value={v.description || ""} onValueChanged={(value, isValid) => updateLine(v, { description: value, isDescriptionValid: isValid })} /></FieldWrapper>,
                headerRender: translate("PERIODS.CRITERIAS.Description"),
            },
            {
                columnKey: "weight-percentage",
                cellRenderProp: (v: EmployeeCriteria) => <FieldWrapper value={v.weightPercentage} isLocked={props.isPeriodClosed || !props.hasTopPermissions || props.isloadingTopPermissions}><WeightField key={v.idx} value={v.weightPercentage || 0} onValueChanged={(value, isValid) => updateLine(v, { weightPercentage: value, isWeightPercentageValid: isValid })} className="small-input" /> </FieldWrapper>,
                headerRender: translate("PERIODS.CRITERIAS.WeightPercentage"),
                width: "10%"
            },
            {
                columnKey: "delete",
                cellRenderProp: (v: EmployeeCriteria) => {
                    if (props.isPeriodClosed || !props.hasTopPermissions || props.isloadingTopPermissions) return;
                    return <ScalableIconButton
                        size={24} icon={<DeleteIcon />}
                        onClick={() => handleDeleteCriteriaBtnClicked(v)} />
                },
                width: "3.5714rem",
                isMobileHeaderIcon: true,
            },
            {
                columnKey: "save",
                cellRenderProp: (v: EmployeeCriteria) => {
                    if (v.state === CriteriaState.SAVED || props.isPeriodClosed || (!props.hasTopPermissions && !props.isloadingTopPermissions))
                        return;
                    return <ScalableIconButton
                        size={24} icon={<SaveIcon />}
                        isDisabled={!AllValid(v.isNameValid, v.isDescriptionValid, v.isWeightPercentageValid)}
                        onClick={() => handleSaveCriteriaBtnClicked(v)} />
                },
                width: "3.5714rem",
                isMobileHeaderIcon: true,
            },
        ], [updateLine, handleSaveCriteriaBtnClicked, handleDeleteCriteriaBtnClicked, props.isPeriodClosed, props.hasTopPermissions, props.isloadingTopPermissions]);




    const handleAddCriteriaBtnClicked = useCallback(() => {

        var clone = [...data];

        clone.push({
            ...baseValidCriteria,
            idx: uuid(),
            state: CriteriaState.NEW
        });

        setData(clone);

    }, [setData, data]);





    const updateMultipleLines = useCallback((recordsToPatch: Array<{ original: EmployeeCriteria, patch: Partial<EmployeeCriteria> }>) => {
        setData(prevData => {
            let clone = [...prevData];

            recordsToPatch.forEach(rec => {
                const { original, patch } = rec;
                const targetIdx = clone.findIndex(i => i.idx === original.idx);

                if (targetIdx !== -1) {
                    clone[targetIdx] = { ...clone[targetIdx], ...patch };

                    if (clone[targetIdx].state === CriteriaState.SAVED && !patch.state) {
                        if (!ObjectHelper.deepEqual(clone[targetIdx], original)) {
                            clone[targetIdx].state = CriteriaState.EDITED;
                        }
                    }

                    if (clone[targetIdx].state === CriteriaState.SAVED) {
                        setMatrixSubtotal(calcSumAllCriteriaWeights(clone ?? []));
                    }
                }
            });

            return clone;
        });
    }, [setData, setMatrixSubtotal]);




    const handleSaveAllCriteriasBtnClicked = useCallback(async () => {

        try {

            var recordLinesToPatch: RecordLine[] = [];
            var clone = [...data];

            clone = clone.filter(crit => AllValid(crit.isNameValid, crit.isDescriptionValid, crit.isWeightPercentageValid));

            for (const companyCriteria of clone) {

                if (companyCriteria.state === CriteriaState.NEW) {
                    const endpointResponse = await createEmployeeCriteriaSvcCall.invoke(props.locationId, props.periodId, {
                        name: companyCriteria.name ?? "",
                        description: companyCriteria.description ?? "",
                        weightPercentage: companyCriteria.weightPercentage ?? 0,
                    });

                    recordLinesToPatch.push({ original: companyCriteria, patch: endpointResponse });

                } else if (companyCriteria.state === CriteriaState.EDITED) {
                    const endpointResponse = await updateEmployeeCriteriaSvcCall.invoke(props.locationId, props.periodId, "" + companyCriteria.id, {
                        name: companyCriteria.name ?? "",
                        description: companyCriteria.description ?? "",
                        weightPercentage: companyCriteria.weightPercentage ?? 0,
                    });

                    recordLinesToPatch.push({ original: companyCriteria, patch: endpointResponse });
                }
            }

            const recordsToUpdate = recordLinesToPatch.map(rec => ({
                original: rec.original,
                patch: {
                    state: CriteriaState.SAVED,
                    weightPercentage: rec.patch.weightPercentage,
                    id: rec.patch.id,
                }
            }));

            updateMultipleLines(recordsToUpdate);

            props.onCompletedOperations();
            createNotification(
                <Notification
                    type="success"
                    title={translate("COMMON.SYSTEMPOPUPS.Success")}
                    text={translate("PERIODS.MESSAGES.MultipleEmployeeCriteriasUpdatedSuccess")}
                />
            );

        } catch {
            openPopup(<ErrorPopup>{translate("COMMON.ERRORS.ErrorSavingValues")}</ErrorPopup>);
        }
    }, [data, createEmployeeCriteriaSvcCall.invoke, updateEmployeeCriteriaSvcCall.invoke, updateMultipleLines,
        updateLine, props.locationId, props.periodId, props.onCompletedOperations, createNotification, openPopup]);




    useEffect(() => {

        setIsSaveAllEnabled(data.some(crit => (crit.state !== CriteriaState.SAVED) && AllValid(
            crit.isNameValid,
            crit.isDescriptionValid,
            crit.isWeightPercentageValid
        )));
    }, [data]);




    return (
        <div className="criteria-section employee-factors">
            {deleteEmployeeCriteriaSvcCall.isLoading || createEmployeeCriteriaSvcCall.isLoading || updateEmployeeCriteriaSvcCall.isLoading
                ? <FullScreenLoader /> : null}
            <div className="criteria-section-header">
                <div className="subtitle">
                    {translate("PERIODS.INFOANDFORM.EmployeeEvaluationFactors")}
                    {matrixSubtotal ? <Tag backgroundColor="status-green" text={"Subtotal: " + matrixSubtotal + "%"} isTiny /> : null}

                </div>
                <ConditionalRender if={!props.isPeriodClosed && props.hasTopPermissions && !props.isloadingTopPermissions}>

                    <div className="subtitle-right-btns">
                        {data.length > 0 && (!props.isPeriodClosed && props.hasTopPermissions && !props.isloadingTopPermissions) ?
                            <Tooltip text={translate("PERIODS.INFOANDFORM.SaveAllEmployeeCriterias")}>
                                <IconButton type="secondary" icon={<SaveIcon />} isDisabled={!isSaveAllEnabled} onClick={handleSaveAllCriteriasBtnClicked} />
                            </Tooltip> : null}

                        {!props.isPeriodClosed && props.hasTopPermissions && !props.isloadingTopPermissions ?
                            <Tooltip text={translate("PERIODS.INFOANDFORM.AddFactor")}>
                                <IconButton type="secondary" icon={<AddIcon />} onClick={handleAddCriteriaBtnClicked} />
                            </Tooltip> : null}
                    </div>
                </ConditionalRender>
            </div>


            <ResponsiveDataTable
                items={data || []}
                columnDefinitions={companyCriteriasColumns}
                totalitems={0}
                isLoading={getMatrixSvcCall.isLoading}
            />
        </div>)
}



export interface IFieldWrapperProps {
    children: React.ReactNode;
    value: string | number | undefined;
    isLocked: boolean;
}

export function FieldWrapper(props: IFieldWrapperProps) {

    if (props.isLocked) {
        return <>{props.value}</>
    }

    return <>{props.children}</>
}


/********************** */

