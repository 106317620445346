import './ViewAttachmentsPopup.css'

import { IResponsiveDataTableProps, ResponsiveDataTable } from '../../../../lib/components/table/ResponsiveDataTable';
import { useCallback, useState } from 'react';

import { PopupContainer } from '../../../../lib/layouts/containers/popup-container/PopupContainer';
import { PopupContent } from '../../../../lib/layouts/containers/popup-content/PopupContent';
import { PopupHeader } from '../../../../lib/layouts/containers/popup-header/PopupHeader';
import { translate } from '../../../../lib/infrastructure/i18n/InternationalizationService';

export interface IViewAttachmentsPopupProps<T> {
    locationId: number | undefined;
    evaluationPeriodId: string | undefined;
    className?: string;
    tableInfo?: IResponsiveDataTableProps<T>;
    isLoading: boolean;
}

export function ViewAttachmentsPopup<T>(props: IViewAttachmentsPopupProps<T>) {

    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);


    /****************************
    * DATA REQUESTS
    *****************************/




    const handleOnPageAndItemsChanged = useCallback(
        (items: number, currentPage: number) => {
            setItemsPerPage(items);
            setCurrentPage(currentPage);
        },
        [setItemsPerPage, setCurrentPage]
    );




    /****************************
    * CSS & HTML
    *****************************/


    return (
        <PopupContainer className="view-evaluation-attachments">
            <PopupHeader title={translate("EVALUATIONS.EvaluationAttachments")} />
            <PopupContent>
                {props.tableInfo ?
                    <ResponsiveDataTable
                        columnDefinitions={props.tableInfo.columnDefinitions}
                        items={props.tableInfo.items || []}
                        totalitems={props.tableInfo.items.length || 0}
/*                         paginationOptions={{
                            itemsPerPage: itemsPerPage,
                            itemsPerPageOptions: [10, 20, 30, 40, 50],
                        }} */
                        currentpage={currentPage}
                        isLoading={props.isLoading}
                        onPageAndItemsChanged={handleOnPageAndItemsChanged}
                    ></ResponsiveDataTable> : null}
            </PopupContent>
        </PopupContainer>
    );
}
