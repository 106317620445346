import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { HttpClient } from "../../../lib/infrastructure/http/HttpClient";
import { ManagerDelegationCreateDTO } from "../models/api/ManagerDelegationCreateDTO";

const Route = (path: string) => {
    return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};

export class ManagersDelegationsService {

    createManagerDelegation(locationID: string, periodID: string, requestDto: ManagerDelegationCreateDTO): Promise<void> {

        return HttpClient.sessionRequest({
            method: "POST",
            url: Route(`/api/v1/Locations/${locationID}/EvaluationPeriods/${periodID}/ManagersDelegations`),
            data: requestDto
        })
            .then(() => { })
            .catch((error) => {
                throw error;
            });
    }

    deleteManagerDelegation(locationID: string, periodID: string, managerDelegationID: string): Promise<void> {
        return HttpClient.sessionRequest({
            method: "DELETE",
            url: Route(`/api/v1/Locations/${locationID}/EvaluationPeriods/${periodID}/ManagersDelegations/${managerDelegationID}`),
        })
            .then(() => { })
            .catch((error) => {
                throw error;
            });
    }
}