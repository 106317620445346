import "./MainContainer.css";

import { useEffect, useMemo } from "react";
import {
  useSetSideBarButtons,
  useWindowResize,
} from "../../../lib/infrastructure/ui/UIServices";

import { ReactComponent as AdminIcon } from "../../../lib/assets/icons/administracao.svg";
import { ReactComponent as EmployeesIcon } from "../../../lib/assets/icons/employees.svg";
import { ReactComponent as PeriodsIcon } from "../../../lib/assets/icons/evaluation-periods.svg";
import { Sidebar } from "../../../lib/components/side-bar/Sidebar";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { useHasPermissions } from "../../../lib/infrastructure/authorization/useHasLocationPermissions";
import { Permission } from "../../../models/api/gate/Permission";

interface IMainContainerProps {
  children: React.ReactNode;
}

export const basePermissions = [
  Permission.GLOBAL_ADMIN,
  Permission.LOCATION_ADMIN,
  Permission.HUMAN_RESOURCES,
  Permission.LOCATION_MANAGER
];


export const adminPermissions = [
  Permission.GLOBAL_ADMIN,
  Permission.LOCATION_ADMIN
];

export function MainContainer(props: IMainContainerProps) {
  const windowResize = useWindowResize();
  const setSideBarButtons = useSetSideBarButtons();


  const { isLoadingPermissions: loadingBasePermissions, hasPermission: hasBasePermissions } = useHasPermissions(basePermissions);
  const { isLoadingPermissions: loadingAdminPermissions, hasPermission: hasAdminPermissions } = useHasPermissions(adminPermissions);



  const sideBarButtons = useMemo(() => [
    {
      icon: <PeriodsIcon />,
      navigateTo: "bonus/periods",
      name: translate("PERIODS.EvaluationPeriods"),
      isHidden: !loadingBasePermissions && !hasBasePermissions
    },
    {
      icon: <EmployeesIcon />,
      navigateTo: "me",
      name: ""

    },
    {
      icon: <AdminIcon />,
      navigateTo: "settings/locations",
      name: translate("GLOBALADMIN.GlobalAdmin"),
      isHidden: !loadingAdminPermissions && !hasAdminPermissions,
      isBottomAligned: true
    },
  ], [loadingAdminPermissions, loadingBasePermissions, hasAdminPermissions, hasBasePermissions]);




  useEffect(() => {
    setSideBarButtons(sideBarButtons);
  }, [setSideBarButtons]);



  const renderSideBar = useMemo(() => {
    if (windowResize > 768) return <Sidebar buttons={sideBarButtons} />;
    else return null;
  }, [windowResize, sideBarButtons]);


  return (
    <div className="main-content-container">
      {renderSideBar}
      {props.children}
    </div>
  );
}
