import "./ImportPopup.css";
import { Button } from "../buttons/Button";
import { CardContainer } from "../../layouts/containers/card/CardContainer";
import { ReactComponent as DownloadIcon } from "../../../lib/assets/icons/download.svg";
import { FileUploadButton } from "../file/FileUploadButton";
import { ReactComponent as ImportIcon } from "../../../lib/assets/icons/import.svg";
import { PopupContainer } from "../../layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../layouts/containers/popup-header/PopupHeader";
import { translate } from "../../infrastructure/i18n/InternationalizationService";
import { useClosePopup } from "../../infrastructure/ui/UIServices";
import { ReactComponent as WarningIcon } from "../../../lib/assets/icons/warning-icon-v2.svg";
import { Tag } from "../tag/Tag";



export interface IImportPopupProps {
  onClickImportBtn: (file: File) => void;
  onClickDownloadTemplateBtn: () => void;
  warningMessage?: string | null;
}



export function ImportPopup(props: IImportPopupProps) {
  const closePopup = useClosePopup();

  return (
    <PopupContainer className="popup-import">
      <PopupHeader title={translate("COMMON.POPUPS.ImportOptions")} />
      <PopupContent>
        <div className="import-btns">
          <CardContainer className="template-section">
            <div>{translate("COMMON.POPUPS.ExcelTemplateText")}</div>
            <Button
              text={translate("COMMON.POPUPS.ExcelTemplate")}
              type="tertiary"
              onClick={() => { props.onClickDownloadTemplateBtn(); closePopup() }}
              icon={<DownloadIcon />}
            />
          </CardContainer>
          <CardContainer className="import-section">
            <div>{translate("COMMON.POPUPS.ImportText")}</div>
            {props.warningMessage ? 
          
              <Tag
                className="warning-import-message"
                icon={<WarningIcon />}
                text={translate("HOURSANDABSENCE.POPUPS.ImportHoursMapConfirmText")}
                backgroundColor="status-red"
              />
            : null}
            <FileUploadButton
              text={translate("COMMON.POPUPS.Import")}
              type="secondary"
              onFileSelected={(file: File) => { props.onClickImportBtn(file); closePopup(); }}
              className="import-material"
              icon={<ImportIcon />}
            />
          </CardContainer>
        </div>
      </PopupContent>
    </PopupContainer>
  );
}