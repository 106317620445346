import { APIEvaluationHistoryDetailsToDomainMapper } from "../models/mappers/APIEvaluationHistoryDetailsToDomainMapper";
import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { EvaluationHistoriesQueryOptions } from "../models/dtos/EvaluationHistoriesQueryOptions";
import { EvaluationHistoriesResponseDTO } from "../models/dtos/EvaluationHistoriesResponseDTO";
import { EvaluationHistoriesSummary } from "../models/domain/EvaluationHistoriesSummary";
import { HttpClient } from "../../../lib/infrastructure/http/HttpClient";
import qs from "qs";

const Route = (path: string) => {
    return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};

export class EvaluationHistoriesService {

    getEvaluationHistoryLines(locationId: string, periodId: string, evaluationId: string, queryOptions?: EvaluationHistoriesQueryOptions): Promise<EvaluationHistoriesSummary> {
        return HttpClient.sessionRequest<EvaluationHistoriesResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/EvaluationPeriods/${periodId}/Evaluations/${evaluationId}/History`),
            params: queryOptions,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" });
            },
        })
            .then((response) => {

                return ({
                    evaluations: response.data.evaluations.map(e => APIEvaluationHistoryDetailsToDomainMapper(e)),
                    totalItems: response.data.totalItems
                })
            })
            .catch((error) => {
                throw error;
            });
    }
}