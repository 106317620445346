import "./AddAbsenceCodePopup.css";

import { useCallback, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../../lib/infrastructure/ui/UIServices";

import { AbsenteeismCodeCreateDTO } from "../../models/dtos/AbsenteeismCodeCreateDTO";
import { AbsenteeismCodeService } from "../../services/AbsenteeismCodeService";
import { AllValid } from "../../../../../common/validators/ValidateFormControls";
import { Checkbox } from "../../../../../lib/components/checkbox/Checkbox";
import { ErrorPopup } from "../../../../../lib/components/popup/ErrorPopup";
import { FormContainer } from "../../../../../lib/layouts/containers/form/FormContainer";
import { FormFieldTextInput } from "../../../../../lib/components/form/form-field/FormFieldTextInput";
import { FullScreenLoader } from "../../../../../lib/components/loader/FullScreenLoader";
import { Notification } from "../../../../../lib/components/notifications/Notification";
import { PopupActionButtons } from "../../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../../lib/layouts/containers/popup-header/PopupHeader";
import { Spacer } from "../../../../../lib/components/separator/Spacer";
import { Tooltip } from "../../../../../lib/components/tooltip/Tooltip";
import { translate } from "../../../../../lib/infrastructure/i18n/InternationalizationService";
import { trimString } from "../../../../../lib/utils/TrimString";
import { useFormControl } from "../../../../../lib/components/form/Form";
import { useServiceCallPro } from "../../../../../lib/hooks/useServiceCall";
import { validateWhiteSpaceStrings } from "../../../../../lib/validators/ValidateWhiteSpaceStrings";

interface IAddAbsenceCodePopupProps {
    onChangesMade: () => void;
    locationId: string | undefined; 
}

var absenteeismCodeSvc = new AbsenteeismCodeService();

export function AddAbsenceCodePopup(props: IAddAbsenceCodePopupProps) {
    const closePopup = useClosePopup();
    const openPopup = usePopup();
    const createNotification = useCreateNotification();

    const [onClickSubmit, setOnClickSubmit] = useState<boolean>(false);
    const [isProblematic, setIsProblematic] = useState<boolean>(false);

    const createAbsenteeismCodesCall = useServiceCallPro(absenteeismCodeSvc.createAbsenteeismCodes);

    const codeFormControl = useFormControl<string>({
        validators: [validateWhiteSpaceStrings()],
        enableAutoValidate: true,
    });

    const nameFormControl = useFormControl<string>({
        validators: [validateWhiteSpaceStrings()],
        enableAutoValidate: true,
    });

    const handleAddAbsenteeismCode = useCallback(() => {
        if (!AllValid(nameFormControl.validate(), codeFormControl.validate())) return;

        let request: AbsenteeismCodeCreateDTO = {
            description: trimString(nameFormControl.value),
            sapNumber: trimString(codeFormControl.value),
            isProblematic: isProblematic
        };

        setOnClickSubmit(true);

        createAbsenteeismCodesCall
            .invoke("" + props.locationId, request)
            .then(() => {
                closePopup();
                setOnClickSubmit(false);
                props.onChangesMade();
                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate(
                            "GLOBALADMIN.ABSENCECODES.POPUPS.CreateAbsenceCodeSuccess"
                        )}
                    ></Notification>
                );
            })
            .catch((error) => {
                if (!error) return;
                setOnClickSubmit(false);
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [nameFormControl.validate, nameFormControl.value, codeFormControl.validate, codeFormControl.value, setOnClickSubmit, createAbsenteeismCodesCall.invoke,
        closePopup, createNotification, openPopup]);



    return (
        <PopupContainer className="popup-add-absence-code">
            {onClickSubmit ? <FullScreenLoader /> : null}

            <PopupHeader title={translate("GLOBALADMIN.ABSENCECODES.AddCode")} />
            <PopupContent className="create-absenteeism-code">
                <FormContainer>
                    <div className="inputs-container">
                        <div className="column">
                            <FormFieldTextInput
                                formControl={codeFormControl}
                                label={translate("GLOBALADMIN.ABSENCECODES.INFOANDFORM.Code")}
                                placeholder={translate("GLOBALADMIN.ABSENCECODES.INFOANDFORM.CodePlaceholder")}
                            />
                            <FormFieldTextInput
                                formControl={nameFormControl}
                                label={translate("GLOBALADMIN.ABSENCECODES.INFOANDFORM.Description")}
                                placeholder={translate("GLOBALADMIN.ABSENCECODES.INFOANDFORM.DescriptionPlaceholder")}
                            />
                        </div>
                        <Spacer mode="vertical" px={10}></Spacer>
                        <div className="separator">{translate("GLOBALADMIN.ABSENCECODES.INFOANDFORM.AbsenceCodeStatus")}</div>
                        <Spacer mode="vertical" px={10}></Spacer>
                        <div className="column">
                            <Tooltip text={translate("GLOBALADMIN.ABSENCECODES.INFOANDFORM.ProblematicExplanation")}>
                            <Checkbox
                                text={translate("GLOBALADMIN.ABSENCECODES.INFOANDFORM.Problematic")}
                                isChecked={isProblematic}
                                onClick={() => setIsProblematic(!isProblematic)}
                            /></Tooltip>
                        </div>
                    </div>
                </FormContainer>
                <Spacer mode={"vertical"} px="30" />
                <PopupActionButtons
                    buttons={[
                        {
                            text: translate("COMMON.Cancel"),
                            type: "tertiary",
                            onClick: () => closePopup(),
                        },
                        {
                            text: translate("GLOBALADMIN.ABSENCECODES.AddCode"),
                            type: "primary",
                            onClick: handleAddAbsenteeismCode,
                        },
                    ]}
                />
            </PopupContent>
        </PopupContainer>
    );
}
