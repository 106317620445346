import "./MobileTableRow.css";

import {
  IMobileTableCellProps as IMobileTableCell,
  MobileTableCell,
} from "../table-cell/MobileTableCell";
import { useCallback, useMemo, useState } from "react";

import { ReactComponent as ArrowDown } from "../../../assets/icons/arrowDown-dark.svg";
import { ReactComponent as ArrowUp } from "../../../assets/icons/arrowUp-dark.svg";
import { CssClassnameBuilder } from "../../../utils/CssClassnameBuilder";
import { ScalableIconButton } from "../../buttons/ScalableIconButton";

export interface IMobileTableRowProps {
  className?: string;
  cells: IMobileTableCell[];
  onClick?: (cells: IMobileTableCell[]) => void;
  showSelectedRowHighlighted?: boolean;
}

export function MobileTableRow(props: IMobileTableRowProps) {
  const [isShowAllCells, setShowAllCells] = useState<boolean>(false);
  const [cellContent, setCellContent] = useState<IMobileTableCell[]>([]);

  const tableRowCss = useMemo(() => {
    return CssClassnameBuilder.new()
      .add("mobile-table-row")
      .addConditional(props.className, props.className)
      .addConditional(props.showSelectedRowHighlighted, "highlighted")
      .build();
  }, [props.className, props.showSelectedRowHighlighted]);

  const renderStatusCell = useMemo(() => {
    return props.cells.map((cell) => cell.isStatusCell && cell.isVisible !== false ?
      <div className="status-cell">{cell.children}{" "}</div>
      : null
    );
  }, [props.cells]);

  const cells = useMemo(() => {
    if (isShowAllCells)
      return props.cells.map((cell, idx) => {
        let tableCellCss = CssClassnameBuilder.new()
          .addConditional(cell.className, cell.className)
          .add("mobile-table-row-cell")
          .build();
        return !cell.isMobilePrimary && !cell.isMobileIcon && !cell.isStatusCell && cell.children && cell.isVisible !== false ? (
          <MobileTableCell
            className={tableCellCss}
            key={idx}
            label={cell.label}
          >
            {cell.children}{" "}
          </MobileTableCell>
        ) : null;
      });
  }, [props.cells, isShowAllCells]);

  const primaryCells = useMemo(() => {
    return props.cells.map((cell, idx) => cell.isMobilePrimary && !cell.isMobileIcon && !cell.isStatusCell && cell.children && cell.isVisible !== false ?
      <MobileTableCell
        key={idx}
        className={cell.className}
        label={cell.label}
      >
        {cell.children}{" "}
      </MobileTableCell> : null);
  }, [props.cells]);

  const mobileIcons = useMemo(() => {
    return props.cells.map((cell, idx) => cell.isMobileIcon && !cell.isMobilePrimary && !cell.isStatusCell && cell.children && cell.isVisible !== false ?
      <MobileTableCell
        key={idx}
        className={cell.className}
        label={cell.label}
      >
        {cell.children}{" "}
      </MobileTableCell>
      : null
    );
  }, [props.cells]);

  const handleIconButtonClick = useCallback(() => {
    setShowAllCells(!isShowAllCells);
  }, [setShowAllCells, isShowAllCells]);

  const handleRowClick = useCallback(() => {
    props.onClick && props.onClick(props.cells);
  }, [props.onClick, props.cells]);

  const renderIconButton = useMemo(() => {
    let cellContent = props.cells.filter((cell) => {
      return !cell.isMobileIcon && !cell.isVisible && !cell.isStatusCell && !cell.isMobilePrimary && cell.children && !cell.isMobilePrimary;
    });
    setCellContent(cellContent);
    if (cellContent.length > 0)
      return (
        <ScalableIconButton
          size={30}
          icon={isShowAllCells ? <ArrowUp /> : <ArrowDown />}
          onClick={() => handleIconButtonClick()}
        />
      );
  }, [handleIconButtonClick, props.cells, isShowAllCells]);

  return (
    <div className={tableRowCss} onClick={handleRowClick}>
      {renderStatusCell}
      <div className={"mobile-table-row-header" + (cellContent.length === 0 ? " align-center" : "")}>
        <div className="primary-cells">{primaryCells}</div>
        <div className="mobile-table-row-header-btns">
          {mobileIcons}
          {renderIconButton}
        </div>
      </div>
      {cells}
    </div>
  );
}
