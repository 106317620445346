import { APIEvaluationDetailsToDomainMapper } from "../../evaluations/models/mappers/APIEvaluationDetailsToDomainMapper";
import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { EvaluationComment } from "../models/domain/EvaluationComment";
import { EvaluationCommentCreateDTO } from "../models/dtos/EvaluationCommentCreateDTO";
import { EvaluationCommentsResponseDTO } from "../models/dtos/EvaluationCommentsResponseDTO";
import { EvaluationDetails } from "../../evaluations/models/domain/EvaluationDetails";
import { EvaluationDetailsDTO } from "../../evaluations/models/dtos/EvaluationDetailsDTO";
import { HttpClient } from "../../../lib/infrastructure/http/HttpClient";

const Route = (path: string) => {
    return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};

export class EvaluationCommentService {
    addEvaluationComment(locationId: string, periodId: string, evaluationId: string, requestDto: EvaluationCommentCreateDTO): Promise<EvaluationDetails> {
        return HttpClient.sessionRequest<EvaluationDetailsDTO>({
            method: "POST",
            url: Route(`/api/v1/Locations/${locationId}/EvaluationPeriods/${periodId}/Evaluations/${evaluationId}/Comments`),
            data: requestDto
        })
            .then((response) => { 
                return APIEvaluationDetailsToDomainMapper(response.data);
            })
            .catch((error) => {
                throw error;
            });
    }


    getEvaluationComments(locationId: string, periodId: string, evaluationId: string): Promise<EvaluationComment[]> {
        return HttpClient.sessionRequest<EvaluationCommentsResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/EvaluationPeriods/${periodId}/Evaluations/${evaluationId}/Comments`),
        })
            .then((response) => {

                var mappedEvaluationComments: EvaluationComment[] = response.data.comments.map(ob => ({
                    id: ob.id,
                    createdBy: ob.createdBy,
                    createdDate: ob.createdDate,
                    comment: ob.comment
                }));

                return mappedEvaluationComments;

            })
            .catch((error) => {
                throw error;
            });
    }
}