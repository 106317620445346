import './HoursAndAbsencePage.css'

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCreateNotification, usePopup, useWindowResize } from '../../../lib/infrastructure/ui/UIServices';

import { ColumnDefinition } from '../../../lib/components/table/TableInterfaces';
import { ConditionalRender } from '../../../lib/functional/ConditionalRender';
import { EmployeeHoursRecordsService } from '../services/EmployeeHoursRecordsService';
import { EmployeesHoursRecordsDetails } from '../models/domain/EmployeesHoursRecordsDetails';
import { EmployeesHoursRecordsQueryOptions } from '../models/dtos/EmployeesHoursRecordsQueryOptions';
import { ErrorPopup } from '../../../lib/components/popup/ErrorPopup';
import { FormFieldTextInput } from '../../../lib/components/form/form-field/FormFieldTextInput';
import { FullScreenLoader } from '../../../lib/components/loader/FullScreenLoader';
import { Notification } from '../../../lib/components/notifications/Notification';
import { PageLayout } from '../../../lib/layouts/main-content/PageLayout';
import { ResponsiveDataTable } from '../../../lib/components/table/ResponsiveDataTable';
import { ReactComponent as SearchIcon } from "../../../lib/assets/icons/search-icon.svg";
import { translate } from '../../../lib/infrastructure/i18n/InternationalizationService';
import { useEvaluationPeriodStatus } from '../../../lib/infrastructure/period/useEvaluationPeriodStatus';
import { useFormControl } from '../../../lib/components/form/Form';
import { useGlobalLocation } from '../../../lib/infrastructure/location/LocationServices';
import { useParams } from 'react-router-dom';
import { useServiceCallPro2 } from '../../../lib/hooks/useServiceCall';
import { Button } from '../../../lib/components/buttons/Button';
import { ImportPopup } from '../../../lib/components/popup/ImportPopup';
import { ConfigurationProvider } from '../../../lib/infrastructure/configuration/ConfigurationProvider';
import { IconButton } from '../../../lib/components/buttons/IconButton';
import { ReactComponent as ImportIcon } from "../../../lib/assets/icons/import.svg";
import { Permission } from '../../../models/api/gate/Permission';
import { PagePermissions } from '../../../lib/infrastructure/authorization/PagePermissions';
import axios from "axios";

var svc = new EmployeeHoursRecordsService();
const permissions = [Permission.GLOBAL_ADMIN, Permission.LOCATION_ADMIN, Permission.HUMAN_RESOURCES];

export function HoursAndAbsencePage() {
    const openPopup = usePopup();
    const createNotification = useCreateNotification();
    const periodInfo = useEvaluationPeriodStatus();
    const windowResize = useWindowResize();

    const locationID = useGlobalLocation();
    const { id: evaluationPeriodID } = useParams();

    const [employeesHoursRecords, setEmployeesHoursRecords] = useState<EmployeesHoursRecordsDetails[]>([]);
    const [totalItems, setTotalItems] = useState<number>();

    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [searchWord, setSearchWord] = useState<string>();

    const [timer, setTimer] = useState<NodeJS.Timeout>();

    var searchBoxFormControl = useFormControl<string>({
        isDisabled: false,
        enableAutoValidate: false,
    });


    /*****************
     * SERVICE CALLS 
     ****************/

    const [uploadFileCall, uploadFileCallIsLoading] = useServiceCallPro2(svc, svc.uploadEmployeeHoursRecordsFromXLSX);
    const [getEmployeesHoursRecordsCall, getEmployeesHoursRecordsCallIsLoading] = useServiceCallPro2(svc, svc.getEmployeesHoursRecords);


    /****************************
    * DATA REQUESTS
    *****************************/

    const handleOnPageAndItemsChanged = useCallback(
        (items: number, currentPage: number) => {
            setItemsPerPage(items);
            setCurrentPage(currentPage);
        },
        [setItemsPerPage, setCurrentPage]
    );


    const getEmployeesHoursRecords = useCallback(() => {

        if (!locationID || !evaluationPeriodID) return;

        var queryOptions: EmployeesHoursRecordsQueryOptions = {
            page: currentPage,
            pageLength: itemsPerPage,
            searchWord: searchWord,
        };

        getEmployeesHoursRecordsCall(`${locationID}`, evaluationPeriodID, queryOptions)
            .then((data) => {
                setEmployeesHoursRecords(data?.employeeHoursRecords);
                setTotalItems(data.totalItems);
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [locationID, evaluationPeriodID, getEmployeesHoursRecordsCall, setEmployeesHoursRecords, setTotalItems, openPopup, currentPage, itemsPerPage, searchWord]);


    const handleFileImport = useCallback((file: File) => {

        if (!locationID || !evaluationPeriodID) return;

        uploadFileCall(`${locationID}`, "" + evaluationPeriodID, file)
            .then((_) => {
                getEmployeesHoursRecords();

                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("HOURSANDABSENCE.AddMultipleHoursRecordsSuccessMessage")}
                    ></Notification>
                );
            })
            .catch((error) => {
                if (!error || axios.isCancel(error)) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [locationID, evaluationPeriodID, uploadFileCall, getEmployeesHoursRecords, openPopup, createNotification]);


    /****************************
    * DATA MANIPULATION EFFECTS
    *****************************/

    useEffect(() => {
        getEmployeesHoursRecords();
    }, [currentPage, itemsPerPage, searchWord]);


    useEffect(() => {
        if (timer) {
            clearTimeout(timer);
        }
        var responseTimeOut = setTimeout(() => {
            if (timer) {
                setCurrentPage(0);
                setSearchWord(searchBoxFormControl.value);
            }
        }, 1000);

        setTimer(responseTimeOut);
    }, [searchBoxFormControl.value]);


    const hoursAndAbsenceColumnsList: ColumnDefinition<EmployeesHoursRecordsDetails>[] = useMemo(
        () => [
            {
                cellRenderProp: (v) => v.employeeName,
                headerRender: translate("HOURSANDABSENCE.INFOANDFORM.CollaboratorName"),
                width: "45%",
                columnKey: "employeeName",
                isSortable: false,
                isMobilePrimaryCell: true
            },
            {
                cellRenderProp: (v) => (
                    <div className='absenteeism'>
                        {(v.code && v.codeDescription) ? <div className="absenteeism-code">{<b>{v.code + ": "}</b>} {v.codeDescription}</div> : null}
                    </div>
                ),
                width: "45%",
                headerRender: translate("HOURSANDABSENCE.INFOANDFORM.AbsenteeismCode"),
                columnKey: "absenteeism-code",
                isSortable: false,
            },
            {
                cellRenderProp: (v) => v.abscenceDays,
                headerRender: translate("HOURSANDABSENCE.INFOANDFORM.AbsenceDays"),
                columnKey: "absenceDays",
                width: "10%",
                isSortable: false,
            },
        ],
        []);


    const renderActionBtns = useMemo(() => {
        if (windowResize > 768)
            return (<ConditionalRender if={!periodInfo.isPeriodClosed}>

                <Button
                    text={translate("COMMON.Import")}
                    type="secondary"
                    onClick={() => openPopup(
                        <ImportPopup
                            warningMessage={(employeesHoursRecords && employeesHoursRecords.length > 0) ? translate("HOURSANDABSENCE.POPUPS.ImportHoursMapConfirmText") : null}
                            onClickDownloadTemplateBtn={() =>
                                window.open(ConfigurationProvider.getConfiguration().App.BackendUrl + "/templates/Bonus_Template_Mapa_Horas_V4.xlsx", "_blank")}
                            onClickImportBtn={handleFileImport} />)}
                    className="import-hours-and-absence-map"
                />


            </ConditionalRender>
            );
        else
            return (
                <ConditionalRender if={!periodInfo.isPeriodClosed}>
                    <IconButton
                        icon={<ImportIcon />}
                        type="secondary"
                        onClick={() => openPopup(
                            <ImportPopup
                                warningMessage={(employeesHoursRecords && employeesHoursRecords.length > 0) ? translate("HOURSANDABSENCE.POPUPS.ImportHoursMapConfirmText") : null}
                                onClickDownloadTemplateBtn={() =>
                                    window.open(ConfigurationProvider.getConfiguration().App.BackendUrl + "/templates/Bonus_Template_Mapa_Horas_V4.xlsx", "_blank")}
                                onClickImportBtn={handleFileImport} />)}
                        className="import-hours-and-absence-map"
                    />

                </ConditionalRender>
            );
    }, [handleFileImport, periodInfo.isPeriodClosed, employeesHoursRecords, windowResize, openPopup]);



    return (

        <PagePermissions permissions={permissions} >
            <PageLayout
                tabTitle={translate("HOURSANDABSENCE.HoursAndAbsenceMap")}
                showBreadcrumb
                pageTitle={translate("HOURSANDABSENCE.HoursAndAbsenceMap")}
                className="hours-and-absence-page"
                actions={renderActionBtns}
            >
                <FormFieldTextInput
                    formControl={searchBoxFormControl}
                    icon={<SearchIcon />}
                    placeholder={translate("COMMON.FORM.SearchPlaceholder")}
                    className="search-box"
                />
                <ResponsiveDataTable
                    columnDefinitions={hoursAndAbsenceColumnsList}
                    items={employeesHoursRecords || []}
                    totalitems={totalItems || 0}
                    paginationOptions={{ itemsPerPage: itemsPerPage, itemsPerPageOptions: [20, 40, 60, 80] }}
                    currentpage={currentPage}
                    isLoading={getEmployeesHoursRecordsCallIsLoading}
                    onPageAndItemsChanged={handleOnPageAndItemsChanged}
                />
                <ConditionalRender if={uploadFileCallIsLoading} >
                    <FullScreenLoader />
                </ConditionalRender>
            </PageLayout>
        </PagePermissions>)
        ;
}