import "./AdminContainer.css";

import { useEffect, useMemo } from "react";
import {
  useSetSideBarButtons,
  useWindowResize,
} from "../../../lib/infrastructure/ui/UIServices";

import { ReactComponent as BackIcon } from "../../../lib/assets/icons/back.svg";
import { ReactComponent as LocationsIcon } from "../../../lib/assets/icons/localizacoes.svg";
import { Sidebar } from "../../../lib/components/side-bar/Sidebar";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";

interface IAdminContainerProps {
  children: React.ReactNode;
}

const sideBarButtons = [
  {
    icon: <LocationsIcon />,
    navigateTo: "/settings/locations",
    name: translate("GLOBALADMIN.LOCATIONS.Locations"),
  },
  {
    icon: <BackIcon />, /*TEMP: needs a better icon*/
    navigateTo: "/bonus",
    name: "",
  },
];

export function AdminContainer(props: IAdminContainerProps) {
  const windowResize = useWindowResize();
  const setSideBarButtons = useSetSideBarButtons();

  useEffect(() => {
    setSideBarButtons(sideBarButtons);
  }, [setSideBarButtons]);

  const renderSideBar = useMemo(() => {
    if (windowResize > 768) {
      return <Sidebar buttons={sideBarButtons} />;
    } else return null;
  }, [windowResize]);

  return (
    <div className="admin-content-container">
      {renderSideBar}
      {props.children}
    </div>
  );
}
