import './EvaluationsEditor.css';

import { Evaluation, EvaluationState, EvaluationValue, EvaluationValueState } from '../models/domain/Evaluation';
import { EvaluationContextMenu, EvaluationCriteriaColumnDefinition } from "./EvaluationCriteriaColumnDefinition";
import { EvaluationStatusEnum, EvaluationStatusTranslationTerms } from "../models/domain/EvaluationStatusEnum";
import { FinalScoreFromPercentage, ScoreTagColor } from '../helpers/FinalScoreFromPercentage';
import lodash, { forEach, last } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useCreateNotification, usePopup } from "../../../lib/infrastructure/ui/UIServices";

import { AllValid } from '../../../common/validators/ValidateFormControls';
import { ColumnDefinition } from "../../../lib/components/table/TableInterfaces";
import { ReactComponent as CommentsIcon } from "../../../lib/assets/icons/comments.svg";
import { ConditionalRender } from '../../../lib/functional/ConditionalRender';
import { ConsultEmployeeHourRecordsPopup } from './popups/ConsultEmployeeHourRecordsPopup';
import { DateTimeHelper } from '../../../lib/helpers/datetime/DateTime';
import { ErrorPopup } from "../../../lib/components/popup/ErrorPopup";
import { EvaluationBatchUpdateDTO } from '../models/dtos/EvaluationBatchUpdateDTO';
import { EvaluationBatchUpdateStatusDTO } from '../models/dtos/EvaluationBatchUpdateStatusDTO';
import { EvaluationDetails } from "../models/domain/EvaluationDetails";
import { EvaluationMatrix } from "../../matrixes/models/domain/EvaluationMatrix";
import { EvaluationPeriodState } from '../../../models/enums/EvaluationPeriodState';
import { EvaluationUpdateDTO } from "../models/dtos/EvaluationUpdateDTO";
import { EvaluationUpdateStatusDTO } from '../models/dtos/EvaluationUpdateStatusDTO';
import { EvaluationValueCreateDTO } from "../models/dtos/EvaluationValueCreateDTO";
import { EvaluationsService } from "../services/EvaluationsService";
import { ReactComponent as FeedbackIcon } from "../../../lib/assets/icons/feedback.svg";
import { ReactComponent as FeedbackGivenInPerson } from "../../../lib/assets/icons/personal-feedback.svg";
import { FieldWrapper } from '../../periods/pages/details-page/period-criterias/EmployeeCriteriasSection';
import { FullScreenLoader } from '../../../lib/components/loader/FullScreenLoader';
import { ReactComponent as InfoIcon } from "../../../lib/assets/icons/info.svg";
import { ReactComponent as LockIcon } from "../../../lib/assets/icons/lock-btn.svg";
import { ManagerEvaluations } from '../models/domain/ManagerEvaluations';
import { NextEvaluationStatusMachine } from '../helpers/NextEvaluationStatusMachine';
import { Notification } from "../../../lib/components/notifications/Notification";
import { ObjectHelper } from "../../../lib/helpers/ObjectHelper";
import { PopupContainer } from '../../../lib/layouts/containers/popup-container/PopupContainer';
import { PopupContent } from '../../../lib/layouts/containers/popup-content/PopupContent';
import { PopupHeader } from '../../../lib/layouts/containers/popup-header/PopupHeader';
import { ReactComponent as ProblematicAbsenceIcon } from "../../../lib/assets/icons/absence-warning.svg";
import React from 'react';
import { RegisterCommentPopup } from "./popups/RegisterCommentPopup";
import { RegisterFeedbackPopup } from "./popups/RegisterFeedbackPopup";
import { ResponsiveDataTable } from "../../../lib/components/table/ResponsiveDataTable";
import { RoundIconButton } from "../../../lib/components/buttons/RoundIconButton";
import { ReactComponent as SaveIcon } from "../../../lib/assets/icons/save2.svg";
import { ScalableIconButton } from "../../../lib/components/buttons/ScalableIconButton";
import { Spacer } from '../../../lib/components/separator/Spacer';
import { Tag } from '../../../lib/components/tag/Tag';
import { Tooltip } from "../../../lib/components/tooltip/Tooltip";
import { ReactComponent as ValidateIcon } from "../../../lib/assets/icons/check-btn.svg";
import { ReactComponent as WarningFeedbackIcon } from "../../../lib/assets/icons/feedback-warning.svg";
import { ReactComponent as WarningIcon } from "../../../lib/assets/icons/warning-icon-v2.svg";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { useServiceCallPro } from "../../../lib/hooks/useServiceCall";
import { v4 as uuid } from 'uuid';

var evaluationSvc = new EvaluationsService();


interface ICalculationsLabelProps {
    state: EvaluationState;
    children: React.ReactNode;
}

function CalculationLabel(props: ICalculationsLabelProps) {
    if (props.state !== EvaluationState.SAVED) {
        return (<div className="dirty-calculations">
            {props.children}
        </div>)
    }
    return <>{props.children}</>
}


export interface IEvaluationsEditorProps {
    locationId?: number;
    evaluationPeriodId?: string;
    isLoading?: boolean;
    matrix?: EvaluationMatrix | null;
    state?: EvaluationPeriodState | null;
    managerEvaluations?: ManagerEvaluations | null;
    showMidtermEvaluationColumn: boolean;
    showAbsentismColumn: boolean;
    isLoadingPermissions: boolean;
    hasPermission: boolean;
    onlyRHPermission: boolean;
    loadingOnlyRHPermission: boolean;
    isPeriodClosed: boolean;
    showTable: boolean;
    handleOnClickRow: () => void;
}

interface MultipleEvaluationsToUpdate {
    originalGuid: string;
    evaluation: EvaluationDetails;
}


export interface EvaluationSummaryCalculations {
    employeeScoreTotalPercentage: number;
    companyFactorTotalPercentage: number;
    employeeFactorTotalPercentage: number;
    employeeFactorTotal: number;
    companyFactorTotal: number;
    finalScorePercentage: number;
    finalScoreWithCutPercentage: number;
    finalPayout: number;
    status: EvaluationStatusEnum;
    statusReason: string;
}


export function EvaluationsEditor(props: IEvaluationsEditorProps) {
    const openPopup = usePopup();
    const createNotification = useCreateNotification();

    const { matrix } = props;
    const [data, setData] = useState<Evaluation[]>([]);

    const [allEvaluations] = useState<EvaluationDetails[] | null>(props.managerEvaluations?.evaluations ?? null);

    const updateEvaluationCall = useServiceCallPro(evaluationSvc.updateEvaluation);

    const updateEvaluationsBatchCall = useServiceCallPro(evaluationSvc.updateEvaluationsBatch);

    const updateEvaluationStatusCall = useServiceCallPro(evaluationSvc.updateEvaluationStatus);
    const updateEvaluationsStatusBatchCall = useServiceCallPro(evaluationSvc.updateEvaluationStatusBatch);


    const [hasSomeEvaluationToCalibrate, setHasSomeEvaluationToCalibrate] = useState<boolean>(false);
    const [hasSomeEvaluationToValidate, setHasSomeEvaluationToValidate] = useState<boolean>(false);
    const [hasSomeEvaluationToSave, setHasSomeEvaluationToSave] = useState<boolean>(false);




    /****************************
    * DATA REQUESTS *
    *****************************/

    const updateLine = useCallback((original: Evaluation, originalValue: EvaluationValue | undefined, patch: Partial<EvaluationValue>) => {

        if (!originalValue) return;

        const clone = [...data];
        let targetIdx = clone.findIndex(i => i.internalGuid === original.internalGuid);

        let cloneEvaluation = lodash.cloneDeep(clone[targetIdx]);

        let targetFactorValueIdx = cloneEvaluation.factorValues.findIndex(i => i.targetFactorId === originalValue.targetFactorId);

        let cloneEvaluationValue = { ...cloneEvaluation.factorValues[targetFactorValueIdx], ...patch };
        cloneEvaluation.factorValues[targetFactorValueIdx] = cloneEvaluationValue;


        // Calculate in RealTime 1)
        let weightedSum = 0;
        let totalWeight = 0;
        
        cloneEvaluation.factorValues.forEach(item => {
            const employeeFactorWeight = (matrix?.employeeCriterias.find(crit => crit.id === item.targetFactorId)?.weightPercentage ?? 0) / 100;
            weightedSum += item.value * employeeFactorWeight;
            totalWeight += employeeFactorWeight;
        });
        
        // Calculate in RealTime weighted average 2)
        cloneEvaluation.employeeScoreTotalPercentage = totalWeight !== 0 ? weightedSum / totalWeight : 0;

        cloneEvaluation.isValid = AllValid(...cloneEvaluation.factorValues.map(e => e.isValueValid));

        clone[targetIdx] = cloneEvaluation;

        if (clone[targetIdx].state === EvaluationState.SAVED && !patch.state) {
            if (!ObjectHelper.deepEqual(clone[targetIdx], original) || !ObjectHelper.deepEqual(cloneEvaluation.factorValues, original.factorValues)) {
                clone[targetIdx].state = EvaluationState.EDITED
            }
        }

        setData(clone);

    }, [data, setData]);





    const updateLineState = useCallback((originalInternalGuid: string, state: EvaluationState, freshCalculations: EvaluationSummaryCalculations | undefined,
        evalStatus: EvaluationStatusEnum | undefined, lastComment: string | undefined, canCalibrate: boolean | undefined, canEvaluate: boolean | undefined, canRevertConfirmation: boolean | undefined) => {
        const clone = [...data];
        let targetIdx = clone.findIndex(i => i.internalGuid === originalInternalGuid);

        clone[targetIdx].state = state;

        if (freshCalculations !== undefined) {
            clone[targetIdx].employeeScoreTotalPercentage = freshCalculations.employeeScoreTotalPercentage;
            clone[targetIdx].companyFactorTotal = freshCalculations.companyFactorTotal;
            clone[targetIdx].employeeFactorTotal = freshCalculations.employeeFactorTotal;
            clone[targetIdx].finalPayout = freshCalculations.finalPayout;
            clone[targetIdx].finalScorePercentage = freshCalculations.finalScorePercentage;
            clone[targetIdx].finalScoreWithCutPercentage = freshCalculations.finalScoreWithCutPercentage;
            clone[targetIdx].status = freshCalculations.status;
            clone[targetIdx].statusReason = freshCalculations.statusReason;
            clone[targetIdx].companyFactorTotalPercentage = freshCalculations.companyFactorTotalPercentage;
            clone[targetIdx].employeeFactorTotalPercentage = freshCalculations.employeeFactorTotalPercentage;
        }

        if (lastComment !== undefined)
            clone[targetIdx].lastComment = lastComment;

        if (evalStatus !== undefined)
            clone[targetIdx].status = evalStatus;

        if (canCalibrate !== undefined && canEvaluate !== undefined && canRevertConfirmation !== undefined) {
            clone[targetIdx].canCalibrate = canCalibrate;
            clone[targetIdx].canEvaluate = canEvaluate;
            clone[targetIdx].canRevertConfirmation = canRevertConfirmation;
        }

        setData(clone);

    }, [data, setData]);




    const updateLineBonus = useCallback((originalInternalGuid: string, freshEvaluationDetails: EvaluationDetails | undefined) => {
        const clone = [...data];
        let targetIdx = clone.findIndex(i => i.internalGuid === originalInternalGuid);

        if (freshEvaluationDetails !== undefined) {
            clone[targetIdx].finalPayout = freshEvaluationDetails.finalPayout;
            clone[targetIdx].finalScorePercentage = freshEvaluationDetails.finalScorePercentage;
            clone[targetIdx].employeeFactorTotal = freshEvaluationDetails.employeeFactorTotal;
            clone[targetIdx].finalScoreWithCutPercentage = freshEvaluationDetails.finalScoreWithCutPercentage;
            clone[targetIdx].isBonusCut = freshEvaluationDetails.isBonusCut;
            clone[targetIdx].status = freshEvaluationDetails.status ?? EvaluationStatusEnum.ONGOING;
            clone[targetIdx].bonusCutReasonId = freshEvaluationDetails.bonusCutReasonId ?? undefined;
            clone[targetIdx].cutReasonJustification = freshEvaluationDetails.cutReasonJustification ?? "";
            clone[targetIdx].bonusCutReason = freshEvaluationDetails.bonusCutReason ?? "";


            clone[targetIdx].companyFactorTotalPercentage = freshEvaluationDetails.companyFactorTotalPercentage;
            clone[targetIdx].employeeFactorTotalPercentage = freshEvaluationDetails.employeeFactorTotalPercentage;
            clone[targetIdx].employeeScoreTotalPercentage = freshEvaluationDetails.employeeScoreTotalPercentage;
        }
        setData(clone);
    }, [data, setData]);



    const updateLineOnRejectEvaluation = useCallback((originalInternalGuid: string, freshEvaluationDetails: EvaluationDetails | undefined) => {

        const clone = [...data];
        let targetIdx = clone.findIndex(i => i.internalGuid === originalInternalGuid);

        if (freshEvaluationDetails !== undefined) {
            clone[targetIdx].status = freshEvaluationDetails.status ?? EvaluationStatusEnum.ONGOING;
            clone[targetIdx].statusReason = freshEvaluationDetails.statusReason;
            clone[targetIdx].rejectedAt = freshEvaluationDetails.rejectedAt;
            clone[targetIdx].rejectedby = freshEvaluationDetails.rejectedBy;

            clone[targetIdx].canEvaluate = freshEvaluationDetails.permissions?.canEvaluate;
            clone[targetIdx].canCalibrate = freshEvaluationDetails.permissions?.canCalibrate;
            clone[targetIdx].canRevertConfirmation = freshEvaluationDetails.permissions?.canRevertConfirmation;
        }

        setData(clone);

    }, [data, setData]);




    const updateLineLastComment = useCallback((originalInternalGuid: string, freshEvaluationDetails: EvaluationDetails | undefined) => {

        const clone = [...data];
        let targetIdx = clone.findIndex(i => i.internalGuid === originalInternalGuid);

        if (freshEvaluationDetails !== undefined)
            clone[targetIdx].lastComment = freshEvaluationDetails.lastComment;
        clone[targetIdx].totalComments = clone[targetIdx].totalComments + 1;

        setData(clone);

    }, [data, setData]);



    const updateLineFeedback = useCallback((originalInternalGuid: string, freshEvaluationDetails: EvaluationDetails | undefined) => {

        const clone = [...data];
        let targetIdx = clone.findIndex(i => i.internalGuid === originalInternalGuid);

        if (freshEvaluationDetails !== undefined) {
            clone[targetIdx].feedback = freshEvaluationDetails.feedback;
            clone[targetIdx].isFeedbackGivenInPerson = freshEvaluationDetails.isFeedbackGivenInPerson;
        }
        clone[targetIdx].needsFeedback = false;

        setData(clone);

    }, [data, setData]);



    const handleEvaluationStatusClicked = useCallback((evaluationId: number, evalInternalGuid: string, evStatus?: EvaluationStatusEnum) => {

        if (!props.locationId || !props.evaluationPeriodId) return;

        var dto: EvaluationUpdateStatusDTO = {
            nextStatus: evStatus ? NextEvaluationStatusMachine(evStatus) : EvaluationStatusEnum.ONGOING,
            statusReason: "-"
        };

        updateEvaluationStatusCall
            .invoke("" + props.locationId, props.evaluationPeriodId, "" + evaluationId, dto)
            .then((data) => {

                updateLineState(evalInternalGuid, EvaluationState.SAVED, {
                    employeeScoreTotalPercentage: data.employeeScoreTotalPercentage,
                    companyFactorTotalPercentage: data.companyFactorTotalPercentage,
                    employeeFactorTotalPercentage: data.employeeFactorTotalPercentage,
                    companyFactorTotal: data.companyFactorTotal,
                    employeeFactorTotal: data.employeeFactorTotal,
                    finalPayout: data.finalPayout,
                    finalScorePercentage: data.finalScorePercentage,
                    finalScoreWithCutPercentage: data.finalScoreWithCutPercentage,
                    status: data.status ?? EvaluationStatusEnum.ONGOING,
                    statusReason: data.statusReason ?? ""
                }
                    , data.status ?? EvaluationStatusEnum.ONGOING, undefined, data.permissions?.canCalibrate, data.permissions?.canEvaluate, data.permissions?.canRevertConfirmation);

                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("EVALUATIONS.EvaluationStatusUpdatedTo", translate(EvaluationStatusTranslationTerms[data.status ?? EvaluationStatusEnum.ONGOING]))}
                    ></Notification>
                );
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [props.locationId, props.evaluationPeriodId, createNotification, openPopup,
    updateEvaluationStatusCall.invoke, updateLineState]);



    const updateMultipleLineStates = useCallback((evaluationsToUpdate: MultipleEvaluationsToUpdate[]) => {
        setData(prevData => {
            let clone = [...prevData];
            evaluationsToUpdate.forEach(fresh => {
                let targetIdx = clone.findIndex(i => i.internalGuid === fresh.originalGuid);

                if (targetIdx !== -1) {
                    clone[targetIdx].employeeScoreTotalPercentage = fresh.evaluation.employeeScoreTotalPercentage;
                    clone[targetIdx].companyFactorTotal = fresh.evaluation.companyFactorTotal;
                    clone[targetIdx].employeeFactorTotal = fresh.evaluation.employeeFactorTotal;
                    clone[targetIdx].finalPayout = fresh.evaluation.finalPayout;
                    clone[targetIdx].finalScorePercentage = fresh.evaluation.finalScorePercentage;
                    clone[targetIdx].finalScoreWithCutPercentage = fresh.evaluation.finalScoreWithCutPercentage;
                    clone[targetIdx].status = fresh.evaluation.status ?? EvaluationStatusEnum.ONGOING;
                    clone[targetIdx].statusReason = fresh.evaluation.statusReason;
                    clone[targetIdx].companyFactorTotalPercentage = fresh.evaluation.companyFactorTotalPercentage;
                    clone[targetIdx].employeeFactorTotalPercentage = fresh.evaluation.employeeFactorTotalPercentage;
                    clone[targetIdx].canCalibrate = fresh.evaluation.permissions?.canCalibrate;
                    clone[targetIdx].canEvaluate = fresh.evaluation.permissions?.canEvaluate;
                    clone[targetIdx].canRevertConfirmation = fresh.evaluation.permissions?.canRevertConfirmation;
                    clone[targetIdx].state = EvaluationState.SAVED;
                }
            })
            return clone;
        });
        createNotification(
            <Notification
                type="success"
                title={translate("COMMON.SYSTEMPOPUPS.Success")}
                text={translate("EVALUATIONS.MESSAGES.MultipleScoresSavedSuccess")}
            ></Notification>
        );
    }, [setData, createNotification]);




    const handleSaveAllEvaluationsClicked = useCallback(() => {
        if (!props.locationId || !props.evaluationPeriodId) return;

        var clone = [...data];
        clone = clone.filter(e => e.canEvaluate && e.isValid && e.state === EvaluationState.EDITED && e.status !== EvaluationStatusEnum.CALIBRATED && e.status !== EvaluationStatusEnum.CONFIRMED);

        if (clone.length < 1)
            return;

        var dto: EvaluationBatchUpdateDTO = {
            evaluations: clone.map((ev) => {
                return {
                    evaluationId: ev.id,
                    evaluationValues: ev.factorValues.map((f): EvaluationValueCreateDTO => ({
                        targetEmployeeCriteriaId: f.targetFactorId,
                        value: f.value
                    })),
                };
            })
        };

        updateEvaluationsBatchCall.invoke("" + props.locationId, "" + props.evaluationPeriodId, dto)
            .then((res) => {
                if (!res) {
                    openPopup(<ErrorPopup>{translate("COMMON.ERRORS.ErrorSavingValues")}</ErrorPopup>);
                    return;
                }

                var clone = [...data];

                var matchEvaluations: MultipleEvaluationsToUpdate[] = res.evaluations.map((ev) => {
                    return {
                        evaluation: ev,
                        originalGuid: clone.find(c => c.id === ev.id)?.internalGuid ?? "0"
                    }
                });

                updateMultipleLineStates(matchEvaluations);

            }).catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });


    }, [props.locationId, props.evaluationPeriodId, data, updateEvaluationsBatchCall.isLoading,
        updateEvaluationCall, updateMultipleLineStates, openPopup]);



    const updateMultipleEvaluationStatus = useCallback((evaluationsToUpdate: MultipleEvaluationsToUpdate[], status: EvaluationStatusEnum) => {

        setData(prevData => {
            let clone = [...prevData];
            evaluationsToUpdate.forEach(fresh => {
                let targetIdx = clone.findIndex(i => i.internalGuid === fresh.originalGuid);

                if (targetIdx !== -1) {
                    clone[targetIdx].status = fresh.evaluation.status ?? EvaluationStatusEnum.ONGOING;
                    clone[targetIdx].canCalibrate = fresh.evaluation.permissions?.canCalibrate;
                    clone[targetIdx].canEvaluate = fresh.evaluation.permissions?.canEvaluate;
                    clone[targetIdx].canRevertConfirmation = fresh.evaluation.permissions?.canRevertConfirmation;
                    clone[targetIdx].state = EvaluationState.SAVED;
                    clone[targetIdx].statusReason = fresh.evaluation.statusReason;
                }
            })
            return clone;
        });
        createNotification(
            <Notification
                type="success"
                title={translate("COMMON.SYSTEMPOPUPS.Success")}
                text={translate("EVALUATIONS.MultipleEvaluationsStatusUpdatedTo", translate(EvaluationStatusTranslationTerms[status ?? EvaluationStatusEnum.ONGOING]))}
            ></Notification>
        );
    }, [setData, createNotification]);



    const handleValidateMultipleEvaluationClicked = useCallback(() => {
        if (!props.locationId || !props.evaluationPeriodId) return;

        var clone = [...data];

        clone = clone.filter(e => e.canEvaluate
            && e.isValid && e.state === EvaluationState.SAVED
            && (e.status === EvaluationStatusEnum.READYTOEVALUATE || e.status === EvaluationStatusEnum.ONGOING || e.status === EvaluationStatusEnum.REJECTED));


        if (clone.length < 1)
            return;

        var dto: EvaluationBatchUpdateStatusDTO = {
            evaluations: clone.map((ev) => {
                return {
                    evaluationId: ev.id,
                    evaluationStatus: {
                        nextStatus: NextEvaluationStatusMachine(EvaluationStatusEnum.ONGOING),
                        statusReason: "-",
                    }
                };
            })
        };
        updateEvaluationsStatusBatchCall.invoke("" + props.locationId, "" + props.evaluationPeriodId, dto)
            .then((res) => {
                if (!res) {
                    openPopup(<ErrorPopup>{translate("COMMON.ERRORS.ErrorSavingValues")}</ErrorPopup>);
                    return;
                }
                var newClone = [...data];
                var matchEvaluations: MultipleEvaluationsToUpdate[] = res.evaluations.map((ev) => {
                    return {
                        evaluation: ev,
                        originalGuid: newClone.find(c => c.id === ev.id)?.internalGuid ?? "0"
                    }
                });
                updateMultipleEvaluationStatus(matchEvaluations, EvaluationStatusEnum.CONFIRMED);

            }).catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [props.locationId, props.evaluationPeriodId, data, updateEvaluationsStatusBatchCall,
        updateEvaluationStatusCall, updateMultipleEvaluationStatus, openPopup]);




    const handleCalibrateMultipleEvaluationClicked = useCallback(() => {
        if (!props.locationId || !props.evaluationPeriodId) return;

        var clone = [...data];

        clone = clone.filter(e => e.canCalibrate && e.isValid && e.state === EvaluationState.SAVED && (e.status === EvaluationStatusEnum.CONFIRMED));

        if (clone.length < 1)
            return;

        var dto: EvaluationBatchUpdateStatusDTO = {
            evaluations: clone.map((ev) => {
                return {
                    evaluationId: ev.id,
                    evaluationStatus: {
                        nextStatus: NextEvaluationStatusMachine(EvaluationStatusEnum.CONFIRMED),
                        statusReason: "-",
                    }
                };
            })
        };
        updateEvaluationsStatusBatchCall.invoke("" + props.locationId, "" + props.evaluationPeriodId, dto)
            .then((res) => {
                if (!res) {
                    openPopup(<ErrorPopup>{translate("COMMON.ERRORS.ErrorSavingValues")}</ErrorPopup>);
                    return;
                }
                var newClone = [...data];
                var matchEvaluations: MultipleEvaluationsToUpdate[] = res.evaluations.map((ev) => {
                    return {
                        evaluation: ev,
                        originalGuid: newClone.find(c => c.id === ev.id)?.internalGuid ?? "0"
                    }
                });
                updateMultipleEvaluationStatus(matchEvaluations, EvaluationStatusEnum.CALIBRATED);

            }).catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [props.locationId, props.evaluationPeriodId, data, updateEvaluationsStatusBatchCall,
        updateEvaluationStatusCall, updateMultipleEvaluationStatus, openPopup]);







    useEffect(() => {
        setHasSomeEvaluationToCalibrate(data.some(ev => ev.canCalibrate));
        setHasSomeEvaluationToValidate(data.some(ev => ev.canEvaluate));
        setHasSomeEvaluationToSave(data.some(ev => ev.state === EvaluationState.EDITED && ev.canEvaluate));
    }, [data]);





    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/



    useEffect(() => {
        if (matrix) {
            const evaluations = allEvaluations?.map((e): Evaluation => {

                return ({
                    internalGuid: uuid(),
                    isValid: true,
                    state: EvaluationState.SAVED,

                    id: e.id,
                    employee: {
                        id: e.targetEmployee?.id ?? "",
                        email: e.targetEmployee.email,
                        name: e.targetEmployee.name,
                        employeeID: e.targetEmployee?.employeeID ?? ""
                    },

                    factorValues: matrix.employeeCriterias.map(crit => {
                        const evaluationValue = e.evaluationValues.find((x) => x.targetEmployeeCriteriaId === crit.id);

                        return ({
                            id: evaluationValue?.id ?? 0,
                            targetFactorId: crit.id,
                            value: evaluationValue?.value ?? 0,
                            isValueValid: evaluationValue?.value !== undefined ? (evaluationValue.value >= 0 && evaluationValue.value <= 100) : true,
                            state: evaluationValue ? EvaluationValueState.SAVED : EvaluationValueState.NEW
                        })
                    }),



                    companyFactorTotal: e.companyFactorTotal,
                    companyFactorTotalPercentage: e.companyFactorTotalPercentage,

                    absentismFactorTotal: e.absentismFactorTotal,
                    absentismFactorTotalPercentage: e.absentismFactorTotalPercentage,

                    employeeFactorTotal: e.employeeFactorTotal,
                    employeeFactorTotalPercentage: e.employeeFactorTotalPercentage,

                    employeeScoreTotal: e.employeeScoreTotal,
                    employeeScoreTotalPercentage: e.employeeScoreTotalPercentage,

                    finalScore: e.finalScore,
                    finalScorePercentage: e.finalScorePercentage,

                    finalScoreWithCut: e.finalScoreWithCut,
                    finalScoreWithCutPercentage: e.finalScoreWithCutPercentage,

                    baseValue: e.baseValue,
                    finalPayout: e.finalPayout,

                    isBonusCut: e.isBonusCut,
                    cutReasonJustification: e.cutReasonJustification ?? "",
                    bonusCutReasonId: e.bonusCutReasonId ?? undefined,
                    bonusCutReason: e.bonusCutReason ?? "",

                    totalComments: e.totalComments ?? 0,
                    lastComment: e.lastComment,

                    status: e.status ?? EvaluationStatusEnum.READYTOEVALUATE,
                    feedback: e.feedback,

                    isFeedbackGivenInPerson: e.isFeedbackGivenInPerson,

                    statusReason: e.statusReason,
                    needsFeedback: e.needsFeedback ?? true,

                    rejectedAt: e.rejectedAt,
                    rejectedby: e.rejectedBy,

                    managerId: e.managerId,
                    managerName: e.managerName,

                    canCalibrate: e.permissions?.canCalibrate,
                    canEvaluate: e.permissions?.canEvaluate,
                    canRevertConfirmation: e.permissions?.canRevertConfirmation,

                    hasProblematicAbsences: e.hasProblematicAbsences,
                    hourRecords: e.hourRecords
                })
            })

            setData(evaluations || []);
        }
    }, [matrix, allEvaluations])




    /****************************
     * USER ACTIONS
     *****************************/

    const updateEvaluation = useCallback((evaluationId: number, evaluationInternalGuid: string, dto: EvaluationUpdateDTO) => {
        if (!props.locationId || !props.evaluationPeriodId) return

        updateEvaluationCall
            .invoke("" + props.locationId, props.evaluationPeriodId, "" + evaluationId, dto)
            .then((data) => {

                updateLineState(evaluationInternalGuid, EvaluationState.SAVED, {
                    employeeScoreTotalPercentage: data.employeeScoreTotalPercentage,
                    companyFactorTotalPercentage: data.companyFactorTotalPercentage,
                    employeeFactorTotalPercentage: data.employeeFactorTotalPercentage,
                    companyFactorTotal: data.companyFactorTotal,
                    employeeFactorTotal: data.employeeFactorTotal,
                    finalPayout: data.finalPayout,
                    finalScorePercentage: data.finalScorePercentage,
                    finalScoreWithCutPercentage: data.finalScoreWithCutPercentage,
                    status: data.status ?? EvaluationStatusEnum.ONGOING,
                    statusReason: data.statusReason ?? ""
                }, undefined, undefined, data.permissions?.canCalibrate, data.permissions?.canEvaluate, data.permissions?.canRevertConfirmation);

                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("EVALUATIONS.MESSAGES.ScoreSavedSuccess")}
                    ></Notification>
                );
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [props.locationId, props.evaluationPeriodId, updateEvaluationCall.invoke,
        createNotification, openPopup, updateLineState]);





    const handleLineSaveClicked = useCallback((evaluation: Evaluation) => {
        if (!props.locationId || !props.evaluationPeriodId) return;

        var dto: EvaluationUpdateDTO = {
            evaluationValues: evaluation.factorValues.map((f): EvaluationValueCreateDTO => ({
                targetEmployeeCriteriaId: f.targetFactorId,
                value: f.value
            })),
        }


        updateEvaluation(evaluation.id, evaluation.internalGuid, dto);

    }, [props.locationId, props.evaluationPeriodId, updateEvaluation]);



    const handleDeleteEvaluationCompleted = useCallback((evaluationID: number) => {

        var clone = [...data];

        let targetIdx = clone.findIndex(ev => ev.id === evaluationID);

        if (targetIdx !== -1) {
            clone.splice(targetIdx, 1);
        }

        setData(clone);

    }, [data, setData]);



    /****************************
     * CSS & HTML
     ****************************/

    const evaluationFactorsColumns: ColumnDefinition<Evaluation>[] = useMemo(() => {
        const employeefactors = matrix?.employeeCriterias.map((crit): ColumnDefinition<Evaluation> => ({
            columnKey: `employeeFactor-${crit.id}`,
            cellRenderProp: (v) => {
                const factorValue = v.factorValues.find(f => f.targetFactorId === crit.id);

                if ((v.status === EvaluationStatusEnum.CALIBRATED || v.status === EvaluationStatusEnum.CONFIRMED || !v.canEvaluate) && !props.isPeriodClosed)
                    return (<div className='locked-value'>{factorValue?.value}</div>);

                return (
                    <FieldWrapper value={factorValue?.value} isLocked={props.isPeriodClosed} key={v.employee.email + "-" + crit.id}>
                        <EvaluationCriteriaColumnDefinition
                            placeholder={"0-100"}
                            value={factorValue?.value}
                            onValueChanged={(value, isValid) => { updateLine(v, factorValue, { value: value, isValueValid: isValid }) }}
                        />
                    </FieldWrapper>);
            },
            headerRender: <Tooltip text={`${crit.description}  (${translate("COMMON.Weight")}: ${crit.weightPercentage}%)`}>{crit.name + " (%)"}</Tooltip>,
        })) ?? [];


        const midtermEvaluationColumn: ColumnDefinition<Evaluation> = {
            columnKey: `midterm-evaluation`,
            cellRenderProp: (v) => {
                return (
                    <CalculationLabel state={v.state}>
                        {<>{((v.companyFactorTotalPercentage ?? 0) + (v.employeeFactorTotalPercentage ?? 0)).toFixed(2)}%</> ?? "-"}
                    </CalculationLabel>
                );
            },
            headerRender: <Tooltip text={translate("EVALUATIONS.INFOANDFORM.MidtermScoreTooltip")}>{translate("EVALUATIONS.INFOANDFORM.MidtermScore")}</Tooltip>,
        };


        const absenteeismColumn: ColumnDefinition<Evaluation> =
        {
            columnKey: `total-absentism`,
            cellRenderProp: (v) => {

                return <div className='presence-cell'>
                    <CalculationLabel state={v.state}>
                        {v.hasProblematicAbsences && v.absentismFactorTotalPercentage ?
                            <Tag backgroundColor='status-secondary-orange' isTiny text={v.absentismFactorTotalPercentage?.toFixed(2) + "%"} /> : (
                                v.absentismFactorTotalPercentage ? <>{v.absentismFactorTotalPercentage?.toFixed(2)}%</> : "0%"
                            )}
                        {v.hourRecords && v.hourRecords?.length > 0 ?
                            <Tooltip text={translate("HOURSANDABSENCE.ViewAbsenceRecords")} >
                                <ScalableIconButton
                                    icon={<InfoIcon />}
                                    size={24}
                                    onClick={() =>
                                        openPopup(<ConsultEmployeeHourRecordsPopup
                                            employeeName={"" + v.employee.name}
                                            employeeValmetID={"" + v.employee.employeeID}
                                            hourRecords={v.hourRecords ?? []}
                                        />)}
                                />
                            </Tooltip>
                            : null}
                    </CalculationLabel>
                </div>
            },
            headerRender: <Tooltip text={translate("EVALUATIONS.INFOANDFORM.AbseentismHeaderTooltip")}>{translate("EVALUATIONS.INFOANDFORM.Presence") + " (%)"}</Tooltip>,
        };

        const bonusColumn: ColumnDefinition<Evaluation> =
        {
            columnKey: `bonus`,
            cellRenderProp: (v) =>
                <div className='bonus-container'>
                    {v?.baseValue === 0 ?
                        <Tag
                            isTiny
                            className="warning-salary-undefined"
                            icon={<WarningIcon />}
                            text={translate("EVALUATIONS.INFOANDFORM.SalaryUndefined")}
                            backgroundColor="status-yellow"
                        /> :
                        <CalculationLabel state={v.state}>{v?.finalPayout?.toFixed(2)}€</CalculationLabel>
                    }
                </div>,
            headerRender: translate("EVALUATIONS.INFOANDFORM.Bonus"),
        };

        const columns: ColumnDefinition<Evaluation>[] = [
            {
                columnKey: `employeeName`,
                cellRenderProp: (v) =>

                    <div className="employee-info">
                        <div className="small-copy employee-name">
                            {v.employee.name}
                        </div>
                        <div className="caption employee-id">{v.employee?.employeeID ?? ""}</div>
                    </div>,

                headerRender: translate("EVALUATIONS.INFOANDFORM.EmployeeName"),
                isMobilePrimaryCell: true,
            },
            ...employeefactors,
            {
                columnKey: `employee-factors`,
                cellRenderProp: (v) => <CalculationLabel state={v.state}>
                    <div className="score">
                        <Tag isTiny backgroundColor={ScoreTagColor(v.employeeScoreTotalPercentage)} text={"" + FinalScoreFromPercentage(v.employeeScoreTotalPercentage)} />
                        <small>{v.employeeScoreTotalPercentage ? <>({v.employeeScoreTotalPercentage?.toFixed(2)}%)</> : "(0%)"}</small>
                    </div>
                </CalculationLabel>,
                headerRender: <Tooltip text={translate("EVALUATIONS.INFOANDFORM.EmployeeFactorsScoreHeaderTooltip")}>{translate("EVALUATIONS.INFOANDFORM.EmployeeFactorsScore")}</Tooltip>,
            },
            ...(props.showMidtermEvaluationColumn ? [midtermEvaluationColumn] : []),
            ...(props.showAbsentismColumn ? [absenteeismColumn] : []),
            {
                columnKey: `cut-bonus`,
                cellRenderProp: (v) => {
                    if (v.bonusCutReason) {
                        return (
                            <div className='cut-bonus-container' onClick={() => openPopup(<PopupContainer className='popup-bonus-cut-reason'>
                                <PopupHeader title={translate("EVALUATIONS.INFOANDFORM.Cut")} />
                                <PopupContent>
                                    <div className='bold'>{v.bonusCutReason}</div>
                                    <Spacer mode="vertical" px={10} />
                                    <div>{v.cutReasonJustification}</div>
                                </PopupContent>
                            </PopupContainer>
                            )}>
                                <div className='bold'>{v.bonusCutReason}</div>
                                <div>{v.cutReasonJustification}</div>
                            </div>
                        )
                    }
                },
                headerRender: translate("EVALUATIONS.INFOANDFORM.Cut"),
                width: "15%"
            },
            {
                columnKey: `total-final-score`,
                cellRenderProp: (v) => v.finalScoreWithCutPercentage ? <>{v.finalScoreWithCutPercentage?.toFixed(2) + " %"}</> : "0%",
                headerRender: <Tooltip text={translate("EVALUATIONS.INFOANDFORM.TotalFinalScoreHeaderTooltip")}>{translate("EVALUATIONS.INFOANDFORM.TotalFinalScore")}</Tooltip>,
            },
            ...(!props.loadingOnlyRHPermission && props.onlyRHPermission ? [bonusColumn] : []),
            {
                columnKey: `rejected-evaluation`,
                cellRenderProp: (v) => v.statusReason ?
                    (
                        <div className="rejected-reason" onClick={() => openPopup(
                            <PopupContainer className='popup-rejected-reason'>
                                <PopupHeader title={translate("EVALUATIONS.INFOANDFORM.RejectedReason")} /><PopupContent>
                                    {v.rejectedby && v.rejectedAt ?
                                        <div className="bold rejection">
                                            <div className="name">{v.rejectedby}</div>
                                            <div className="at">{DateTimeHelper.formatDateTime(v.rejectedAt)}</div>
                                        </div> : null}
                                    <Spacer mode="vertical" px={10} />
                                    <div className="description">{v.statusReason}</div>
                                </PopupContent></PopupContainer>)}>
                            <div className="description">{v.statusReason}</div>
                        </div>
                    ) : null,
                headerRender: translate("EVALUATIONS.INFOANDFORM.RejectedReason"),
                width: "20%"
            },
            {
                columnKey: `feedbackComments`,
                cellRenderProp: (v) => <div>
                    {v.totalComments > 0 && v.lastComment ?
                        <Tooltip text={translate("EVALUATIONS.INFOANDFORM.LastMessageToCalibrator") + `: ${v.lastComment}`}><ScalableIconButton icon={<CommentsIcon />} size={24}
                            onClick={() => openPopup(<RegisterCommentPopup
                                isPeriodClosed={props.isPeriodClosed}
                                employeeName={v.employee.name ?? "-"} periodId={props.evaluationPeriodId ?? ""}
                                locationId={props.locationId ?? 0} evaluationId={v.id}
                                isPeriodDisabled={props.isPeriodClosed}
                                onAddCommentCompleted={(e) => { updateLineLastComment(v.internalGuid, e); }} />)} /></Tooltip> : <></>}
                    {v.needsFeedback ?
                        <Tooltip text={translate("EVALUATIONS.INFOANDFORM.NeedsFeedback")}><WarningFeedbackIcon /></Tooltip> :
                        (v.feedback ?
                            <Tooltip text={translate("EVALUATIONS.INFOANDFORM.FeedbackToCollaborator") + `: ${v.feedback}`}>
                                <ScalableIconButton
                                    className={v.isFeedbackGivenInPerson ? "feedback-given-inperson" : "feedback-submitted"}
                                    icon={v.isFeedbackGivenInPerson ? <FeedbackGivenInPerson /> : <FeedbackIcon />}
                                    size={24}
                                    onClick={() =>
                                        openPopup(<RegisterFeedbackPopup
                                            evaluationStatus={v.status}
                                            onlyRead={props.isPeriodClosed || (v.status !== EvaluationStatusEnum.ONGOING && v.status !== EvaluationStatusEnum.READYTOEVALUATE)}
                                            employeeName={v.employee.name ?? "-"}
                                            periodId={props.evaluationPeriodId ?? ""}
                                            locationId={props.locationId ?? 0} evaluationId={v.id}
                                            isPeriodStatusInvalid={props.isPeriodClosed}
                                            onAddFeedbackCompleted={(e) => updateLineFeedback(v.internalGuid, e)} />)} /></Tooltip> : <></>)}

                    {v.hasProblematicAbsences ? <Tooltip text={translate("EVALUATIONS.ProblematicAbsencesInfo")}><ProblematicAbsenceIcon className='problematic-absence' /></Tooltip> : null}
                </div>,
                width: "24px",
            },
            {
                columnKey: `validate`,
                cellRenderProp: (v) => {

                    if (!v.status) return;

                    if (v.status === EvaluationStatusEnum.READYTOEVALUATE || v.status === EvaluationStatusEnum.ONGOING || v.status === EvaluationStatusEnum.REJECTED) {
                        return (<RoundIconButton
                            icon={<ValidateIcon />}
                            onClick={() => v.canEvaluate && handleEvaluationStatusClicked(v.id, v.internalGuid, v.status)}
                            isDisabled={v.state !== EvaluationState.SAVED || props.isPeriodClosed}
                        />);
                    }

                    if (v.status === EvaluationStatusEnum.CONFIRMED) {
                        return (<RoundIconButton
                            className={"confirmed-btn"}
                            icon={<LockIcon />}
                            onClick={() => v.canCalibrate && handleEvaluationStatusClicked(v.id, v.internalGuid, v.status)}
                            isDisabled={v.state !== EvaluationState.SAVED || props.isPeriodClosed}
                        />);
                    }

                    if (v.status === EvaluationStatusEnum.CALIBRATED) {
                        return (<RoundIconButton
                            className={"calibrated-btn"}
                            icon={<LockIcon />}
                            isDisabled={v.state !== EvaluationState.SAVED || props.isPeriodClosed}
                        />);
                    }
                }
                ,
                width: "24px",
                isMobileHeaderIcon: true
            },
            {
                columnKey: `save`,
                cellRenderProp: (v) => v.status !== EvaluationStatusEnum.CALIBRATED && v.status !== EvaluationStatusEnum.CONFIRMED && !props.isPeriodClosed ?
                    <ScalableIconButton
                        isDisabled={v.state !== EvaluationState.EDITED || !v.isValid}
                        icon={<SaveIcon />}
                        onClick={() => v.canEvaluate && handleLineSaveClicked(v)} size={24}
                    /> : <></>,
                width: "24px",
                isMobileHeaderIcon: true
            },
            {
                columnKey: `moreActions`,
                cellRenderProp: (v) => <EvaluationContextMenu
                    evaluation={v}
                    locationId={props.locationId ?? 0}
                    evaluationPeriodId={props.evaluationPeriodId ?? ""}
                    loadingPermissions={props.isLoadingPermissions}
                    onlyRHPermission={props.onlyRHPermission}
                    loadingOnlyRHPermission={props.loadingOnlyRHPermission}
                    hasPermission={props.hasPermission}
                    canCalibrate={v.canCalibrate ?? false}
                    canRevertConfirmation={v.canRevertConfirmation || false}
                    isPeriodClosed={props.isPeriodClosed}
                    onCommentCompleted={(e) => updateLineLastComment(v.internalGuid, e)}
                    onFeedbackCompleted={(e) => updateLineFeedback(v.internalGuid, e)}
                    onRejectEvaluationCompleted={(ev) => updateLineOnRejectEvaluation(v.internalGuid, ev)}
                    onCutBonusApplied={(ev) => updateLineBonus(v.internalGuid, ev)}
                    onCancelBonusCutCompleted={(ev) => updateLineBonus(v.internalGuid, ev)}
                    onRevertEvaluationStatusClicked={() => handleEvaluationStatusClicked(v.id, v.internalGuid, undefined)}
                    onDeleteEvaluationCompleted={(id) => handleDeleteEvaluationCompleted(id)}
                />,
                width: "24px",
                isMobileHeaderIcon: true
            }
        ];
        return columns;
    }, [matrix?.employeeCriterias, updateLine, props.locationId, props.evaluationPeriodId,
        handleLineSaveClicked, props.isPeriodClosed, updateLineOnRejectEvaluation, updateLineFeedback,
    props.onlyRHPermission, props.loadingOnlyRHPermission, handleDeleteEvaluationCompleted,
        openPopup, handleEvaluationStatusClicked, updateLineBonus, updateLineLastComment,
    props.showMidtermEvaluationColumn, props.showAbsentismColumn, props.isLoadingPermissions, props.hasPermission]);




    return (
        <div className={"evaluations"}>
            <ConditionalRender if={updateEvaluationCall.isLoading ||
                updateEvaluationStatusCall.isLoading || updateEvaluationsBatchCall.isLoading || updateEvaluationsStatusBatchCall.isLoading}>
                <FullScreenLoader />
            </ConditionalRender>

            <div className="evaluations-group-header" onClick={props.handleOnClickRow}>
                <div className="left-header-info">
                    {props.managerEvaluations?.managerName ? <div className="manager-title">{translate("EVALUATIONS.ManagerEvaluations", props.managerEvaluations.managerName)}</div> : "-"}
                    {props.managerEvaluations?.nextManagerName ? <div className="delegated-to">{translate("EVALUATIONS.EvaluationsDelegatedTo", props.managerEvaluations.nextManagerName)}</div> : ""}
                </div>

                <div className="right-header-info">
                    <ScalableIconButton
                        icon={<SaveIcon />}
                        onClick={() => { handleSaveAllEvaluationsClicked() }}
                        size={24}
                        isDisabled={props.isPeriodClosed || !hasSomeEvaluationToSave}
                    />
                    <RoundIconButton
                        icon={<ValidateIcon />}
                        onClick={() => { handleValidateMultipleEvaluationClicked() }}
                        isDisabled={props.isPeriodClosed || !hasSomeEvaluationToValidate}
                    />
                    <RoundIconButton
                        className={"confirmed-btn"}
                        icon={<LockIcon />}
                        onClick={() => { handleCalibrateMultipleEvaluationClicked() }}
                        isDisabled={props.isPeriodClosed || !hasSomeEvaluationToCalibrate}
                    />
                </div>
            </div>

            {props.showTable ?
                <ResponsiveDataTable
                    className="evaluations-table"
                    items={data || []}
                    columnDefinitions={evaluationFactorsColumns}
                    totalitems={props.managerEvaluations?.totalItems || 0}
                    rowClassName={(item) => item.status === EvaluationStatusEnum.REJECTED ? "evaluation-rejected" : ""}
                />
                : null}
        </div >
    )
}
