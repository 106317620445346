import './SalariesPage.css'

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCreateNotification, usePopup, useWindowResize } from '../../../lib/infrastructure/ui/UIServices';

import { Button } from '../../../lib/components/buttons/Button';
import { ColumnDefinition } from '../../../lib/components/table/TableInterfaces';
import { ConditionalRender } from '../../../lib/functional/ConditionalRender';
import { ConfigurationProvider } from '../../../lib/infrastructure/configuration/ConfigurationProvider';
import { EmployeeSalaryService } from '../services/EmployeeSalaryService';
import { EmployeesSalariesDetails } from '../models/domain/EmployeesSalariesDetails';
import { EmployeesSalariesQueryOptions } from '../models/dtos/EmployeesSalariesQueryOptions';
import { ErrorPopup } from '../../../lib/components/popup/ErrorPopup';
import { FormFieldTextInput } from '../../../lib/components/form/form-field/FormFieldTextInput';
import { FullScreenLoader } from '../../../lib/components/loader/FullScreenLoader';
import { IconButton } from '../../../lib/components/buttons/IconButton';
import { ReactComponent as ImportIcon } from "../../../lib/assets/icons/import.svg";
import { ImportPopup } from '../../../lib/components/popup/ImportPopup';
import { Notification } from '../../../lib/components/notifications/Notification';
import { PageLayout } from '../../../lib/layouts/main-content/PageLayout';
import { PagePermissions } from '../../../lib/infrastructure/authorization/PagePermissions';
import { Permission } from '../../../models/api/gate/Permission';
import { ResponsiveDataTable } from '../../../lib/components/table/ResponsiveDataTable';
import { ReactComponent as SearchIcon } from "../../../lib/assets/icons/search-icon.svg";
import { translate } from '../../../lib/infrastructure/i18n/InternationalizationService';
import { useEvaluationPeriodStatus } from '../../../lib/infrastructure/period/useEvaluationPeriodStatus';
import { useFormControl } from '../../../lib/components/form/Form';
import { useGlobalLocation } from '../../../lib/infrastructure/location/LocationServices';
import { useHasPermissions } from '../../../lib/infrastructure/authorization/useHasLocationPermissions';
import { useParams } from 'react-router-dom';
import { useServiceCallPro2 } from '../../../lib/hooks/useServiceCall';
import { Tooltip } from '../../../lib/components/tooltip/Tooltip';
import { Tag } from '../../../lib/components/tag/Tag';

var svc = new EmployeeSalaryService();
const permissions = [Permission.GLOBAL_ADMIN, Permission.LOCATION_ADMIN, Permission.HUMAN_RESOURCES];
const monetaryValuePermissions = [Permission.HUMAN_RESOURCES];



export function SalariesPage() {
    const openPopup = usePopup();
    const createNotification = useCreateNotification();
    const periodInfo = useEvaluationPeriodStatus();
    const windowResize = useWindowResize();

    const { isLoadingPermissions, hasPermission } = useHasPermissions(permissions);

    const { isLoadingPermissions: loadingCanViewMonetaryValues, hasPermission: canViewMonetaryValues } = useHasPermissions(monetaryValuePermissions);

    const locationID = useGlobalLocation();
    const { id: evaluationPeriodID } = useParams();

    const [employeesSalaries, setEmployeesSalaries] = useState<EmployeesSalariesDetails[]>([]);
    const [totalItems, setTotalItems] = useState<number>();

    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(15);
    const [searchWord, setSearchWord] = useState<string>();

    const [timer, setTimer] = useState<NodeJS.Timeout>();

    var searchBoxFormControl = useFormControl<string>({
        isDisabled: false,
        enableAutoValidate: false,
    });


    /*****************
     * SERVICE CALLS
     ****************/

    const [uploadFileCall, uploadFileCallIsLoading] = useServiceCallPro2(svc, svc.uploadEmployeeSalaryFromXLSX);
    const [getEmployeesSalariesCall, getEmployeesSalariesCallIsLoading] = useServiceCallPro2(svc, svc.getEmployeesSalaries);


    /****************************
    * DATA REQUESTS
    *****************************/

    const handleOnPageAndItemsChanged = useCallback(
        (items: number, currentPage: number) => {
            setItemsPerPage(items);
            setCurrentPage(currentPage);
        },
        [setItemsPerPage, setCurrentPage]
    );


    const getEmployeesSalaries = useCallback(() => {

        if (!locationID || !evaluationPeriodID) return;

        var queryOptions: EmployeesSalariesQueryOptions = {
            page: currentPage,
            pageLength: itemsPerPage,
            searchWord: searchWord,
        };

        getEmployeesSalariesCall(`${locationID}`, evaluationPeriodID, queryOptions)
            .then((data) => {
                setEmployeesSalaries(data?.employeesSalaries);
                setTotalItems(data.totalItems);
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [locationID, evaluationPeriodID, getEmployeesSalariesCall, setEmployeesSalaries, setTotalItems, openPopup, currentPage, itemsPerPage, searchWord]);


    const handleFileImport = useCallback((file: File) => {

        if (!locationID || !evaluationPeriodID) return;

        uploadFileCall(`${locationID}`, "" + evaluationPeriodID, file)
            .then((_) => {
                getEmployeesSalaries();

                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("SALARIES.AddMultipleSalariesSuccessMessage")}
                    ></Notification>
                );
            })
            .catch(error => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [locationID, evaluationPeriodID, uploadFileCall, getEmployeesSalaries, openPopup, createNotification]);


    /****************************
    * DATA MANIPULATION EFFECTS
    *****************************/

    useEffect(() => {
        getEmployeesSalaries();
    }, [currentPage, itemsPerPage, searchWord]);


    useEffect(() => {
        if (timer) {
            clearTimeout(timer);
        }
        var responseTimeOut = setTimeout(() => {
            if (timer) {
                setCurrentPage(0);
                setSearchWord(searchBoxFormControl.value);
            }
        }, 1000);

        setTimer(responseTimeOut);
    }, [searchBoxFormControl.value]);


    const salariesColumnsList: ColumnDefinition<EmployeesSalariesDetails>[] = useMemo(
        () => [
            {
                cellRenderProp: (v) => v.collaboratorName,
                headerRender: translate("SALARIES.INFOANDFORM.CollaboratorName"),
                width: "30%",
                columnKey: "collaboratorName",
                isSortable: false,
                isMobilePrimaryCell: true
            },
            {
                cellRenderProp: (v) => v.employeeNumber,
                headerRender: translate("SALARIES.INFOANDFORM.EmployeeCode"),
                width: "20%",
                columnKey: "collaboratorValmetId",
                isSortable: false,
            },
            {
                cellRenderProp: (v) => <> {!loadingCanViewMonetaryValues && canViewMonetaryValues ?
                    v.monthlySalary ? v.monthlySalary.toFixed(2) + " €" : "0 €" : <Tag backgroundColor='status-light-grey' isTiny text={translate("SALARIES.INFOANDFORM.ConfidentialInformation")} />
                }</>,
                headerRender: translate("SALARIES.INFOANDFORM.MonthlySalary"),
                columnKey: "monthlySalary",
                width: "15%",
                isSortable: false,
            },

            {
                cellRenderProp: (v) => <Tooltip text={`${translate("SALARIES.INFOANDFORM.QuarterSalary")} = ${v.monthlySalary}€  *  ${translate("PERIODS.INFOANDFORM.MonthlySalaryMultiplier")}`}>

                    {!loadingCanViewMonetaryValues && canViewMonetaryValues ?
                        v.salaryInTheQuarter ? v.salaryInTheQuarter.toFixed(2) + " €" : "0 €" : <Tag backgroundColor='status-light-grey' isTiny text={translate("SALARIES.INFOANDFORM.ConfidentialInformation")} />
                    }

                </Tooltip>,
                headerRender: translate("SALARIES.INFOANDFORM.QuarterSalary"),
                columnKey: "salaryInTheQuarter",
                width: "15%",
                isSortable: false,
            },
            {
                cellRenderProp: (v) => <Tooltip text={`${translate("SALARIES.INFOANDFORM.MaxBonusCeiling")} = ${v.salaryInTheQuarter ? v.salaryInTheQuarter.toFixed(2) + " €" : translate("SALARIES.INFOANDFORM.QuarterSalary")} / ${translate("PERIODS.INFOANDFORM.PeriodMonetaryCeiling")}`}>

                    {!loadingCanViewMonetaryValues && canViewMonetaryValues ?
                        v.maximumBonusCeiling ? v.maximumBonusCeiling.toFixed(2) + " €" : "0 €" : <Tag backgroundColor='status-light-grey' isTiny text={translate("SALARIES.INFOANDFORM.ConfidentialInformation")} />
                    }

                </Tooltip>,
                headerRender: translate("SALARIES.INFOANDFORM.MaxBonusCeiling"),
                columnKey: "maximumBonusCeiling",
                width: "15%",
                isSortable: false,
            },
        ],
        [loadingCanViewMonetaryValues, canViewMonetaryValues]);




    const renderActionBtns = useMemo(() => { //TODO Rever Imports
        if(loadingCanViewMonetaryValues || !canViewMonetaryValues) return; 

        if (windowResize > 768)
            return (
                <ConditionalRender if={!periodInfo.isPeriodClosed}>
                    <Button
                        text={translate("COMMON.Import")}
                        type="secondary"
                        onClick={() => openPopup(<ImportPopup onClickDownloadTemplateBtn={() => {
     
                            window.open(ConfigurationProvider.getConfiguration().App.BackendUrl + "/templates/Bonus_Template_Salarios_V2.xlsx", "_blank")
                        }}
                            onClickImportBtn={handleFileImport} />)}
                        className="import-period-salaries"
                    />
                </ConditionalRender>
            );
        else
            return (
                <ConditionalRender if={!periodInfo.isPeriodClosed}>
                    <IconButton
                        icon={<ImportIcon />}
                        type="secondary"
                        onClick={() => openPopup(
                            <ImportPopup
                                onClickDownloadTemplateBtn={() =>
                                    window.open(ConfigurationProvider.getConfiguration().App.BackendUrl + "/templates/Bonus_Template_Salarios_V2.xlsx", "_blank")}
                                onClickImportBtn={handleFileImport} />)}
                        className="import-salaries"
                    />
                </ConditionalRender>
            );
    }, [handleFileImport, periodInfo.isPeriodClosed, windowResize, openPopup, loadingCanViewMonetaryValues, canViewMonetaryValues]);




    return (
        <PagePermissions permissions={permissions} >
            <PageLayout
                tabTitle={translate("SALARIES.Salaries")}
                showBreadcrumb
                pageTitle={translate("SALARIES.Salaries")}
                className="salaries-page"
                actions={renderActionBtns}
            >
                <FormFieldTextInput
                    formControl={searchBoxFormControl}
                    icon={<SearchIcon />}
                    placeholder={translate("COMMON.FORM.SearchPlaceholder")}
                    className="search-box"
                />
                <ResponsiveDataTable
                    columnDefinitions={salariesColumnsList}
                    items={employeesSalaries || []}
                    totalitems={totalItems || 0}
                    paginationOptions={{ itemsPerPage: itemsPerPage, itemsPerPageOptions: [15, 30, 45] }}
                    currentpage={currentPage}
                    isLoading={getEmployeesSalariesCallIsLoading}
                    onPageAndItemsChanged={handleOnPageAndItemsChanged}
                />
                <ConditionalRender if={uploadFileCallIsLoading} >
                    <FullScreenLoader />
                </ConditionalRender>
            </PageLayout>
        </PagePermissions>)
}
