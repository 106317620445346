import { APIEmployeesSalariesDetailsToDomainMapper } from "../models/mappers/APIEmployeesSalariesDetailsToDomainMapper";
import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { EmployeesSalariesQueryOptions } from "../models/dtos/EmployeesSalariesQueryOptions";
import { EmployeesSalariesResponseDTO } from "../models/dtos/EmployeesSalariesResponseDTO";
import { EmployeesSalariesSummary } from "../models/domain/EmployeesSalariesSummary";
import { HttpClient } from "../../../lib/infrastructure/http/HttpClient";
import qs from "qs";

const Route = (path: string) => {
    return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};

export class EmployeeSalaryService {
    getEmployeesSalaries(locationId: string, periodId: string, queryOptions?: EmployeesSalariesQueryOptions): Promise<EmployeesSalariesSummary> {
        return HttpClient.sessionRequest<EmployeesSalariesResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/EvaluationPeriods/${periodId}/EmployeesSalaries`),
            params: queryOptions,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" });
            },
        })
            .then((response) => {
                
                return ({
                    employeesSalaries: response.data.employeesSalaries.map(e => APIEmployeesSalariesDetailsToDomainMapper(e)),
                    totalItems: response.data.totalItems
                })
            })
            .catch((error) => {
                throw error;
            });
    }



    uploadEmployeeSalaryFromXLSX(locationId: string, periodId: string, file: File): Promise<void> {

        var formData = new FormData();
        formData.append("file", file)

        return HttpClient.sessionRequest({
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept": "application/json",
            },
            url: Route(`/api/v1/Locations/${locationId}/EvaluationPeriods/${periodId}/EmployeesSalaries/FromXLSX`),
            data: formData,
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }
}