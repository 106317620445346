import "./AbsenceCodesPage.css";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup, useWindowResize } from "../../../../lib/infrastructure/ui/UIServices";

import { AbsenteeismCodeDetails } from "../models/domain/AbsenteeismCodeDetails";
import { AbsenteeismCodeService } from "../services/AbsenteeismCodeService";
import { AbsenteeismCodesQueryOptions } from "../models/dtos/AbsenteeismCodesQueryOptions";
import { AddAbsenceCodePopup } from "./popups/AddAbsenceCodePopup";
import { ReactComponent as AddIcon } from "../../../../lib/assets/icons/add.svg";
import { Button } from "../../../../lib/components/buttons/Button";
import { ColumnDefinition } from "../../../../lib/components/table/TableInterfaces";
import { ReactComponent as DeleteIcon } from "../../../../lib/assets/icons/remover.svg";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { FormFieldTextInput } from "../../../../lib/components/form/form-field/FormFieldTextInput";
import { IconButton } from "../../../../lib/components/buttons/IconButton";
import { Notification } from "../../../../lib/components/notifications/Notification";
import { PageLayout } from "../../../../lib/layouts/main-content/PageLayout";
import { Permission } from "../../../../models/api/gate/Permission";
import { ResponsiveDataTable } from "../../../../lib/components/table/ResponsiveDataTable";
import { ScalableIconButton } from "../../../../lib/components/buttons/ScalableIconButton";
import { ReactComponent as SearchIcon } from "../../../../lib/assets/icons/search-icon.svg";
import { Toggle } from "../../../../lib/components/form/toggle/Toggle";
import { WarningPopup } from "../../../../lib/components/popup/WarningPopup";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../../lib/components/form/Form";
import { useHasPermissions } from "../../../../lib/infrastructure/authorization/useHasLocationPermissions";
import { useParams } from "react-router-dom";
import { useServiceCallPro } from "../../../../lib/hooks/useServiceCall";

var absenteeismCodeSvc = new AbsenteeismCodeService();

const permissions = [Permission.GLOBAL_ADMIN, Permission.LOCATION_ADMIN];

interface IAbsenceCodesPageProps { }

export function AbsenceCodesPage(props: IAbsenceCodesPageProps) {
  const windowResize = useWindowResize();
  const openPopup = usePopup();
  const closePopup = useClosePopup();
  const { id: locationID } = useParams();
  const createNotification = useCreateNotification();

  const { isLoadingPermissions, hasPermission } = useHasPermissions(permissions);

  const [absenteeismCodes, setAbsenteeismCodes] = useState<AbsenteeismCodeDetails[]>();
  const [totalAbsenteeismCodes, setTotalAbsenteeismCodes] = useState<number>();

  const getAbsenteeismCodesCall = useServiceCallPro(absenteeismCodeSvc.getAbsenteeismCodes);
  const updateProblematicIndicatorCall = useServiceCallPro(absenteeismCodeSvc.updateAbsenteeismCodeProblematicIndicator);
  const deleteEvaluationCall = useServiceCallPro(absenteeismCodeSvc.deleteEvaluation);

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentpage, setCurrentPage] = useState(0);

  const [timer, setTimer] = useState<NodeJS.Timeout>();
  const [searchWord, setSearchWord] = useState<string>();


  var searchboxController = useFormControl<string>({
    isDisabled: false,
    enableAutoValidate: false,
  });

  

  /****************************
 * DATA REQUESTS
 *****************************/

  const getAbsenteeismCodes = useCallback(() => {
    if (!locationID) return;

    var queryOptions: AbsenteeismCodesQueryOptions = {
      page: currentpage,
      pageLength: itemsPerPage,
      searchWord: searchWord,
    };

    getAbsenteeismCodesCall.invoke("" + locationID, queryOptions)
      .then((data) => {
        if (data) {
          setAbsenteeismCodes(data.absenteeismCodes);
          setTotalAbsenteeismCodes(data.total);
        }
      })
      .catch((error) => {
        if (!error) return;
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
      });
  }, [locationID, setAbsenteeismCodes, getAbsenteeismCodesCall.invoke, openPopup, setTotalAbsenteeismCodes, currentpage, itemsPerPage, searchWord]);


  const onClickTurnIndicatorProblematic = useCallback((absenteeismCodeId: number) => {

    if (absenteeismCodeId && locationID) {
      updateProblematicIndicatorCall.invoke("" + locationID, absenteeismCodeId, true)
        .then((_) => {
          getAbsenteeismCodes();
          closePopup();
          createNotification(
            <Notification type="success" title={translate("COMMON.SYSTEMPOPUPS.Success")}
              text={translate("GLOBALADMIN.ABSENCECODES.POPUPS.ChangeAbsenceCodeIndicatorSuccess")}></Notification>);
        })
        .catch((error) => {
          if (!error) return;
          openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
        });
    }
  }, [locationID, updateProblematicIndicatorCall.invoke, getAbsenteeismCodes, createNotification, openPopup, closePopup])


  const onClickTurnIndicatorNotProblematic = useCallback((absenteeismCodeId: number) => {

    if (absenteeismCodeId && locationID) {
      updateProblematicIndicatorCall.invoke("" + locationID, absenteeismCodeId, false)
        .then((_) => {
          getAbsenteeismCodes();
          closePopup();
          createNotification(
            <Notification type="success" title={translate("COMMON.SYSTEMPOPUPS.Success")}
              text={translate("GLOBALADMIN.ABSENCECODES.POPUPS.ChangeAbsenceCodeIndicatorSuccess")}></Notification>);
        })
        .catch((error) => {
          if (!error) return;
          openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
        });
    }
  }, [locationID, updateProblematicIndicatorCall.invoke, getAbsenteeismCodes, createNotification, openPopup, closePopup])


  const handleOnPageAndItemsChanged = useCallback(
    (items: number, currentPage: number) => {
      setItemsPerPage(items);
      setCurrentPage(currentPage);
    },
    [setItemsPerPage, setCurrentPage]
  );


  const handleDeleteAbsenteeismCodeBtnClicked = useCallback((absenteeismCodeId: number) => {
    if (absenteeismCodeId && locationID) {
      deleteEvaluationCall.invoke(locationID, absenteeismCodeId)
        .then((_) => {
          getAbsenteeismCodes();
          createNotification(
            <Notification
              type="success"
              title={translate("COMMON.SYSTEMPOPUPS.Success")}
              text={translate("GLOBALADMIN.ABSENCECODES.POPUPS.DeleteAbsenceCodeSuccess")}
            ></Notification>
          );
        })
        .catch((error) => {
          if (!error) return;
          openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
        });
    }
  }, [locationID, deleteEvaluationCall.invoke, getAbsenteeismCodes, createNotification, openPopup]);


  /****************************
  * DATA MANIPULATION EFFECTS
  *****************************/

  useEffect(() => {
    getAbsenteeismCodes();
  }, [currentpage, itemsPerPage, searchWord]);


  useEffect(() => {
    if (timer)
      clearTimeout(timer);

    var responseTimeOut = setTimeout(() => {
      if (timer) {
        setCurrentPage(0);
        setSearchWord(searchboxController.value);
      }
    }, 1000);

    setTimer(responseTimeOut);
  }, [searchboxController.value]);


  const isLoading = useMemo(() =>
    (getAbsenteeismCodesCall.isLoading || updateProblematicIndicatorCall.isLoading || deleteEvaluationCall.isLoading)
    , [getAbsenteeismCodesCall.isLoading, updateProblematicIndicatorCall.isLoading, deleteEvaluationCall.isLoading]);


  const columns: ColumnDefinition<AbsenteeismCodeDetails>[] = useMemo(
    () => [
      {
        cellRenderProp: (v) => v.sapNumber,
        headerRender: translate("GLOBALADMIN.ABSENCECODES.INFOANDFORM.Code"),
        width: "20%",
      },
      {
        cellRenderProp: (v) => v.description,
        headerRender: translate("GLOBALADMIN.ABSENCECODES.INFOANDFORM.Description"),
        isMobilePrimaryCell: true,
      },
      {
        cellRenderProp: (v) => {
          if (!isLoadingPermissions && hasPermission)
            return (<div className="toggle-div">
              <Toggle value={v.isProblematic} />
              <div
                className="toggle-div-overlay"
                onClick={(ev) => {
                  ev.stopPropagation();
                  if (v.isProblematic) {
                    openPopup(
                      <WarningPopup
                        className="disable-absence-code-popup"
                        onDismissClicked={() => {
                          closePopup();
                        }}
                        onConfirmClicked={() => {
                          onClickTurnIndicatorNotProblematic(v.absenteeismCodeId);
                          v.isProblematic = false;
                        }}
                        dismissText={translate("COMMON.Cancel")}
                        confirmText={translate(
                          "GLOBALADMIN.ABSENCECODES.POPUPS.Change"
                        )}
                      >
                        {translate(
                          "GLOBALADMIN.ABSENCECODES.POPUPS.ChangeAbsenceCodeNotProblematicConfirmText"
                        )}
                      </WarningPopup>
                    );
                  }
                  else {
                    openPopup(
                      <WarningPopup
                        onDismissClicked={() => {
                          closePopup();
                        }}
                        onConfirmClicked={() => {
                          onClickTurnIndicatorProblematic(v.absenteeismCodeId);
                          v.isProblematic = true;
                        }}
                        dismissText={translate("COMMON.Cancel")}
                        confirmText={translate("GLOBALADMIN.ABSENCECODES.POPUPS.Change")}
                      >
                        {translate("GLOBALADMIN.ABSENCECODES.POPUPS.ChangeAbsenceCodeProblematicConfirmText")}
                      </WarningPopup>
                    );
                  }
                }}
              />
            </div>)
        },
        headerRender: translate("GLOBALADMIN.ABSENCECODES.INFOANDFORM.Problematic"),
        columnKey: "problematic",
        width: "5%",
        isSortable: false,
        isMobilePrimaryCell: true,
      },
      {
        cellRenderProp: (v) => {
          if (!isLoadingPermissions && hasPermission)
            return (
              <ScalableIconButton
                size={24} icon={<DeleteIcon />}
                onClick={() => handleDeleteAbsenteeismCodeBtnClicked(v.absenteeismCodeId)} />)
          return null;
        },
        columnKey: "delete",
        width: "24px",
        isMobileHeaderIcon: true,
      },
    ], [openPopup, closePopup, onClickTurnIndicatorNotProblematic, onClickTurnIndicatorProblematic, handleDeleteAbsenteeismCodeBtnClicked, isLoadingPermissions, hasPermission]);


  const handleClickAddBtn = useCallback(() => {
    openPopup(<AddAbsenceCodePopup onChangesMade={() => { getAbsenteeismCodes() }} locationId={locationID} />);
  }, [getAbsenteeismCodes, locationID, openPopup])


  const renderAddAbsenceCodeBtn = useMemo(() => {
    //if (locationID) {
    if (!isLoadingPermissions && hasPermission) {
      if (windowResize > 768)
        return (
          <Button
            text={translate("GLOBALADMIN.ABSENCECODES.AddCode")}
            type="primary"
            onClick={handleClickAddBtn}
          />
        );
      else
        return (
          <IconButton
            icon={<AddIcon />}
            type="primary"
            onClick={handleClickAddBtn}
          />
        );
    }
    //}
  }, [windowResize, isLoadingPermissions, hasPermission, handleClickAddBtn]);


  /****************************
* USER ACTIONS
*****************************/


  /****************************
   * CSS & HTML
   *****************************/

  return <PageLayout
    tabTitle={translate("GLOBALADMIN.ABSENCECODES.AbsenceCodes")}
    showBreadcrumb
    pageTitle={translate("GLOBALADMIN.ABSENCECODES.AbsenceCodes")}
    className="absence-codes-page"
    actions={renderAddAbsenceCodeBtn}
  >
    <FormFieldTextInput
      formControl={searchboxController}
      icon={<SearchIcon />}
      placeholder={translate("COMMON.FORM.SearchPlaceholder")}
      className="search-box"
    />
    <ResponsiveDataTable
      columnDefinitions={columns}
      items={absenteeismCodes || []}
      totalitems={totalAbsenteeismCodes || 0}
      paginationOptions={{ itemsPerPage: itemsPerPage, itemsPerPageOptions: [10, 15, 20] }}
      currentpage={currentpage}
      isLoading={isLoading}
      onPageAndItemsChanged={handleOnPageAndItemsChanged}
    />
  </PageLayout>;
}