import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import { AbsenceCodesPage } from "../features/admin/absence-codes/pages/AbsenceCodesPage";
import { EditLocationGroupAccessPage } from "../features/admin/location-group-accesses/EditLocationGroupAccessPage";
import { EmployeesListPage } from "../features/admin/employees-list/EmployeesListPage";
import { FacilitiesHomePage } from "../features/admin/facilities/FacilitiesHomePage";
import { LocationsContainer } from "../features/admin/layout/LocationsContainer";
import { LocationsHome } from "../features/admin/locations/LocationsHomePage";
import { ModuleDefinition } from "../lib/infrastructure/modules/ModuleDefinition";
import { Permission } from '../models/api/gate/Permission';
import { RouteGuard } from "../lib/infrastructure/authorization/RouteGuard";
import { translate } from "../lib/infrastructure/i18n/InternationalizationService";
import { useEffect } from "react";
import { useRegisterBreadcrumbRoutes } from "../lib/infrastructure/navigation/hooks/useBreadcrumb";

const moduleDefinition: ModuleDefinition = {
  basePath: "/settings/locations",
  routes: [
    {
      name: () => translate("GLOBALADMIN.LOCATIONS.Locations"),
      pathPattern: "/",
    },
    {
      name: () => "##LOCATION##",
      pathPattern: "/:id",
    },
    {
      name: () => translate("GLOBALADMIN.FACILITIES.Facilities"),
      pathPattern: "/:id/facilities",
    },
    {
      name: () => translate("GLOBALADMIN.ACCESSGROUPS.AccessGroups"),
      pathPattern: "/:id/groupaccess",
    },
    {
      name: () => translate("GLOBALADMIN.EMPLOYEES.Employees"),
      pathPattern: "/:id/employees",
    },
    {
      name: () => translate("GLOBALADMIN.ABSENCECODES.AbsenceCodes"),
      pathPattern: "/:id/absencecodes",
    },
  ],
};


const permissions = [Permission.GLOBAL_ADMIN, Permission.LOCATION_ADMIN];

export function LocationsModule() {
  const registerBreadcrumbRoutes = useRegisterBreadcrumbRoutes();


  useEffect(() => {
    registerBreadcrumbRoutes(moduleDefinition);
  }, []);

  return (

    <RouteGuard permissions={permissions} >
      <Routes>
        <Route index element={<LocationsHome />} />
        <Route
          path=":id/*"
          element={
            <LocationsContainer>
              <Outlet />
            </LocationsContainer>}
        >
          <Route index element={<Navigate to="groupaccess" />} />
          <Route path="groupaccess" element={<EditLocationGroupAccessPage />} />
          <Route path="facilities" element={<FacilitiesHomePage />} />
          <Route path="employees" element={<EmployeesListPage />} />
          <Route path="absencecodes" element={<AbsenceCodesPage />} />
        </Route>

      </Routes>
    </RouteGuard>
  );
}
