import { Checkbox } from "../../../../lib/components/checkbox/Checkbox";
import { EmployeeCriteria } from "../../../matrixes/models/domain/EmployeeCriteria";
import { Spacer } from "../../../../lib/components/separator/Spacer";
import { useCallback } from "react";

interface IEmployeeCriteriaSelectorProps {
    options: EmployeeCriteria[];
    selected: EmployeeCriteria[];
    onSelectionChanged: (values: EmployeeCriteria[]) => void;
}

export function EmployeeCriteriaSelector(props: IEmployeeCriteriaSelectorProps) {




    const isChecked = useCallback((i: EmployeeCriteria) => {
        return props.selected.filter(l => l.id === i.id).length > 0
    }, [props.selected]);


    const handleFactorChecked = useCallback((i: EmployeeCriteria, isChecked: boolean)=>{
        const clone = [...props.selected.filter(l => l.id !== i.id)];

        if(isChecked) clone.push(i)

        props.onSelectionChanged(clone)
    },[props.selected, props.onSelectionChanged]);

    return (<>
    {props.options.map(i => {

        return (
            <div>
                <Spacer mode="vertical" px={10}></Spacer>
                <Checkbox text={i.name} isChecked={isChecked(i)} onClick={(isChecked) => handleFactorChecked(i, isChecked)} />
            </div>
        )
    })}
    </>)
}