import { EmployeeCriteria } from "../domain/EmployeeCriteria";
import { EmployeeCriteriaDTO } from "../dtos/EmployeeCriteriaDTO";

export function APIEmployeeCriteriaToDomainMapper(apiDto: EmployeeCriteriaDTO): EmployeeCriteria {
    return {
        id: apiDto.id,
        name: apiDto.name,
        description: apiDto.description,
        weightPercentage: apiDto.weightPercentage
    }
}