export enum EvaluationPeriodState {
    Registered = 1,
    Canceled = 2,
    InProgress = 3,
    Completed = 4
}


export const EvaluationPeriodStateTranslationTerms: { [key: string]: string } = {
    1: "PERIODS.STATUS2.Registered",
    2: "PERIODS.STATUS2.Canceled",
    3: "PERIODS.STATUS2.InProgress",
    4: "PERIODS.STATUS2.Completed",
}


export const EvaluationPeriodStateAsEnum: { [key: number]: EvaluationPeriodState } = {
    1: EvaluationPeriodState.Registered,
    2: EvaluationPeriodState.Canceled,
    3: EvaluationPeriodState.InProgress,
    4: EvaluationPeriodState.Completed,
};