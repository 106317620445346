import "./SessionExpiredPage.css";
import { ReactComponent as IconSessionExpired } from "../../../lib/assets/icons/session-expired-icon.svg";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../lib/components/buttons/Button";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";

export function SessionExpiredPage() {
  const navigate = useNavigate();

  return (
    <div className="session-expired-page">
      <div className="wrapper">
        <div className="error-text">
          <div className="error">
            <IconSessionExpired />
          </div>
          <div className="info">
            <div className="title">
              {translate("COMMON.ERRORPAGES.SessionExpired")}
            </div>
            <div className="text">
              {translate("COMMON.ERRORPAGES.SessionExpiredText")}
            </div>
          </div>
        </div>
        <div className="action-btns">
          <Button
            className="session-expired-btn"
            type="primary"
            text={translate("COMMON.ERRORPAGES.Login")}
            onClick={() => navigate("/identity/login")}
          />
        </div>
      </div>
    </div>
  );
}
