import "./CriteriasSections.css";

import { AchievementPercentageField, CurrentValueField, DescriptionField, NameField, StartValueField, TargetValueField, WeightField } from "./PeriodCriteriasSectionFields";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useCreateNotification, usePopup } from '../../../../../lib/infrastructure/ui/UIServices';

import { ReactComponent as AddIcon } from "../../../../../lib/assets/icons/add.svg";
import { AllValid } from '../../../../../common/validators/ValidateFormControls';
import { CalculationsHelper } from '../../../../evaluations/helpers/CalculationsHelper';
import { ColumnDefinition } from "../../../../../lib/components/table/TableInterfaces";
import { ConditionalRender } from "../../../../../lib/functional/ConditionalRender";
import { ReactComponent as DeleteIcon } from "../../../../../lib/assets/icons/remover.svg";
import { EvaluationMatrix } from '../../../../matrixes/models/domain/EvaluationMatrix';
import { EvaluationPeriodMatrixesService } from '../../../../matrixes/services/EvaluationPeriodMatrixesService';
import { FieldWrapper } from "./EmployeeCriteriasSection";
import { FullScreenLoader } from "../../../../../lib/components/loader/FullScreenLoader";
import { IconButton } from "../../../../../lib/components/buttons/IconButton";
import { Notification } from "../../../../../lib/components/notifications/Notification";
import { ObjectHelper } from "../../../../../lib/helpers/ObjectHelper";
import { ResponsiveDataTable } from "../../../../../lib/components/table/ResponsiveDataTable";
import { ReactComponent as SaveIcon } from "../../../../../lib/assets/icons/save2.svg";
import { ScalableIconButton } from "../../../../../lib/components/buttons/ScalableIconButton";
import { Tooltip } from "../../../../../lib/components/tooltip/Tooltip";
import { translate } from "../../../../../lib/infrastructure/i18n/InternationalizationService";
import { useServiceCallPro } from "../../../../../lib/hooks/useServiceCall";
import { v4 as uuid } from 'uuid';
import { ErrorPopup } from "../../../../../lib/components/popup/ErrorPopup";
import { Tag } from "../../../../../lib/components/tag/Tag";
import { SaveCompanyCriteriasEvent } from "../../../../../events/SaveCompanyCriteriasEvent";
import { TextButton } from "../../../../../lib/components/buttons/TextButton";
import { CompanyCriteriaDTO } from "../../../../matrixes/models/dtos/CompanyCriteriaDTO";
import { AxiosError } from "axios";

export interface CompanyCriteriasSectionProps {
    isClosed: boolean;
    locationId: string;
    periodId: string;
    isloadingTopPermissions: boolean;
    hasTopPermissions: boolean;
    onCompletedOperations: () => void;
}



interface RecordLine {
    original: CompanyCriteria;
    patch: Partial<CompanyCriteria>;
}



export interface CompanyCriteria {
    id?: number;
    idx: string;
    name?: string;
    isNameValid?: boolean;
    description?: string;
    isDescriptionValid?: boolean;
    weightPercentage?: number;
    isWeightPercentageValid?: boolean;
    weight?: number;
    achievement?: number;
    targetValue?: number;
    startValue?: number;
    isTargetValueValid?: boolean;
    currentValue?: number;
    isCurrentValueValid?: boolean;
    state: CriteriaState;
    achievmentPercentage?: number;
    isAchievmentPercentageValid?: boolean;
}

const baseValidCriteria: Partial<CompanyCriteria> = {
    isNameValid: true,
    isDescriptionValid: true,
    isWeightPercentageValid: true,
    isTargetValueValid: true,
    isCurrentValueValid: true,
    isAchievmentPercentageValid: true
};

export enum CriteriaState {
    NEW = 1,
    SAVED = 2,
    EDITED = 3,
}

const svc = new EvaluationPeriodMatrixesService();


function calcSumAllCriteriaWeights(criterias: CompanyCriteria[]) {
    const sumAllCriteriasWeightPercentage = criterias?.reduce((sum, criteria) => {
        return sum + (criteria?.weightPercentage ?? 0);
    }, 0);
    return sumAllCriteriasWeightPercentage;
}

export function CompanyCriteriasSection(props: CompanyCriteriasSectionProps) {

    const openPopup = usePopup();
    const createNotification = useCreateNotification();

    const getMatrixSvcCall = useServiceCallPro(svc.getEvaluationPeriodMatrix);
    const deleteCompanyCriteriaSvcCall = useServiceCallPro(svc.deleteCompanyCriteria);
    const createCompanyCriteriaSvcCall = useServiceCallPro(svc.createCompanyCriteria);
    const updateCompanyCriteriaSvcCall = useServiceCallPro(svc.updateCompanyCriteria);

    const [matrixSubtotal, setMatrixSubtotal] = useState<number>();
    const [companySubTotalAchievement, setCompanySubTotalAchievement] = useState<string>();

    const [data, setData] = useState<CompanyCriteria[]>([]);

    const [isSaveAllEnabled, setIsSaveAllEnabled] = useState<boolean>(false);



    useEffect(() => {
        if (props.locationId && props.periodId) {
            getMatrixSvcCall.invoke(props.locationId, props.periodId)
                .then((res: EvaluationMatrix | null) => {
                    const criterias = res?.companyCriterias.map(c => ({
                        ...baseValidCriteria,
                        idx: uuid(),
                        state: CriteriaState.SAVED,
                        id: c.id,
                        name: c.name,
                        description: c.description,
                        startValue: c.startValue,
                        weightPercentage: c.weightPercentage,
                        targetValue: c.targetValue,
                        currentValue: c.currentValue,
                        achievmentPercentage: c.achievementPercentage,
                        achievement: c.achievement,
                        weight: c.weight
                    }));

                    setData(criterias ?? []);
                    setMatrixSubtotal(calcSumAllCriteriaWeights(criterias ?? []));


                }).catch((error) => {
                    if (!error) return;
                    openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
                })
        }
    }, [props.locationId, props.periodId]);



    useEffect(() => {
        const subTotal = data.reduce((acc, v) => {
            const achievement = v?.achievement || 0; // Se v.Achievement for undefined ou null, use 0
            const weight = v?.weight || 0; // Se v.Weight for undefined ou null, use 0
            return acc + (achievement * weight);
        }, 0) * 100;
        setCompanySubTotalAchievement("" + subTotal.toFixed(2) ?? "0");
    }, [data]);






    const updateLine = useCallback((original: CompanyCriteria, patch: Partial<CompanyCriteria>) => {
        const clone = [...data];
        let targetIdx = clone.findIndex(i => i.idx === original.idx);
        clone[targetIdx] = { ...clone[targetIdx], ...patch }

        if (clone[targetIdx].state === CriteriaState.SAVED && !patch.state) {
            if (!ObjectHelper.deepEqual(clone[targetIdx], original)) {
                clone[targetIdx].state = CriteriaState.EDITED
            }
        }
        if (clone[targetIdx].state === CriteriaState.SAVED)
            setMatrixSubtotal(calcSumAllCriteriaWeights(clone ?? []));

        setData(clone);
    }, [data, setData, setMatrixSubtotal]);







    const handleDeleteCriteriaBtnClicked = useCallback((record: CompanyCriteria) => {
        const clone = [...data.filter(t => t.idx !== record.idx)];

        if (record.id) {
            deleteCompanyCriteriaSvcCall.invoke(props.locationId, props.periodId, "" + record.id).then(_ => {
                props.onCompletedOperations();
                setData(clone);
                setMatrixSubtotal(calcSumAllCriteriaWeights(clone ?? []));
                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("PERIODS.MESSAGES.DeleteCompanyCriteriaSucess")}
                    ></Notification>
                );
            }).catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            })
        } else {
            setData(clone);
        }
    }, [data, setData, createNotification, deleteCompanyCriteriaSvcCall.invoke, props.onCompletedOperations, setMatrixSubtotal]);



    const handleSaveCriteriaBtnClicked = useCallback((record: CompanyCriteria) => {

        if (record.state === CriteriaState.NEW) {
            createCompanyCriteriaSvcCall.invoke(props.locationId, props.periodId, {
                name: record.name ?? "",
                description: record.description ?? "",
                weightPercentage: record.weightPercentage ?? 0,
                startValue: record.startValue ?? 0,
                targetValue: record.targetValue ?? 0,
                currentValue: record.currentValue ?? 0,
                achievementPercentage: record.achievmentPercentage ?? 0,
            }).then(res => {

                updateLine(record, { state: CriteriaState.SAVED, id: res.id, weightPercentage: res.weightPercentage, weight: res.weight, achievement: res.achievement })

                props.onCompletedOperations();
                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("PERIODS.MESSAGES.CreatedCompanyCriteriaSuccess")}
                    />
                );
            }).catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
        }


        if (record.state === CriteriaState.EDITED) {

            updateCompanyCriteriaSvcCall.invoke(props.locationId, props.periodId, "" + record.id, {
                name: record.name ?? "",
                description: record.description ?? "",
                weightPercentage: record.weightPercentage ?? 0,
                startValue: record.startValue ?? 0,
                targetValue: record.targetValue ?? 0,
                currentValue: record.currentValue ?? 0,
                achievementPercentage: record.achievmentPercentage ?? 0,
            }).then(res => {

                updateLine(record, { state: CriteriaState.SAVED, weightPercentage: res.weightPercentage, weight: res.weight, achievement: res.achievement })

                props.onCompletedOperations();

                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("PERIODS.MESSAGES.ChangedCompanyCriteriaSuccess")}
                    />
                );
            }).catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            })
        }
    }, [updateLine, props.locationId, props.periodId, createNotification, createCompanyCriteriaSvcCall.invoke,
        updateCompanyCriteriaSvcCall.invoke, openPopup, data, props.onCompletedOperations]);



    const companyCriteriasColumns: ColumnDefinition<CompanyCriteria>[] = useMemo(() => [
        {
            columnKey: "name",
            cellRenderProp: (v: CompanyCriteria) =>
                <FieldWrapper value={v.name} isLocked={props.isClosed || !props.hasTopPermissions || props.isloadingTopPermissions}>
                    <NameField key={v.idx} value={v.name || ""} onValueChanged={(value, isValid) => updateLine(v, { name: value, isNameValid: isValid })} />
                </FieldWrapper>,
            headerRender: translate("PERIODS.CRITERIAS.Name"),
            isMobilePrimaryCell: true,
            width: "20%"
        },
        {
            columnKey: "description",
            cellRenderProp: (v: CompanyCriteria) =>
                <FieldWrapper value={v.description} isLocked={props.isClosed || !props.hasTopPermissions || props.isloadingTopPermissions}>
                    <DescriptionField key={v.idx} value={v.description || ""} onValueChanged={(value, isValid) => updateLine(v, { description: value, isDescriptionValid: isValid })} />
                </FieldWrapper>,
            headerRender: translate("PERIODS.CRITERIAS.Description"),
            width: "35%"
        },
        {
            columnKey: "weight-percentage",
            cellRenderProp: (v: CompanyCriteria) =>
                <FieldWrapper value={v.weightPercentage} isLocked={props.isClosed || !props.hasTopPermissions || props.isloadingTopPermissions}>
                    <WeightField key={v.idx} value={v.weightPercentage || 0} onValueChanged={(value, isValid) => updateLine(v, { weightPercentage: value, isWeightPercentageValid: isValid })} className="small-input" />
                </FieldWrapper>,
            headerRender: translate("PERIODS.CRITERIAS.WeightPercentage"),
        },
        {
            columnKey: "target-value",
            cellRenderProp: (v: CompanyCriteria) =>
                <FieldWrapper value={v.targetValue} isLocked={props.isClosed || !props.hasTopPermissions || props.isloadingTopPermissions}>
                    <TargetValueField key={v.idx} value={v.targetValue || 0} onValueChanged={(value, isValid) => updateLine(v, { targetValue: value, isTargetValueValid: isValid })} className="small-input" />
                </FieldWrapper>,
            headerRender: translate("PERIODS.CRITERIAS.TargetValueV2"),
        },
        {
            columnKey: "start-value",
            cellRenderProp: (v: CompanyCriteria) =>
                <FieldWrapper value={v.startValue} isLocked={props.isClosed || !props.hasTopPermissions || props.isloadingTopPermissions}>
                    <StartValueField key={v.idx} value={v.startValue || 0} onValueChanged={(value, isValid) => updateLine(v, { startValue: value, isCurrentValueValid: isValid })} className="small-input" />
                </FieldWrapper>,
            headerRender: translate("PERIODS.CRITERIAS.StartValueV2"),
        },
        {
            columnKey: "current-value",
            cellRenderProp: (v: CompanyCriteria) =>
                <FieldWrapper value={v.currentValue} isLocked={props.isClosed || !props.hasTopPermissions || props.isloadingTopPermissions}>
                    <CurrentValueField key={v.idx} value={v.currentValue || 0} onValueChanged={(value, isValid) => updateLine(v, { currentValue: value, isCurrentValueValid: isValid })} className="small-input" />
                </FieldWrapper>,
            headerRender: translate("PERIODS.CRITERIAS.CurrentValue"),
        },
        {
            columnKey: "achivement",
            cellRenderProp: (v: CompanyCriteria) =>
                <FieldWrapper value={v.achievmentPercentage} isLocked={props.isClosed || !props.hasTopPermissions || props.isloadingTopPermissions}>
                    <AchievementPercentageField key={v.idx} value={v.achievmentPercentage || 0} onValueChanged={(value, isValid) => updateLine(v, { achievmentPercentage: value, isAchievmentPercentageValid: isValid })} className="small-input" />
                </FieldWrapper>,
            headerRender: <>{translate("PERIODS.CRITERIAS.Achievement") + " (%)"}</>,
        },
        {
            columnKey: "delete",
            cellRenderProp: (v: CompanyCriteria) => {
                if (props.isClosed || !props.hasTopPermissions || props.isloadingTopPermissions) return;
                return <ScalableIconButton
                    key={v.idx} size={24}
                    icon={<DeleteIcon />}
                    onClick={() => handleDeleteCriteriaBtnClicked(v)} />
            },
            width: "3.5714rem",
            isMobileHeaderIcon: true,
        },
        {
            columnKey: "save",
            cellRenderProp: (v: CompanyCriteria) => {

                if (v.state === CriteriaState.SAVED || props.isClosed || (!props.hasTopPermissions && !props.isloadingTopPermissions))
                    return;
                return <ScalableIconButton
                    key={v.idx}
                    size={24}
                    icon={<SaveIcon />}
                    isDisabled={!AllValid(v.isNameValid, v.isDescriptionValid, v.isWeightPercentageValid, v.isTargetValueValid, v.isCurrentValueValid, v.isAchievmentPercentageValid)}
                    onClick={() => handleSaveCriteriaBtnClicked(v)} />
            },
            width: "3.5714rem",
            isMobileHeaderIcon: true,
        },
    ], [updateLine, handleSaveCriteriaBtnClicked, handleDeleteCriteriaBtnClicked, props.isClosed, props.hasTopPermissions, props.isloadingTopPermissions]);



    const handleAddCriteriaBtnClicked = useCallback(() => {
        var clone = [...data];

        clone.push({
            ...baseValidCriteria,
            idx: uuid(),
            state: CriteriaState.NEW
        });

        setData(clone);

    }, [setData, data]);




    const updateMultipleLines = useCallback((recordsToPatch: Array<{ original: CompanyCriteria, patch: Partial<CompanyCriteria> }>) => {
        setData(prevData => {
            let clone = [...prevData];

            recordsToPatch.forEach(rec => {
                const { original, patch } = rec;
                const targetIdx = clone.findIndex(i => i.idx === original.idx);

                if (targetIdx !== -1) {
                    clone[targetIdx] = { ...clone[targetIdx], ...patch };

                    if (clone[targetIdx].state === CriteriaState.SAVED && !patch.state) {
                        if (!ObjectHelper.deepEqual(clone[targetIdx], original)) {
                            clone[targetIdx].state = CriteriaState.EDITED;
                        }
                    }

                    if (clone[targetIdx].state === CriteriaState.SAVED) {
                        setMatrixSubtotal(calcSumAllCriteriaWeights(clone ?? []));
                    }
                }
            });

            return clone;
        });
    }, [setData, setMatrixSubtotal]);




    const handleSaveAllCriteriasBtnClicked = useCallback(async () => {
        try {
            var recordLinesToPatch: RecordLine[] = [];
            var clone = [...data];

            clone = clone.filter(crit => AllValid(crit.isNameValid, crit.isDescriptionValid, crit.isWeightPercentageValid, crit.isTargetValueValid, crit.isCurrentValueValid, crit.isAchievmentPercentageValid));

            for (const crit of clone) {
                if (crit.state === CriteriaState.NEW) {
                    const res = await createCompanyCriteriaSvcCall.invoke(props.locationId, props.periodId, {
                        name: crit.name ?? "",
                        description: crit.description ?? "",
                        weightPercentage: crit.weightPercentage ?? 0,
                        startValue: crit.startValue ?? 0,
                        targetValue: crit.targetValue ?? 0,
                        currentValue: crit.currentValue ?? 0,
                        achievementPercentage: crit.achievmentPercentage ?? 0,
                    });

                    recordLinesToPatch.push({ original: crit, patch: res });


                } else if (crit.state === CriteriaState.EDITED) {
                    const res = await updateCompanyCriteriaSvcCall.invoke(props.locationId, props.periodId, "" + crit.id, {
                        name: crit.name ?? "",
                        description: crit.description ?? "",
                        weightPercentage: crit.weightPercentage ?? 0,
                        startValue: crit.startValue ?? 0,
                        targetValue: crit.targetValue ?? 0,
                        currentValue: crit.currentValue ?? 0,
                        achievementPercentage: crit.achievmentPercentage ?? 0,
                    });

                    recordLinesToPatch.push({ original: crit, patch: res });
                }
            }

            const recordsToUpdate = recordLinesToPatch.map(rec => ({
                original: rec.original,
                patch: {
                    state: CriteriaState.SAVED,
                    weightPercentage: rec.patch.weightPercentage,
                    weight: rec.patch.weight,
                    achievement: rec.patch.achievement,
                    id: rec.patch.id
                }
            }));

            updateMultipleLines(recordsToUpdate);

            props.onCompletedOperations();
            createNotification(
                <Notification
                    type="success"
                    title={translate("COMMON.SYSTEMPOPUPS.Success")}
                    text={translate("PERIODS.MESSAGES.MultipleCompanyCriteriasUpdatedSuccess")}
                />
            );
        } catch {
            openPopup(<ErrorPopup>{translate("COMMON.ERRORS.ErrorSavingValues")}</ErrorPopup>);
        }
    }, [data, createCompanyCriteriaSvcCall.invoke, updateCompanyCriteriaSvcCall.invoke, updateMultipleLines,
        updateLine, props.locationId, props.periodId, props.onCompletedOperations, createNotification, openPopup]);


    useEffect(() => {
        setIsSaveAllEnabled(data.some(crit =>
            (crit.state !== CriteriaState.SAVED) && AllValid(crit.isNameValid, crit.isDescriptionValid, crit.isWeightPercentageValid, crit.isTargetValueValid, crit.isCurrentValueValid, crit.isAchievmentPercentageValid
            )));
    }, [data]);



    return (
        <div className="criteria-section company-factors">
            {deleteCompanyCriteriaSvcCall.isLoading || createCompanyCriteriaSvcCall.isLoading || updateCompanyCriteriaSvcCall.isLoading
                ? <FullScreenLoader /> : null}
            <div className="criteria-section-header">
                <div className="subtitle">
                    {translate("PERIODS.INFOANDFORM.CompanyEvaluationFactors")}
                    {matrixSubtotal ? <Tag backgroundColor="status-green" text={"Subtotal: " + matrixSubtotal + "%"} isTiny /> : null}
                </div>

                <ConditionalRender if={!props.isClosed && props.hasTopPermissions && !props.isloadingTopPermissions}>
                    <div className="subtitle-right-btns">
                        {data.length > 0 && (!props.isClosed && props.hasTopPermissions && !props.isloadingTopPermissions) ?
                            <Tooltip text={translate("PERIODS.INFOANDFORM.SaveAllCompanyCriterias")}>
                                <IconButton type="secondary" isDisabled={!isSaveAllEnabled} icon={<SaveIcon />} onClick={handleSaveAllCriteriasBtnClicked} />
                            </Tooltip> : null}

                        {!props.isClosed && props.hasTopPermissions && !props.isloadingTopPermissions ?
                            <Tooltip text={translate("PERIODS.INFOANDFORM.AddFactor")}>
                                <IconButton type="secondary" icon={<AddIcon />} onClick={handleAddCriteriaBtnClicked} />
                            </Tooltip> : null}

                    </div>

                </ConditionalRender>
            </div>

            <ResponsiveDataTable
                items={data || []}
                columnDefinitions={companyCriteriasColumns}
                totalitems={0}
                isLoading={getMatrixSvcCall.isLoading}
            />
            <div className="company-factors-subtotalAchievement">
                {companySubTotalAchievement ? <Tag backgroundColor="status-yellow" text={translate("PERIODS.SubTotalAchieved") + ": " + companySubTotalAchievement + "%"} /> : null}
            </div>

        </div>)
}
