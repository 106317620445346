import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import { CutReasonsPage } from "../features/cut-reasons/pages/CutReasonsPage";
import { EmployeesContainer } from "../common/layouts/content/EmployeesContainer";
import { EmployeesEvaluationsPage } from "../features/employees/pages/evaluations/EmployeesEvaluationsPage";
import { EmployeesPage } from "../features/employees/pages/EmployeesPage";
import { EvaluationPeriodDetailsPage } from "../features/periods/pages/details-page/EvaluationPeriodDetailsPage";
import { EvaluationsAttachmentsPage } from "../features/evaluations-attachments/pages/EvaluationsAttachmentsPage";
import { EvaluationsPage } from "../features/evaluations/pages/EvaluationsPage";
import { HoursAndAbsencePage } from "../features/hours-absence-map/pages/HoursAndAbsencePage";
import { ModuleDefinition } from "../lib/infrastructure/modules/ModuleDefinition";
import { PeriodManagersPage } from "../features/managers/PeriodManagersPage";
import { PeriodsContainer } from "../common/layouts/content/PeriodsContainer";
import { Permission } from "../models/api/gate/Permission";
import { PersonalEvaluationPage } from "../features/employees/pages/personal-evaluation/PersonalEvaluationPage";
import { RouteGuard } from "../lib/infrastructure/authorization/RouteGuard";
import { SalariesPage } from "../features/salaries/pages/SalariesPage";
import { ViewEvaluationPeriodsPage } from "../features/periods/pages/ViewEvaluationPeriodsPage";
import { translate } from "../lib/infrastructure/i18n/InternationalizationService";
import { useEffect } from "react";
import { useRegisterBreadcrumbRoutes } from "../lib/infrastructure/navigation/hooks/useBreadcrumb";
import { PermissionNavigate } from "../lib/infrastructure/authorization/PermissionNavigate";

const moduleDefinition: ModuleDefinition = {
    basePath: "/",
    routes: [
        {
            name: () => translate("PERIODS.EvaluationPeriods"),
            pathPattern: "/",
        },
        {
            name: () => translate("PERIODS.EvaluationPeriods"),
            pathPattern: "/periods",
        },
        {
            name: () => "##PERIOD##",
            pathPattern: "/period/:id",
        },
        {
            name: () => translate("GLOBALADMIN.EMPLOYEES.Employees"),
            pathPattern: "/employees",
        },
        {
            name: () => "##EMPLOYEE##",
            pathPattern: "/employees/:id",
        },
        {
            name: () => "##EVALUATION##",
            pathPattern: "/employees/:id/evaluation/:id",
        },
    ],
};


export const humanResourcePermissions = [
    Permission.LOCATION_ADMIN,
    Permission.HUMAN_RESOURCES
];

export const adminPermissions = [
    Permission.GLOBAL_ADMIN,
    Permission.LOCATION_ADMIN,
    Permission.HUMAN_RESOURCES
];

export const basePermissions = [
    Permission.GLOBAL_ADMIN,
    Permission.LOCATION_ADMIN,
    Permission.HUMAN_RESOURCES,
    Permission.LOCATION_MANAGER
];




export function BonusModule() {
    const registerBreadcrumbRoutes = useRegisterBreadcrumbRoutes();

    useEffect(() => {
        registerBreadcrumbRoutes(moduleDefinition);
    }, []);


    return (
        <RouteGuard permissions={basePermissions}>
            <Routes>
                <Route path="/" element={<Navigate to="periods" />} />
                <Route path="periods" element={<ViewEvaluationPeriodsPage />} />
                <Route
                    path="periods/:id"
                    element={
                        <PeriodsContainer>
                            <Outlet />
                        </PeriodsContainer>}
                >

                    <Route index element={<PermissionNavigate to="matrix" fallbackTo="evaluations" permissions={basePermissions} />} />

                    <Route path="matrix" element={<EvaluationPeriodDetailsPage />} />
                    <Route path="evaluations" element={<EvaluationsPage />} />
                    <Route path="cut-reasons" element={<CutReasonsPage />} />
                    <Route path="managers" element={<PeriodManagersPage />} />
                    <Route path="hours-and-absence" element={<HoursAndAbsencePage />} />
                    <Route path="salaries" element={<SalariesPage />} />
                    <Route path="attachments" element={<EvaluationsAttachmentsPage />} />
                </Route>

            </Routes>
        </RouteGuard>
    );
}
