import { AttachmentsResponseDTO } from "../model/dtos/AttachmentsResponseDTO";
import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { EvaluationPeriodFileSummary } from "../model/domain/EvaluationPeriodFileSummary";
import { HttpClient } from "../../../lib/infrastructure/http/HttpClient";
import { SignURLRequestDTO } from "../model/dtos/SignURLRequestDTO";
import { SignURLResponseDTO } from "../model/dtos/SignURLResponseDTO";

const Route = (path: string) => {
    return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};

export class EvaluationPeriodFilesService {


    downloadUrlForExportBonusXLSX(locationId: string, periodID: string): Promise<string> {

        return Promise.resolve(Route(`/api/v1/Locations/${locationId}/EvaluationPeriods/${periodID}/Evaluations/AsXLSX`));
    }


    uploadEvaluationPeriodAttachment(locationId: string, periodId: string, file: File): Promise<void> {

        var formData = new FormData();
        formData.append("file", file, file.name);

        return HttpClient.sessionRequest({
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept": "application/json",
            },
            url: Route(`/api/v1/Locations/${locationId}/EvaluationPeriods/${periodId}/EvaluationPeriodFiles`),
            data: formData
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }


    getEvaluationPeriodAttachments(locationId: string, periodId: string): Promise<EvaluationPeriodFileSummary[]> {
        return HttpClient.sessionRequest<AttachmentsResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/EvaluationPeriods/${periodId}/EvaluationPeriodFiles`),
        })
            .then((response) => {
                var mappedAttachments = response.data.files.map((ob) => {
                    var attachment: EvaluationPeriodFileSummary = {
                        id: ob.id,
                        name: ob.name,
                        creatorName: ob.creatorName,
                        creatorEmail: ob.creatorEmail
                    }
                    return attachment;
                });
                return mappedAttachments;
            })
            .catch((error) => {
                throw error;
            });
    }




    getUrlDownloadAttachment(locationId: string, periodId: string, fileId: string): Promise<string> {
        var requestDTO: SignURLRequestDTO = {
            url: Route(`/api/v1/Locations/${locationId}/EvaluationPeriods/${periodId}/EvaluationPeriodFiles/${fileId}`)
        }

        return HttpClient.sessionRequest<SignURLResponseDTO>({
            method: "POST",
            url: Route(`/api/v1/Locations/${locationId}/EvaluationPeriods/${periodId}/EvaluationPeriodFiles/SignURL`),
            data: requestDTO
        })
            .then((res) => {
                return res.data.url;
            })
            .catch((error) => {
                throw error;
            });
    }



    deleteAttachment(locationId: string, periodId: string, fileId: string): Promise<void> {
        return HttpClient.sessionRequest({
            method: "DELETE",
            url: Route(`/api/v1/Locations/${locationId}/EvaluationPeriods/${periodId}/EvaluationPeriodFiles/${fileId}`)
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }
}