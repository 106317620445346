import './AddCutReasonPopup.css';

import { regexIntegerOrDecimals, validateRegexOrEmpty, weigthPercentageExpression, weigthPercentageExpression2 } from "../../../../lib/validators/ValidateRegex";
import { useCallback, useEffect, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../lib/infrastructure/ui/UIServices";

import { AllValid } from "../../../../common/validators/ValidateFormControls";
import { Checkbox } from "../../../../lib/components/checkbox/Checkbox";
import { CompanyCriteria } from "../../../matrixes/models/domain/CompanyCriteria";
import { CompanyCriteriaSelector } from "./CompanyCriteriaSelector";
import { CutReasonCreateDTO } from "../../models/dtos/CutReasonCreateDTO";
import { CutReasonsService } from "../../services/CutReasonsService";
import { EmployeeCriteria } from "../../../matrixes/models/domain/EmployeeCriteria";
import { EmployeeCriteriaSelector } from "./EmployeeCriteriaSelector";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { EvaluationPeriodMatrixesService } from "../../../matrixes/services/EvaluationPeriodMatrixesService";
import { FormContainer } from "../../../../lib/layouts/containers/form/FormContainer";
import { FormFieldTextInput } from "../../../../lib/components/form/form-field/FormFieldTextInput";
import { FullScreenLoader } from "../../../../lib/components/loader/FullScreenLoader";
import { Notification } from "../../../../lib/components/notifications/Notification";
import { PopupActionButtons } from "../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../lib/layouts/containers/popup-header/PopupHeader";
import { Spacer } from "../../../../lib/components/separator/Spacer";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { trimString } from "../../../../lib/utils/TrimString";
import { useFormControl } from "../../../../lib/components/form/Form";
import { useServiceCallPro } from "../../../../lib/hooks/useServiceCall";
import { validateWhiteSpaceStrings } from "../../../../lib/validators/ValidateWhiteSpaceStrings";

var periodMatrixSvc = new EvaluationPeriodMatrixesService();
var cutReasonSvc = new CutReasonsService();

export interface IAddCutReasonProps {
    locationId: string;
    periodId: string;
    onChangesMade: () => void;
}



export function AddCutReasonPopup(props: IAddCutReasonProps) {
    const createNotification = useCreateNotification();
    const closePopup = useClosePopup();
    const openPopup = usePopup();

    const [onClickSubmit, setOnClickSubmit] = useState<boolean>(false);

    const [companyCriterias, setCompanyCriterias] = useState<CompanyCriteria[]>([]);
    const [employeeCriterias, setEmployeeCriterias] = useState<EmployeeCriteria[]>([]);

    const [selectedCompanyCutFactors, setSelectedCompanyCutFactors] = useState<CompanyCriteria[]>([]);
    const [selectedEmployeeCutFactors, setSelectedEmployeeCutFactors] = useState<EmployeeCriteria[]>([]);

    const getCriteriasInfoCall = useServiceCallPro(periodMatrixSvc.getEvaluationPeriodMatrix);

    const nameEnFormControl = useFormControl<string>({
        validators: [validateWhiteSpaceStrings()],
        enableAutoValidate: true,
    });

    const namePtFormControl = useFormControl<string>({
        validators: [validateWhiteSpaceStrings()],
        enableAutoValidate: true,
    });

    const cutPercentageFormControl = useFormControl<string>({
        validators: [validateRegexOrEmpty(weigthPercentageExpression, true, "PERIODS.VALIDATIONS.WeigthPercentage")],
        enableAutoValidate: true,
    });

    useEffect(() => {
        if (!props.locationId || !props.periodId) return;

        getCriteriasInfoCall.invoke(`${props.locationId}`, `${props.periodId}`)
            .then((data) => {
                if (data) {
                    setCompanyCriterias(data.companyCriterias);
                    setEmployeeCriterias(data.employeeCriterias);
                }
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, []);


    const onClickAddCutReason = useCallback(() => {
        if (!props.locationId) return;

        if (!AllValid(namePtFormControl.validate(), nameEnFormControl.validate(), cutPercentageFormControl.validate())) return;

        setOnClickSubmit(true);

        var dto: CutReasonCreateDTO = {
            companyCutFactors: selectedCompanyCutFactors.map(i => i.id),
            employeeCutFactors: selectedEmployeeCutFactors.map(i => i.id),
            nameEn: nameEnFormControl.value?.toString().replace(/\\s+/g, ' ').trim() ?? "",
            namePt: namePtFormControl.value?.toString().replace(/\\s+/g, ' ').trim() ?? "",
            cutPercentage: parseFloat(trimString(cutPercentageFormControl.value))
        }

        cutReasonSvc
            .createCutReason(props.locationId, props.periodId, dto)
            .then((_) => {
                props.onChangesMade();
                setOnClickSubmit(false);
                closePopup();
                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("CUTREASONS.POPUP.CreateCutReasonSuccess")}
                    ></Notification>
                );
            })
            .catch((error) => {
                if (!error) return;
                setOnClickSubmit(false);
                openPopup(
                    <ErrorPopup>{error.response.data.message}</ErrorPopup>,
                    false
                );
            });

    }, [
        props.locationId,
        props.periodId,
        props.onChangesMade,
        namePtFormControl.validate,
        namePtFormControl.value,
        nameEnFormControl.validate,
        nameEnFormControl.value,
        cutPercentageFormControl.validate,
        cutPercentageFormControl.value,
        setOnClickSubmit,
        closePopup,
        createNotification,
        openPopup,
        selectedCompanyCutFactors,
        selectedEmployeeCutFactors
    ]);

    return (
        <PopupContainer className="popup-add-cut-reason">
            {onClickSubmit ? <FullScreenLoader /> : null}

            <PopupHeader
                title={translate("CUTREASONS.INFOANDFORM.CreateCutReason")}
            />
            <PopupContent className="add-cut-reason">
                <FormContainer>
                    <div className="inputs-container">

                        <div className="separator">{translate("CUTREASONS.INFOANDFORM.Description")}</div>
                        <Spacer mode="vertical" px={10}></Spacer>

                        <div className="column">
                            <FormFieldTextInput
                                formControl={nameEnFormControl}
                                label={translate("CUTREASONS.INFOANDFORM.NameEN")}
                                placeholder={translate("CUTREASONS.INFOANDFORM.CutReasonNamePlaceholder")}
                            />
                            <FormFieldTextInput
                                formControl={namePtFormControl}
                                label={translate("CUTREASONS.INFOANDFORM.NamePT")}
                                placeholder={translate("CUTREASONS.INFOANDFORM.CutReasonNamePlaceholder")}
                            />
                            <FormFieldTextInput
                                formControl={cutPercentageFormControl}
                                label={translate("CUTREASONS.INFOANDFORM.CutPercentage")}
                                placeholder={translate("CUTREASONS.INFOANDFORM.CutPercentagePlaceholder")}
                            />
                        </div>
                        <Spacer mode="vertical" px={10}></Spacer>
                        <div className="separator">{translate("CUTREASONS.INFOANDFORM.CompanyFactors")}</div>
                        <div className="column-factors">
                            <CompanyCriteriaSelector options={companyCriterias} selected={selectedCompanyCutFactors} onSelectionChanged={setSelectedCompanyCutFactors} />
                        </div>

                        <Spacer mode="vertical" px={10}></Spacer>
                        <div className="separator">{translate("CUTREASONS.INFOANDFORM.EmployeeFactors")}</div>
                        <div className="column-factors">
                            <EmployeeCriteriaSelector options={employeeCriterias} selected={selectedEmployeeCutFactors} onSelectionChanged={setSelectedEmployeeCutFactors} />
                        </div>
                    </div>
                </FormContainer>

                <Spacer mode={"vertical"} px="30"></Spacer>
                <PopupActionButtons
                    className="add-cut-reasons-btns"
                    buttons={[
                        {
                            text: translate("COMMON.Cancel"),
                            type: "tertiary",
                            onClick: () => closePopup(),
                        },
                        {
                            text: translate("COMMON.Create"),
                            type: "primary",
                            onClick: onClickAddCutReason
                        },
                    ]}
                />
            </PopupContent>
        </PopupContainer>
    );
}

