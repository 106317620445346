import './EditCutReasonPopup.css'

import { regexIntegerOrDecimals, validateRegexOrEmpty, weigthPercentageExpression, weigthPercentageExpression2 } from "../../../../lib/validators/ValidateRegex";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../lib/infrastructure/ui/UIServices";

import { AllValid } from "../../../../common/validators/ValidateFormControls";
import { CompanyCriteria } from "../../../matrixes/models/domain/CompanyCriteria";
import { CompanyCriteriaSelector } from "./CompanyCriteriaSelector";
import { CutReasonDetails } from '../../models/domain/CutReasonDetails';
import { CutReasonUpdateDTO } from "../../models/dtos/CutReasonUpdateDTO";
import { CutReasonsService } from "../../services/CutReasonsService";
import { EmployeeCriteria } from "../../../matrixes/models/domain/EmployeeCriteria";
import { EmployeeCriteriaSelector } from "./EmployeeCriteriaSelector";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { EvaluationMatrix } from '../../../matrixes/models/domain/EvaluationMatrix';
import { EvaluationPeriodMatrixesService } from "../../../matrixes/services/EvaluationPeriodMatrixesService";
import { FormContainer } from "../../../../lib/layouts/containers/form/FormContainer";
import { FormFieldTextInput } from "../../../../lib/components/form/form-field/FormFieldTextInput";
import { FullScreenLoader } from "../../../../lib/components/loader/FullScreenLoader";
import { Notification } from "../../../../lib/components/notifications/Notification";
import { PopupActionButtons } from "../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../lib/layouts/containers/popup-header/PopupHeader";
import { Spacer } from "../../../../lib/components/separator/Spacer";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { trimString } from "../../../../lib/utils/TrimString";
import { useFormControl } from "../../../../lib/components/form/Form";
import { useServiceCallPro } from "../../../../lib/hooks/useServiceCall";
import { validateWhiteSpaceStrings } from "../../../../lib/validators/ValidateWhiteSpaceStrings";

var cutReasonSvc = new CutReasonsService();
var periodMatrixSvc = new EvaluationPeriodMatrixesService();

interface IEditCutReasonProps {
    locationId: string;
    periodId: string;
    cutReasonId: string;
    onCompleted: () => void;
}

export function EditCutReasonPopup(props: IEditCutReasonProps) {
    const closePopup = useClosePopup();
    const openPopup = usePopup();

    const createNotification = useCreateNotification();

    const [criterias, setCriterias] = useState<EvaluationMatrix>();
    const [cutReason, setCutReason] = useState<CutReasonDetails>();

    const [selectedCompanyCutFactors, setSelectedCompanyCutFactors] = useState<CompanyCriteria[]>([]);
    const [selectedEmployeeCutFactors, setSelectedEmployeeCutFactors] = useState<EmployeeCriteria[]>([]);

    const getEvaluationPeriodMatrixCall = useServiceCallPro(periodMatrixSvc.getEvaluationPeriodMatrix);
    const updateCutReasonCall = useServiceCallPro(cutReasonSvc.updateCutReason);
    const getCutReasonByIdCall = useServiceCallPro(cutReasonSvc.getCutReasonById);

    const nameEnFormControl = useFormControl<string>({
        validators: [validateWhiteSpaceStrings()],
        enableAutoValidate: true,
    });

    const namePtFormControl = useFormControl<string>({
        validators: [validateWhiteSpaceStrings()],
        enableAutoValidate: true,
    });

    const cutPercentageFormControl = useFormControl<string>({
        validators: [validateRegexOrEmpty(weigthPercentageExpression, true, "PERIODS.VALIDATIONS.WeigthPercentage")],
        enableAutoValidate: true,
    });



    /****************************
    * DATA REQUESTS
    *****************************/

    const getEvaluationPeriodMatrix = useCallback(() => {
        if (!props.locationId || !props.periodId) return;

        getEvaluationPeriodMatrixCall.invoke(`${props.locationId}`, `${props.periodId}`)
            .then((data) => {
                if (data) {
                    setCriterias(data);
                }
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [props.locationId, props.periodId, setCriterias, getEvaluationPeriodMatrixCall.invoke, openPopup]);


    const getCutReasonById = useCallback(() => {
        if (props.locationId && props.periodId && props.cutReasonId) {
            getCutReasonByIdCall
                .invoke("" + props.locationId, "" + props.periodId, "" + props.cutReasonId)
                .then((response) => {
                    setCutReason(response);
                    nameEnFormControl.setValue(response.nameEn);
                    namePtFormControl.setValue(response.namePt);
                    cutPercentageFormControl.setValue(response.cutPercentage.toString());
                })
                .catch((error) => {
                    if (!error) return;
                    openPopup(
                        <ErrorPopup>{error.response.data.message}</ErrorPopup>,
                        false
                    );
                });
        }
    }, [props.locationId, props.periodId, props.cutReasonId, setCutReason, getCutReasonByIdCall.invoke, openPopup, nameEnFormControl.setValue, namePtFormControl.setValue,
    cutPercentageFormControl.setValue]);



    const updateCutReason = useCallback((dto: CutReasonUpdateDTO) => {
        if (!props.locationId || !props.periodId || !props.cutReasonId) return

        updateCutReasonCall
            .invoke(
                props.locationId,
                props.periodId,
                props.cutReasonId,
                dto
            )
            .then((_) => {
                props.onCompleted();
                closePopup();
                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate(
                            "CUTREASONS.POPUP.EditCutReasonSuccess"
                        )}
                    ></Notification>
                );
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [props.locationId, props.periodId, props.cutReasonId, updateCutReasonCall.invoke, props.onCompleted, closePopup, createNotification, openPopup]);

    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/

    useEffect(() => {
        getEvaluationPeriodMatrix();
    }, []);



    useEffect(() => {
        getCutReasonById();
    }, [props.locationId, props.periodId, props.cutReasonId]);



    useEffect(() => {
        if (criterias && cutReason) {
            var companyCriteria = criterias.companyCriterias.filter((cc) => cutReason.companyCutFactors.find((ccf) => ccf.criteriaId === cc.id));
            var employeeCriteria = criterias.employeeCriterias.filter((ec) => cutReason.employeeCutFactors.find((ecf) => ecf.criteriaId === ec.id));

            setSelectedCompanyCutFactors(companyCriteria);
            setSelectedEmployeeCutFactors(employeeCriteria);

        }
    }, [criterias, cutReason]);



    const isLoading = useMemo(() =>
        (getCutReasonByIdCall.isLoading || getEvaluationPeriodMatrixCall.isLoading)
        , [getCutReasonByIdCall.isLoading, getEvaluationPeriodMatrixCall.isLoading]);

    /****************************
     * USER ACTIONS
     *****************************/

    const handleEditCutReasonClicked = useCallback(() => {
        if (!props.locationId || !props.periodId || !props.cutReasonId || !AllValid(nameEnFormControl.validate(), nameEnFormControl.validate(), cutPercentageFormControl.validate())) return;


        var dto: CutReasonUpdateDTO = {
            nameEn: nameEnFormControl.value?.toString().replace(/\\s+/g, ' ').trim() ?? "",
            namePt: namePtFormControl.value?.toString().replace(/\\s+/g, ' ').trim() ?? "",
            cutPercentage: parseFloat(trimString(cutPercentageFormControl.value)),
            companyCutFactors: selectedCompanyCutFactors.map(i => i.id),
            employeeCutFactors: selectedEmployeeCutFactors.map(i => i.id),
        }

        updateCutReason(dto);

    }, [
        props.locationId,
        props.periodId,
        props.cutReasonId,
        nameEnFormControl.value,
        nameEnFormControl.validate,
        namePtFormControl.value,
        namePtFormControl.validate,
        cutPercentageFormControl.value,
        cutPercentageFormControl.validate,
        selectedCompanyCutFactors,
        selectedEmployeeCutFactors,
        updateCutReason
    ]);

    /****************************
     * CSS & HTML
     *****************************/

    return (
        <PopupContainer className="popup-edit-cut-reason">
            {updateCutReasonCall.isLoading ? <FullScreenLoader /> : null}

            <PopupHeader
                title={translate("CUTREASONS.INFOANDFORM.EditCutReason")}
            />
            <PopupContent className="edit-cut-reason" isLoading={isLoading}>

                <FormContainer>
                    <div className="inputs-container">

                        <div className="separator">{translate("CUTREASONS.INFOANDFORM.Description")}</div>
                        <Spacer mode="vertical" px={10}></Spacer>

                        <div className="column">
                            <FormFieldTextInput
                                formControl={nameEnFormControl}
                                label={translate("CUTREASONS.INFOANDFORM.NameEN")}
                                placeholder={translate("CUTREASONS.INFOANDFORM.CutReasonNamePlaceholder")}
                            />
                            <FormFieldTextInput
                                formControl={namePtFormControl}
                                label={translate("CUTREASONS.INFOANDFORM.NamePT")}
                                placeholder={translate("CUTREASONS.INFOANDFORM.CutReasonNamePlaceholder")}
                            />
                            <FormFieldTextInput
                                formControl={cutPercentageFormControl}
                                label={translate("CUTREASONS.INFOANDFORM.CutPercentage")}
                                placeholder={translate("CUTREASONS.INFOANDFORM.CutPercentagePlaceholder")}
                            />
                        </div>

                        <Spacer mode="vertical" px={10}></Spacer>
                        <div className="separator">{translate("CUTREASONS.INFOANDFORM.CompanyFactors")}</div>
                        <div className="column-factors">
                            <CompanyCriteriaSelector options={criterias?.companyCriterias || []} selected={selectedCompanyCutFactors} onSelectionChanged={setSelectedCompanyCutFactors} />
                        </div>

                        <Spacer mode="vertical" px={10}></Spacer>
                        <div className="separator">{translate("CUTREASONS.INFOANDFORM.EmployeeFactors")}</div>
                        <div className="column-factors">
                            <EmployeeCriteriaSelector options={criterias?.employeeCriterias || []} selected={selectedEmployeeCutFactors} onSelectionChanged={setSelectedEmployeeCutFactors} />
                        </div>
                    </div>
                </FormContainer>

                <Spacer mode={"vertical"} px="30"></Spacer>
                <PopupActionButtons
                    buttons={[
                        {
                            text: translate("COMMON.Cancel"),
                            type: "tertiary",
                            onClick: () => closePopup(),
                        },
                        {
                            text: translate("COMMON.Edit"),
                            type: "primary",
                            onClick: handleEditCutReasonClicked,
                        },
                    ]}
                />
            </PopupContent>
        </PopupContainer>
    );
}