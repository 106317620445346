import { EmployeeHourRecordSummaryDTO } from "../dtos/EmployeeHourRecordSummaryDTO";
import { HourRecordSummary } from "../domain/HourRecordSummary";




export function APIHoursDTOtoHoursSummary(apiDto: EmployeeHourRecordSummaryDTO): HourRecordSummary {
    return {
        abscenceDays: apiDto.abscenceDays,
        code: apiDto.code,
        codeDescription: apiDto.codeDescription,
        isProblematic: apiDto.isProblematic ?? false
    };
}
