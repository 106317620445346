import "./RegisterFeedbackPopup.css";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../lib/infrastructure/ui/UIServices";

import { AllValid } from "../../../../common/validators/ValidateFormControls";
import { CardContainer } from "../../../../lib/layouts/containers/card/CardContainer";
import { ConditionalRender } from "../../../../lib/functional/ConditionalRender";
import { EmptyState } from "../../../../lib/components/emptystates/EmptyState";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { EvaluationDetails } from "../../models/domain/EvaluationDetails";
import { EvaluationUpdateFeedbackDTO } from "../../models/dtos/EvaluationUpdateFeedbackDTO";
import { EvaluationsService } from "../../services/EvaluationsService";
import { FormContainer } from "../../../../lib/layouts/containers/form/FormContainer";
import { FormFieldTextArea } from "../../../../lib/components/form/form-field/FormFieldTextArea";
import { FullScreenLoader } from "../../../../lib/components/loader/FullScreenLoader";
import { Notification } from "../../../../lib/components/notifications/Notification";
import { PopupActionButtons } from "../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../lib/layouts/containers/popup-header/PopupHeader";
import { Spacer } from "../../../../lib/components/separator/Spacer";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { trimString } from "../../../../lib/utils/TrimString";
import { useFormControl } from "../../../../lib/components/form/Form";
import { useServiceCallPro } from "../../../../lib/hooks/useServiceCall";
import { validateWhiteSpaceStrings } from "../../../../lib/validators/ValidateWhiteSpaceStrings";
import { Checkbox } from "../../../../lib/components/checkbox/Checkbox";
import { Tag } from "../../../../lib/components/tag/Tag";
import { ReactComponent as WarningIcon } from "../../../../lib/assets/icons/warning-icon-v2.svg";
import { InfoBlock } from "../../../../lib/components/info-block/InfoBlock";
import { EvaluationFeedback } from "../../models/domain/EvaluationFeedback";
import { EvaluationStatusEnum } from "../../models/domain/EvaluationStatusEnum";

var evaluationSvc = new EvaluationsService();

export interface IRegisterFeedbackPopupProps {
    onlyRead: boolean;
    onAddFeedbackCompleted: (ev: EvaluationDetails) => void;
    employeeName: string;
    locationId: number;
    periodId: string;
    isPeriodStatusInvalid: boolean,
    evaluationId: number;
    evaluationStatus: EvaluationStatusEnum;
}

export function RegisterFeedbackPopup(props: IRegisterFeedbackPopupProps) {
    const openPopup = usePopup();
    const closePopup = useClosePopup();
    const createNotification = useCreateNotification();

    const updateEvaluationFeedbackCall = useServiceCallPro(evaluationSvc.updateEvaluationFeedback);
    const getEvaluationFeedbackByIdCall = useServiceCallPro(evaluationSvc.getEvaluationFeedbackById);
    const updateEvaluationFeedbackGivenInPersonCall = useServiceCallPro(evaluationSvc.updateEvaluationFeedbackGivenInPerson);

    const [evaluationFeedbackAPIResponse, setEvaluationFeedbackAPIResponse] = useState<EvaluationFeedback>();


    const [feedbackGivenInPersonControl, setFeedbackGivenInPersonControl] = useState<boolean>(false);

    const feedbackFormControl = useFormControl<string>({
        validators: [validateWhiteSpaceStrings()],
        enableAutoValidate: true,
        isDisabled: false,
    });

    /****************************
    * DATA REQUESTS
    *****************************/

    const getEvaluationFeedbackById = useCallback(() => {
        if (props.locationId && props.periodId && props.evaluationId) {
            getEvaluationFeedbackByIdCall
                .invoke("" + props.locationId, props.periodId, "" + props.evaluationId)
                .then((response) => {

                    setEvaluationFeedbackAPIResponse({ feedback: "" + response?.feedback, isFeedbackGivenInPerson: response?.isFeedbackGivenInPerson ?? false })

                    feedbackFormControl.setValue(response?.feedback);
                    setFeedbackGivenInPersonControl(response?.isFeedbackGivenInPerson ?? false);

                })
                .catch((error) => {
                    if (!error) return;
                    openPopup(
                        <ErrorPopup>{error.response.data.message}</ErrorPopup>,
                        false
                    );
                });
        }
    }, [props.locationId, props.periodId, props.evaluationId, feedbackFormControl.setIsDisabled, props.onlyRead,
    getEvaluationFeedbackByIdCall.invoke, feedbackFormControl.setValue,
        openPopup]);


    const updateEvaluationFeedback = useCallback((dto: EvaluationUpdateFeedbackDTO) => {
        if (!props.locationId || !props.periodId || !props.evaluationId) return

        updateEvaluationFeedbackCall
            .invoke("" + props.locationId, props.periodId, "" + props.evaluationId, dto)
            .then((response) => {
                props.onAddFeedbackCompleted(response);
                closePopup();
                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate(
                            "EVALUATIONS.POPUPS.RegisterFeedbackSuccess"
                        )}
                    ></Notification>
                );
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [props.locationId, props.periodId, props.evaluationId, updateEvaluationFeedbackCall.invoke, closePopup, createNotification, openPopup]);

    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/

    useEffect(() => {
        getEvaluationFeedbackById();
    }, [props.locationId, props.periodId, props.evaluationId]);


    /****************************
     * USER ACTIONS
     *****************************/

    const handleRegisterFeedbackClicked = useCallback(() => {
        if (!props.locationId || !props.periodId || !props.evaluationId || !AllValid(feedbackFormControl.validate())) return;

        var dto: EvaluationUpdateFeedbackDTO = {
            feedback: trimString(feedbackFormControl.value),
        }
        updateEvaluationFeedback(dto);
    }, [props.locationId, props.periodId, props.evaluationId, feedbackFormControl.value, feedbackFormControl.validate, updateEvaluationFeedback]);


    const handleSaveFeedbackGivenInPersonClicked = useCallback(() => {
        if (!props.locationId || !props.periodId || !props.evaluationId) return;

        updateEvaluationFeedbackGivenInPersonCall.invoke("" + props.locationId, props.periodId, "" + props.evaluationId, { isFeedbackGivenInPerson: feedbackGivenInPersonControl })
            .then((response) => {
                props.onAddFeedbackCompleted(response);
                closePopup();
                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("COMMON.Saved")}
                    ></Notification>
                );
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [props.locationId, props.periodId, props.evaluationId, feedbackFormControl.value, feedbackFormControl.validate, updateEvaluationFeedback]);





    /****************************
     * CSS & HTML
     *****************************/


    return (
        <PopupContainer className="popup-register-feedback">
            <PopupHeader title={props.onlyRead ? translate("EVALUATIONS.INFOANDFORM.FeedbackToCollaborator") : translate("EVALUATIONS.POPUPS.RegisterFeedback")} />
            <ConditionalRender if={updateEvaluationFeedbackCall.isLoading || updateEvaluationFeedbackGivenInPersonCall.isLoading}><FullScreenLoader /></ConditionalRender>
            <PopupContent isLoading={getEvaluationFeedbackByIdCall.isLoading}>

                <div className="register-feedback">
                    <CardContainer className="employee-card">
                        <div className="small-copy employee-label">{translate("EVALUATIONS.INFOANDFORM.Employee")}</div>
                        <div className="employee-value">{props.employeeName}</div>
                    </CardContainer>

                    <Spacer mode={"vertical"} px="20" />

                    <ConditionalRender if={!props.onlyRead}>
                        <FormContainer >
                            <FormFieldTextArea
                                formControl={feedbackFormControl}
                                label={translate("EVALUATIONS.INFOANDFORM.Feedback")}
                                placeholder={translate("EVALUATIONS.INFOANDFORM.RegisterFeedbackPlaceholder")}
                            />
                        </FormContainer>
                    </ConditionalRender>


                    <ConditionalRender if={props.onlyRead && feedbackFormControl.value != undefined}>
                        <InfoBlock label={translate("EVALUATIONS.POPUPS.FeedbackGivenToCollaborator")} value={"" + feedbackFormControl.value} />
                    </ConditionalRender>

                    <ConditionalRender if={props.onlyRead && feedbackFormControl.value == undefined}>
                        <EmptyState hideSubtitle title={translate("EVALUATIONS.INFOANDFORM.Feedback")} subtitleMessage={translate("EVALUATIONS.POPUPS.NoFeedbackGiven")} />
                    </ConditionalRender>

                    <Spacer mode={"vertical"} px="20" />




                    {evaluationFeedbackAPIResponse?.feedback && props.evaluationStatus === EvaluationStatusEnum.CALIBRATED && !evaluationFeedbackAPIResponse?.isFeedbackGivenInPerson ?
                        <Checkbox
                            isChecked={feedbackGivenInPersonControl}
                            onClick={() => setFeedbackGivenInPersonControl(!feedbackGivenInPersonControl)}
                            text={translate("EVALUATIONS.POPUPS.FeedbackGivenInPerson")}
                  
                        /> : null}





                    <Spacer mode={"vertical"} px="20" />
                </div>
                {evaluationFeedbackAPIResponse?.isFeedbackGivenInPerson ?
                    <Tag text={translate("EVALUATIONS.POPUPS.FeedbackWasGiven")} backgroundColor="status-green" /> : <Tag icon={<WarningIcon />} text={translate("EVALUATIONS.POPUPS.FeedbackVisibleToCollaborator")} backgroundColor="status-yellow" />}


                <Spacer mode={"vertical"} px="20" />


                {evaluationFeedbackAPIResponse?.feedback && !evaluationFeedbackAPIResponse?.isFeedbackGivenInPerson
                    && props.evaluationStatus === EvaluationStatusEnum.CALIBRATED ?

                    <PopupActionButtons
                        buttons={[
                            {
                                text: translate("COMMON.Cancel"),
                                type: "tertiary",
                                onClick: () => closePopup(),
                            },
                            {
                                text: translate("COMMON.Save"),
                                className: "register-btn",
                                type: "primary",
                                isDisabled: !feedbackGivenInPersonControl,
                                onClick: handleSaveFeedbackGivenInPersonClicked,
                            },
                        ]}
                    />
                    : null}


                <ConditionalRender if={!props.onlyRead}>
                    <PopupActionButtons
                        buttons={[
                            {
                                text: translate("COMMON.Cancel"),
                                type: "tertiary",
                                onClick: () => closePopup(),
                            },
                            {
                                text: translate("EVALUATIONS.INFOANDFORM.Register"),
                                className: "register-btn",
                                type: "primary",
                                onClick: handleRegisterFeedbackClicked,
                                isDisabled: props.isPeriodStatusInvalid
                            },
                        ]}
                    />
                </ConditionalRender>
            </PopupContent>
        </PopupContainer>
    );
}
