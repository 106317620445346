export enum OperationTypeEnum {
    CUT_BONUS = 1,
    REJECT_EVALUATION = 2,
    CONFIRM_EVALUATION = 3,
    CALIBRATE_EVALUATION = 4,
    REVERT_CUT_BONUS = 5,
    ADD_FEEDBACK_TO_EVALUATION = 6,
    UPDATE_EVALUATION_STATUS = 7,
    UPDATE_EVALUATION_FACTOR = 8,
    REVERT_EVALUATION_STATUS = 9,
    MARK_FEEDBACK_AS_GIVEN_IN_PERSON = 10,

}

export const EvaluationStatusTranslationTerms: { [key: string]: string } = {
    1: "EVALUATIONS.STATUS.CutBonus",
    2: "EVALUATIONS.STATUS.RejectEvaluation",
    3: "EVALUATIONS.STATUS.ConfirmEvaluation",
    4: "EVALUATIONS.STATUS.CalibrateEvaluation",
    5: "EVALUATIONS.STATUS.RevertCutBonus",
    6: "EVALUATIONS.STATUS.AddFeedbackToEvaluation",
    7: "EVALUATIONS.STATUS.UpdateEvaluationStatus",
    8: "EVALUATIONS.STATUS.UpdateEvaluationFactor",
    9: "EVALUATIONS.STATUS.RevertEvaluationStatus",
    10: "EVALUATIONS.STATUS.MarkFeedbackAsGivenInPerson",
}

export const EvaluationStatusAsEnum: { [key: number]: OperationTypeEnum } = {
    1: OperationTypeEnum.CUT_BONUS,
    2: OperationTypeEnum.REJECT_EVALUATION,
    3: OperationTypeEnum.CONFIRM_EVALUATION,
    4: OperationTypeEnum.CALIBRATE_EVALUATION,
    5: OperationTypeEnum.REVERT_CUT_BONUS,
    6: OperationTypeEnum.ADD_FEEDBACK_TO_EVALUATION,
    7: OperationTypeEnum.UPDATE_EVALUATION_STATUS,
    8: OperationTypeEnum.UPDATE_EVALUATION_FACTOR,
    9: OperationTypeEnum.REVERT_EVALUATION_STATUS,
    10: OperationTypeEnum.MARK_FEEDBACK_AS_GIVEN_IN_PERSON
};