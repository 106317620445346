import "./PeriodManagersPage.css";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup, useWindowResize } from "../../lib/infrastructure/ui/UIServices";

import { ReactComponent as AddIcon } from "../../lib/assets/icons/add.svg";
import { AddPeriodManager } from "./popups/AddPeriodManager";
import { Button } from "../../lib/components/buttons/Button";
import { ColumnDefinition } from "../../lib/components/table/TableInterfaces";
import { ConditionalRender } from "../../lib/functional/ConditionalRender";
import { ConfigurationProvider } from "../../lib/infrastructure/configuration/ConfigurationProvider";
import { ContextualMenu } from "../../lib/components/contextual-menu/ContextualMenu";
import { DelegateManagerPopup } from "./popups/DelegateManagerPopup";
import { ReactComponent as DeleteIcon } from "../../lib/assets/icons/remover.svg";
import { ErrorPopup } from "../../lib/components/popup/ErrorPopup";
import { FormFieldTextInput } from "../../lib/components/form/form-field/FormFieldTextInput";
import { FullScreenLoader } from "../../lib/components/loader/FullScreenLoader";
import { IconButton } from "../../lib/components/buttons/IconButton";
import { ImportPopup } from "../../lib/components/popup/ImportPopup";
import { ManagerAndDelegation } from "./models/domain/ManagersAndDelegations";
import { ManagersDelegationsService } from "./services/ManagersDelegationsService";
import { ManagersQueryOptions } from "./models/api/ManagersQueryOptions";
import { ReactComponent as MoreActions } from "../../lib/assets/icons/more-actions.svg";
import { Notification } from "../../lib/components/notifications/Notification";
import { PageLayout } from "../../lib/layouts/main-content/PageLayout";
import { PagePermissions } from "../../lib/infrastructure/authorization/PagePermissions";
import { PeriodManagerService } from "./services/PeriodManagerService";
import { Permission } from "../../models/api/gate/Permission";
import { ReactComponent as RejectIcon } from "../../lib/assets/icons/reject-dark.svg";
import { ResponsiveDataTable } from "../../lib/components/table/ResponsiveDataTable";
import { ScalableIconButton } from "../../lib/components/buttons/ScalableIconButton";
import { ReactComponent as SearchIcon } from "../../lib/assets/icons/search-icon.svg";
import { Spacer } from "../../lib/components/separator/Spacer";
import { ReactComponent as UserIcon } from "../../lib/assets/icons/user.svg";
import { WarningPopup } from "../../lib/components/popup/WarningPopup";
import axios from "axios";
import { translate } from "../../lib/infrastructure/i18n/InternationalizationService";
import { useEvaluationPeriodStatus } from "../../lib/infrastructure/period/useEvaluationPeriodStatus";
import { useFormControl } from "../../lib/components/form/Form";
import { useGlobalLocation } from "../../lib/infrastructure/location/LocationServices";
import { useParams } from "react-router-dom";
import { useServiceCallPro2 } from "../../lib/hooks/useServiceCall";
import { EvaluationsService } from "../evaluations/services/EvaluationsService";

var svc = new PeriodManagerService();
var delegationSvc = new ManagersDelegationsService();
var evaluationsSvc = new EvaluationsService(); 

const permissions = [Permission.GLOBAL_ADMIN, Permission.LOCATION_ADMIN, Permission.HUMAN_RESOURCES];

export function PeriodManagersPage() {
    const openPopup = usePopup();
    const closePopup = useClosePopup();
    const createNotification = useCreateNotification();
    const periodInfo = useEvaluationPeriodStatus();
    const windowResize = useWindowResize();

    const { id: periodID } = useParams();
    const locationID = useGlobalLocation();

    const [periodManagers, setPeriodManagers] = useState<ManagerAndDelegation[]>([]);
    const [totalItems, setTotalItems] = useState<number>();

    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentpage, setCurrentPage] = useState(0);

    const [timer, setTimer] = useState<NodeJS.Timeout>();
    const [searchWord, setSearchWord] = useState<string>();

    var searchboxFormControl = useFormControl<string>({});

    /*****************
     * SERVICE CALLS 
     ****************/
    const [getPeriodManagersCall, getPeriodManagersCallIsLoading] = useServiceCallPro2(svc, svc.getPeriodManagers);
    const [removePeriodManagerCall, removePeriodManagerCallIsLoading] = useServiceCallPro2(svc, svc.removePeriodManager);
    const [deleteManagerDelegationCall, deleteManagerDelegationCallIsLoading] = useServiceCallPro2(delegationSvc, delegationSvc.deleteManagerDelegation);

    const [uploadFileCall, uploadFileCallIsLoading] = useServiceCallPro2(evaluationsSvc, evaluationsSvc.uploadCollaboratorsAndManagersFromXLSX);


    useEffect(() => {
        getPeriodManagers();
    }, [currentpage, itemsPerPage, searchWord]);


    useEffect(() => {
        if (timer) {
            clearTimeout(timer);
        }
        var responseTimeOut = setTimeout(() => {
            if (timer) {
                setCurrentPage(0);
                setSearchWord(searchboxFormControl.value);
            }
        }, 1000);
        setTimer(responseTimeOut);
    }, [searchboxFormControl.value]);


    const getPeriodManagers = useCallback(() => {

        if (!locationID || !periodID) return;

        var queryOptions: ManagersQueryOptions = {
            page: currentpage,
            pageLength: itemsPerPage,
            searchWord: searchWord,
            filterByIsActive: false,
        };

        getPeriodManagersCall(`${locationID}`, periodID, queryOptions)
            .then((data) => {
                setPeriodManagers(data.managers);
                setTotalItems(data.totalItems);
            })
            .catch((error) => {
                if (!error || axios.isCancel(error)) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [locationID, periodID, getPeriodManagersCall, setPeriodManagers, setTotalItems, openPopup, currentpage, itemsPerPage, searchWord]);


    const handleRemoveManagerButtonClicked = useCallback((managerID: string) => {

        if (!locationID || !periodID || !managerID) return;

        removePeriodManagerCall(`${locationID}`, periodID, managerID)
            .then((data) => {
                getPeriodManagers();
                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("MANAGERS.DeleteManagerSuccessMessage")}
                    ></Notification>
                );
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [locationID, periodID, removePeriodManagerCall, getPeriodManagers, openPopup, createNotification]);


    const handleDeleteManagerDelegationButtonClicked = useCallback((managerDelegationID: number) => {

        if (!locationID || !periodID || !managerDelegationID) return;

        deleteManagerDelegationCall(`${locationID}`, periodID, `${managerDelegationID}`)
            .then((_) => {
                getPeriodManagers();
                createNotification(
                    <Notification
                        type="success"
                        title={translate("COMMON.SYSTEMPOPUPS.Success")}
                        text={translate("MANAGERS.POPUPS.RemoveDelegationSuccessMessage")}
                    ></Notification>
                );
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [locationID, periodID, removePeriodManagerCall, getPeriodManagers, openPopup, createNotification]);





    const handleOnPageAndItemsChanged = useCallback(
        (items: number, currentPage: number) => {
            setItemsPerPage(items);
            setCurrentPage(currentPage);
        },
        [setItemsPerPage, setCurrentPage]
    );


    const columns: ColumnDefinition<ManagerAndDelegation>[] = useMemo(
        () => [
            {
                cellRenderProp: (v) => <div className="employee-info"><div className="employee-name">{v.displayName}</div><div className="small-copy employee-email">{v.email}</div></div>,
                headerRender: translate("COMMON.Name"),
                width: "40%",
                isMobilePrimaryCell: true
            },
            {
                cellRenderProp: (v) => v.delegation ?
                    <div className="delegated-to-container">
                        <div className="delegated-to">{translate("MANAGERS.INFOANDFORM.DelegatedTo") + ":"}</div>
                        <div className="name">{v.delegation.displayName}</div>
                    </div> : null,
                headerRender: translate("MANAGERS.INFOANDFORM.Delegations"),
            },
            {
                columnKey: `moreActions`,
                cellRenderProp: (v) => !periodInfo.isPeriodClosed ? <ContextualMenu
                    items={[
                        {
                            label: translate("MANAGERS.INFOANDFORM.Delegate"),
                            navigateTo: "",
                            icon: <UserIcon />,
                            onClick: () => {
                                openPopup(<DelegateManagerPopup originalManagerName={v.displayName} originalManagerEmail={v.email} locationID={locationID} periodID={periodID} onChangesMade={getPeriodManagers} />);
                            }
                        },
                        {
                            label: translate("MANAGERS.INFOANDFORM.RemoveDelegation"),
                            navigateTo: "",
                            icon: <RejectIcon />,
                            isVisible: v.delegation?.id ? true : false,
                            onClick: () => {
                                openPopup(
                                    <WarningPopup
                                        className="remove-delegation-popup"
                                        onDismissClicked={() => {
                                            closePopup();
                                        }}
                                        onConfirmClicked={() => {
                                            if (v.delegation)
                                                handleDeleteManagerDelegationButtonClicked(v.delegation?.id)
                                        }}
                                        dismissText={translate("COMMON.Cancel")}
                                        confirmText={translate(
                                            "COMMON.Remove"
                                        )}
                                    >
                                        {translate(
                                            "MANAGERS.POPUPS.RemoveDelegationConfirmText"
                                        )}
                                    </WarningPopup>
                                );
                            }
                        },
                        {
                            label: translate("MANAGERS.INFOANDFORM.RemoveManager"),
                            navigateTo: "",
                            icon: <DeleteIcon />,
                            onClick: () => {
                                openPopup(
                                    <WarningPopup
                                        className="remove-manager-popup"
                                        onDismissClicked={() => { closePopup(); }}
                                        onConfirmClicked={() => { handleRemoveManagerButtonClicked("" + v.id); }}
                                        dismissText={translate("COMMON.Cancel")}
                                        confirmText={translate("COMMON.Remove")}
                                    >
                                        {translate("MANAGERS.DeleteManagerConfirmText")}
                                    </WarningPopup>
                                );
                            }
                        },
                    ]}>
                    <ScalableIconButton icon={<MoreActions />} size={24} />
                </ContextualMenu> : null,
                width: "24px",
                isMobileHeaderIcon: true
            },
        ], [openPopup, handleRemoveManagerButtonClicked, closePopup, getPeriodManagers, handleDeleteManagerDelegationButtonClicked, locationID, periodID, periodInfo.isPeriodClosed]);





        const handleFileImport = useCallback((file: File) => {

            if (!locationID || !periodID) return;
    
            uploadFileCall(`${locationID}`, "" + periodID, file)
                .then((_) => {
                    getPeriodManagers();
    
                    createNotification(
                        <Notification
                            type="success"
                            title={translate("COMMON.SYSTEMPOPUPS.Success")}
                            text={translate("GLOBALADMIN.EMPLOYEES.EmployeesAddedSuccess")}
                        ></Notification>
                    );
                })
                .catch(error => {
                    if (!error) return;
                    openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
                });
    
        }, [locationID, periodID, uploadFileCall, openPopup, getPeriodManagers, createNotification]); 




    const renderActionBtns = useMemo(() => {

       return <ConditionalRender if={!periodInfo.isPeriodClosed}>
            <Button
                text={translate("COMMON.ImportCollaborators")}
                type="secondary"
                onClick={() => openPopup(<ImportPopup onClickDownloadTemplateBtn={() =>
                    window.open(ConfigurationProvider.getConfiguration().App.BackendUrl + "/templates/Bonus_Template_Colaboradores_V2.xlsx", "_blank")}
                    onClickImportBtn={handleFileImport} />)}
                className="import-period-managers"
            />
        </ConditionalRender>

    }, [openPopup, handleFileImport, periodInfo.isPeriodClosed]);




    return (
        <PagePermissions permissions={permissions} >
            <PageLayout
                tabTitle={translate("MANAGERS.PeriodManagers")}
                pageTitle={translate("MANAGERS.PeriodManagers")}
                className="evaluation-period-managers"
                actions={renderActionBtns}
            >
                {removePeriodManagerCallIsLoading || uploadFileCallIsLoading ? <FullScreenLoader /> : null}

                <FormFieldTextInput
                    formControl={searchboxFormControl}
                    icon={<SearchIcon />}
                    placeholder={translate("COMMON.FORM.SearchPlaceholder")}
                    className="search-manager-box"
                />
                <Spacer mode="vertical" px={8}></Spacer>
                <ResponsiveDataTable
                    columnDefinitions={columns}
                    items={periodManagers || []}
                    totalitems={totalItems || 0}
                    paginationOptions={{ itemsPerPage: itemsPerPage, itemsPerPageOptions: [10, 18, 24, 30] }}
                    currentpage={currentpage}
                    isLoading={getPeriodManagersCallIsLoading}
                    onPageAndItemsChanged={handleOnPageAndItemsChanged}
                ></ResponsiveDataTable>
            </PageLayout >
        </PagePermissions>
    );
}
