import "./ContextualMenu.css";

import { useCallback, useMemo, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useOutsideComponentClickTrigger } from "../../hooks/useOutsideComponentClickTrigger";

export interface ContextualMenuItems {
  label: string;
  icon?: React.ReactNode;
  navigateTo: string;
  onClick?: () => void;
  isDisabled?: boolean;
  isVisible?: boolean;
}
export interface IContextualMenuProps {
  children?: React.ReactNode;
  items: ContextualMenuItems[];
}

export function ContextualMenu(props: IContextualMenuProps) {
  const navigate = useNavigate();

  const ref = useRef<HTMLInputElement>(null);
  const hostRef = useRef<HTMLInputElement>(null);

  useOutsideComponentClickTrigger(hostRef, () =>
    setContextualMenuPosStyle({ display: "none" })
  );

  const [contextualMenuPosStyle, setContextualMenuPosStyle] = useState<{}>({});

  const handleOnClick = useCallback(() => {
    if (hostRef.current && ref.current) {
      const hostPosTop = hostRef.current.getBoundingClientRect().top;
      const hostPosLeft = hostRef.current.getBoundingClientRect().left;
      const remainingHeight = window.innerHeight - hostPosTop;
      const remainingWidth = window.innerWidth - hostPosLeft;
      if (hostPosTop > remainingHeight) {
        if (hostPosLeft > remainingWidth) {
          setContextualMenuPosStyle({
            display: "flex",
            bottom: "calc(100% + 4px)",
            right: 0,
          });
        } else {
          setContextualMenuPosStyle({
            display: "flex",
            bottom: "calc(100% + 4px)",
            left: 0,
          });
        }
      } else {
        if (hostPosLeft > remainingWidth) {
          setContextualMenuPosStyle({
            display: "flex",
            top: "calc(100% + 4px)",
            right: 0,
          });
        } else {
          setContextualMenuPosStyle({
            display: "flex",
            top: "calc(100% + 4px)",
            left: 0,
          });
        }
      }
    }
  }, []);

  const options = useMemo(() => {
    return props.items.map((opt, idx) =>
      opt.isVisible !== false ?
        <div
          className="menu-option"
          key={idx}
          onClick={() => {
            props.items[idx].onClick
              ? props.items[idx].onClick?.()
              : navigate(opt.navigateTo);
          }}
        >
          {opt.icon ? <div className="menu-option-icon">{opt.icon}</div> : null}
          <div className="menu-option-label">{opt.label}</div>
        </div> : null
    );
  }, [props.items, navigate]);

  return (
    <div ref={hostRef} className="contextual-menu-host" onClick={handleOnClick}>
      {props.children}
      <div
        ref={ref}
        className="contextual-menu-panel"
        style={contextualMenuPosStyle}
      >
        {options}
      </div>
    </div>
  );
}
