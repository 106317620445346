import { EmployeesSalariesDetails } from "../domain/EmployeesSalariesDetails";
import { EmployeesSalariesDetailsDTO } from "../dtos/EmployeesSalariesDetailsDTO";

export function APIEmployeesSalariesDetailsToDomainMapper(apiDto: EmployeesSalariesDetailsDTO): EmployeesSalariesDetails {
    return {
        employeeSalaryId: apiDto.employeeSalaryId,
        employeeNumber: apiDto.employeeNumber,
        collaboratorName: apiDto.collaboratorName,
        employeeEmail: apiDto.employeeEmail,
        monthlySalary: apiDto.monthlySalary ?? null,
        salaryInTheQuarter: apiDto.salaryInTheQuarter ?? null,
        maximumBonusCeiling: apiDto.maximumBonusCeiling ?? null ,
    }
}
