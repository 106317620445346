import './CutReasonsPage.css'

import { useCallback, useEffect, useMemo, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup, useWindowResize } from "../../../lib/infrastructure/ui/UIServices";

import { AddCutReasonPopup } from "./popups/AddCutReasonPopup";
import { ReactComponent as AddIcon } from "../../../lib/assets/icons/add.svg";
import { Button } from "../../../lib/components/buttons/Button";
import { ColumnDefinition } from "../../../lib/components/table/TableInterfaces";
import { ConditionalRender } from "../../../lib/functional/ConditionalRender";
import { CutReasonDetails } from '../models/domain/CutReasonDetails';
import { CutReasonsQueryOptions } from '../models/dtos/CutReasonsQueryOptions';
import { CutReasonsService } from '../services/CutReasonsService';
import { EditCutReasonPopup } from './popups/EditCutReasonPopup';
import { ReactComponent as EditIcon } from "../../../lib/assets/icons/editar.svg";
import { ErrorPopup } from '../../../lib/components/popup/ErrorPopup';
import { FormFieldTextInput } from "../../../lib/components/form/form-field/FormFieldTextInput";
import { FullScreenLoader } from "../../../lib/components/loader/FullScreenLoader";
import { IOrderInfo } from "../../periods/pages/ViewEvaluationPeriodsPage";
import { IconButton } from '../../../lib/components/buttons/IconButton';
import { ReactComponent as IconEN } from "../../../lib/assets/icons/en-icon.svg";
import { ReactComponent as IconPT } from "../../../lib/assets/icons/pt-icon.svg";
import { Notification } from '../../../lib/components/notifications/Notification';
import { PageLayout } from "../../../lib/layouts/main-content/PageLayout";
import { ResponsiveDataTable } from "../../../lib/components/table/ResponsiveDataTable";
import { ScalableIconButton } from '../../../lib/components/buttons/ScalableIconButton';
import { ReactComponent as SearchIcon } from "../../../lib/assets/icons/search-icon.svg";
import { Toggle } from "../../../lib/components/form/toggle/Toggle";
import { Tooltip } from '../../../lib/components/tooltip/Tooltip';
import { WarningPopup } from "../../../lib/components/popup/WarningPopup";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { useEvaluationPeriodStatus } from '../../../lib/infrastructure/period/useEvaluationPeriodStatus';
import { useFormControl } from "../../../lib/components/form/Form";
import { useGlobalLocation } from "../../../lib/infrastructure/location/LocationServices";
import { useParams } from "react-router-dom";
import { Permission } from '../../../models/api/gate/Permission';
import { PagePermissions } from '../../../lib/infrastructure/authorization/PagePermissions';
import axios from "axios";

var cutReasonSvc = new CutReasonsService();

const permissions = [
    Permission.HUMAN_RESOURCES,
    Permission.GLOBAL_ADMIN,
    Permission.LOCATION_ADMIN];

export function CutReasonsPage() {

    const openPopup = usePopup();
    const createNotification = useCreateNotification();
    const periodInfo = useEvaluationPeriodStatus();
    const windowResize = useWindowResize();

    const locationID = useGlobalLocation();
    const { id: evaluationPeriodID } = useParams();

    const closePopup = useClosePopup();

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [currentpage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchWord, setSearchWord] = useState<string>();



    const [cutReasons, setCutReasons] = useState<CutReasonDetails[]>();
    const [totalReasons, setTotalReasons] = useState<number>();

    const [isLoadingImport, setIsLoadingImport] = useState(false);

    const [timer, setTimer] = useState<NodeJS.Timeout>();

    var searchBoxFormControl = useFormControl<string>({
        isDisabled: false,
        enableAutoValidate: false,
    });


    useEffect(() => {
        getCutReasons();
    }, [currentpage, itemsPerPage, searchWord]);


    useEffect(() => {
        if (timer) {
            clearTimeout(timer);
        }
        var responseTimeOut = setTimeout(() => {
            if (timer) {
                setCurrentPage(0);
                setSearchWord(searchBoxFormControl.value);
            }
        }, 1000);

        setTimer(responseTimeOut);
    }, [searchBoxFormControl.value]);


    const getCutReasons = useCallback(() => {
        if (locationID && evaluationPeriodID) {
            setIsLoading(true);

            var queryOptions: CutReasonsQueryOptions = {
                page: currentpage,
                pageLength: itemsPerPage,
                searchWord: searchWord,
                filterByIsActive: false,
            };

            if (locationID && evaluationPeriodID) {
                cutReasonSvc
                    .getCutReasons(locationID, evaluationPeriodID, queryOptions)
                    .then((response) => {
                        setCutReasons(response?.cutReasons)
                        setTotalReasons(response?.total)
                        setIsLoading(false);
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        if (!error || axios.isCancel(error)) return;
                        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
                    });
            }
        }
    }, [
        locationID,
        evaluationPeriodID,
        setCutReasons,
        setTotalReasons,
        setIsLoading,
        currentpage,
        itemsPerPage,
        searchWord,
        openPopup
    ]);


    const handleOnOrderChanged = useCallback(
        (orderedPriorities: IOrderInfo[]) => {
            /*   setOrderInfoColumns(orderedPriorities); */
        },
        [/* setOrderInfoColumns */]
    );


    const handleOnPageAndItemsChanged = useCallback(
        (items: number, currentPage: number) => {
            setItemsPerPage(items);
            setCurrentPage(currentPage);
        },
        [setItemsPerPage, setCurrentPage]
    );



    const onClickDisableCutReason = useCallback((cutReasonId: number) => {

        if (cutReasonId && locationID && evaluationPeriodID) {
            cutReasonSvc.editCutReasonStatus("" + locationID, evaluationPeriodID, cutReasonId, true)
                .then((_) => {
                    getCutReasons();
                    closePopup();
                    createNotification(
                        <Notification type="success" title={translate("COMMON.SYSTEMPOPUPS.Success")}
                            text={translate("CUTREASONS.POPUP.DeactivateCutReasonSuccess")}></Notification>);
                })
                .catch((error) => {
                    if (!error) return;
                    openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
                });
        }
    }, [locationID, evaluationPeriodID, getCutReasons, createNotification, openPopup, closePopup])



    const onClickEnableCutReason = useCallback((cutReasonId: number) => {

        if (cutReasonId && locationID && evaluationPeriodID) {
            cutReasonSvc.editCutReasonStatus("" + locationID, evaluationPeriodID, cutReasonId, false)
                .then((_) => {
                    getCutReasons();
                    closePopup();
                    createNotification(
                        <Notification type="success" title={translate("COMMON.SYSTEMPOPUPS.Success")}
                            text={translate("CUTREASONS.POPUP.ActivateCutReasonSuccess")}></Notification>);
                })
                .catch((error) => {
                    if (!error) return;
                    openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
                });
        }
    }, [locationID, evaluationPeriodID, getCutReasons, createNotification, openPopup, closePopup])




    const cutReasonsColumnsList: ColumnDefinition<CutReasonDetails>[] = useMemo(
        () => [
            {
                cellRenderProp: (v) => (
                    <div className="name">
                        {v.namePt ?
                            <div className="pt-pt"><IconPT /> {v.namePt}</div> : null}
                        {v.nameEn ?
                            <div className="en-en"><IconEN /> {v.nameEn}</div> : null}
                    </div>
                ),
                headerRender: translate("CUTREASONS.INFOANDFORM.Name"),
                width: "20%",
                columnKey: "cutReasonName",
                isSortable: false,
                isMobilePrimaryCell: true
            },
            {
                cellRenderProp: (v) => v.cutPercentage,
                headerRender: translate("CUTREASONS.INFOANDFORM.CutPercentageAbrev"),
                columnKey: "cutPercentage",
                isSortable: false,
                isMobilePrimaryCell: true
            },
            {
                cellRenderProp: (v) => v.companyCutFactors.length > 0 ?
                    <div className="cut-factors">
                        {v.companyCutFactors?.map((cf) =>
                            <div className="company-factor">&bull; {cf?.name}</div>
                        )}</div> : null,
                headerRender: translate("CUTREASONS.INFOANDFORM.CompanyFactorsToCut"),
                columnKey: "companyCutFactors",
                width: "30%",
                isSortable: false,
            },
            {
                cellRenderProp: (v) => v.employeeCutFactors.length > 0 ?
                    <div className="cut-factors">
                        {v.employeeCutFactors?.map((cf) =>
                            <div className="employee-factor">&bull; {cf?.name}</div>
                        )}</div> : null,
                headerRender: translate("CUTREASONS.INFOANDFORM.EmployeeFactorsToCut"),
                columnKey: "employeeCutFactors",
                width: "30%",
                isSortable: false,
            },
            {
                cellRenderProp: (v) => (
                    !periodInfo.isPeriodClosed ?
                        <div className="toggle-div">
                            <Toggle value={!v.isDisabled} />
                            <div
                                className="toggle-div-overlay"
                                onClick={(ev) => {
                                    ev.stopPropagation();
                                    if (!v.isDisabled) {
                                        openPopup(
                                            <WarningPopup
                                                className="disable-cut-reason-popup"
                                                onDismissClicked={() => {
                                                    closePopup();
                                                }}
                                                onConfirmClicked={() => {
                                                    onClickDisableCutReason(v.cutReasonId);
                                                }}
                                                dismissText={translate("COMMON.Cancel")}
                                                confirmText={translate(
                                                    "CUTREASONS.POPUP.Deactivate"
                                                )}
                                            >
                                                {translate(
                                                    "CUTREASONS.POPUP.DeactivateCutReasonConfirmText"
                                                )}
                                            </WarningPopup>
                                        );
                                    }
                                    else {
                                        openPopup(
                                            <WarningPopup
                                                onDismissClicked={() => {
                                                    closePopup();
                                                }}
                                                onConfirmClicked={() => {
                                                    onClickEnableCutReason(v.cutReasonId);
                                                }}
                                                dismissText={translate("COMMON.Cancel")}
                                                confirmText={translate(
                                                    "COMMON.Activate"
                                                )}
                                            >
                                                {translate(
                                                    "CUTREASONS.POPUP.ActivateCutReasonConfirmText"
                                                )}
                                            </WarningPopup>
                                        );
                                    }
                                }}
                            />
                        </div> : null
                ),
                width: "36px",
                columnKey: "status",
                isSortable: false,
                isMobileHeaderIcon: true
            },
            {
                cellRenderProp: (v) => (
                    !periodInfo.isPeriodClosed ?
                        < Tooltip text={translate("CUTREASONS.INFOANDFORM.EditCutReason")} >
                            <ScalableIconButton
                                icon={<EditIcon />}
                                onClick={() => {
                                    if (v.cutReasonId && locationID) {
                                        openPopup(<EditCutReasonPopup
                                            locationId={"" + locationID}
                                            periodId={"" + evaluationPeriodID}
                                            cutReasonId={"" + v.cutReasonId}
                                            onCompleted={() => {
                                                getCutReasons();
                                            }}
                                        />);
                                    }
                                }}
                                size={24}
                            />
                        </Tooltip >
                        : null
                ),
                width: "24px",
                columnKey: "stats",
                isSortable: false,
                isMobileHeaderIcon: true
            },

        ],
        [openPopup, closePopup, evaluationPeriodID, getCutReasons, locationID, onClickDisableCutReason, onClickEnableCutReason, periodInfo.isPeriodClosed]
    );

    const renderActionBtns = useMemo(() => {
        if (windowResize > 768)
            return (
                <ConditionalRender if={!periodInfo.isPeriodClosed}>
                    <Button
                        text={translate("COMMON.Create")}
                        type="primary"
                        onClick={() => {
                            if (locationID) {
                                openPopup(<AddCutReasonPopup locationId={"" + locationID} periodId={"" + evaluationPeriodID} onChangesMade={getCutReasons} />);
                            }
                        }}
                        className="create-cut-reason-btn"
                    />
                </ConditionalRender>
            );
        else
            return (
                <ConditionalRender if={!periodInfo.isPeriodClosed}>
                    <IconButton
                        type="primary"
                        icon={<AddIcon />}
                        onClick={() => {
                            if (locationID) {
                                openPopup(<AddCutReasonPopup locationId={"" + locationID} periodId={"" + evaluationPeriodID} onChangesMade={getCutReasons} />);
                            }
                        }}
                        className="create-cut-reason-btn"
                    />
                </ConditionalRender>
            );
    }, [evaluationPeriodID, locationID, openPopup, windowResize, getCutReasons, periodInfo.isPeriodClosed]);



    return (
        <PagePermissions permissions={permissions} >
            <PageLayout
                tabTitle={translate("CUTREASONS.CutReasons")}
                showBreadcrumb
                pageTitle={translate("CUTREASONS.CutReasons")}
                className="cut-reasons-page"
                actions={renderActionBtns}
            >
                <FormFieldTextInput
                    formControl={searchBoxFormControl}
                    icon={<SearchIcon />}
                    placeholder={translate("COMMON.FORM.SearchPlaceholder")}
                    className="search-box"
                />
                <ResponsiveDataTable
                    columnDefinitions={cutReasonsColumnsList}
                    items={cutReasons || []}
                    totalitems={totalReasons || 0}
                    paginationOptions={{
                        itemsPerPage: itemsPerPage,
                        itemsPerPageOptions: [10, 20, 30, 40, 50],
                    }}
                    currentpage={currentpage}
                    isLoading={isLoading}
                    onOrderChanged={handleOnOrderChanged}
                    onPageAndItemsChanged={handleOnPageAndItemsChanged}
                ></ResponsiveDataTable>

                <ConditionalRender if={isLoadingImport} >
                    <FullScreenLoader />
                </ConditionalRender>
            </PageLayout>
        </PagePermissions>
    );
}