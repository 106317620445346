import { CompanyCriteria } from "../domain/CompanyCriteria";
import { CompanyCriteriaDTO } from "../dtos/CompanyCriteriaDTO";

export function APICompanyCriteriaToDomainMapper(apiDto: CompanyCriteriaDTO): CompanyCriteria {
    return {
        id: apiDto.id,
        name: apiDto.name,
        description: apiDto.description,
        weightPercentage: apiDto.weightPercentage,
        startValue: apiDto.startValue,
        targetValue: apiDto.targetValue,
        currentValue: apiDto.currentValue,
        achievement: apiDto.achievement,
        achievementPercentage: apiDto.achievementPercentage,
        weight: apiDto.weight
    }
}