import * as qs from "qs";

import { APIEmployeeEvaluationDetailsToDomainMapper } from "../../employees/models/mappers/APIEmployeeEvaluationDetailsToDomainMapper";
import { APIEmployeeEvaluationSummaryToDomainMapper } from "../../employees/models/mappers/APIEmployeeEvaluationSummaryToDomainMapper";
import { APIEvaluationDetailsToDomainMapper } from "../models/mappers/APIEvaluationDetailsToDomainMapper";
import { APIEvaluationFeedbackToDomainMapper } from "../models/mappers/APIEvaluationFeedbackToDomainMapper";
import { APIManagerEvaluationsDTOtoDomainMapper } from "../models/mappers/APIManagerEvaluationsDTOtoDomainMapper";
import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { CutReasonsQueryOptions } from "../../cut-reasons/models/dtos/CutReasonsQueryOptions";
import { EmployeeEvaluationDetails } from "../../employees/models/domain/EmployeeEvaluationDetails";
import { EmployeeEvaluationDetailsDTO } from "../../employees/models/dtos/EmployeeEvaluationDetailsDTO";
import { EmployeeEvaluationsResponseDTO } from "../../employees/models/dtos/EmployeeEvaluationsResponseDTO";
import { EmployeeEvaluationsSummary } from "../../employees/models/domain/EmployeeEvaluationsSummary";
import { EvaluationCreateDTO } from "../models/dtos/EvaluationCreateDTO";
import { EvaluationCutBonusUpdateDTO } from "../models/dtos/EvaluationCutBonusUpdateDTO";
import { EvaluationDetails } from "../models/domain/EvaluationDetails";
import { EvaluationDetailsDTO } from "../models/dtos/EvaluationDetailsDTO";
import { EvaluationFeedback } from "../models/domain/EvaluationFeedback";
import { EvaluationFeedbackDTO } from "../models/dtos/EvaluationFeedbackDTO";
import { EvaluationUpdateDTO } from "../models/dtos/EvaluationUpdateDTO";
import { EvaluationUpdateFeedbackDTO } from "../models/dtos/EvaluationUpdateFeedbackDTO";
import { EvaluationUpdateStatusDTO } from "../models/dtos/EvaluationUpdateStatusDTO";
import { EvaluationsQueryOptions } from "../../cut-reasons/models/dtos/EvaluationsQueryOptions";
import { EvaluationsResponseDTO } from "../models/dtos/EvaluationsResponseDTO";
import { EvaluationsSummary } from "../models/domain/EvaluationsSummary";
import { HttpClient } from "../../../lib/infrastructure/http/HttpClient";
import { EvaluationsUpdateDTO } from "../models/dtos/EvaluationsUpdateDTO";
import { EvaluationBatchUpdateDTO } from "../models/dtos/EvaluationBatchUpdateDTO";
import { EvaluationsUpdateResponseDTO } from "../models/dtos/EvaluationsUpdateResponseDTO";
import { EvaluationsUpdateResponse } from "../models/domain/EvaluationsUpdateResponse";
import { EvaluationBatchUpdateStatusDTO } from "../models/dtos/EvaluationBatchUpdateStatusDTO";
import { EvaluationUpdateFeedbackGivenInPersonDTO } from "../models/dtos/EvaluationUpdateFeedbackGivenInPersonDTO";

const Route = (path: string) => {
    return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};

export class EvaluationsService {



    getEvaluations(locationId: string, periodId: string, queryOptions?: EvaluationsQueryOptions): Promise<EvaluationsSummary | null> {
        return HttpClient.sessionRequest<EvaluationsResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/EvaluationPeriods/${periodId}/Evaluations`),
            params: queryOptions,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" });
            },
        })
            .then((response) => {

                return ({
                    managerEvaluations: response.data.managerEvaluations.map(e => APIManagerEvaluationsDTOtoDomainMapper(e)),
                    totalEvaluations: response.data.totalEvaluations
                })

            })
            .catch((error) => {
                throw error;
            });
    }





    getEvaluationsByCurrentUser(locationId: string, queryOptions?: EvaluationsQueryOptions): Promise<EmployeeEvaluationsSummary> {
        return HttpClient.sessionRequest<EmployeeEvaluationsResponseDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/me/Evaluations`),
            params: queryOptions,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" });
            },
        })
            .then((response) => {

                return ({
                    evaluations: response.data.evaluations.map(e => APIEmployeeEvaluationSummaryToDomainMapper(e)),
                    totalItems: response.data.totalItems
                })
            })
            .catch((error) => {
                throw error;
            });
    }


    getMyEvaluationDetails(locationId: string, evaluationId: string): Promise<EmployeeEvaluationDetails> {
        return HttpClient.sessionRequest<EmployeeEvaluationDetailsDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/me/Evaluations/${evaluationId}`),
        })
            .then((response) => {
                return APIEmployeeEvaluationDetailsToDomainMapper(response.data)
            })
            .catch((error) => {
                throw error;
            });
    }



    createEvaluations(locationId: string, periodId: string, requestDto: EvaluationCreateDTO): Promise<void> {
        return HttpClient.sessionRequest({
            method: "POST",
            url: Route(`/api/v1/Locations/${locationId}/EvaluationPeriods/${periodId}/Evaluations`),
            data: requestDto,
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }

    getEvaluationById(locationId: string, periodId: string, evaluationId: string): Promise<EvaluationDetails | null> {
        return HttpClient.sessionRequest<EvaluationDetailsDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/EvaluationPeriods/${periodId}/Evaluations/${evaluationId}`),
        })
            .then((response) => {

                if (!response.data) return null;

                return APIEvaluationDetailsToDomainMapper(response.data)
            })
            .catch((error) => {
                throw error;
            });
    }

    updateEvaluation(locationId: string, periodId: string, evaluationId: string, requestDto: EvaluationUpdateDTO): Promise<EvaluationDetails> {
        return HttpClient.sessionRequest<EvaluationDetailsDTO>({
            method: "PUT",
            url: Route(`/api/v1/Locations/${locationId}/EvaluationPeriods/${periodId}/Evaluations/${evaluationId}`),
            data: requestDto
        })
            .then((response) => {
                return APIEvaluationDetailsToDomainMapper(response.data);
            })
            .catch((error) => {
                throw error;
            });
    }

    deleteEvaluation(locationId: string, periodId: string, evaluationId: string): Promise<void> {
        return HttpClient.sessionRequest({
            method: "DELETE",
            url: Route(`/api/v1/Locations/${locationId}/EvaluationPeriods/${periodId}/Evaluations/${evaluationId}`),
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }

    updateEvaluationStatus(locationId: string, periodId: string, evaluationId: string, requestDto: EvaluationUpdateStatusDTO): Promise<EvaluationDetails> {
        return HttpClient.sessionRequest({
            method: "PUT",
            url: Route(`/api/v1/Locations/${locationId}/EvaluationPeriods/${periodId}/Evaluations/${evaluationId}/Status`),
            data: requestDto
        })
            .then((response) => {
                return APIEvaluationDetailsToDomainMapper(response.data);
            })
            .catch((error) => {
                throw error;
            });
    }

    updateEvaluationFeedback(locationId: string, periodId: string, evaluationId: string, requestDto: EvaluationUpdateFeedbackDTO): Promise<EvaluationDetails> {
        return HttpClient.sessionRequest<EvaluationDetailsDTO>({
            method: "PUT",
            url: Route(`/api/v1/Locations/${locationId}/EvaluationPeriods/${periodId}/Evaluations/${evaluationId}/Feedback`),
            data: requestDto
        })
            .then((response) => {
                return APIEvaluationDetailsToDomainMapper(response.data);
            })
            .catch((error) => {
                throw error;
            });
    }

    getEvaluationFeedbackById(locationId: string, periodId: string, evaluationId: string): Promise<EvaluationFeedback | null> {
        return HttpClient.sessionRequest<EvaluationFeedbackDTO>({
            method: "GET",
            url: Route(`/api/v1/Locations/${locationId}/EvaluationPeriods/${periodId}/Evaluations/${evaluationId}/Feedback`),
        })
            .then((response) => {

                if (!response.data) return null;

                return APIEvaluationFeedbackToDomainMapper(response.data)
            })
            .catch((error) => {
                throw error;
            });
    }



    updateEvaluationToCutBonus(locationId: string, periodId: string, evaluationId: string, requestDto: EvaluationCutBonusUpdateDTO): Promise<EvaluationDetails> {
        return HttpClient.sessionRequest<EvaluationDetailsDTO>({
            method: "PUT",
            url: Route(`/api/v1/Locations/${locationId}/EvaluationPeriods/${periodId}/Evaluations/${evaluationId}/CutBonus`),
            data: requestDto
        })
            .then((response) => {
                return APIEvaluationDetailsToDomainMapper(response.data);
            })
            .catch((error) => {
                throw error;
            });
    }



    uploadCollaboratorsAndManagersFromXLSX(locationId: string, periodID: string, file: File): Promise<void> {

        var formData = new FormData();
        formData.append("file", file)

        return HttpClient.sessionRequest({
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept": "application/json",
            },
            url: Route(`/api/v1/Locations/${locationId}/EvaluationPeriods/${periodID}/Evaluations/FromXLSX`),
            data: formData
        })
            .then((_) => { })
            .catch((error) => {
                throw error;
            });
    }




    updateEvaluationsBatch(locationId: string, periodId: string, requestDto: EvaluationBatchUpdateDTO): Promise<EvaluationsUpdateResponse | null> {
        return HttpClient.sessionRequest<EvaluationsUpdateResponseDTO>({
            method: "PUT",
            url: Route(`/api/v1/Locations/${locationId}/EvaluationPeriods/${periodId}/Evaluations/Batch`),
            data: requestDto
        })
            .then((response) => {

                if (!response.data) return null;

                return ({
                    evaluations: response.data.evaluations.map(e => APIEvaluationDetailsToDomainMapper(e)),
                    totalEvaluations: response.data.totalEvaluations
                })
            })
            .catch((error) => {
                throw error;
            });
    }


    updateEvaluationStatusBatch(locationId: string, periodId: string, requestDto: EvaluationBatchUpdateStatusDTO): Promise<EvaluationsUpdateResponse | null> {
        return HttpClient.sessionRequest<EvaluationsUpdateResponseDTO>({
            method: "PUT",
            url: Route(`/api/v1/Locations/${locationId}/EvaluationPeriods/${periodId}/Evaluations/Status/Batch`),
            data: requestDto
        })
            .then((response) => {
                if (!response.data) return null;

                return ({
                    evaluations: response.data.evaluations.map(e => APIEvaluationDetailsToDomainMapper(e)),
                    totalEvaluations: response.data.totalEvaluations
                })
            })
            .catch((error) => {
                throw error;
            });
    }


    updateEvaluationFeedbackGivenInPerson(locationId: string, periodId: string, evaluationId: string, requestDto: EvaluationUpdateFeedbackGivenInPersonDTO): Promise<EvaluationDetails> {
        return HttpClient.sessionRequest<EvaluationDetailsDTO>({
            method: "PUT",
            url: Route(`/api/v1/Locations/${locationId}/EvaluationPeriods/${periodId}/Evaluations/${evaluationId}/Feedback/GivenInPerson`),
            data: requestDto
        })
            .then((response) => {
                return APIEvaluationDetailsToDomainMapper(response.data);
            })
            .catch((error) => {
                throw error;
            });
    }
}
