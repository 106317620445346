import { EvaluationStatusEnum } from "../models/domain/EvaluationStatusEnum";

export function NextEvaluationStatusMachine(evStatus: EvaluationStatusEnum) {
    switch (evStatus) {
        case EvaluationStatusEnum.READYTOEVALUATE:
            return EvaluationStatusEnum.CONFIRMED;
        case EvaluationStatusEnum.ONGOING:
            return EvaluationStatusEnum.CONFIRMED;
        case EvaluationStatusEnum.CONFIRMED:
            return EvaluationStatusEnum.CALIBRATED;
        case EvaluationStatusEnum.REJECTED:
            return EvaluationStatusEnum.CONFIRMED;
        default:
            return EvaluationStatusEnum.ONGOING;
    }
}


