import "./EmployeesEvaluationsPage.css";

import { useCallback, useEffect, useMemo, useState } from "react";
import { ColumnDefinition } from "../../../../lib/components/table/TableInterfaces";
import { DateTime } from "ts-luxon";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { EvaluationsQueryOptions } from "../../../cut-reasons/models/dtos/EvaluationsQueryOptions";
import { EvaluationsService } from "../../../evaluations/services/EvaluationsService";
import { PageLayout } from "../../../../lib/layouts/main-content/PageLayout";
import { ResponsiveDataTable } from "../../../../lib/components/table/ResponsiveDataTable";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../../lib/components/form/Form";
import { useGlobalLocation } from "../../../../lib/infrastructure/location/LocationServices";
import { useNavigate } from "react-router-dom";
import { usePopup } from "../../../../lib/infrastructure/ui/UIServices";
import { useServiceCallPro2 } from "../../../../lib/hooks/useServiceCall";
import { EmployeeEvaluationSummary } from "../../models/domain/EmployeeEvaluationSummary";
import { Spacer } from "../../../../lib/components/separator/Spacer";
import { FormFieldTextInput } from "../../../../lib/components/form/form-field/FormFieldTextInput";
import { ReactComponent as SearchIcon } from "../../../../lib/assets/icons/search-icon.svg";

var svc = new EvaluationsService();

interface IEmployeesEvaluationsPageProps {

}

function FinalScoreFromPercentage(percentage: number | undefined): number | undefined {
    if (percentage === undefined) { return undefined; }
    if (percentage < 26) {
        return 1;
    } else if (percentage < 51) {
        return 2;
    } else if (percentage < 71) {
        return 3;
    } else if (percentage < 90) {
        return 4;
    }

    return 5;
}

export function EmployeesEvaluationsPage(props: IEmployeesEvaluationsPageProps) {

    const navigate = useNavigate();
    const openPopup = usePopup();

    const locationID = useGlobalLocation();

    const [employeeEvaluations, setEmployeeEvaluations] = useState<EmployeeEvaluationSummary[]>([]);
    const [totalItems, setTotalItems] = useState<number>();

    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchWord, setSearchWord] = useState<string>();

    const [timer, setTimer] = useState<NodeJS.Timeout>();

    var searchBoxFormControl = useFormControl<string>({
        isDisabled: false,
        enableAutoValidate: false,
    });


    /*****************
     * SERVICE CALLS 
    ****************/


    const [getEvaluationsByCurrentUserCall, getEvaluationsByCurrentUserCallIsLoading] = useServiceCallPro2(svc, svc.getEvaluationsByCurrentUser);



    /****************************
    * DATA REQUESTS
    *****************************/



    const handleOnPageAndItemsChanged = useCallback(
        (items: number, currentPage: number) => {
            setItemsPerPage(items);
            setCurrentPage(currentPage);
        },
        [setItemsPerPage, setCurrentPage]
    );





    const getEvaluationsByCurrentUser = useCallback(() => {

        if (!locationID) return;

        var queryOptions: EvaluationsQueryOptions = {
            page: currentPage,
            pageLength: itemsPerPage,
            searchWord: searchWord,
        };

        getEvaluationsByCurrentUserCall(`${locationID}`, queryOptions)
            .then((data) => {
                setEmployeeEvaluations(data?.evaluations);
                setTotalItems(data?.totalItems);
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [locationID, getEvaluationsByCurrentUserCall, setEmployeeEvaluations, setTotalItems, openPopup, currentPage, itemsPerPage, searchWord]);



    /****************************
    * DATA MANIPULATION EFFECTS
    *****************************/



    useEffect(() => {
        getEvaluationsByCurrentUser();
    }, [currentPage, itemsPerPage, searchWord]);




    useEffect(() => {
        if (timer) {
            clearTimeout(timer);
        }
        var responseTimeOut = setTimeout(() => {
            if (timer) {
                setCurrentPage(0);
                setSearchWord(searchBoxFormControl.value);
            }
        }, 1000);
        setTimer(responseTimeOut);
    }, [searchBoxFormControl.value]);




    const columns: ColumnDefinition<EmployeeEvaluationSummary>[] = useMemo(() => [
        {
            columnKey: `periodName`,
            cellRenderProp: (v) => v.evaluationPeriodName,
            headerRender: <>{translate("PERIODS.Period")}</>,
            isMobilePrimaryCell: true,
        },
        {
            columnKey: `periodStartDate`,
            cellRenderProp: (v) => v.evaluationPeriodStartDate ? DateTime.fromISO(v.evaluationPeriodStartDate).toFormat("dd/MM/yyyy") : "",
            headerRender: <>{translate("PERIODS.INFOANDFORM.InitialDate")}</>,
        },
        {
            columnKey: `periodEndDate`,
            cellRenderProp: (v) => v.evaluationPeriodEndDate ? DateTime.fromISO(v.evaluationPeriodEndDate).toFormat("dd/MM/yyyy") : "",
            headerRender: <>{translate("PERIODS.INFOANDFORM.FinalDate")}</>,
        },
        {
            columnKey: `totalFinalScore`,
            cellRenderProp: (v) => (<>
                {FinalScoreFromPercentage(v.finalScoreWithCutPercentage)} &nbsp;
            </>),
            headerRender: <>{translate("EVALUATIONS.INFOANDFORM.TotalFinalScore")}</>,
            width: "10%"
        },
    ], []
    );



    /****************************
    * CSS & HTML
    *****************************/

    return <PageLayout
        tabTitle={translate("EVALUATIONS.Evaluations")}
        showBreadcrumb
        pageTitle={translate("EVALUATIONS.MyEvaluations")}
        className="employees-evaluations"
    >
        <FormFieldTextInput
            formControl={searchBoxFormControl}
            icon={<SearchIcon />}
            placeholder={translate("EVALUATIONS.SearchForPeriodName")}
            className="search-box"
        />
        <ResponsiveDataTable
            items={employeeEvaluations || []}
            columnDefinitions={columns}
            totalitems={totalItems || 0}
            paginationOptions={{ itemsPerPage: itemsPerPage, itemsPerPageOptions: [6, 12, 18, 24] }}
            currentpage={currentPage}
            isLoading={getEvaluationsByCurrentUserCallIsLoading}
            onPageAndItemsChanged={handleOnPageAndItemsChanged}
            onClickRow={(evaluation) => navigate(`${evaluation.id}`)}
        />
    </PageLayout >;
}