import "./CutBonusPopup.css";

import { useCallback, useEffect, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../lib/infrastructure/ui/UIServices";

import { CardContainer } from "../../../../lib/layouts/containers/card/CardContainer";
import { CutReasonDetails } from "../../../cut-reasons/models/domain/CutReasonDetails";
import { CutReasonsService } from "../../../cut-reasons/services/CutReasonsService";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { EvaluationCutBonusUpdateDTO } from "../../models/dtos/EvaluationCutBonusUpdateDTO";
import { EvaluationDetails } from "../../models/domain/EvaluationDetails";
import { EvaluationsService } from "../../services/EvaluationsService";
import { FormContainer } from "../../../../lib/layouts/containers/form/FormContainer";
import { FormFieldSelectSingle } from "../../../../lib/components/form/form-field/FormFieldSelectSingle";
import { FormFieldTextArea } from "../../../../lib/components/form/form-field/FormFieldTextArea";
import { FullScreenLoader } from "../../../../lib/components/loader/FullScreenLoader";
import { InternationalizationProvider } from "../../../../lib/infrastructure/i18n/InternationalizationProvider";
import { Notification } from "../../../../lib/components/notifications/Notification";
import { PopupActionButtons } from "../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../lib/layouts/containers/popup-header/PopupHeader";
import { Spacer } from "../../../../lib/components/separator/Spacer";
import { hasOneElementSelected } from "../../../../lib/validators/ValidateSelectSingle";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useFormControl } from "../../../../lib/components/form/Form";
import { validateWhiteSpaceStrings } from "../../../../lib/validators/ValidateWhiteSpaceStrings";
import { AllValid } from "../../../../common/validators/ValidateFormControls";

var cutReasonSvc = new CutReasonsService();
var evaluationsSvc = new EvaluationsService();
const labelSelector = (item: CutReasonDetails) => InternationalizationProvider.getCurrentLocale() === "pt-PT" ? item.namePt : item.nameEn;
const idSelector = (item: CutReasonDetails) => item.cutReasonId;

export interface ICutBonusPopupProps {
    onChange?: () => void;
    employeeName: string;
    evaluationPeriodId: string;
    evaluationId: number;
    locationId: number;
    cutReasonId?: number;
    cutReasonJustification?: string;
    isPeriodStatusInvalid: boolean,
    onCutBonusApplied: (ev: EvaluationDetails) => void;
}

export function CutBonusPopup(props: ICutBonusPopupProps) {
    const openPopup = usePopup();
    const closePopup = useClosePopup();

    const createNotification = useCreateNotification();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [onClickSubmit, setOnClickSubmit] = useState<boolean>(false);

    const cutReasonJustificationFormControl = useFormControl<string>({
        validators: [validateWhiteSpaceStrings()],
        enableAutoValidate: true,
        isDisabled: false,
    });

    const cutReasonFormControl = useFormControl<CutReasonDetails>({
        validators: [hasOneElementSelected()],
        enableAutoValidate: true,
        isDisabled: false
    });

    const [cutReasons, setCutReasons] = useState<CutReasonDetails[]>([]);



    useEffect(() => {
        if (props.locationId && props.evaluationPeriodId) {
            setIsLoading(true);

            if (props.locationId && props.evaluationPeriodId) {
                cutReasonSvc
                    .getCutReasons(props.locationId, props.evaluationPeriodId)
                    .then((response) => {

                        var filteredCutReasons = response?.cutReasons.filter((c) => c.isDisabled === false || c.cutReasonId === props.cutReasonId);

                        var previousSelected = response?.cutReasons.find((c) => c.cutReasonId === props.cutReasonId);

                        if (previousSelected)
                            cutReasonFormControl.setValue(previousSelected);

                        if (props.cutReasonJustification)
                            cutReasonJustificationFormControl.setValue(props.cutReasonJustification);

                        setCutReasons(filteredCutReasons || [])
                        setIsLoading(false);
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        if (!error) return;
                        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
                    });
            }
        }
    }, [props.locationId, props.evaluationPeriodId, cutReasonFormControl.setValue, openPopup,
    props.cutReasonId, props.cutReasonJustification, cutReasonJustificationFormControl.setValue]);



    const handleCutButtonClicked = useCallback(() => {

        if (!AllValid(cutReasonFormControl.validate(), cutReasonJustificationFormControl.validate())) return;
        if (!props.evaluationId || !props.locationId || !props.evaluationPeriodId) return;

        setOnClickSubmit(true);

        var request: EvaluationCutBonusUpdateDTO = {
            bonusCutReason: cutReasonJustificationFormControl.value || null,
            cutReasonId: cutReasonFormControl.value?.cutReasonId || null,
        }

        evaluationsSvc.updateEvaluationToCutBonus(props.locationId.toString(), props.evaluationPeriodId, props.evaluationId.toString(), request)
            .then((data) => {
                setOnClickSubmit(false);

                props.onCutBonusApplied(data);

                createNotification(<Notification type="success" title={translate("COMMON.SYSTEMPOPUPS.Success")}
                    text={translate("EVALUATIONS.POPUPS.CutEvaluationBonusSuccessMessage")}></Notification>);
                closePopup();
            }).catch((error) => {
                setOnClickSubmit(false);
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            })


    }, [props.evaluationId, props.locationId, props.evaluationPeriodId, cutReasonFormControl.value, cutReasonJustificationFormControl.validate, cutReasonJustificationFormControl.value,
    cutReasonFormControl.validate, createNotification, closePopup, props.onCutBonusApplied]);

    return (
        <PopupContainer className="popup-cut-bonus">
            <PopupHeader title={translate("EVALUATIONS.POPUPS.CutBonus")} />
            {onClickSubmit ? <FullScreenLoader /> : null}
            <PopupContent isLoading={isLoading}>
                <div className="cut-bonus">
                    <CardContainer className="employee-card">
                        <div className="small-copy employee-label">{translate("EVALUATIONS.INFOANDFORM.Employee")}</div>
                        <div className="employee-value">{props.employeeName}</div>
                    </CardContainer>
                    <FormContainer>
                        <FormFieldSelectSingle
                            label={translate("CUTREASONS.CutReason")}
                            placeholder={translate("CUTREASONS.INFOANDFORM.SelectCutReasonPlaceholder")}
                            options={cutReasons}
                            labelSelector={labelSelector}
                            idSelector={idSelector}
                            formControl={cutReasonFormControl}
                            isClearSelectionAvailable={false}
                        />
                        <FormFieldTextArea
                            formControl={cutReasonJustificationFormControl}
                            label={translate("EVALUATIONS.INFOANDFORM.Reason")}
                            placeholder={translate("EVALUATIONS.INFOANDFORM.BonusCutReasonPlaceholder")}
                        />
                    </FormContainer>
                </div>
                <Spacer mode={"vertical"} px="30" />
                <PopupActionButtons
                    buttons={[
                        {
                            text: translate("COMMON.Cancel"),
                            type: "tertiary",
                            onClick: () => closePopup(),
                        },
                        {
                            text: translate("EVALUATIONS.INFOANDFORM.CutBonus"),
                            className: "cut-bonus-btn",
                            type: "secondary",
                            onClick: handleCutButtonClicked,
                            /* isDisabled: props.isPeriodStatusInvalid */
                        },
                    ]}
                />
            </PopupContent>
        </PopupContainer>
    );
}
