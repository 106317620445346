import "./App.css";

import { InternationalizationProvider, LanguageLocale } from "./lib/infrastructure/i18n/InternationalizationProvider";
import { InternationalizationService, translate } from "./lib/infrastructure/i18n/InternationalizationService";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useClosePopup, usePopup } from "./lib/infrastructure/ui/UIServices";
import { useEffect, useLayoutEffect } from "react";
import { useGlobalLocation, useSetGlobalLocation } from "./lib/infrastructure/location/LocationServices";

import { AccessDeniedPage } from "./common/pages/access-denied/AccessDeniedPage";
import { AppIdentityRoutingModule } from "./routing-models/App.Identity.RoutingModule";
import { AppPortal } from "./lib/layouts/portal/AppPortal";
import { AppRoutingModule } from "./routing-models/App.RoutingModule";
import { ErrorPage } from "./common/pages/error/ErrorPage";
import { ErrorPopup } from "./lib/components/popup/ErrorPopup";
import { LangChangedEvent } from "./events/LangChangedEvent";
import { SplashScreen } from "./common/pages/splash-screen/SplashScreen";
import { UserSettingsService } from "./services/UserSettingsService";
import { useIdentityIsProcessing } from "./lib/infrastructure/identity/hooks/useIdentityIsProcessing";
import { useIsAuthenticated } from "@azure/msal-react";

var userSettingsService = new UserSettingsService();

function App() {

  const isProcessing = useIdentityIsProcessing();
  const isLogged = useIsAuthenticated();
  const openPopup = usePopup();



  const closePopup = useClosePopup();
  const location = useLocation();

  
  //TODO: Needs refactor
  useLayoutEffect(() => {
    closePopup();
  }, [location.pathname]);





  useEffect(() => {

    const handleLanguageChanged = () => {

      userSettingsService.selectLanguage(InternationalizationService.getLocale())
        .then((_) => { })
        .catch((error) => {
          if (!error) return;
          openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>)

        });
    };

    LangChangedEvent.attachHandler(handleLanguageChanged);

    return () => { LangChangedEvent.detachHandler(handleLanguageChanged); }
  }, []);



  useEffect(() => {

    if (isProcessing || !isLogged) return;

    userSettingsService.getSelectedLanguage()
      .then((response) => {

        if (response.value && response.value !== InternationalizationService.getLocale()) {
          InternationalizationProvider.setCurrentLocale(response.value as LanguageLocale);
        }

        return;
      })
      .catch((error) => {
        if (!error) return;
        openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>)

      });
  }, [isProcessing, isLogged]);



  if (isProcessing) return <SplashScreen />;



  return (
    <AppPortal>
      <Routes>
        <Route path="/notfound" element={<ErrorPage />} />
        <Route path="/error" element={<ErrorPage />} />
        <Route path="/splashscreen" element={<SplashScreen />} />
        <Route path="/access-denied" element={<AccessDeniedPage />} />
        <Route path="/identity/*" element={<AppIdentityRoutingModule />} />
        <Route path="/*" element={<AppRoutingModule />} />
        {/*   <Route path="test" element={<RequireAuth><AppRoutingModule /></RequireAuth>} /> */}
      </Routes>
    </AppPortal>

  );
}

export default App;
