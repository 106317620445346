import { HourRecordSummary } from "../../../hours-absence-map/models/domain/HourRecordSummary";
import { EmployeeSummary } from "./Employee";
import { EvaluationComment } from "./EvaluationComment";
import { EvaluationStatusEnum } from "./EvaluationStatusEnum";

export enum EvaluationValueState {
    NEW = 1,
    SAVED = 2,
    EDITED = 3,
}

export enum EvaluationState {
    NEW = 1,
    SAVED = 2,
    EDITED = 3,
}


export interface Evaluation {

    internalGuid: string;
    isValid: boolean;

    id: number;
    employee: EmployeeSummary;

    companyFactorTotal?: number;
    companyFactorTotalPercentage?: number;

    absentismFactorTotal?: number;
    absentismFactorTotalPercentage?: number;

    employeeFactorTotal?: number;
    employeeFactorTotalPercentage?: number;

    employeeScoreTotal?: number;
    employeeScoreTotalPercentage?: number;


    finalScore?: number;
    finalScorePercentage?: number;

    finalScoreWithCut?: number;
    finalScoreWithCutPercentage?: number;

    baseValue?: number;
    finalPayout?: number;

    factorValues: EvaluationValue[];

    bonusCutReasonId?: number;
    bonusCutReason?: string;
    cutReasonJustification?: string;

    isBonusCut: boolean;

    status: EvaluationStatusEnum;

    state: EvaluationState;

    hasProblematicAbsences: boolean; 
    hourRecords?: HourRecordSummary[];


    totalComments: number;
    lastComment: string | null;
    feedback: string | null;
    needsFeedback: boolean;
    isFeedbackGivenInPerson: boolean | null; 
    statusReason: string | null;
    rejectedby?: string | null;
    rejectedAt?: string | null;

    managerId: number;
    managerName: string;

    canEvaluate?: boolean;
    canCalibrate?: boolean;
    canRevertConfirmation?: boolean; 
}


export interface Evaluation2 {
    internalGuid: string;
    id: number;
    employee: EmployeeSummary;



    calculatedEmployeeFactorTotal?: number;
    calculatedCompanyFactorTotal?: number;
    calculatedAbsentismFactorTotal?: number;
    calculatedFinalScorePercentage?: number;
    calculatedFinalScoreWithCutPercentage?: number;
    calculatedBaseValue?: number;
    calculatedFinalPayout?: number;

    factorValues: EvaluationValue[];

    bonusCutReasonId?: number;
    bonusCutReason?: string;
    bonusCutJustification?: string;
    isBonusCut: boolean;
    status: EvaluationStatusEnum;
    state: EvaluationState;
    totalComments: number;
    lastComment: string | null;
    feedback: string | null;
    needsFeedback: boolean;
    statusReason: string | null;
    rejectedby?: EmployeeSummary;
    rejectedAt?: string | null;

    isValid: boolean;
}


export interface EvaluationValue {
    id: number;
    targetFactorId: number;
    value: number;
    isValueValid?: boolean;
    state?: EvaluationValueState
}
