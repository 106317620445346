import "./DeleteEmployeeEvaluationPopup.css";

import { useCallback } from "react";
import { useClosePopup, useCreateNotification, usePopup } from "../../../../lib/infrastructure/ui/UIServices";
import { CardContainer } from "../../../../lib/layouts/containers/card/CardContainer";
import { EvaluationDetails } from "../../models/domain/EvaluationDetails";
import { EvaluationsService } from "../../services/EvaluationsService";
import { PopupActionButtons } from "../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../lib/layouts/containers/popup-header/PopupHeader";
import { Spacer } from "../../../../lib/components/separator/Spacer";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { Evaluation } from "../../models/domain/Evaluation";
import { Notification } from "../../../../lib/components/notifications/Notification";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { useServiceCallPro } from "../../../../lib/hooks/useServiceCall";
import { FullScreenLoader } from "../../../../lib/components/loader/FullScreenLoader";
import { ReactComponent as WarningIcon } from "../../../../lib/assets/icons/warning-icon-v2.svg";
import { Info } from "ts-luxon";
import { InfoBlock } from "../../../../lib/components/info-block/InfoBlock";


var evaluationsSvc = new EvaluationsService();

export interface IDeleteEvaluationPopupProps {
    locationId: number;
    evaluationPeriodId: string;
    evaluation: Evaluation;
    onDeleteEvaluationCompleted: (ev: number) => void;
}

export function DeleteEmployeeEvaluationPopup(props: IDeleteEvaluationPopupProps) {
    const openPopup = usePopup();
    const closePopup = useClosePopup();

    const createNotification = useCreateNotification();


    const deleteEvaluationCall = useServiceCallPro(evaluationsSvc.deleteEvaluation);


    const handleDeleteEvaluationBtnClicked = useCallback(() => {

        if (!props.evaluation.id || !props.locationId || !props.evaluationPeriodId) return;

        deleteEvaluationCall.invoke("" + props.locationId, props.evaluationPeriodId, "" + props.evaluation.id)
            .then((_) => {
                props.onDeleteEvaluationCompleted(props.evaluation.id);
                createNotification(<Notification type="success" title={translate("COMMON.SYSTEMPOPUPS.Success")}
                    text={translate("EVALUATIONS.POPUPS.DeleteEvaluationSuccess")}></Notification>);
                closePopup();

            }).catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            })

    }, [props.evaluation.id, props.locationId, props.evaluationPeriodId, openPopup, deleteEvaluationCall.invoke,
        createNotification, closePopup, props.onDeleteEvaluationCompleted]);




    return (
        <PopupContainer className="popup-delete-evaluation">
            <div className="wrapper">
                <PopupHeader title={translate("EVALUATIONS.POPUPS.RemoveEvaluationTitleCAPSLOCK")} />
                {deleteEvaluationCall.isLoading ? <FullScreenLoader /> : null}
                <PopupContent>
                    <div className="popup-icon">
                        <WarningIcon />
                    </div>

                    <div className="alert-message">
                    {translate("EVALUATIONS.POPUPS.DeleteEvaluationConfirmMessage")}
                    </div>


                    <div className="delete-evaluation">
                        <CardContainer className="employee-card">
                            <div className="small-copy employee-label">{translate("EVALUATIONS.INFOANDFORM.Employee")}</div>
                            <div className="employee-value">{props.evaluation.employee.name ?? "-"}</div>

                            <Spacer mode="vertical" px={12}/>

                            <div className="small-copy employee-label">Valmet ID</div>
                            <div className="employee-value">{props.evaluation.employee.employeeID ?? "-"}</div>
                        </CardContainer>

                    </div>
                    <Spacer mode={"vertical"} px="30" />


                </PopupContent>
            </div>
            <PopupActionButtons
                buttons={[
                    {
                        text: translate("COMMON.Cancel"),
                        type: "tertiary",
                        onClick: () => closePopup(),
                    },
                    {
                        text: translate("COMMON.DeleteCAPSLOCK"),
                        className: "delete-evaluation-btn",
                        type: "secondary",
                        onClick: handleDeleteEvaluationBtnClicked,
                    },
                ]}
            />
        </PopupContainer>
    );
}
