import "./CreatePeriodPopup.css";

import { regexIntegerOrDecimals, regexOnlyPositiveIntegers, validateRegexOrEmpty } from "../../../../lib/validators/ValidateRegex";
import { useCallback, useEffect } from "react";
import { useClosePopup, useCreateNotification, usePopup, useWindowResize } from "../../../../lib/infrastructure/ui/UIServices";
import { useServiceCallPro, useServiceCallPro2 } from "../../../../lib/hooks/useServiceCall";

import { AllValid } from "../../../../common/validators/ValidateFormControls";
import { DateTime } from "luxon";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { EvaluationPeriodsService } from "../../services/EvaluationPeriodsService";
import { FormContainer } from "../../../../lib/layouts/containers/form/FormContainer";
import { FormFieldDatePicker } from "../../../../lib/components/form/form-field/FormFieldDatePicker";
import { FormFieldTextInput } from "../../../../lib/components/form/form-field/FormFieldTextInput";
import { FormSection } from "../../../../lib/components/form/section/FormSection";
import { FullScreenLoader } from "../../../../lib/components/loader/FullScreenLoader";
import { Notification } from "../../../../lib/components/notifications/Notification";
import { PopupActionButtons } from "../../../../lib/layouts/containers/popup-buttons/PopupActionButtons";
import { PopupContainer } from "../../../../lib/layouts/containers/popup-container/PopupContainer";
import { PopupContent } from "../../../../lib/layouts/containers/popup-content/PopupContent";
import { PopupHeader } from "../../../../lib/layouts/containers/popup-header/PopupHeader";
import { Spacer } from "../../../../lib/components/separator/Spacer";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { trimString } from "../../../../lib/utils/TrimString";
import { useFormControl } from "../../../../lib/components/form/Form";
import { useNavigate } from "react-router-dom";
import { validateDateTime } from "../../../../lib/validators/ValidateDateTime";
import { validateWhiteSpaceStrings } from "../../../../lib/validators/ValidateWhiteSpaceStrings";
import { replaceCommaByPoint } from "../../../../common/helpers/replaceCommaByPoint";
import { validateExternalDateTimeControl } from "../../../../common/validators/ValidateDateTimeFormControls";

var evaluationPeriodsSvc = new EvaluationPeriodsService();


export interface ICreatePeriodPopupPopup {
    locationID: string;
    periodIdToClone?: string;
    onCompletedOperations?: () => void;
}

export function CreatePeriodPopup(props: ICreatePeriodPopupPopup) {
    const navigate = useNavigate();
    const openPopup = usePopup();
    const closePopup = useClosePopup();
    const createNotification = useCreateNotification();
    const windowResize = useWindowResize();

    const nameFormControl = useFormControl<string>({
        validators: [validateWhiteSpaceStrings()],
        enableAutoValidate: true,
    });

    const initialDateFormControl = useFormControl<DateTime>({
        validators: [validateDateTime()],
        enableAutoValidate: false
    });

    const finalDateFormControl = useFormControl<DateTime>({
        validators: [validateDateTime()],
        enableAutoValidate: false
    });

    const totalPeriodDaysFormControl = useFormControl<string>({
        validators: [validateRegexOrEmpty(regexOnlyPositiveIntegers, false, "COMMON.FORM.VALIDATIONS.PositiveNumber")],
        enableAutoValidate: true,
    });

    const monthlySalaryMultiplier = useFormControl<string>({
        validators: [validateRegexOrEmpty(regexIntegerOrDecimals, false, "COMMON.FORM.VALIDATIONS.PositiveNumberOrDecimal")],
        enableAutoValidate: true,
    });

    const periodMonetaryCeiling = useFormControl<string>({
        validators: [validateRegexOrEmpty(regexIntegerOrDecimals, false, "COMMON.FORM.VALIDATIONS.PositiveNumberOrDecimal")],
        enableAutoValidate: true,
    });

    const minimumBonusPercentage = useFormControl<string>({
        validators: [validateRegexOrEmpty(regexIntegerOrDecimals, false, "COMMON.FORM.VALIDATIONS.PositiveNumberOrDecimal")],
        enableAutoValidate: true,
    });

    const evaluationsStartDayControl = useFormControl<DateTime>({
        validators: [],
        enableAutoValidate: false
    });

    const evaluationsFinalDayControl = useFormControl<DateTime>({
        validators: [validateExternalDateTimeControl(evaluationsStartDayControl)],
        enableAutoValidate: false,
        isDisabled: true
    });

    const calibrationsStartDayControl = useFormControl<DateTime>({
        validators: [],
        enableAutoValidate: false
    });

    const calibrationsFinalDayControl = useFormControl<DateTime>({
        validators: [validateExternalDateTimeControl(calibrationsStartDayControl)],
        enableAutoValidate: false,
        isDisabled: true
    });



    /*****************
     * SERVICE CALLS 
     ****************/

    const createEvaluationPeriodCall = useServiceCallPro(evaluationPeriodsSvc.createEvaluationPeriods);
    const cloneEvaluationPeriodsCall = useServiceCallPro(evaluationPeriodsSvc.cloneEvaluationPeriods);
    const [getEvaluationPeriodByIDCall, getEvaluationPeriodByIDCallIsLoading] = useServiceCallPro2(evaluationPeriodsSvc, evaluationPeriodsSvc.getEvaluationPeriodByID);



    /****************************
    * DATA REQUESTS
    *****************************/

    const handleCreatePeriodClicked = useCallback(() => {

        if (!props.locationID) return;

        if (!AllValid(nameFormControl.validate(), totalPeriodDaysFormControl.validate(), monthlySalaryMultiplier.validate(),
            periodMonetaryCeiling.validate(), minimumBonusPercentage.validate(),
            calibrationsFinalDayControl.validate(), evaluationsFinalDayControl.validate())) return;

        if (props.periodIdToClone) {

            cloneEvaluationPeriodsCall
                .invoke(props.locationID, props.periodIdToClone, {
                    name: trimString(nameFormControl.value),
                    startDate: initialDateFormControl.value?.toISO() || undefined,
                    endDate: finalDateFormControl.value?.toISO() || undefined,
                    periodDays: totalPeriodDaysFormControl.value ? parseInt(trimString(totalPeriodDaysFormControl.value)) : 0,
                    monthlySalaryMultiplier: monthlySalaryMultiplier.value ? parseFloat(trimString(replaceCommaByPoint(monthlySalaryMultiplier.value))) : 0,
                    periodMonetaryCeilingPercentage: periodMonetaryCeiling ? parseFloat(trimString(replaceCommaByPoint(periodMonetaryCeiling.value))) : 0,
                    minimumBonusPercentage: minimumBonusPercentage ? parseFloat(trimString(replaceCommaByPoint(minimumBonusPercentage.value))) : 0,

                    evaluationsStartDay: evaluationsStartDayControl.value && evaluationsFinalDayControl.value ? evaluationsStartDayControl.value?.toISO() : undefined,
                    evaluationsFinalDay: evaluationsStartDayControl.value && evaluationsFinalDayControl.value ? evaluationsFinalDayControl.value?.toISO() : undefined,

                    calibrationsStartDay: calibrationsStartDayControl.value && calibrationsFinalDayControl.value ? calibrationsStartDayControl.value?.toISO() : undefined,
                    calibrationsFinalDay: calibrationsStartDayControl.value && calibrationsFinalDayControl.value ? calibrationsFinalDayControl.value?.toISO() : undefined,
                })
                .then((_) => {
                    props.onCompletedOperations && props.onCompletedOperations();
                    createNotification(
                        <Notification
                            type="success"
                            title={translate("COMMON.SYSTEMPOPUPS.Success")}
                            text={translate("PERIODS.MESSAGES.CreatePeriodSuccess")}
                        ></Notification>
                    );
                    closePopup();
                })
                .catch((error) => {
                    if (!error) return;
                    openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
                });
        }
        else {
            createEvaluationPeriodCall
                .invoke(props.locationID, {
                    name: trimString(nameFormControl.value),
                    startDate: initialDateFormControl.value?.toISO() || undefined,
                    endDate: finalDateFormControl.value?.toISO() || undefined,
                    periodDays: totalPeriodDaysFormControl.value ? parseInt(trimString(totalPeriodDaysFormControl.value)) : 0,
                    monthlySalaryMultiplier: monthlySalaryMultiplier.value ? parseFloat(trimString(replaceCommaByPoint(monthlySalaryMultiplier.value))) : 0,
                    periodMonetaryCeilingPercentage: periodMonetaryCeiling ? parseFloat(trimString(replaceCommaByPoint(periodMonetaryCeiling.value))) : 0,
                    minimumBonusPercentage: minimumBonusPercentage ? parseFloat(trimString(replaceCommaByPoint(minimumBonusPercentage.value))) : 0,

                    evaluationsStartDay: evaluationsStartDayControl.value && evaluationsFinalDayControl.value ? evaluationsStartDayControl.value?.toISO() : undefined,
                    evaluationsFinalDay: evaluationsStartDayControl.value && evaluationsFinalDayControl.value ? evaluationsFinalDayControl.value?.toISO() : undefined,

                    calibrationsStartDay: calibrationsStartDayControl.value && calibrationsFinalDayControl.value ? calibrationsStartDayControl.value?.toISO() : undefined,
                    calibrationsFinalDay: calibrationsStartDayControl.value && calibrationsFinalDayControl.value ? calibrationsFinalDayControl.value?.toISO() : undefined,
                })
                .then((_) => {
                    props.onCompletedOperations && props.onCompletedOperations();
                    createNotification(
                        <Notification
                            type="success"
                            title={translate("COMMON.SYSTEMPOPUPS.Success")}
                            text={translate("PERIODS.MESSAGES.CreatePeriodSuccess")}
                        ></Notification>
                    );
                    closePopup();
                })
                .catch((error) => {
                    if (!error) return;
                    openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
                });
        }
    }, [props.locationID, createEvaluationPeriodCall, navigate, props.onCompletedOperations,
        openPopup, closePopup, createNotification, , minimumBonusPercentage.validate, minimumBonusPercentage.value,
    totalPeriodDaysFormControl.validate, totalPeriodDaysFormControl.value,
    periodMonetaryCeiling.validate, periodMonetaryCeiling.value,
    monthlySalaryMultiplier.validate, monthlySalaryMultiplier.value, calibrationsFinalDayControl.validate,
    evaluationsFinalDayControl.validate, nameFormControl.validate, nameFormControl.value,
    initialDateFormControl.value, finalDateFormControl.value, props.periodIdToClone,
    evaluationsStartDayControl.value, evaluationsFinalDayControl.value,
    calibrationsStartDayControl.value, calibrationsFinalDayControl.value])





    const getEvaluationPeriodByID = useCallback(() => {

        if (!props.locationID || !props.periodIdToClone) return;


        getEvaluationPeriodByIDCall(`${props.locationID}`, props.periodIdToClone)
            .then((data) => {
                nameFormControl.setValue(data?.name);
                if (data?.startDate)
                    initialDateFormControl.setValue(DateTime.fromISO(data?.startDate));
                if (data?.endDate)
                    finalDateFormControl.setValue(DateTime.fromISO(data.endDate));
                totalPeriodDaysFormControl.setValue("" + data?.periodDays);
                monthlySalaryMultiplier.setValue("" + data?.monthlySalaryMultiplier);
                periodMonetaryCeiling.setValue("" + data?.periodMonetaryCeilingPercentage);
                minimumBonusPercentage.setValue("" + data?.minimumBonusPercentage);

            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [props.locationID, props.periodIdToClone, getEvaluationPeriodByIDCall, openPopup,
    nameFormControl.setValue, initialDateFormControl.setValue, minimumBonusPercentage.setValue,
    finalDateFormControl.setValue, totalPeriodDaysFormControl.setValue,
    monthlySalaryMultiplier.setValue, periodMonetaryCeiling.setValue,]);


    /****************************
    * DATA MANIPULATION EFFECTS
    *****************************/



    useEffect(() => {
        getEvaluationPeriodByID();
    }, [props.locationID, props.periodIdToClone]);





    useEffect(() => {
        if (evaluationsStartDayControl.value)
            evaluationsFinalDayControl.setIsDisabled(false);
        if (calibrationsStartDayControl.value)
            calibrationsFinalDayControl.setIsDisabled(false);

        if (!evaluationsStartDayControl.value)
            evaluationsFinalDayControl.setIsDisabled(true);
        if (!calibrationsStartDayControl.value)
            calibrationsFinalDayControl.setIsDisabled(true);
    }, [evaluationsStartDayControl.value, calibrationsStartDayControl.value]);



    /****************************
    * CSS & HTML
    *****************************/

    return (
        <PopupContainer className="create-period-popup">
            <PopupHeader
                title={translate("PERIODS.INFOANDFORM.EvaluationPeriodData")}
            />
            <PopupContent isLoading={getEvaluationPeriodByIDCallIsLoading}>
                {createEvaluationPeriodCall.isLoading || cloneEvaluationPeriodsCall.isLoading ? <FullScreenLoader /> : null}
                <FormContainer>
                    <FormSection isInputGap>
                        <FormFieldTextInput formControl={nameFormControl} label={translate("PERIODS.INFOANDFORM.Name")} placeholder={translate("PERIODS.INFOANDFORM.NamePlaceholder")} />
                    </FormSection>

                    <FormSection isInputGap>
                        <FormFieldDatePicker formControl={initialDateFormControl} placeholder={translate("PERIODS.INFOANDFORM.InitialDatePlaceholder")} label={translate("PERIODS.INFOANDFORM.InitialDate")} maxDate={finalDateFormControl.value}
                        />
                        <FormFieldDatePicker formControl={finalDateFormControl} placeholder={translate("PERIODS.INFOANDFORM.FinalDatePlaceholder")} label={translate("PERIODS.INFOANDFORM.FinalDate")} minDate={initialDateFormControl.value} />
                    </FormSection>


                    <FormSection isInputGap>
                        <FormFieldTextInput formControl={totalPeriodDaysFormControl} label={translate("PERIODS.INFOANDFORM.PeriodDays")} placeholder={"Ex: 64"} />
                        <FormFieldTextInput formControl={monthlySalaryMultiplier} label={translate("PERIODS.INFOANDFORM.MonthlySalaryMultiplier")} placeholder={"Ex: 3.5"} />
                    </FormSection>

                    <FormSection isInputGap >
                        <FormFieldTextInput formControl={periodMonetaryCeiling} label={translate("PERIODS.INFOANDFORM.PeriodMonetaryCeilingPercentageReduced")} placeholder={"Ex: 10 (%)"} />
                        <FormFieldTextInput formControl={minimumBonusPercentage} label={translate("PERIODS.INFOANDFORM.MinimumBonusPercentage")} placeholder={"Ex: 25 (%)"} />
                    </FormSection>

                    <Spacer mode="vertical" px="5" />
                    <div className="separator">{translate("PERIODS.INFOANDFORM.DatesToNotificateManagersByEmail")}</div>

                    <FormSection isInputGap>
                        <FormFieldDatePicker
                            formControl={evaluationsStartDayControl}
                            placeholder={translate("COMMON.POPUPS.SelectDate")}
                            label={translate("PERIODS.INFOANDFORM.InitialDayToEvaluate")}

                            maxDate={evaluationsFinalDayControl.value ?? calibrationsStartDayControl.value ?? calibrationsFinalDayControl.value} />

                        <FormFieldDatePicker
                            formControl={evaluationsFinalDayControl}
                            placeholder={translate("COMMON.POPUPS.SelectDate")}
                            label={translate("PERIODS.INFOANDFORM.FinalDayToEvaluate")}
                            minDate={evaluationsStartDayControl.value}
                            maxDate={calibrationsStartDayControl.value ?? calibrationsFinalDayControl.value}
                        />
                    </FormSection>

                    <FormSection isInputGap>
                        <FormFieldDatePicker
                            formControl={calibrationsStartDayControl}
                            placeholder={translate("COMMON.POPUPS.SelectDate")}
                            label={translate("PERIODS.INFOANDFORM.InitialDayToCalibrate")}
                            minDate={evaluationsStartDayControl.value ?? evaluationsFinalDayControl.value}
                            maxDate={calibrationsFinalDayControl.value}
                        />
                        <FormFieldDatePicker
                            formControl={calibrationsFinalDayControl}
                            placeholder={translate("COMMON.POPUPS.SelectDate")}
                            label={translate("PERIODS.INFOANDFORM.FinalDayToCalibrate")}
                            minDate={calibrationsStartDayControl.value ?? evaluationsStartDayControl.value ?? evaluationsFinalDayControl.value}
                        />
                    </FormSection>

                </FormContainer>
                <Spacer mode={"vertical"} px="30" />
                <PopupActionButtons
                    buttons={[
                        {
                            text: translate("COMMON.Cancel"),
                            type: "tertiary",
                            onClick: () => closePopup(),
                        },
                        {
                            text: translate("PERIODS.CreatePeriod"),
                            type: "primary",
                            onClick: handleCreatePeriodClicked,
                        },
                    ]}
                />
            </PopupContent>
        </PopupContainer>
    );
}
