import './EvaluationHistory.css'

import { Avatar } from "../../../lib/components/avatar/Avatar";
import { DateTimeHelper } from '../../../lib/helpers/datetime/DateTime';
import { EvaluationHistoryDetails } from "../models/domain/EvaluationHistoryDetails";
import { Loader } from '../../../lib/components/loader/Loader';
import { OperationTypeEnum } from '../models/domain/OperationTypeEnum';
import { Spacer } from '../../../lib/components/separator/Spacer';
import { translate } from '../../../lib/infrastructure/i18n/InternationalizationService';
import { useCallback } from 'react';

export interface IHistoryLineProps {
    locationId: number;
    periodId: string;
    historyLines: EvaluationHistoryDetails[];
}



export function EvaluationHistory(props: IHistoryLineProps) {


    /****************************
     * DATA REQUESTS
    *****************************/

    const calculateHistoryLineText = useCallback((historyLine: EvaluationHistoryDetails) => {
        switch (historyLine.operation) {

            case OperationTypeEnum.CUT_BONUS:
                if (historyLine.cutReason && historyLine.cutReasonJustification)
                    return translate("EVALUATIONS.HISTORY.HistoryLineForCuttingBonusWithJustification", historyLine.cutReason, historyLine.cutReasonJustification);

                if (historyLine.cutReason && !historyLine.cutReasonJustification)
                    return translate("EVALUATIONS.HISTORY.HistoryLineForCuttingBonusWithoutJustification", historyLine.cutReason);

                break;

            case OperationTypeEnum.REJECT_EVALUATION:
                if (historyLine.rejectReason)
                    return translate("EVALUATIONS.HISTORY.HistoryLineForRejectingEvaluationWithJustification", historyLine.rejectReason);

                return translate("EVALUATIONS.HISTORY.HistoryLineForRejectingEvaluationWithoutJustification");

            case OperationTypeEnum.CONFIRM_EVALUATION:
                return translate("EVALUATIONS.HISTORY.HistoryLineForConfirmingEvaluation");

            case OperationTypeEnum.CALIBRATE_EVALUATION:
                return translate("EVALUATIONS.HISTORY.HistoryLineForCalibratingEvaluation");

            case OperationTypeEnum.REVERT_CUT_BONUS:
                return translate("EVALUATIONS.HISTORY.HistoryLineForRevertingCutBonus");

            case OperationTypeEnum.ADD_FEEDBACK_TO_EVALUATION:
                if (historyLine.feedback)
                    return translate("EVALUATIONS.HISTORY.HistoryLineForAddingFeedbackToEvaluation", historyLine.feedback);

                break;

            case OperationTypeEnum.UPDATE_EVALUATION_STATUS:
                return translate("EVALUATIONS.HISTORY.HistoryLineForUpdatingEvaluationStatus");

            case OperationTypeEnum.UPDATE_EVALUATION_FACTOR:
                if (historyLine.factor) {
                    return translate("EVALUATIONS.HISTORY.HistoryLineForUpdatingEvaluationFactor", historyLine.factor, "" + (historyLine.oldFactorValue || 0), "" + (historyLine.newFactorValue || 0));
                }
                break;

            case OperationTypeEnum.REVERT_EVALUATION_STATUS:
                return translate("EVALUATIONS.HISTORY.HistoryLineForRevertingEvaluationStatus");

            case OperationTypeEnum.MARK_FEEDBACK_AS_GIVEN_IN_PERSON:
                if (historyLine.isFeedbackGivenInPerson)
                    return translate("EVALUATIONS.HISTORY.HistoryLineForMarkingFeedbackAsGivenInPerson");

                return translate("EVALUATIONS.HISTORY.HistoryLineForMarkingFeedbackAsNotGivenInPerson");
            default:
                return "";
        }
    }, []);



    /****************************
     * CSS & HTML
    *****************************/





    return <>
        {props.historyLines?.map((historyLine) =>
            <div className="history">
                <Avatar className="history-avatar" name={historyLine.user.name} />
                <div className="history-info">
                    <div className="small-copy operated-by">{`${historyLine.user.name} ${DateTimeHelper.formatDateTime(historyLine.operationAt)}`}</div>
                    <div className="history-line-text">{calculateHistoryLineText(historyLine)}</div>
                    <div className="separator"></div>
                    <Spacer mode={"vertical"} px="16" />
                </div>
            </div>
        )}</>
}