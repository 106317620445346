import "./PeriodsContainer.css";

import { SubSidebar, SubSidebarUser } from "../../../lib/components/side-bar/SubSidebar";

import { ReactComponent as CutIcon } from "../../../lib/assets/icons/cut.svg";
import { ReactComponent as EmployeesIcon } from "../../../lib/assets/icons/employees.svg";
import { ReactComponent as EvaluationsAttachmentsIcon } from "../../../lib/assets/icons/documents-management.svg";
import { ReactComponent as EvaluationsIcon } from "../../../lib/assets/icons/evaluations.svg";
import { ReactComponent as HoursAndAbsenceIcon } from "../../../lib/assets/icons/working-hours.svg";
import { ReactComponent as MatrixIcon } from "../../../lib/assets/icons/matrix.svg";
import { MobileSubSidebar } from "../../../lib/components/side-bar/MobileSubSidebar";
import { Permission } from "../../../models/api/gate/Permission";
import { ReactComponent as SalariesIcon } from "../../../lib/assets/icons/salaries.svg";
import { translate } from "../../../lib/infrastructure/i18n/InternationalizationService";
import { useHasPermissions } from "../../../lib/infrastructure/authorization/useHasLocationPermissions";
import { useMemo } from "react";
import { useWindowResize } from "../../../lib/infrastructure/ui/UIServices";

interface IPeriodsContainerProps {
    children: React.ReactNode;
}

export const humanResourcesPermissions = [
    Permission.LOCATION_ADMIN,
    Permission.HUMAN_RESOURCES
];

export const adminPermissions = [
    Permission.GLOBAL_ADMIN,
    Permission.LOCATION_ADMIN,
    Permission.HUMAN_RESOURCES
];

export const basePermissions = [
    Permission.GLOBAL_ADMIN,
    Permission.LOCATION_ADMIN,
    Permission.HUMAN_RESOURCES,
    Permission.LOCATION_MANAGER
];


export function PeriodsContainer(props: IPeriodsContainerProps) {

    const { isLoadingPermissions: loadingBasePermissions, hasPermission: hasBasePermissions } = useHasPermissions(basePermissions);
    const { isLoadingPermissions: loadingAdminPermissions, hasPermission: hasAdminPermissions } = useHasPermissions(adminPermissions);

    const windowResize = useWindowResize();

    const subSideBarButtons = useMemo(() => [
        {
            icon: <MatrixIcon />,
            navigateTo: "matrix",
            name: translate("PERIODS.Matrix"),
            isHidden: loadingBasePermissions || !hasBasePermissions
        },
        {
            icon: <EvaluationsIcon />,
            navigateTo: "evaluations",
            name: translate("EVALUATIONS.Evaluations"),
            isHidden: loadingBasePermissions || !hasBasePermissions
        },
        {
            icon: <CutIcon />,
            navigateTo: "cut-reasons",
            name: translate("EVALUATIONS.BonusCutReasons"),
            isHidden: loadingAdminPermissions || !hasAdminPermissions
        },
        {
            icon: <EmployeesIcon />,
            navigateTo: "managers",
            name: translate("PERIODS.Managers"),
            isHidden: loadingAdminPermissions || !hasAdminPermissions
        },
        {
            icon: <HoursAndAbsenceIcon />,
            navigateTo: "hours-and-absence",
            name: translate("PERIODS.HoursAndAbsenceMap"),
            isHidden: loadingAdminPermissions || !hasAdminPermissions
        },
        {
            icon: <SalariesIcon />,
            navigateTo: "salaries",
            name: translate("PERIODS.Salaries"),
            isHidden: loadingAdminPermissions || !hasAdminPermissions
        },
        {
            icon: <EvaluationsAttachmentsIcon />,
            navigateTo: "attachments",
            name: translate("PERIODS.EvaluationsAttachments"),
            isHidden: loadingAdminPermissions || !hasAdminPermissions
        },
    ], [loadingBasePermissions, loadingAdminPermissions, hasBasePermissions, hasAdminPermissions]);


    const renderSubSideBar = useMemo(() => {
        return windowResize > 425 ? <SubSidebar buttons={subSideBarButtons} /> : <MobileSubSidebar buttons={subSideBarButtons} />;
    }, [windowResize, subSideBarButtons]);


    return (
        <div className="periods-content-container">
            {renderSubSideBar}
            {props.children}
        </div>
    );
}
